const globalMixin = {
  methods: {
    limitStringLength(string) {
      let resString = '';
      if (!string || string === '') {
        return resString;
      }
      const words = string.split(' ');
      for (let i = 0; i < words.length; i += 1) {
        if (resString.length + words[i].length < 200) {
          resString += `${words[i]} `;
        } else {
          break;
        }
      }
      if (resString === '') {
        resString = string.slice(0, 200);
      }
      if (string.length > 200) {
        resString += '[...]';
      }
      return resString;
    },
    capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),
    date(value) {
      if (!value || value === '') return '';
      return this.$moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    strip_extension(str) {
      return str.substr(0, str.lastIndexOf('.'));
    },
    basename(str, sep) {
      return str.substr(str.lastIndexOf(sep) + 1);
    },
    formatAmount(amount) {
      if (!amount) return '';
      return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(amount);
    },
  },
};

export default globalMixin;
