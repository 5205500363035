<template>
  <div class="container pt-0">
     <h1 class="py-3">Contacto</h1>
     <p>¿Quieres probar <strong>Legal Hound</strong>?</p>
     <p>Contacta con nosotros en <a href="mailto:contacto@legalhound.io">contacto@legalhound.io</a></p>
     <p>¡Te esperamos!</p>
    <img alt="LegalHound logo" src="@/assets/logo.png">
  </div>
</template>

<script>

export default {
  name: 'contact',
};
</script>
