<template>

<span class="tag label label-outlined" :style="tagStyle"
  @mouseenter="updateHoverState(true)"
  @mouseleave="updateHoverState(false)"
  @click="toggleTag"
  :class="[ hovered || selectedState ? 'tagHovered' : '' ]" >
    {{tag.name}}
  <a v-if="editable"><i class="remove fa fa-window-close" @click="remove()"></i></a>

</span>
</template>

<script>

export default {
  name: 'tags_list',
  props: ['tag',
    'hoverable',
    'editable',
    'selectable',
    'eventBus'],
  data() {
    return {
      hoverState: false,
      editState: false,
      selectedState: false,
      tagName: '',
    };
  },
  created() {
    if (this.eventBus) {
      this.eventBus.$on('toggle', (args) => {
        if (args.id === this.tag.id) this.selectedState = args.selectedState;
      });
    }
  },
  computed: {
    hovered() {
      return this.hoverState && this.hoverable;
    },
    selected() {
      return this.selectable && this.selectedState;
    },
    tagStyle() {
      return {
        '--currentBackground': this.tag.background_color,
        '--currentColor': this.tag.font_color,
      };
    },
  },
  methods: {
    updateHoverState(isHover) {
      if (this.hoverable) this.hoverState = isHover;
    },
    toggleTag() {
      if (this.selectable) {
        this.selectedState = !this.selectedState;
        if (this.selectedState) {
          this.$emit('selected');
        } else {
          this.$emit('deselected');
        }
      }
    },
    remove() {
      this.$emit('remove-tag');
    },
  },
};
</script>

<style scoped>

.tag {
  font-size: 14px;
  padding: 0.3em 0.4em 0.3em;
  margin: 2px 1px !important;
  display: inline-block;
  background-color: var(--currentBackground);
  color: var(--currentColor) !important;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border: 2px solid transparent;
}
.tag > span {
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-align: center;
  width: 80%;
}
.tag a {
  color: #bbb;
  cursor: pointer;
  opacity: 0.6;
  margin: 0 0 0 0.3em;
  float: right;
}
.tag a:hover {
  opacity: 1.0;
}
.tag a {
  color: #fff;
}
.tag a i {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.tagHovered {
  border: 2px solid black;
}

/*
  .tag {
    background-color: var(--currentBackground);
    color: var(--currentColor) !important;
    display: block;
    padding: 5px;
  }

  .tagHovered {
    border: 1px solid black;
  }
*/
/*
  .tag {
    background: #eee;
    border-radius: 3px 0 0 3px;
    background: var(--currentBackground);
    height: 26px;
    line-height: 26px;
    padding: 0 20px 0 23px;
    position: relative;
    margin: 0 10px 10px 0;
    text-decoration: none;
    -webkit-transition: var(--currentColor) 0.2s;
    color: var(--currentColor);
    display: table;
  }

  .tag>span {
    text-align: left;
    color: var(--currentColor);
    margin-left: 5px;
  }

  .tagHovered {
    border: 2px solid black;
  }
*/
</style>
