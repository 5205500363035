<template>
  <modal name="confirmAccountDelete" class="warningBox" :height="'350px'">
    <div slot="top-right">
    </div>
    <div class="grid">
      <div class="col-12">
        <div class="warningIcon">
          <i class="fa fa-exclamation-circle fa-5x"></i>
        </div>
      </div>
      <div class="col-12">
        <div class="Box Box--danger">
          <div class="Box-body">
            <div class="input">
              <label for="confirmText">Por favor, escribe "confirmar" para cancelar la cuenta. Esta acción <strong> NO ES REVERSIBLE</strong>. Se perderán todos los datos asociados a la cuenta</label>
              <input type="text"
              id="confirmText"
              placeholder="confirmar"
              name="confirmText" v-model="confirmText"
              v-validate ="'required|confirm2'"  >
              <span v-show="errors.has('confirmText')" class="invalid">{{ errors.first('confirmText') }}</span>
            </div>
              <a class="btn btn-danger" @click="submitDeleteAccount()" >Cancelar cuenta</a>
              <a class="btn btn-success" @click="$modal.hide('confirmAccountDelete')" >Cerrar</a>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'confirmAccountDelete',
  props: {
    message: String,
    buttonText: String,
  },
  data() {
    return {
      confirmText: '',
    };
  },
  created() {
    const self = this;

    this.$validator.extend('confirm2', {
      getMessage() {
        return 'Debes introducir la palabra confirmar para poder cancelar la cuenta';
      },
      validate() {
        return self.confirmText === 'confirmar';
      },
    });
  },
  methods: {
    ...mapActions('usersModule', {
      deleteAccount: 'deleteAccount',
    }),
    submitDeleteAccount() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.deleteAccount();
          this.$modal.hide('deleteSubscriptionWarningBox');
        }
      });
    },
  },
};
</script>
<style lang="scss">

.warningBox a {
  margin-left: 10px !important;
}

.warningIcon {
  font-size: 24px;
  text-align: center;
  margin-top: 30px;
  color: #FACEA8;
}

</style>
