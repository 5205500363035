<template>
  <div id="invoices" class="col-12">
      <table headers=",Issue date,Total,Amount paid,Amount due,Status,Paid on,Due date / Period," class="table">
        <thead>
          <tr>
            <th id=""><span>Factura</span></th>
            <th id="issue-date"><span>Fecha Emisión</span></th>
            <th id="due-date-period"><span>Fecha Vencimiento</span></th>
            <th id="total"><span>Subtotal</span></th>
            <th id="total"><span>Total</span></th>
            <th id="starting_balance"><span>Saldo aplicado</span></th>
            <th id="amount-due"><span>Cantidad Debida</span></th>
            <th id="amount-paid"><span>Cantidad Pagada</span></th>
            <th id="amount-paid"><span>Cantidad Pendiente</span></th>
            <th id="status"><span>Status</span></th>
            <th id="paid-on"><span>Fecha Pago</span></th>
            <th id="pending-upgrade" v-if="user.pending_subscription_upgrade"><span></span></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="fetchingInvoices">
          <tr>
            <td colspan="12">
              <i class="fas fa-spinner fa-3x fa-spin"></i>
            </td>
          </tr>
          </template>
          <template v-else>
            <tr v-for="invoice in invoices" v-bind:key="invoice.id">
              <td> <a class="link" @click="downloadInvoice(invoice.issue_date, invoice.number, invoice.id)"> {{ invoice.number }} </a> </td>
              <td> {{ invoice.issue_date | moment('DD/MM/YYYY')}} </td>
              <td> {{ invoice.due_date | moment('DD/MM/YYYY')}} </td>

              <td v-if = "invoice.currency === 'eur'"> {{ $n(stripeMoney(invoice.subtotal), 'currency', 'es-ES') }}</td>
              <td v-if = "invoice.currency === 'dollar'"> {{ $n(stripeMoney(invoice.subtotal), 'currency', 'en-US') }}</td>

              <td v-if = "invoice.currency === 'eur'"> {{ $n(stripeMoney(invoice.total), 'currency', 'es-ES') }}</td>
              <td v-if = "invoice.currency === 'dollar'"> {{ $n(stripeMoney(invoice.total), 'currency', 'en-US') }}</td>

              <td v-if = "invoice.currency === 'eur'"> {{ $n(stripeMoney(invoice.starting_balance), 'currency', 'es-ES') }}</td>
              <td v-if = "invoice.currency === 'dollar'"> {{ $n(stripeMoney(invoice.starting_balance), 'currency', 'en-US') }}</td>

              <td v-if = "invoice.currency === 'eur'"> {{ $n(stripeMoney(invoice.amount_due), 'currency', 'es-ES') }}</td>
              <td v-if = "invoice.currency === 'dollar'"> {{ $n(stripeMoney(invoice.amount_due), 'currency', 'en-US') }}</td>

              <td v-if = "invoice.currency === 'eur'"> {{ $n(stripeMoney(invoice.amount_paid), 'currency', 'es-ES') }}</td>
              <td v-if = "invoice.currency === 'dollar'"> {{ $n(stripeMoney(invoice.amount_paid), 'currency', 'en-US') }}</td>

              <td v-if = "invoice.currency === 'eur'"> {{ $n(stripeMoney(invoice.amount_remaining), 'currency', 'es-ES') }}</td>
              <td v-if = "invoice.currency === 'dollar'"> {{ $n(stripeMoney(invoice.amount_remaining), 'currency', 'en-US') }}</td>

              <td v-bind:class="invoiceStatusStyle(invoice.status)"> {{ invoice.status.toUpperCase() }} </td>
              <td>
                <template v-if="user.pending_payment_method && invoice.id === user.pending_invoice_id">
                  Por favor, actualiza tu método de pago
                </template>
                <template v-else-if="user.pending_subscription_upgrade && invoice.id === user.pending_invoice_id">
                  <button class="btn btn-success" @click="payInvoice(invoice.id)"> Pagar factura </button>
                </template>
                <template v-else>
                  {{ invoice.paid_at | moment('DD/MM/YYYY')}}
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import subscriptionsService from '../../_services/subscriptions.service';

export default {

  computed: {
    ...mapState({
      user: state => state.usersModule.user,
      invoices: state => state.subscriptionsModule.invoices,
      fetchingInvoices: state => state.subscriptionsModule.fetchingInvoices,
    }),
  },
  created() {
    this.indexInvoices();
  },
  methods: {
    ...mapActions('subscriptionsModule', {
      indexInvoices: 'indexInvoices',
      payInvoice: 'payInvoice',
    }),
    downloadInvoice(issue_date, number, id) {
      const vue = this;
      subscriptionsService.downloadInvoice(id).then((response) => {
        const date = vue.$moment.unix(issue_date);

        // handle success
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${date.format('YYYYMMDD')}_${number}.pdf`); // or any other extension
        document.body.appendChild(link);
        link.click();
      })
        .catch((error) => {
          // handle error
          console.log(error);
        });
    },
    stripeMoney(amount) {
      return amount / 100;
    },
    invoiceStatusStyle(status) {
      if (status === 'paid') return 'text-green';
      return 'text-red';
    },
  },
};
</script>

<style scoped>
  .table thead > tr > th { border-top:none; }
  .link {
    cursor: pointer;
    color: #697788;
    display: inline-block;
  }

  .link:hover {
    text-decoration: underline !important;
  }

  .text-green {
    color: #28a745 !important;
  }

  .text-red {
    color: red !important;
  }

</style>
