<template>
  <div id="procurement">
    <div class="container pb-0">
      <div class="input-group">
        <i class="fas fa-search fa-2x pt-2 px-2 border" @click="startSearch()"></i>
        <input type="text" class="form-control"
                id="search" placeholder="Buscar"
                v-model="searchTerms" @keyup.enter="startSearch()" v-debounce:500ms="startAutomaticSearch">
          <button  class="btn btn-secondary ml-3"
            @click="filtersVisibility = !filtersVisibility"
            aria-controls="procurement-filters-collapse"
            :aria-expanded="filtersVisibility ? 'true' : 'false'">
            <i class="fas fa-filter ml-1" ></i>
             Añadir filtro
          </button>
        <button  v-if="total_results<=0" class="disabled p-2 ml-3"> Guardar Alerta </button>
        <button v-else class="btn btn-secondary ml-3" @click="$bvModal.show('new-alert-modal')"> Guardar Alerta </button>
      </div>
      <p class ="ml-0 mt-2">Encontradas {{total_results}} licitaciones</p>
    </div>
    <div class="row p-0">
      <div class="col-12">
        <b-collapse v-model="filtersVisibility" class="collapse-tags" id="procurement-filters-collapse">
          <procurement-filters
          ref="procurement_filters"
          :currentFilters="currentFilters"
          v-on:setFilters="setFilters($event)"
          />
        </b-collapse>
      </div>
    </div>
    <div v-if="total_results>0">
      <div class="row">
        <div class="col-6">
          <paginationComponent
            id="procurement-pagination"
            :currentPage="current_page"
            :totalPages="total_pages"
            v-on:next-page-procurement-pagination="changePage($event)">
          </paginationComponent>
        </div>
        <div class="col-6 text-right">
          <b-form-select v-model="selectedSort" :options="sortOptions" class="search-select"></b-form-select>
          <button v-b-tooltip.hover title="Exportar a excel" class="btn btn-excel ml-2 mr-2" @click="openExcelExportModal()">
            <i class="fas fa-file-excel"/> Exportar
          </button>
        </div>
      </div>
      <div class ="row">
        <div class="col-12">
          <procurementResultsTable :projects="projects"/>
        </div>
        <paginationComponent
          id="procurement-pagination"
          :currentPage="current_page"
          :totalPages="total_pages"
          v-on:next-page-procurement-pagination="changePage($event)"/>
      </div>
    </div>
    <newAlertModal :searchTerms="currentSearchTerms"
      :filters="currentFilters"
      alert_type="procurement"/>
    <b-modal
      ref="excel-export-modal"
      hide-footer>
       <template #modal-title>
        <h2 class="corporative-principal">Exportar licitaciones a Excel </h2>
      </template>
        Licitaciones a exportar:
        <b-form-select class="my-2" v-model="exportNotificationsNumber" :options="exportNotificationOptions"></b-form-select>
        <button class="btn btn-secondary mt-2" @click="exportExcel()"> Exportar  <i class="fas fa-file-export"></i></button>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import excelExporter from '@/_helpers/excel_exporter';
import procurementService from '@/_services/procurement.service';
import alertService from '@/_services/alert.service';

import newAlertModal from '@/components/alerts/new_alert_modal.vue';
import paginationComponent from '@/components/helpers/pagination.vue';
import procurementFilters from './procurement_filters.vue';
import procurementResultsTable from './results_table.vue';

export default {
  name: 'searchProcurementComponent',
  props: ['alert'],
  components: {
    procurementFilters,
    newAlertModal,
    procurementResultsTable,
    paginationComponent,
  },
  data() {
    return {
      selectedSources: [],
      searchTerms: '',
      filtersVisibility: true,
      selectedSort: 0,
      sortOptions: [
        { value: 0, text: 'Ordernar por relevancia' },
        { value: 1, text: 'Ordernar por fecha descendente' },
        { value: 2, text: 'Ordernar por fecha ascendente' },
        { value: 3, text: 'Ordernar por valor estimado descendente' },
        { value: 4, text: 'Ordernar por valor estimado ascendente' },
      ],
      exportNotificationsNumber: 25,
      exportNotificationOptions: [
        { value: 25, text: '25 (Página actual)' },
        { value: 50, text: '50' },
        { value: 75, text: '75' },
        { value: 100, text: '100' },
        { value: 1000, text: 'Todas (máximo 1000)' },
      ],
    };
  },
  computed: {
    ...mapState({
      projects: state => state.procurementModule.projects,
      total_results: state => state.procurementModule.total_results,
      total_pages: state => state.procurementModule.total_pages,
      current_page: state => state.procurementModule.current_page,
      searching: state => state.procurementModule.searching,
      currentSearchTerms: state => state.procurementModule.searchTerms,
      currentFilters: state => state.procurementModule.filters,
      newSearchFlag: state => state.procurementModule.newSearchFlag,
      currentSortField: state => state.procurementModule.currentSortField,
      currentSortOrder: state => state.procurementModule.currentSortOrder,
    }),
    currentSort() {
      if (this.selectedSort === 1) {
        return {
          sortField: 'date',
          sortOrder: 'desc',
        };
      } if (this.selectedSort === 2) {
        return {
          sortField: 'date',
          sortOrder: 'asc',
        };
      } if (this.selectedSort === 3) {
        return {
          sortField: 'estimated_overall_contract_amount',
          sortOrder: 'desc',
        };
      } if (this.selectedSort === 4) {
        return {
          sortField: 'estimated_overall_contract_amount',
          sortOrder: 'asc',
        };
      }
      return {
        sortField: 'score',
        sortOrder: 'desc',
      };
    },
  },
  mounted() {
    const { alert_id } = this.$route.query;

    if (alert_id) {
      alertService.getAlert(alert_id).then((response) => {
        const { filters, searchTerms } = response.data;
        this.searchTerms = searchTerms;
        this.$store.commit('procurementModule/setSearchTerms', { searchTerms, newSearchFlag: false, root: true });
        this.setFilters({ filters });
        this.$nextTick(() => {
          this.$refs.procurement_filters.setFiltersFromCurrent();
        });
      });
    } else {
      this.searchTerms = this.currentSearchTerms;
    }
  },
  methods: {
    ...mapActions('procurementModule', {
      search: 'search',
      changePage: 'changePage',
      setFilters: 'setFilters',
    }),
    exportExcel() {
      const filename = `${this.$moment().format('YYYY_MM_DD')}_exportacion_licitaciones.xlsx`;

      const searchParams = {
        searchTerms: this.currentSearchTerms,
        sortField: this.currentSortField,
        sortOrder: this.currentSortOrder,
        filters: this.currentFilters,
        pageSize: this.exportNotificationsNumber,
      };

      procurementService.search(searchParams).then((response) => {
        const { results } = response.data;
        excelExporter.saveProcurementProjects(results, filename);
      });
    },
    openExcelExportModal() {
      this.$refs['excel-export-modal'].show();
    },
    startSearch({ newSearchFlag = true } = {}) {
      this.search({ searchTerms: this.searchTerms, ...this.currentSort, newSearchFlag });
    },
    startAutomaticSearch() {
      if (this.searchTerms === '') {
        return;
      }
      this.search({
        searchTerms: this.searchTerms,
        ...this.currentSort,
      });
    },
  },
  watch: {
    projects() {
      if (this.projects.length === 0) return;
      if (this.newSearchFlag) {
        this.filtersVisibility = false;
      }
    },
    selectedSort() {
      this.startSearch({ newSearchFlag: false });
    },
  },
};
</script>

<style scoped>

#procurement{
  text-align: left;
}
input{
  max-width: 40%;
  font-size: 1.5em;
}

.disabled{
  cursor: default;
  background-color: #ccc;
  color: white;
}
/* Style to create scroll bar in dropdown */
.scrollable-dropdown {
  height: auto;
  max-height: 320px;
  /* Increase / Decrease value as per your need */
  overflow-x: hidden;
}

.hidden {
  visibility: hidden;
}
.search-results{
    overflow-y: scroll;
    height:100vh;
    padding: 0;
}
.not-collapsed  {
 background-color: #4953CF !important;

}
.fa-filter:focus {
 outline: 0px !important;
}
.fa-search{
  color: #6C757D;
  cursor: pointer;
}


</style>r
