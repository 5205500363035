import countries from '@/_config/countries.json';
import cpv_codes from '@/_config/cpv_codes.json';
import provinces from '@/_config/provinces.json';

const alertFiltersMixin = {
  methods: {
    country(code) {
      const country = countries.find(c => c.code === code);
      if (country) return country.name;

      return '';
    },
    cpv_codes(codes) {
      let cpv_code_str = '';

      for (let i = 0; i < codes.length; i += 1) {
        const code = codes[i];
        const cpv_code = cpv_codes.find(c => c.code === code);
        if (cpv_code) {
          if (cpv_code_str !== '') cpv_code_str += ', ';
          cpv_code_str += `${cpv_code.code} – ${cpv_code.value}`;
        }
      }
      return cpv_code_str;
    },
    region_names(postal_codes) {
      let region_name = '';

      for (let i = 0; i < postal_codes.length; i += 1) {
        const postal_code = postal_codes[i];
        const region = provinces.find(r => r.code === postal_code);
        if (region) {
          if (region_name !== '') region_name += ', ';
          region_name += region.name;
        }
      }
      return region_name;
    },
  },
};

export default alertFiltersMixin;
