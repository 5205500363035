/* eslint-disable import/prefer-default-export */
/* eslint no-shadow: ["error", {"allow": ["state"]}] */
/* eslint no-param-reassign: ["error", { "props": true,
                                        "ignorePropertyModificationsFor": ["state"] }] */
import Vue from 'vue';
import officialBulletinsService from '../_services/official_bulletins.service';

const state = {
  results: [],
  searching: false,
  searchTerms: '',
  total_results: 0,
  current_page: 1,
  total_pages: 0,
  currentSortField: 'score',
  currentSortOrder: 'desc',
  filters: {
    sources: [],
  },
  newSearchFlag: true,
  updateFiltersUI: false,
};

const actions = {
  changePage({ dispatch }, page) {
    dispatch('search', {
      searchTerms: state.searchTerms,
      newSearchFlag: false,
      page,
      sortField: state.currentSortField,
      sortOrder: state.currentSortOrder,
    });
  },
  search({ commit, state }, {
    searchTerms, newSearchFlag = true, page = 1, sortField = 'score', sortOrder = 'desc',
  }) {
    commit('setSearchTerms', { searchTerms, newSearchFlag });

    const params = {
      searchTerms,
      page,
      sortField,
      sortOrder,
      filters: state.filters,
    };

    // eslint-disable-next-line consistent-return
    return officialBulletinsService.search(params).then(
      (response) => {
        const { results } = response.data;
        const { total_results, total_pages, current_page } = response.data.metadata;
        commit('storeResults', {
          results, total_results, total_pages, current_page, sortField, sortOrder,
        });
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  setFilters({ commit, dispatch }, { filters }) {
    commit('storeFilters', { filters });
    dispatch('search', { searchTerms: state.searchTerms, newSearchFlag: false });
  },
};

const mutations = {
  setSearchTerms(state, { searchTerms, newSearchFlag }) {
    state.searchTerms = searchTerms;
    state.newSearchFlag = newSearchFlag;
  },
  storeFilters(state, { filters }) {
    Vue.set(state, 'filters', filters);
  },
  storeResults(state, {
    results, total_results, total_pages, current_page, sortField, sortOrder,
  }) {
    state.total_results = total_results;
    state.total_pages = total_pages;
    state.current_page = current_page;
    state.currentSortField = sortField;
    state.currentSortOrder = sortOrder;
    Vue.set(state, 'results', results);
  },
  cleanSearch(state) {
    Vue.set(state, 'results', []);
    Vue.set(state, 'filters', {
      sources: [],
    });
    state.total_results = 0;
    state.searchTerms = '';
    state.searching = false;
  },
  updateFiltersUI(state) {
    state.updateFiltersUI = !state.updateFiltersUI;
  },
  changePage({ dispatch }, page) {
    dispatch('search', { searchTerms: state.searchTerms, newSearchFlag: false, page });
  },
};

const getters = {

};

const officialBulletinsModule = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default officialBulletinsModule;
