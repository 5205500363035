<template>
<div id="subscriptionPricing">
  <div class="flex-container">
    <div class="flex-item">
      <ul class="package">
        <li class="header">10€/usuario/mes</li>
        <li class="gray">Para los primeros 10 usuarios<br> <br></li>
      </ul>
    </div>
    <div class="flex-item">
      <ul class="package">
        <li class="header">7€/usuario/mes</li>
        <li class="gray">Por cada usuario adicional<br> <br></li>
      </ul>
    </div>
<!--
    <div class="flex-item">
      <ul class="package active">
        <li class="header">5€/usuario/mes</li>
        <li class="gray">Para los siguientes 150 usuarios <br> <br></li>
      </ul>
    </div>
    <div class="flex-item">
      <ul class="package">
        <li class="header">2€/usuario/mes</li>
        <li class="gray">Por cada usuario adicional superados los 250</li>
      </ul>
    </div>
-->
  </div>
</div>
</template>

<script>

export default {
  name: 'SubscriptionPricing',
};

</script>

<style scoped>
* {
  box-sizing: border-box;
  font-family: -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif;
  margin: 0;
  padding: 0;
}

.flex-container {
  display: flex;
  padding: 1em;
}

.flex-item {
  flex: 1;
  width: 0;
}

.flex-item:not(:last-child) {
  margin-right: 1em;
}

.package {
  border: 1px solid #eee;
  list-style-type: none;
  margin: 0;
  padding: 0;
  transition: 0.25s;
}

.package:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.025);
}

.package .header {
  background-color: #333;
  color: #fff;
  font-size: 1.2em;
}

.package .highlight {
  background-color: #29b6f6;
}

.package li {
  background-color: #fff;
  border-bottom: 1px solid #eee;
  padding: 1.2em;
  text-align: center;
}

.package .gray {
  background-color: #eee;
  font-size: 1em;
}

button {
  background-color: #29b6f6;
  border: none;
  border-radius: .15em;
  color: #fff;
  cursor: pointer;
  padding: .75em 1.5em;
  font-size: 1em;
}

@media only screen and (max-width: 600px) {
  .flex-container {
    flex-wrap: wrap;
  }

  .flex-item {
    flex: 0 0 100%;
    margin-bottom: 1em;
    width: 100%;
  }

  .package:hover {
    box-shadow: none;
    transform: none;
  }

  button {
    padding: .75em 1.5em;
  }
}

</style>
