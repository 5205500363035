import axios from '../_helpers/axios';

function getNotification(id) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/notifications/${id}`).then(res => res);
}

function indexNotifications(params) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/notifications`, { params }).then(res => res);
}

function updateNotification({ id, processed, starred }) {
  return axios.put(`/${process.env.VUE_APP_API_VERSION}/notifications/${id}`,
    { // Payload
      ...(processed !== null && { processed }),
      ...(starred !== null && { starred }),
    }).then(res => res);
}

function updateNotifications({ ids, processed }) {
  return axios.put(`/${process.env.VUE_APP_API_VERSION}/notifications/update_batches`,
    { // Payload
      ids,
      processed,
    }).then(res => res);
}


const notificationService = {
  getNotification,
  indexNotifications,
  updateNotification,
  updateNotifications,
};

export default notificationService;
