<template>
  <div ref="document" :id="`document_${_uid}`" style="height: 80vh;">
    <div v-html="currentDocument">
    </div>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import { mapState, mapGetters } from 'vuex';
import axios from 'axios';
import pdf2htmlEX from '../../assets/js/pdf2htmlEX';
import stopwords from '../../_config/stopwords.json';

export default {
  name: 'pdfViewer',
  data() {
    return {
      pages: [],
      pages_loading: [],
      pages_matches: [],
      page_map: {},
      loaded_pages: 0,
      container: '',
      outline: '',
      loading_indicator: '',
      scale: 1.5,
      /*
      * index of the active page (the one with largest visible area)
      * which estimates the page currently being viewed
      */
      cur_page_idx: 0,
      /*
      * index of the first visible page
      * used when determining current view
      */
      first_page_idx: 0,
      currentMatchIndex: -1,
      currentSearchMatchIndex: -1,
    };
  },
  created() {
    /* hide all pages before loading, will reveal only visible ones later */
  },
  mounted() {
    if (this.currentDocument !== '') {
      this.pages = [];
      this.pages_loading = [];
      this.pages_matches = [];
      this.page_map = {};
      this.container = '';
      this.outline = '';
      this.cur_page_idx = 0;
      this.first_page_idx = 0;
      this.currentMatchIndex = -1;
      this.currentSearchMatchIndex = -1;
      this.$emit('match-index', this.currentMatchIndex);

      this.$nextTick(() => {
        this.init_after_loading_content();
      });
    }
  },
  computed: {
    ...mapState('documents', [
      'currentDocument',
      'currentDocumentPages',
      'currentDocumentSource',
      'currentDocumentId',
      'currentHighlightTriggers',
      'currentMainTriggers',
      'currentDocumentParagraphs',
      'currentMatches',
      'currentSearchMatches']),
    ...mapGetters({
      totalMatches: 'documents/totalMatches',
      totalSearchMatches: 'documents/totalSearchMatches',
    }),
  },
  watch: {
    currentDocument: {
      handler() {
        this.pre_hide_pages();
        this.pages = [];
        this.pages_loading = [];
        this.pages_matches = [];
        this.page_map = {};
        this.container = '';
        this.outline = '';
        this.cur_page_idx = 0;
        this.first_page_idx = 0;
        this.currentMatchIndex = -1;
        this.$emit('match-index', this.currentMatchIndex);
        this.$nextTick(() => {
          // We make sure to init everything after we have rendered the initial document
          this.init_after_loading_content();
        });
      },
      deep: true,
    },
    currentSearchMatches: {
      handler() {
        // Clean old search marks
        const old_marks = this.$refs.document.querySelectorAll("span[class='search_marked']");
        for (let i = 0; i < old_marks.length; i += 1) {
          const plainTextNode = document.createTextNode(old_marks[i].innerHTML);
          old_marks[i].replaceWith(plainTextNode);
        }

        for (let i = 0; i < this.pages.length; i += 1) {
          this.highlight_searches_page(i);
        }

        this.currentSearchMatchIndex = -1;
        this.scrollToSearchMatch(1);
      },
      deep: true,
    },
  },
  methods: {
    find_pages() {
      const new_pages = [];
      const new_page_map = {};
      const pages_matches = [];
      if (!this.container) return;

      const nodes = this.container.childNodes;
      for (let i = 0, l = nodes.length; i < l; i += 1) {
        const cur_node = nodes[i];
        if ((cur_node.nodeType === Node.ELEMENT_NODE)
            && cur_node.classList.contains(pdf2htmlEX.CSS_CLASS_NAMES.page_frame)) {
          const p = new pdf2htmlEX.Page(cur_node);
          new_pages.push(p);
          pages_matches.push(0);
          new_page_map[p.num] = new_pages.length - 1;
        }
      }
      this.pages = new_pages;
      this.pages_matches = pages_matches;
      this.page_map = new_page_map;
    },
    /**
     * generate the hash for the current view
     */
    get_current_view_hash() {
      const detail = [];
      const cur_page = this.pages[this.cur_page_idx];

      detail.push(cur_page.num);
      detail.push('XYZ');

      let cur_pos = cur_page.view_position();
      cur_pos = pdf2htmlEX.transform(cur_page.ictm, [cur_pos[0], cur_page.height() - cur_pos[1]]);
      detail.push(cur_pos[0] / this.scale);
      detail.push(cur_pos[1] / this.scale);
      detail.push(this.scale);

      return JSON.stringify(detail);
    },
    /**
     * @param{Node} ele
     */
    get_containing_page(ele) {
      /* get the page obj containing obj */
      while (ele) {
        if ((ele.nodeType === Node.ELEMENT_NODE)
            && ele.classList.contains(pdf2htmlEX.CSS_CLASS_NAMES.page_frame)) {
          /*
          * Get original page number and map it to index of pages
          * TODO: store the index on the dom element
          */
          const pn = this.get_page_number(/** @type{Element} */(ele));
          const pm = this.page_map;
          return (pn in pm) ? this.pages[pm[pn]] : null;
        }
        ele = ele.parentNode;
      }
      return null;
    },
    get_page_number(ele) {
      return parseInt(ele.getAttribute('data-page-no'), 16);
    },
    init_after_loading_content() {
      this.sidebar = this.$refs.document.querySelector(`#${pdf2htmlEX.CONFIG.sidebar_id}`);
      this.outline = this.$refs.document.querySelector(`#${pdf2htmlEX.CONFIG.outline_id}`);
      this.container = this.$refs.document.querySelector(`#${pdf2htmlEX.CONFIG.container_id}`);

      // eslint-disable-next-line prefer-destructuring
      this.loading_indicator = this.$refs.document.getElementsByClassName(pdf2htmlEX.CONFIG.loading_indicator_cls)[0];
      // Open the outline if nonempty
      let empty = true;

      if (this.outline) {
        const nodes = this.outline.childNodes;
        for (let i = 0, l = nodes.length; i < l; i += 1) {
          const cur_node = nodes[i];
          if (cur_node.nodeName.toLowerCase() === 'ul') {
            empty = false;
            break;
          }
        }
      }

      if (!empty) this.sidebar.classList.add('opened');

      this.find_pages();
      // do nothing if there's nothing
      if (this.pages.length === 0) return;

      // disable dragging of background images
      pdf2htmlEX.disable_dragstart(this.$refs.document.getElementsByClassName(pdf2htmlEX.CSS_CLASS_NAMES.background_image));

      if (pdf2htmlEX.CONFIG.key_handler) this.register_key_handler();

      const self = this;

      if (pdf2htmlEX.CONFIG.hashchange_handler) {
        window.addEventListener('hashchange', () => {
          self.navigate_to_dest(self.$refs.document.location.hash.substring(1));
        }, false);
      }

      if (pdf2htmlEX.CONFIG.view_history_handler) {
        window.addEventListener('popstate', (e) => {
          if (e.state) self.navigate_to_dest(e.state);
        }, false);
      }

      // register schedule rendering
      // renew old schedules since scroll() may be called frequently
      this.container.addEventListener('scroll', () => {
        self.update_page_idx();
        self.schedule_render(true);
      }, false);

      // handle links
      [this.container, this.outline].forEach((ele) => {
        ele.addEventListener('click', self.link_handler.bind(self), false);
      });

      this.initialize_radio_button();
      this.render();

      this.scrollToMatch(1);
    },
    initialize_radio_button() {
      const elements = this.$refs.document.getElementsByClassName(pdf2htmlEX.CSS_CLASS_NAMES.input_radio);

      for (let i = 0; i < elements.length; i += 1) {
        const r = elements[i];

        r.addEventListener('click', function toggleChecked() {
          this.classList.toggle('checked');
        });
      }
    },
    /**
     * @param{Event} e
     */
    link_handler(e) {
      const { target } = e; /** @type{Node} */
      const detail_str = /** @type{string} */ (target.getAttribute('data-dest-detail'));
      if (!detail_str) return;

      if (pdf2htmlEX.CONFIG.view_history_handler) {
        try {
          const cur_hash = this.get_current_view_hash();
          window.history.replaceState(cur_hash, '', `#${cur_hash}`);
          window.history.pushState(detail_str, '', `#${detail_str}`);
        } catch (ex) {
          console.log('Error in link handler');
        }
      }

      this.navigate_to_dest(detail_str, this.get_containing_page(target));
      e.preventDefault();
    },
    load_page(idx, pages_to_preload, callback) {
      const self = this;
      return new Promise((resolve, reject) => {
        const { pages } = self;
        if (idx >= pages.length) {
          reject(); // Page does not exist
        }

        const cur_page = pages[idx];
        if (cur_page.loaded) {
          resolve(); // Page is loaded
        }

        if (self.pages_loading[idx]) {
          reject(new Error('page-loading')); // Page is already loading
        }

        const cur_page_ele = cur_page.page;
        let url = cur_page_ele.getAttribute('data-page-url');
        if (url) {
          url = `${process.env.VUE_APP_S3_BUCKET_URL}/${self.currentDocumentSource}/${self.currentDocumentId}/${url}`;
          self.pages_loading[idx] = true; // set semaphore

          // add a copy of the loading indicator if not already present
          let new_loading_indicator = cur_page_ele.getElementsByClassName(pdf2htmlEX.CONFIG.loading_indicator_cls)[0];
          if (typeof new_loading_indicator === 'undefined') {
            new_loading_indicator = this.loading_indicator.cloneNode(true);
            new_loading_indicator.classList.add('active');
            cur_page_ele.appendChild(new_loading_indicator);
          }

          // eslint-disable-next-line no-lone-blocks
          {
            // eslint-disable-next-line no-underscore-dangle
            const _idx = idx;

            axios.get(url).then((response) => {
              const div = document.createElement('div');
              div.innerHTML = response.data;


              let new_page = null;
              const nodes = div.childNodes;
              for (let i = 0, l = nodes.length; i < l; i += 1) {
                const cur_node = nodes[i];
                if ((cur_node.nodeType === Node.ELEMENT_NODE)
                    && cur_node.classList.contains(pdf2htmlEX.CSS_CLASS_NAMES.page_frame)) {
                  new_page = cur_node;
                  break;
                }
              }

              // replace the old page with loaded data
              // the loading indicator on this page should also be destroyed
              let p = self.pages[_idx];
              p.page.replaceWith(new_page); // Vanilla js. https://stackoverflow.com/questions/21627276/replacing-nodes-error-the-node-to-be-replaced-is-not-a-child-of-this-node/21627371
              p = new pdf2htmlEX.Page(new_page);
              self.pages[_idx] = p;

              p.hide();
              p.rescale(self.scale);

              // disable background image dragging
              pdf2htmlEX.disable_dragstart(new_page.getElementsByClassName(pdf2htmlEX.CSS_CLASS_NAMES.background_image));

              self.schedule_render(false);
              self.highlight_page(_idx + 1);
              self.highlight_searches_page(_idx);

              if (callback) {
                callback(p);
              }
              delete self.pages_loading[_idx];
              resolve();
            }).catch((error) => {
              console.log(error);
              reject();
            });
          }
        }

        // Concurrent prefetch of the next pages
        if (pages_to_preload === undefined) {
          pages_to_preload = pdf2htmlEX.CONFIG.preload_pages;
        }

        // eslint-disable-next-line no-plusplus
        if (--pages_to_preload > 0) {
          setTimeout(() => {
            self.load_page(idx + 1, pages_to_preload).catch(() => {
            });
          }, 0);
        }
      });
    },
    highlight_page(idx) {
      let triggers = '';

      if (this.currentHighlightTriggers !== '') triggers = this.currentHighlightTriggers;
      else if (this.currentMainTriggers !== '') triggers = this.currentMainTriggers;

      if (triggers === '') {
        return;
      }

      if (this.currentHighlightTriggers === '') return;

      const hexId = idx.toString(16);

      const { paragraphs } = this.currentDocumentParagraphs[hexId];
      const regex_quoted_words = /(".*?")/g;
      const regex_unquoted_words = /[a-zA-Z\u00C0-\u00FF]+(?=[^"]*(?:"[^"]*"[^"]*)*$)/g;
      const lowercase_triggers = triggers.toLowerCase();
      const quoted_words_matches = lowercase_triggers.match(regex_quoted_words);
      const unquoted_words_matches = lowercase_triggers.match(regex_unquoted_words);

      for (let i = 0; i < paragraphs.length; i += 1) {
        let highlight_paragraph = false;
        const paragraph_text = paragraphs[i].text.toLowerCase();

        if (unquoted_words_matches) {
          const unquoted_words = unquoted_words_matches.filter(word => stopwords[word] !== '');
          for (let j = 0; j < unquoted_words.length && !highlight_paragraph; j += 1) {
            if (paragraph_text.includes(unquoted_words[j])) {
              highlight_paragraph = true;
            }
          }
        }

        if (quoted_words_matches && !highlight_paragraph) {
          const quoted_words_list = quoted_words_matches.filter(word => word !== ' ' && stopwords[word] !== '');
          const splitted_quoted_words = quoted_words_list.map(word => word.split(' '));

          for (let j = 0; j < splitted_quoted_words.length && !highlight_paragraph; j += 1) {
            let found_words = 0;
            const quoted_words = splitted_quoted_words[j];

            for (let k = 0; k < quoted_words.length; k += 1) {
              if (paragraph_text.includes(quoted_words[k])) {
                found_words += 1;
              }
            }
            if (found_words === quoted_words.length) {
              highlight_paragraph = true;
            }
          }
        }

        if (highlight_paragraph) {
          for (let j = 0; j < paragraphs[i].ids.length; j += 1) {
            const div = this.$refs.document.querySelector(`div[data-id='${paragraphs[i].ids[j]}']`);
            if (div) {
              div.classList.add('highlighted');
            }
          }
        }
      }
    },
    highlight_searches_page(idx) {
      for (let l = 0; l < this.currentSearchMatches.length; l += 1) {
        const { page_id, match_ids, terms_found } = this.currentSearchMatches[l];
        const pageIndex = parseInt(page_id, 16) - 1;

        if (pageIndex === idx && this.pages[idx].loaded) {
          for (let i = 0; i < match_ids.length; i += 1) {
            for (let j = 0; j < terms_found.length; j += 1) {
              const regex = new RegExp(`${terms_found[j]}`, 'ig');

              const div = this.$refs.document.querySelector(`div[data-id='${match_ids[i]}']`);
              const matches = div.innerHTML.match(regex);
              if (matches) {
                let innerHtml = div.innerHTML;
                for (let k = 0; k < matches.length; k += 1) {
                  innerHtml = innerHtml.replace(matches[k], `<span class="search_marked">${matches[k]}</span>`);
                }
                div.innerHTML = innerHtml;
              }
            }
          }
        }
      }
    },
    /**
     * @param{string} detail_str may come from user provided hashtag, need sanitizing
     * @param{Page=} src_page page containing the source event (e.g. link)
     */
    navigate_to_dest(detail_str, src_page) {
      let detail = {};
      try {
        detail = JSON.parse(detail_str);
      } catch (e) {
        return;
      }

      if (!(detail instanceof Array)) return;

      const target_page_no = detail[0];
      const { page_map } = this;

      if (!(target_page_no in page_map)) return;

      const target_page_idx = page_map[target_page_no];
      const target_page = this.pages[target_page_idx];

      // eslint-disable-next-line no-plusplus
      for (let i = 2, l = detail.length; i < l; ++i) {
        const d = detail[i];
        if (!((d === null) || (typeof d === 'number'))) return;
      }

      while (detail.length < 6) {
        detail.push(null);
      }

      // cur_page might be undefined, e.g. from Outline
      const cur_page = src_page || this.pages[this.cur_page_idx];

      let cur_pos = cur_page.view_position();
      cur_pos = pdf2htmlEX.transform(cur_page.ictm, [cur_pos[0], cur_page.height() - cur_pos[1]]);

      let zoom = this.scale;
      let pos = [0, 0];
      let upside_down = true;
      let ok = false;

      // position specified in `detail` are in the raw coordinate system of the page (unscaled)
      const { scale } = this;
      // TODO: fitb*
      // TODO: BBox
      switch (detail[1]) {
        case 'XYZ':
          pos = [(detail[2] === null) ? cur_pos[0] : detail[2] * scale,
            (detail[3] === null) ? cur_pos[1] : detail[3] * scale];
          // eslint-disable-next-line prefer-destructuring
          zoom = detail[4];
          if ((zoom === null) || (zoom === 0)) zoom = this.scale;
          ok = true;
          break;
        case 'Fit':
        case 'FitB':
          pos = [0, 0];
          ok = true;
          break;
        case 'FitH':
        case 'FitBH':
          pos = [0, (detail[2] === null) ? cur_pos[1] : detail[2] * scale];
          ok = true;
          break;
        case 'FitV':
        case 'FitBV':
          pos = [(detail[2] === null) ? cur_pos[0] : detail[2] * scale, 0];
          ok = true;
          break;
        case 'FitR':
          /* locate the top-left corner of the rectangle */
          // TODO
          pos = [detail[2] * scale, detail[5] * scale];
          upside_down = false;
          ok = true;
          break;
        default:
          break;
      }

      if (!ok) return;

      this.rescale(zoom, false);

      const self = this;
      /**
       * page should have type Page
       * @param{Page} page
       */
      const transform_and_scroll = function transform_and_scroll(page) {
        pos = pdf2htmlEX.transform(page.ctm, pos);
        if (upside_down) {
          pos[1] = page.height() - pos[1];
        }
        self.scroll_to(target_page_idx, pos);
      };

      if (target_page.loaded) {
        transform_and_scroll(target_page);
      } else {
        // TODO: scroll_to may finish before load_page
        // Scroll to the exact position once loaded.
        this.load_page(target_page_idx, undefined, transform_and_scroll).then(() => {

        }).catch(() => {
        });

        // In the meantime page gets loaded, scroll approximately position for maximum responsiveness.
        this.scroll_to(target_page_idx);
      }
    },
    /*
    * Hide all pages that have no 'opened' class
    * The 'opened' class will be added to visible pages by JavaScript
    * We cannot add this in the default CSS because JavaScript may be disabled
    */
    pre_hide_pages() {
      /* pages might have not been loaded yet, so add a CSS rule */
      const s = `@media screen{.${pdf2htmlEX.CSS_CLASS_NAMES.page_content_box}{display:none;}}`;
      const n = document.createElement('style');
      if (n.styleSheet) {
        n.styleSheet.cssText = s;
      } else {
        n.appendChild(document.createTextNode(s));
      }
      document.head.appendChild(n);
    },
    register_key_handler() {
      /*
      * When user try to zoom in/out using ctrl + +/- or mouse wheel
      * handle this and prevent the default behaviours
      *
      * Code credit to PDF.js
      */
      const self = this;

      // Firefox specific event, so that we can prevent browser from zooming
      window.addEventListener('DOMMouseScroll', (e) => {
        if (e.ctrlKey) {
          e.preventDefault();
          const { container } = self;
          const rect = container.getBoundingClientRect();
          const fixed_point = [e.clientX - rect.left - container.clientLeft,
            e.clientY - rect.top - container.clientTop];
          // eslint-disable-next-line no-restricted-properties
          self.rescale(Math.pow(pdf2htmlEX.CONFIG.scale_step, e.detail), true, fixed_point);
        }
      }, false);

      window.addEventListener('keydown', (e) => {
        let handled = false;
        /*
        var cmd = (e.ctrlKey ? 1 : 0)
                  | (e.altKey ? 2 : 0)
                  | (e.shiftKey ? 4 : 0)
                  | (e.metaKey ? 8 : 0)
                  ;
                  */
        const with_ctrl = e.ctrlKey || e.metaKey;
        const with_alt = e.altKey;
        // eslint-disable-next-line default-case
        switch (e.keyCode) {
          case 61: // FF/Mac '='
          case 107: // FF '+' and '='
          case 187: // Chrome '+'
            if (with_ctrl) {
              self.rescale(1.0 / pdf2htmlEX.CONFIG.scale_step, true);
              handled = true;
            }
            break;
          case 173: // FF/Mac '-'
          case 109: // FF '-'
          case 189: // Chrome '-'
            if (with_ctrl) {
              self.rescale(pdf2htmlEX.CONFIG.scale_step, true);
              handled = true;
            }
            break;
          case 48: // '0'
            if (with_ctrl) {
              self.rescale(0, false);
              handled = true;
            }
            break;
          case 33: // Page UP:
            if (with_alt) { // alt-pageup    -> scroll one page up
              self.scroll_to(self.cur_page_idx - 1);
            } else { // pageup        -> scroll one screen up
              self.container.scrollTop -= self.container.clientHeight;
            }
            handled = true;
            break;
          case 34: // Page DOWN
            if (with_alt) { // alt-pagedown  -> scroll one page down
              self.scroll_to(self.cur_page_idx + 1);
            } else { // pagedown      -> scroll one screen down
              self.container.scrollTop += self.container.clientHeight;
            }
            handled = true;
            break;
          case 35: // End
            self.container.scrollTop = self.container.scrollHeight;
            handled = true;
            break;
          case 36: // Home
            self.container.scrollTop = 0;
            handled = true;
            break;
        }
        if (handled) {
          e.preventDefault();
        }
      }, false);
    },
    /*
    * show visible pages and hide invisible pages
    */
    render() {
      const { container } = this;
      /*
      * show the pages that are 'nearly' visible -- it's right above or below the container
      *
      * all the y values are in the all-page element's coordinate system
      */

      const container_min_y = container.scrollTop;
      const container_height = container.clientHeight;
      const container_max_y = container_min_y + container_height;
      const visible_min_y = container_min_y - container_height;
      const visible_max_y = container_max_y + container_height;

      const pl = this.pages;
      for (let i = 0, l = pl.length; i < l; i += 1) {
        const cur_page = pl[i];
        const cur_page_ele = cur_page.page;
        const page_min_y = cur_page_ele.offsetTop + cur_page_ele.clientTop;
        const page_height = cur_page_ele.clientHeight;
        const page_max_y = page_min_y + page_height;
        if ((page_min_y <= visible_max_y) && (page_max_y >= visible_min_y)) {
          // cur_page is 'nearly' visible, show it or load it
          if (cur_page.loaded) {
            cur_page.show();
          } else {
            this.load_page(i).catch(() => {
            });
          }
        } else {
          cur_page.hide();
        }
      }
    },
    /**
     * @param{number} ratio
     * @param{boolean} is_relative
     * @param{Array.<number>=} fixed_point preserve the position (relative to the top-left corner of the viewer) after rescaling
     */
    rescale(ratio, is_relative, fixed_point) {
      const old_scale = this.scale;
      let new_scale = old_scale;
      // set new scale
      if (ratio === 0) {
        new_scale = 1;
        is_relative = false;
      } else if (is_relative) {
        new_scale *= ratio;
      } else {
        new_scale = ratio;
      }

      this.scale = new_scale;

      if (!fixed_point) fixed_point = [0, 0];

      // translate fixed_point to the coordinate system of all pages
      const { container } = this;
      fixed_point[0] += container.scrollLeft;
      fixed_point[1] += container.scrollTop;

      // find the visible page that contains the fixed point
      // if the fixed point lies between two pages (including their borders), it's contained in the first one
      const pl = this.pages;
      const pl_len = pl.length;
      let i = 0;
      for (i = this.first_page_idx; i < pl_len; i += 1) {
        const p = pl[i].page;
        if (p.offsetTop + p.clientTop >= fixed_point[1]) break;
      }
      let fixed_point_page_idx = i - 1;

      // determine the new scroll position
      // each-value consists of two parts, one inside the page, which is affected by rescaling,
      // the other is outside, (e.g. borders and margins), which is not affected

      // if the fixed_point is above the first page, use the first page as the reference
      if (fixed_point_page_idx < 0) fixed_point_page_idx = 0;

      const fp_p = pl[fixed_point_page_idx].page;
      const fp_p_width = fp_p.clientWidth;
      const fp_p_height = fp_p.clientHeight;

      const fp_x_ref = fp_p.offsetLeft + fp_p.clientLeft;
      let fp_x_inside = fixed_point[0] - fp_x_ref;
      if (fp_x_inside < 0) {
        fp_x_inside = 0;
      } else if (fp_x_inside > fp_p_width) {
        fp_x_inside = fp_p_width;
      }

      const fp_y_ref = fp_p.offsetTop + fp_p.clientTop;
      let fp_y_inside = fixed_point[1] - fp_y_ref;
      if (fp_y_inside < 0) {
        fp_y_inside = 0;
      } else if (fp_y_inside > fp_p_height) {
        fp_y_inside = fp_p_height;
      }

      // Rescale pages
      for (i = 0; i < pl_len; i += 1) {
        pl[i].rescale(new_scale);
      }

      // Correct container scroll to keep view aligned while zooming
      container.scrollLeft += fp_x_inside / old_scale * new_scale + fp_p.offsetLeft + fp_p.clientLeft - fp_x_inside - fp_x_ref;
      container.scrollTop += fp_y_inside / old_scale * new_scale + fp_p.offsetTop + fp_p.clientTop - fp_y_inside - fp_y_ref;

      // some pages' visibility may be toggled, wait for next render()
      // renew old schedules since rescale() may be called frequently
      this.schedule_render(true);
    },
    /**
     * @param{boolean} renew renew the existing schedule instead of using the old one
     */
    schedule_render(renew) {
      if (this.render_timer !== undefined) {
        if (!renew) return;
        clearTimeout(this.render_timer);
      }

      const self = this;
      this.render_timer = setTimeout(() => {
        /*
        * render() may trigger load_page(), which may in turn trigger another render()
        * so delete render_timer first
        */
        delete self.render_timer;
        self.render();
      }, pdf2htmlEX.CONFIG.render_timeout);
    },
    scrollToMatch(direction) {
      if (this.totalMatches === 0) {
        return;
      }

      const oldMatchIndex = this.currentMatchIndex;

      if (direction === 1) {
        if (this.currentMatchIndex < this.totalMatches - 1) {
          this.currentMatchIndex += 1;
        } else {
          this.currentMatchIndex = 0;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.currentMatchIndex > 0) {
          this.currentMatchIndex -= 1;
        } else {
          this.currentMatchIndex = this.totalMatches - 1;
        }
      }

      const scrollOptions = {
        container: `#document_${this._uid} #page-container`,
        offset: -20,
      };

      const { page_id, match_ids } = this.currentMatches[this.currentMatchIndex];

      const old_match_ids = (oldMatchIndex >= 0) ? this.currentMatches[oldMatchIndex].match_ids : [];
      const scroll_match_id = match_ids[0];

      const pageIndex = parseInt(page_id, 16) - 1;

      const self = this;

      const markNewElemsUnmarkOld = function markNewElemsUnmarkOld(new_ids, old_ids) {
        new_ids.forEach((id) => {
          const div = self.$refs.document.querySelector(`div[data-id='${id}']`);
          div.classList.add('marked');
        });

        old_ids.forEach((id) => {
          const div = self.$refs.document.querySelector(`div[data-id='${id}']`);
          div.classList.remove('marked');
        });
      };

      const scrollToElem = function scrollToElem(id, new_ids, old_ids) {
        const scrollElementId = `#document_${self._uid} #page-container div[data-id='${id}']`; // Always scroll to first line of highlighted paragraph

        self.$scrollTo(scrollElementId, 500, scrollOptions);
        markNewElemsUnmarkOld(new_ids, old_ids);
      };


      if (this.pages[pageIndex] && this.pages[pageIndex].loaded) {
        scrollToElem(scroll_match_id, match_ids, old_match_ids);
        this.$emit('match-index', this.currentMatchIndex);
      } else if (this.pages_loading[pageIndex]) {
        setTimeout(() => {
          // If page was already loading, wait some time and try to scroll again.
          this.currentMatchIndex -= 1;
          this.scrollToMatch(direction);
        }, 500);
      } else {
        this.load_page(pageIndex, 3).then(() => {
          scrollToElem(scroll_match_id, match_ids, old_match_ids);
          this.$emit('match-index', this.currentMatchIndex);
        }).catch(() => {
          // Couldnt load that page, reset currentMatchIndex
          this.currentMatchIndex -= 1;
        });
      }
    },
    scrollToSearchMatch(direction) {
      if (this.totalSearchMatches === 0) {
        return;
      }

      if (direction === 1) {
        if (this.currentSearchMatchIndex < this.totalSearchMatches - 1) {
          this.currentSearchMatchIndex += 1;
        } else {
          this.currentSearchMatchIndex = 0;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.currentSearchMatchIndex > 0) {
          this.currentSearchMatchIndex -= 1;
        } else {
          this.currentSearchMatchIndex = this.totalSearchMatches - 1;
        }
      }

      const scrollOptions = {
        container: `#document_${this._uid} #page-container`,
        offset: -20,
      };

      const { page_id, match_ids } = this.currentSearchMatches[this.currentSearchMatchIndex];

      const scroll_match_id = match_ids[0];

      const pageIndex = parseInt(page_id, 16) - 1;

      const self = this;
      const scrollToElem = function scrollToElem(id) {
        const scrollElementId = `#document_${self._uid} #page-container div[data-id='${id}']`; // Always scroll to first line of highlighted paragraph

        self.$scrollTo(scrollElementId, 500, scrollOptions);
      };

      if (this.pages[pageIndex] && this.pages[pageIndex].loaded) {
        scrollToElem(scroll_match_id);
        this.$emit('search-match-index', this.currentSearchMatchIndex);
      } else {
        this.load_page(pageIndex, 3).then(() => {
          scrollToElem(scroll_match_id);
          this.$emit('search-match-index', this.currentSearchMatchIndex);
        }).catch(() => {
          // Couldnt load that page, reset currentMatchIndex
          this.currentSearchMatchIndex -= 1;
        });
      }
    },
    /**
     * @param{number} page_idx
     * @param{Array.<number>=} pos [x,y] where (0,0) is the top-left corner
     */
    scroll_to(page_idx, pos) {
      const pl = this.pages;
      if ((page_idx < 0) || (page_idx >= pl.length)) return;
      const target_page = pl[page_idx];
      const cur_target_pos = target_page.view_position();

      if (pos === undefined) pos = [0, 0];

      const { container } = this;
      container.scrollLeft += pos[0] - cur_target_pos[0];
      container.scrollTop += pos[1] - cur_target_pos[1];
    },
    update_page_idx() {
      const { pages, container } = this;
      const pages_len = pages.length;
      // there is no chance that cur_page_idx or first_page_idx is modified
      if (pages_len < 2) return;

      const container_min_y = container.scrollTop;
      const container_max_y = container_min_y + container.clientHeight;

      // binary search for the first page
      // whose bottom border is below the top border of the container
      let first_idx = -1;
      let last_idx = pages_len;
      let rest_len = last_idx - first_idx;
      // TODO: use current first_page_idx as a hint?
      while (rest_len > 1) {
        const idx = first_idx + Math.floor(rest_len / 2);
        const cur_page_ele = pages[idx].page;
        if (cur_page_ele.offsetTop + cur_page_ele.clientTop + cur_page_ele.clientHeight >= container_min_y) {
          last_idx = idx;
        } else {
          first_idx = idx;
        }
        rest_len = last_idx - first_idx;
      }
      /*
      * with malformed settings it is possible that no page is visible, e.g.
      * - the container is to thin, which lies in the margin between two pages
      * - all pages are completely above or below the container
      * but we just assume that they won't happen.
      */
      this.first_page_idx = last_idx;

      // find the page with largest visible area
      const { cur_page_idx } = this;
      let max_visible_page_idx = cur_page_idx;
      let max_visible_ratio = 0.0;

      for (let i = last_idx; i < pages_len; i += 1) {
        const cur_page_ele = pages[i].page;
        const page_min_y = cur_page_ele.offsetTop + cur_page_ele.clientTop;
        const page_height = cur_page_ele.clientHeight;
        const page_max_y = page_min_y + page_height;
        if (page_min_y > container_max_y) break;

        // check the visible fraction of the page
        const page_visible_ratio = (Math.min(container_max_y, page_max_y)
                                  - Math.max(container_min_y, page_min_y)) / page_height;

        // stay with the current page if it is still fully visible
        if ((i === cur_page_idx) && (Math.abs(page_visible_ratio - 1.0) <= pdf2htmlEX.EPS)) {
          max_visible_page_idx = cur_page_idx;
          break;
        }

        if (page_visible_ratio > max_visible_ratio) {
          max_visible_ratio = page_visible_ratio;
          max_visible_page_idx = i;
        }
      }

      this.cur_page_idx = max_visible_page_idx;
    },
    zoomIn() {
      this.rescale(1.0 / pdf2htmlEX.CONFIG.scale_step, true);
    },
    zoomOut() {
      this.rescale(pdf2htmlEX.CONFIG.scale_step, true);
    },
  },
};

</script>
