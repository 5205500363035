<template>
  <div class="row py-0">
    <div class="col-4 search-results text-left">
      <div v-for="(result, index) in results" v-bind:key="`source_${index}`" >
        <div class="col-12 mb-2 p-3 border-top">
          <h6>{{result.source}}, {{ result.date | moment('DD-MM-YYYY')}}  </h6>
          <h5>
            <a :href="result.pdf_url" target="_blank"><h4 v-html="highlightedField(result, 'title')"/></a>
          </h5>
          <h6 v-html="highlightedField(result, 'department')"/>
          <p v-html="highlightedField(result, 'summary')"/>
          <p v-html="highlightedField(result, 'content')"/>
          <span @click="fetchDocument({
                  stringId: result.string_id,
                  source: result.source,
                  mainTriggers: currentSearchTerms,
                  highlightTriggers: currentFilters.highlightTerms,
                  advancedTriggersMode: false,
                  notificationTitle: result.title})"
                  class="analyze ml-2">[<i class="fas fa-search mr-1"></i>Analizar]</span>
          <i v-b-tooltip.hover title="Descargar" class="far fa-arrow-alt-circle-down fa-2x ml-3" @click="download(result.pdf_url)"></i>
        </div>
      </div>
    </div>
    <div class="col-8">
      <DocViewer/>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DocViewer from '@/components/docviewer/docviewer.vue';

export default {
  name: 'searchResults',
  props: ['results', 'currentSearchTerms', 'currentFilters'],
  components: {
    DocViewer,
  },
  methods: {
    ...mapActions('documents', {
      fetchDocument: 'fetchDocument',
    }),
    download(url) {
      window.open(url, '_blank');
    },
    fetchFirstDocument() {
      if (this.results.length === 0) return;
      const first_result = this.results[0];

      this.fetchDocument({
        stringId: first_result.string_id,
        source: first_result.source,
        mainTriggers: this.currentSearchTerms,
        highlightTriggers: this.currentFilters.highlightTerms,
        advancedTriggersMode: false,
        notificationTitle: first_result.title,
      });
    },
    highlightedField(result, field) {
      if (result.highlights[field]) {
        return result.highlights[field].join(' ');
      }
      return result[field];
    },
  },
  mounted() {
    this.fetchFirstDocument();
  },
  watch: {
    results() {
      this.fetchFirstDocument();
    },
  },
};
</script>

<style scoped>

.search-results{
    overflow-y: scroll;
    padding: 0;
    height: 100vh;
  }

</style>r
