import Vue from 'vue';
import VueRouter from 'vue-router';

// Main Pages
import Home from '@/components/HomePage.vue';
import Contact from '@/components/contact/contact.vue';

// Admin pages
import AdminUsersPage from '@/components/admin/users.vue';
import AdminSubscription from '@/components/admin/subscription.vue';
import AdminUser from '@/components/admin/user.vue';
import AdminSidekiq from '@/components/admin/sidekiq.vue';


// Auth components
import recoverPasswordPage from '@/components/users/recover_password.vue';
import resetPasswordPage from '@/components/users/reset_password.vue';
import SignupPage from '@/components/users/signup.vue';
import invitePage from '@/components/users/invite.vue';
import SigninPage from '@/components/users/signin.vue';

import UserPage from '@/components/users/user.vue';
import PricingPage from '@/components/users/pricing.vue';
import SubscribePage from '@/components/users/subscribe.vue';
// import Charts from './components/charts/sourcesListStats.vue';

import procurementDashboard from '@/components/procurement/procurement_dashboard.vue';

import officialBulletinsDashboard from '@/components/official_bulletins/official_bulletins_dashboard.vue';

Vue.use(VueRouter);

// function subscribed() {
//  return true;
/* const { status } = router.app.$store.state.usersModule.user.subscription;
if (status === 'active' || status === 'trialing') {
  return true;
}
return false; */
// }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'nav-active',
  routes: [
    {
      path: '/',
      redirect: 'licitaciones/buscar',
    },
    {
      path: '/inicio',
      name: 'home',
      component: Home,
      meta: { auth: false },
    },
    {
      path: '/contacto',
      component: Contact,
    },
    {
      path: '/admin/users',
      component: AdminUsersPage,
      name: 'admin_users',
      meta: { auth: ['admin'] },
    },
    {
      path: '/admin/sidekiq',
      component: AdminSidekiq,
      meta: { auth: ['admin'] },
    },
    {
      path: '/admin/subscriptions/:subscription_id',
      component: AdminSubscription,
      meta: { auth: ['admin'] },
      props: true,
    },
    {
      path: '/admin/users/:user_id',
      component: AdminUser,
      meta: { auth: ['admin'] },
      props: true,
    },
    {
      path: '/precios',
      component: PricingPage,
    },
    {
      path: '/reset_password',
      component: resetPasswordPage,
    },
    {
      path: '/recover_password',
      component: recoverPasswordPage,
    },
    {
      path: '/invitacion',
      component: invitePage,
      meta: { auth: false },
    },
    {
      path: '/sign_in',
      component: SigninPage,
      name: 'sign_in',
      meta: { auth: false },
    },
    {
      path: '/sign_up',
      component: SignupPage,
      name: 'sign_up',
      meta: { auth: false },
    },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: SubscribePage,
      meta: { auth: true },
    },
    {
      path: '/publicaciones',
      name: 'publicaciones',
      component: officialBulletinsDashboard,
      meta: { auth: true, requiresSubscription: true },
      props: true,
      alias: [
        '/publicaciones/buscar',
        '/publicaciones/alertas',
        '/publicaciones/notificaciones',
        '/publicaciones/mis_publicaciones',
      ],
    },
    {
      path: '/notifications',
      redirect: {
        name: 'publicaciones',
        params: {
          tab: 1,
        },
      },
      meta: { auth: true, requiresSubscription: true },
    },
    {
      path: '/user',
      name: 'userPage',
      component: UserPage,
      meta: { auth: true },
    },
    {
      path: '/licitaciones',
      name: 'procurement_dashboard',
      component: procurementDashboard,
      props: true,
      meta: { auth: true, requiresSubscription: true },
      alias: [
        '/licitaciones/buscar',
        '/licitaciones/mis_licitaciones',
        '/licitaciones/mis_empresas',
        '/licitaciones/alertas',
        '/licitaciones/notificaciones',
      ],
    },
    {
      path: '/licitaciones/detalle/:id',
      name: 'procurement',
      component: procurementDashboard,
      props: true,
      meta: { auth: true, requiresSubscription: true },
    },
    {
      path: '/licitaciones/empresa/:id',
      name: 'procurement_party',
      component: procurementDashboard,
      props: true,
      meta: { auth: true, requiresSubscription: true },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresSubscription)) {
    const user = router.app.$store.getters['usersModule/user'];
    if (!user || ((!user.subscription || !user.subscription.status === 'active') && user.trial_days_remaining === 0)) {
      next({
        path: '/user',
      });
    } else {
      // User is active or trialing
      next();
    }
    next();
  } else {
    // Doesnt require subscription, just access it
    next();
  }
});


export default router;
