<template>
  <div id="users" class="p-3">
    <h1>Usuarios</h1>
    <div class="row">
      <div id="userStats" class="col-12 p-3 text-left">
        <p>Número total de usuarios de Legalhound: {{total_results}}</p>
        <p>Registrados la última semana: {{last_week_registered_users}}</p>
        <label for="email">Invitar usuario</label>
        <input type="email"
                  id="email"
                  name="email" v-model="inviteEmail"
                  class="border mx-3">
        <button class="btn btn-secondary" @click="inviteUser()"> Invitar <i class="far fa-envelope"></i></button>
      </div>
    </div>
    <paginationComponent
        id="users-pagination"
        :currentPage="current_page"
        :totalPages="total_pages"
        v-on:next-page-users-pagination="changePage($event)"/>

    <div class="row">
      <div class="col-12 p-3 text-left">
        <b-form inline @submit.prevent="filterUsers({
            filter_by: filterUsersValue.by,
            filter_value: filterUsersValue.value,
          })">
          <label class="mr-1">Filtrar por:</label>
          <b-form-select v-model="filterUsersValue.by" :options="filterUsersValue.options"></b-form-select>
          <b-input class="ml-2 mr-2" v-model="filterUsersValue.value" />
          <b-btn type="submit">Filtrar</b-btn>
        </b-form>
      </div>
    </div>
    <table class="table p-3 text-left">
      <thead class="corporative">
        <tr>
          <th scope="col" @click="sortTable('id')">User id</th>
          <th scope="col" @click="sortTable('name')">User Name</th>
          <th scope="col" @click="sortTable('email')">Email</th>
          <th scope="col" @click="sortTable('created_at')">Created At</th>
          <th scope="col" @click="sortTable('active')">Subscription</th>
          <th scope="col" @click="sortTable('last_sign_in_at')">Last Login</th>
          <th scope="col" @click="sortTable('total_alerts')">Number of alerts</th>
          <th scope="col" @click="sortTable('unprocessed_notifications')">Unprocessed Notifications</th>
          <th scope="col" @click="sortTable('total_notifications')">Total Notifications</th>
          <th scope="col">Percentage processed</th>
          <th scope="col">Last notification date</th>
          <th scope="col"> Subscripciones a licitaciones </th>
          <th scope="col"> Subscripciones a empresas o entidades </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="user in users">
          <tr v-bind:key="user.id">
            <td>{{ user.id }} </td>
            <td>{{user.name}} </td>
            <td>{{user.email}}</td>
            <td>{{ user.created_at | moment('DD/MM/YYYY')}}</td>
            <td>
              <template v-if="user.subscription.id">
                Suscrito.<br/> {{ user.email === user.subscription.owner_email ? `Tiene  ${user.subscription.quantity} licencia(s).`
                  : `Dueño suscripción: ${user.subscription.owner_email}` }}
                  <br/>
                  <router-link  :to="`/admin/subscriptions/${user.subscription.id}`"> Ver </router-link>
              </template>
              <template v-else>
                  {{ user.active ? user.active : `Quedan ${user.subscription.trial_days_remaining} días de prueba`}}
                  <button class="create-subscription p-1" :id="`subscription_${user.id}`" @click="showPopover(`subscription_${user.id}`)" >
                    Crear suscripción
                  </button>
                  <b-popover
                    :target="`subscription_${user.id}`"
                    :show="currentPopover === `subscription_${user.id}`"
                    placement="bottom"
                  >
                    <template #title>
                      <b-button @click="closePopover(`subscription_${user.id}`)" class="close" aria-label="Close">
                        <span class="d-inline-block" aria-hidden="true">&times;</span>
                      </b-button>
                      Crear suscripción</template>
                    <table class="table">
                      <tr>
                        <td><strong>Licencias:</strong></td>
                        <td><input class="border p-1" v-model.number="newSubscriptionQuantity" type="number"/></td>
                      </tr>
                      <tr>
                        <td><strong>Periodo:</strong> </td>
                        <td><b-form-select v-model="newSubscriptionBillingFrequency" :options="billingFrequencyOptions"></b-form-select></td>
                      </tr>
                      <tr class="text-center">
                        <td colspan="2"> <button class="btn btn-secondary" @click="createSubscription(user)"> Crear suscripción </button></td>
                      </tr>
                    </table>
                  </b-popover>
              </template>
            </td>
            <td>
              <template v-if="!user.confirmed"> Needs user confirmation</template>
              <template v-else-if="user.last_sign_in_at">{{ user.last_sign_in_at | moment('DD/MM/YYYY')}}</template>
              <template v-else>User has never logged in</template>
            </td>
            <td> {{user.total_alerts}} </td>
            <td> {{user.unprocessed_notifications}} </td>
            <td> {{user.total_notifications}} </td>
            <td>
              <template v-if="user.total_notifications > 0">
                {{(user.total_notifications - user.unprocessed_notifications) / user.total_notifications * 100}}%
              </template>
              <template v-else> No Notifications </template>
            </td>
            <td>
              <template v-if="user.last_notification_date"> {{user.last_notification_date | moment('DD/MM/YYYY')}} </template>
              <template v-else> No Notifications </template>
            </td>
            <td>
              {{user.total_project_subscriptions}}
            </td>
            <td>
              {{user.total_party_subscriptions}}
            </td>
            <td>
              <b-button class="btn btn-secondary"
                v-if="user.total_alerts > 0"
                @click="getUserAlerts(user.id)">
                {{ showAlerts.user_id !== user.id ? 'Ver Alertas' : 'Ocultar Alertas' }}
              </b-button>
              <b-button class="btn btn-secondary mt-1"
                v-if="user.total_project_subscriptions > 0"
                @click="getUserProjectSubscriptions(user.id)">
                {{ showProjectSubscriptions.user_id !== user.id ? 'Ver Subscripciones a licitaciones' : 'Ocultar subscripciones a licitaciones' }}
              </b-button>
              <b-button class="btn btn-secondary mt-1"
                v-if="user.total_party_subscriptions > 0"
                @click="getUserPartySubscriptions(user.id)">
                {{ showPartySubscriptions.user_id !== user.id ? 'Ver Subscripciones a empresas' : 'Ocultar subscripciones a empresas' }}
              </b-button>
            </td>
          </tr>
          <tr :id="`${user.id}_alerts_collapse`"
            v-bind:key="`alert_details_${user.id}`"
            v-if="showAlerts.user_id === user.id">
            <td colspan="12">
              <b-card v-for="alert in showAlerts.alerts" v-bind:key="`alert_${alert.id}_${user.id}`" bg-variant="light" >
                <b-card-text>
                  <h4> <strong> {{alert.name}} </strong> </h4>
                  <p> Términos de búsqueda: <strong> {{alert.searchTerms}} </strong> </p>
                  <p> Notificaciones encontradas: <strong> {{alert.total_notifications}} </strong>
                  </p>
                  <p> Frecuencia emails: <strong> {{alert.mailing_frequency}} </strong> </p>
                  <p> Tipo de alerta: <strong> {{alert.alert_type}} </strong> </p>
                  <advancedFilters :filters="alert.filters" :alert_type="alert.alert_type"/>
                  <button class="btn btn-secondary" @click="runAlert(alert)"> Ejecutar Búsqueda con esta alerta</button>
                </b-card-text>
              </b-card>
            </td>
          </tr>
          <tr :id="`${user.id}_projects_subscriptions_collapse`"
            v-bind:key="`projects_subscriptions_details_${user.id}`"
            v-if="showProjectSubscriptions.user_id === user.id">
            <td colspan="12">
              <b-card v-for="(subscription, index) in showProjectSubscriptions.subscriptions"
                v-bind:key="`project_${index}_${user.id}`" bg-variant="light" >
                <b-card-text>
                  <p> Subscripción creada el: <strong> {{subscription.created_at | moment('DD/MM/YYYY')}} </strong> </p>
                  <p>
                  <router-link :to="{
                        name: 'procurement',
                        params: {
                          id: subscription.project.id
                        }
                      }"
                      target="_blank"
                  >
                  {{subscription.project.title}}
                  </router-link>
                  </p>
                  <p> Notificaciones encontradas: <strong> {{subscription.total_notifications}} </strong> </p>
                  <p> Fecha última notificación: <strong> {{subscription.last_notification_date | moment('DD/MM/YYYY')}} </strong> </p>
                  <button class="btn btn-secondary"
                    v-if="subscription.total_notifications > 0"
                   @click="toggleProjectNotificationsSidebar(subscription.id)">
                    Mostrar Notificaciones
                  </button>

                </b-card-text>
              </b-card>
            </td>
          </tr>
          <tr :id="`${user.id}_parties_subscriptions_collapse`"
            v-bind:key="`parties_subscriptions_details_${user.id}`"
            v-if="showPartySubscriptions.user_id === user.id">
            <td colspan="12">
              <b-card v-for="(subscription, index) in showPartySubscriptions.subscriptions"
                v-bind:key="`project_${index}_${user.id}`" bg-variant="light" >
                <b-card-text>
                  <p> Subscripción creada el: <strong> {{subscription.created_at | moment('DD/MM/YYYY')}} </strong> </p>
                  <p>
                  <router-link :to="{
                        name: 'procurement_party',
                        params: {
                          id: subscription.party.id
                        }
                      }"
                      target="_blank"
                  >
                  {{subscription.party.name}}
                  </router-link>
                  </p>
                  <p> Notificaciones encontradas: <strong> {{subscription.total_notifications}} </strong> </p>
                  <p> Fecha última notificación: <strong> {{subscription.last_notification_date | moment('DD/MM/YYYY')}} </strong> </p>
                  <button class="btn btn-secondary"
                    v-if="subscription.total_notifications > 0"
                   @click="togglePartyNotificationsSidebar(subscription.id)">
                    Mostrar Notificaciones
                  </button>

                </b-card-text>
              </b-card>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <b-sidebar id="notifications-sidebar" title="Notificaciones" width="50%"  right shadow backdrop>
      <div class="p-3">
        <div class="row">
          <div class="col-12 mt-3">
            <paginationComponent
              id="notifications-admin-pagination"
              :currentPage="notificationsSidebar.current_notifications_page"
              :totalPages="notificationsSidebar.total_notifications_pages"
              v-on:next-page-notifications-admin-pagination="changeNotificationsPage($event)"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <div class="card p-3 mb-1 text-left" v-for="(notification, index) in notificationsSidebar.notifications"
            v-bind:key="`notification_${index}`">
              <template v-if="notification.noticeable_type === 'Procurement::Project'">
                <template v-if="notification.notification_types.includes('procurement_project_subscription')">
                  <procurementProjectChangesNotification :notification="notification" :showAlerts="false" :showActions="false"/>
                </template>
                <template v-else>
                  <procurementProjectNotification :notification="notification" :showAlerts="false" :showActions="false"/>
                </template>
              </template>
              <template v-else-if="notification.noticeable_type === 'Procurement::Party'">
                <procurementPartyNotification :notification="notification" :showAlerts="false" :showActions="false"/>
              </template>
              <template v-else>
                <officialBulletinNotification :notification="notification" :showAlerts="false" :showActions="false"/>
              </template>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import userService from '@/_services/user.service';
import adminService from '@/_services/admin.service';
import alertService from '@/_services/alert.service';

import paginationComponent from '@/components/helpers/pagination.vue';
import advancedFilters from '@/components/alerts/advanced_filters.vue';
import procurementPartyNotification from '@/components/procurement/notifications/procurement_party_notification.vue';
import procurementProjectNotification from '@/components/procurement/notifications/procurement_project_notification.vue';
import procurementProjectChangesNotification from '@/components/procurement/notifications/procurement_project_changes_notification.vue';
import officialBulletinNotification from '@/components/official_bulletins/notifications/notification.vue';

export default {
  name: 'UsersComponent',
  components: {
    paginationComponent,
    advancedFilters,
    procurementPartyNotification,
    procurementProjectNotification,
    procurementProjectChangesNotification,
    officialBulletinNotification,
  },
  data() {
    return {
      filterUsersValue: {
        by: 'name',
        value: '',
        options: [
          { value: 'name', text: 'Nombre' },
          { value: 'email', text: 'email' },
        ],
      },
      showAlerts: {
        user_id: -1,
        alerts: [],
        current_alert_id: -1,
      },
      showProjectSubscriptions: {
        user_id: -1,
        subscription_id: -1,
        subscriptions: [],
      },
      showPartySubscriptions: {
        user_id: -1,
        subscription_id: -1,
        subscriptions: [],
      },
      notificationsSidebar: {
        notification_source: '',
        notifications: [],
        current_notifications_page: 1,
        total_notifications: 0,
        total_notifications_pages: 1,
      },
      inviteEmail: '',
      descending: true,
      newSubscriptionQuantity: 1,
      newSubscriptionBillingFrequency: 'monthly',
      billingFrequencyOptions: [
        { value: 'monthly', text: 'Mensual' },
        { value: 'yearly', text: 'Anual' },
      ],
      currentPopover: '',
    };
  },
  computed: {
    ...mapState('usersModule', [
      'users',
      'current_page',
      'total_pages',
      'total_results',
      'last_week_registered_users',
      'sort_by',
      'sort_order']),
  },
  created() {
    this.indexUsers();
  },
  methods: {
    ...mapActions('usersModule', {
      indexUsers: 'indexUsers',
      changePage: 'changePage',
      setUsersSort: 'setUsersSort',
      filterUsers: 'filterUsers',
    }),
    ...mapActions('flashMessages', {
      successFlashMessage: 'success',
      errorFlashMessage: 'error',
    }),
    closePopover(id) {
      this.$root.$emit('bv::hide::popover', id);
    },
    createSubscription(user) {
      adminService.createSubscription(
        {
          billing_frequency: this.newSubscriptionBillingFrequency,
          quantity: this.newSubscriptionQuantity,
          user_id: user.id,
        },
      ).then(
        () => {
          this.indexUsers();
        },
      );
    },
    getUserAlerts(user_id) {
      Vue.set(this.showAlerts, 'alerts', []);

      if (user_id === this.showAlerts.user_id) {
        this.showAlerts.user_id = -1;
        return;
      }
      this.showAlerts.user_id = -1;

      userService.userAlerts(user_id).then((response) => {
        const { alerts } = response.data;
        this.showAlerts.user_id = user_id;
        Vue.set(this.showAlerts, 'alerts', alerts);
      });
    },
    getUserProjectSubscriptions(user_id) {
      Vue.set(this.showProjectSubscriptions, 'subscriptions', []);

      if (user_id === this.showProjectSubscriptions.user_id) {
        this.showProjectSubscriptions.user_id = -1;
        return;
      }
      this.showProjectSubscriptions.user_id = -1;

      userService.userProjectSubscriptions(user_id).then((response) => {
        const { project_subscriptions } = response.data;
        this.showProjectSubscriptions.user_id = user_id;
        Vue.set(this.showProjectSubscriptions, 'subscriptions', project_subscriptions);
      });
    },
    getUserPartySubscriptions(user_id) {
      Vue.set(this.showPartySubscriptions, 'subscriptions', []);

      if (user_id === this.showPartySubscriptions.user_id) {
        this.showPartySubscriptions.user_id = -1;
        return;
      }
      this.showPartySubscriptions.user_id = -1;

      userService.userPartySubscriptions(user_id).then((response) => {
        const { party_subscriptions } = response.data;
        this.showPartySubscriptions.user_id = user_id;
        Vue.set(this.showPartySubscriptions, 'subscriptions', party_subscriptions);
      });
    },
    toggleAlertNotificationsSidebar(alert_id) {
      this.$root.$emit('bv::toggle::collapse', 'notifications-sidebar');
      this.showAlerts.current_alert_id = alert_id;
      this.getAlertNotifications();
    },
    toggleProjectNotificationsSidebar(subscription_id) {
      this.$root.$emit('bv::toggle::collapse', 'notifications-sidebar');
      this.showProjectSubscriptions.subscription_id = subscription_id;
      this.getProjectNotifications();
    },
    togglePartyNotificationsSidebar(subscription_id) {
      this.$root.$emit('bv::toggle::collapse', 'notifications-sidebar');
      this.showPartySubscriptions.subscription_id = subscription_id;
      this.getPartyNotifications();
    },
    changeNotificationsPage(page) {
      if (this.notificationsSidebar.notification_source === 'alert') {
        this.getAlertNotifications(page);
      } else if (this.notificationsSidebar.notification_source === 'project_subscription') {
        this.getProjectSubscriptionNotifications(page);
      } else if (this.notificationsSidebar.notification_source === 'party_subscription') {
        this.getPartySubscriptionNotifications(page);
      }
    },
    getAlertNotifications(page = 1) {
      Vue.set(this.notificationsSidebar, 'notifications', []);
      this.notificationsSidebar.notification_source = 'alert';

      alertService.getAlertNotifications(this.showAlerts.current_alert_id, page).then((response) => {
        const { notifications } = response.data;
        const { total_results, total_pages, current_page } = response.data.metadata;

        Vue.set(this.notificationsSidebar, 'notifications', notifications);
        this.notificationsSidebar.total_notifications = total_results;
        this.notificationsSidebar.current_notifications_page = current_page;
        this.notificationsSidebar.total_notifications_pages = total_pages;
      });
    },
    getProjectNotifications(page = 1) {
      Vue.set(this.notificationsSidebar, 'notifications', []);
      this.notificationsSidebar.notification_source = 'project_subscription';

      userService.userProjectSubscriptionNotifications(
        this.showProjectSubscriptions.user_id, this.showProjectSubscriptions.subscription_id, page,
      ).then((response) => {
        const { notifications } = response.data;
        const { total_results, total_pages, current_page } = response.data.metadata;

        Vue.set(this.notificationsSidebar, 'notifications', notifications);
        this.notificationsSidebar.total_notifications = total_results;
        this.notificationsSidebar.current_notifications_page = current_page;
        this.notificationsSidebar.total_notifications_pages = total_pages;
      });
    },
    getPartyNotifications(page = 1) {
      Vue.set(this.notificationsSidebar, 'notifications', []);
      this.notificationsSidebar.notification_source = 'party_subscription';

      userService.userPartySubscriptionNotifications(
        this.showPartySubscriptions.user_id, this.showPartySubscriptions.subscription_id, page,
      ).then((response) => {
        const { notifications } = response.data;
        const { total_results, total_pages, current_page } = response.data.metadata;

        Vue.set(this.notificationsSidebar, 'notifications', notifications);
        this.notificationsSidebar.total_notifications = total_results;
        this.notificationsSidebar.current_notifications_page = current_page;
        this.notificationsSidebar.total_notifications_pages = total_pages;
      });
    },
    inviteUser() {
      userService.createUserInvitation({ email: this.inviteEmail }).then(() => {
        this.successFlashMessage({
          message: `${this.inviteEmail} invitado con éxito.`,
        });
      }).catch((error) => {
        const err_messages = error.response.data.message;
        this.errorFlashMessage({ message: err_messages });
      });
    },
    runAlert(alert) {
      if (alert.alert_type === 'official_bulletin') {
        window.open(`/publicaciones?alert_id=${alert.id}`);
      } else if (alert.alert_type === 'procurement') {
        window.open(`/licitaciones?alert_id=${alert.id}`);
      }
    },
    showPopover(id) {
      this.currentPopover = id;
      this.newSubscriptionQuantity = 1;
    },
    sortTable(column) {
      if (column === this.sort_by) {
        this.descending = !this.descending;
      } else {
        this.descending = true;
      }
      this.setUsersSort({
        sort_by: column,
        sort_order: this.descending ? 'desc' : 'asc',
      });
    },
  },
};
</script>

<style scoped>
label, input, button{
  display: inline;
}
.create-subscription{
  color:#4953CF;
}
.create-subscription:hover{
  color:#8F8EF8;
  text-decoration: underline;
}

</style>
