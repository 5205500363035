<template>
   <div>
    <div class="row mt-2">
      <div :class="`col-${column_size} pt-3 stripe-dark`">
       <!-- <input v-model="boolFilters.only_active" type="checkbox"> Solo activas-->
        <div class="filter" v-show="includeFilter('status')">
          <h3> Estado </h3>
          <multiselect
            :options="status_codes"
            :value="selectFilters.selected_status"
            :multiple="true"
            :preserve-search="true"
            @select="selectStatus($event)"
            @remove="removeStatus($event)"
            placeholder="Selecciona estado de la licitación"
            label="value"
            track-by="value">
              <span slot="noResult">No se han encontrado resultados. Por favor, comprueba lo escrito.</span>
          </multiselect>
        </div>
        <div class="filter" v-show="includeFilter('has_lots')">
          <h3> División </h3>

          <b-form-select v-model="selectFilters.selected_division_option" :options="division_options"/>

        </div>
        <div class="filter" v-show="includeFilter('contract_type')">
          <h3> Tipo de contrato </h3>
          <multiselect
            :options="contract_type_codes"
            :value="selectFilters.selected_contract_types"
            :multiple="true"
            :preserve-search="true"
            @select="selectContractType($event)"
            @remove="removeContractType($event)"
            placeholder="Selecciona tipo de contrato"
            label="value"
            track-by="value">
              <span slot="noResult">No se han encontrado resultados. Por favor, comprueba lo escrito.</span>
          </multiselect>
        </div>
        <div class="filter" v-show="includeFilter('cpv_codes')">
          <h3> Códigos CPV </h3>
          <multiselect
            :options="cpv_codes"
            :multiple="true"
            :preserve-search="true"
            :custom-label="cpvCodeLabel"
            :value="selectFilters.selected_cpv_codes"
            placeholder="Selecciona códigos CPV"
            @select="selectCpvCode($event)"
            @remove="removeCpvCode($event)"
            label="value"
            track-by="code">
            <span slot="noResult">No se han encontrado resultados. Por favor, comprueba lo escrito.</span>
          </multiselect>
        </div>
      </div>
      <div :class="`col-${column_size} pt-3 stripe-light`" v-show="includeColumn('amountColumn')">
        <div class="filter agreement" v-show="includeFilter('amount')">
          <h3> Valor estimado del contrato </h3>
          <input type="number"
            v-model="amount.from"
            size="32"
            v-debounce:500ms="saveFilters"
            placeholder="Desde €"
            class="mr-3">
          <input type="number"
            v-model="amount.to"
            size="32"
            v-debounce:500ms="saveFilters"
            placeholder="Hasta €"/>
        </div>
        <div class="filter mt-2" v-show="includeFilter('contracting_party')">
          <h3> Órgano de contratación </h3>
          <input type="text" v-model="textFilters.contracting_party_name" v-debounce:500ms="saveFilters">
        </div>
        <div class="filter company mt-2" v-show="includeFilter('awardee_party')">
          <h3> Adjudicataria </h3>
          <b-form-tags v-model="awardee_parties" no-outer-focus>
            <template v-slot="{ tags, inputAttrs, inputHandlers, removeTag }">
              <b-input-group aria-controls="awardee-parties-list">
                <input
                  v-bind="inputAttrs"
                  v-on="inputHandlers"
                  placeholder="Escribe el nombre y presiona enter">
              </b-input-group>
              <ul
                id="awardee-parties-list"
                class="list-unstyled d-inline-flex flex-wrap mb-0"
                aria-live="polite"
                aria-atomic="false"
                aria-relevant="additions removals"
              >
                <!-- Always use the tag value as the :key, not the index! -->
                <!-- Otherwise screen readers will not read the tag
                    additions and removals correctly -->
                <b-card
                  v-for="(tag, index) in tags"
                  :key="tag"
                  :id="`tag_${index}`"
                  tag="li"
                  class="mt-1 mr-1"
                  body-class="py-1 pr-2 text-nowrap"
                >
                  <strong>{{ tag }}</strong>
                  <b-button-close
                    @click="removeTag(tag)"
                    variant="link"
                    size="sm"
                    class="ml-2"
                    :aria-controls="`tag_${index}`"
                  >x</b-button-close>
                </b-card>
              </ul>
            </template>
          </b-form-tags>
        </div>
      </div>
      <div :class="`col-${column_size} pt-3 stripe-dark`">
        <div class="filter" v-show="includeFilter('country')">
          <h3> País </h3>
          <b-form-select
            v-model="selectFilters.selected_country"
            :options="countries_list"
            class="mb-3"
            value-field="code"
            text-field="name"
          ></b-form-select>
        </div>
        <div class="filter" v-if="selectFilters.selected_country=='ES'" v-show="includeFilter('region')" >
          <h3> Región </h3>
          <multiselect
            :options="region_options"
            :multiple="true"
            group-values="provinces"
            group-label="region"
            :group-select="true"
            :value="selectFilters.selected_regions"
            :preserve-search="true"
            @select="selectRegion($event)"
            @remove="removeRegion($event)"
            placeholder="Selecciona regiones"
            track-by="name" label="name">
            <span slot="noResult">No se han encontrado resultados. Por favor, comprueba lo escrito.</span>
          </multiselect>
        </div>
        <div class="filter" v-show="includeFilter('city')">
          <h3> Ciudad </h3>
          <input type="text" v-model="textFilters.city" v-debounce:500ms="saveFilters">
        </div>
      </div>
      <div :class="`col-${column_size} pt-3 stripe-light`" v-show="includeColumn('dateColumn')">
        <div class="filter" v-show="includeFilter('tender_submission_deadline')">
          <h3> Fecha límite de presentación </h3>
          <b-input-group>
            <b-form-input
              name="from"
              v-model="textFilters.submission_deadline"
              format="-"
              type="text"
              placeholder="Hasta el"
              autocomplete="off"
              v-debounce:500ms="saveFilters"
            >
            </b-form-input>
            <b-input-group-append>
              <b-form-datepicker class="datepicker"
                name="from"
                v-model="textFilters.submission_deadline"
                format="yyyy-MM-dd"
                button-only
                right
                show-decade-nav
                size="sm"
                locale="en-US"
                v-on:input="saveFilters"
              >
              </b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="filter" v-show="includeFilter('date')">
          <h3> Fecha publicación entre</h3>
          <b-input-group class ="mb-2">
            <b-form-input
              name="from"
              v-model="textFilters.date.from"
              format="-"
              type="text"
              placeholder="Entre el"
              autocomplete="off"
              v-debounce:500ms="saveFilters"
            >
            </b-form-input>
            <b-input-group-append>
              <b-form-datepicker class="datepicker"
                name="from"
                v-model="textFilters.date.from"
                format="yyyy-MM-dd"
                button-only
                right
                show-decade-nav
                size="sm"
                locale="en-US"
                v-on:input="saveFilters"
              >
              </b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
          <b-input-group>
            <b-form-input
              name="from"
              v-model="textFilters.date.to"
              format="-"
              type="text"
              placeholder="hasta el"
              autocomplete="off"
              v-debounce:500ms="saveFilters"
            >
            </b-form-input>
            <b-input-group-append>
              <b-form-datepicker class="datepicker"
                name="from"
                v-model="textFilters.date.to"
                format="yyyy-MM-dd"
                button-only
                right
                show-decade-nav
                size="sm"
                locale="en-US"
                v-on:input="saveFilters"
              >
              </b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="filter" v-show="includeFilter('date_years')">
          <h3> Fecha publicación en el año</h3>
          <b-form-tags v-model="date_years" no-outer-focus>
            <template v-slot="{ tags, inputAttrs, inputHandlers, removeTag }">
              <b-input-group aria-controls="date-years">
                <input
                  v-bind="inputAttrs"
                  v-on="inputHandlers"
                  placeholder="Escribe el año y presiona enter">
              </b-input-group>
              <ul
                id="date-years"
                class="list-unstyled d-inline-flex flex-wrap mb-0"
                aria-live="polite"
                aria-atomic="false"
                aria-relevant="additions removals"
              >
                <b-card
                  v-for="(tag, index) in tags"
                  :key="tag"
                  :id="`tag_${index}`"
                  tag="li"
                  class="mt-1 mr-1"
                  body-class="py-1 pr-2 text-nowrap"
                >
                  <strong>{{ tag }}</strong>
                  <b-button-close
                    @click="removeTag(tag)"
                    variant="link"
                    size="sm"
                    class="ml-2"
                    :aria-controls="`tag_${index}`"
                  >x</b-button-close>
                </b-card>
              </ul>
            </template>
          </b-form-tags>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-center">
        <button class="btn btn-secondary ml-3" @click="clearFilters()"> Limpiar filtros </button>
      </div>
    </div>
  </div>
</template>

<script>

// TODO Add award date as filter

import Vue from 'vue';

import Multiselect from 'vue-multiselect';
import countries from '@/_config/countries.json';
import cpv_codes from '@/_config/cpv_codes.json';
import contract_type_codes from '@/_config/contract_type_codes.json';
import status_codes from '@/_config/status_codes.json';
import provinces from '@/_config/provinces.json';

export default {
  name: 'procurementFilters',
  components: {
    Multiselect,
  },
  props: {
    currentFilters: {
    },
    updateFiltersUI: {
      type: Boolean,
    },
    excludedFilters: {
      type: Array,
    },
    excludedColumns: {
      type: Array,
    },
  },
  data() {
    return {
      boolFilters: {
        only_active: false,
        has_lots: false,
      },
      textFilters: {
        contracting_party_name: '',
        submission_deadline: '',
        city: '',
        date: {
          from: '',
          to: '',
        },
      },
      selectFilters: {
        selected_status: [],
        selected_country: 'ES',
        selected_regions: [],
        selected_cpv_codes: [],
        selected_contract_types: [],
        selected_division_option: 'all',
      },
      amount: {
        from: '',
        to: '',
      },
      awardee_parties: [],
      date_years: [],
      region_options: [
        {
          region: 'Andalucía',
          provinces: [
            { name: 'Almería', code: '04' },
            { name: 'Cádiz', code: '11' },
            { name: 'Córdoba', code: '14' },
            { name: 'Granada', code: '18' },
            { name: 'Huelva', code: '21' },
            { name: 'Jaén', code: '23' },
            { name: 'Málaga', code: '29' },
            { name: 'Sevilla', code: '41' },
          ],
        },
        {
          region: 'Aragón',
          provinces: [
            { name: 'Huesca', code: '22' },
            { name: 'Teruel', code: '44' },
            { name: 'Zaragoza', code: '50' },
          ],
        },
        {
          region: 'Asturias',
          provinces: [
            { name: 'Asturias', code: '33' },
          ],
        },
        {
          region: 'Baleares / Illes Balears',
          provinces: [
            { name: 'Baleares / Illes Balears', code: '07' },
          ],
        },
        {
          region: 'Canarias',
          provinces: [
            { name: 'Las Palmas', code: '35' },
            { name: 'Santa Cruz de Tenerife', code: '38' },
          ],
        },
        {
          region: 'Cantabria',
          provinces: [
            { name: 'Cantabria', code: '39' },
          ],
        },
        {
          region: 'Castilla y León',
          provinces: [
            { name: 'Ávila', code: '05' },
            { name: 'Burgos', code: '09' },
            { name: 'León', code: '24' },
            { name: 'Palencia', code: '34' },
            { name: 'Salamanca', code: '37' },
            { name: 'Segovia', code: '40' },
            { name: 'Soria', code: '42' },
            { name: 'Valladolid', code: '47' },
            { name: 'Zamora', code: '49' },
          ],
        },
        {
          region: 'Castilla-La Mancha',
          provinces: [
            { name: 'Albacete', code: '02' },
            { name: 'Ciudad Real', code: '13' },
            { name: 'Cuenca', code: '16' },
            { name: 'Guadalajara', code: '19' },
            { name: 'Toledo', code: '45' },
          ],
        },
        {
          region: 'Cataluña',
          provinces: [
            { name: 'Barcelona', code: '08' },
            { name: 'Girona / Gerona', code: '17' },
            { name: 'Lleida / Lérida', code: '25' },
            { name: 'Tarragona', code: '43' },
          ],
        },
        {
          region: 'Comunidad Valenciana',
          provinces: [
            { name: 'Alicante / Alacant', code: '03' },
            { name: 'Castellón / Castelló', code: '12' },
            { name: 'Valencia / València', code: '46' },
          ],
        },
        {
          region: 'Extremadura',
          provinces: [
            { name: 'Badajoz', code: '06' },
            { name: 'Cáceres', code: '10' },
          ],
        },
        {
          region: 'Galicia',
          provinces: [
            { name: 'A Coruña', code: '15' },
            { name: 'Lugo', code: '27' },
            { name: 'Ourense', code: '32' },
            { name: 'Pontevedra', code: '36' },
          ],
        },
        {
          region: 'Comunidad de Madrid',
          provinces: [
            { name: 'Comunidad de Madrid', code: '28' },
          ],
        },
        {
          region: 'Región de Murcia',
          provinces: [
            { name: 'Región de Murcia', code: '30' },
          ],
        },
        {
          region: 'Navarra',
          provinces: [
            { name: 'Navarra', code: '31' },
          ],
        },
        {
          region: 'País Vasco',
          provinces: [
            { name: 'Álava / Araba ', code: '01' },
            { name: 'Vizcaya / Bizkaia', code: '48' },
            { name: 'Guipúzcoa / Gipuzkoa', code: '20' },
          ],
        },
        {
          region: 'La Rioja',
          provinces: [
            { name: 'La Rioja', code: '26' },
          ],
        },
        {
          region: 'Ceuta',
          provinces: [
            { name: 'Ceuta', code: '51' },
          ],
        },
        {
          region: 'Melilla',
          provinces: [
            { name: 'Melilla', code: '52' },
          ],
        },
      ],
      division_options: [
        { value: 'all', text: 'Todos' },
        { value: 'no_lots', text: 'Sin división' },
        { value: 'has_lots', text: 'Dividido en Lotes' },
      ],
      countries_list: countries,
      cpv_codes,
      contract_type_codes,
      status_codes,
    };
  },
  computed: {
    filters() {
      const postal_codes = [];

      if (this.selectFilters.selected_country === 'ES') {
        for (let i = 0; i < this.selectFilters.selected_regions.length; i += 1) {
          postal_codes.push(this.selectFilters.selected_regions[i].code);
        }
      }

      const selected_cpv_codes = [];
      for (let i = 0; i < this.selectFilters.selected_cpv_codes.length; i += 1) {
        selected_cpv_codes.push(this.selectFilters.selected_cpv_codes[i].code);
      }

      const selected_contract_types = [];
      for (let i = 0; i < this.selectFilters.selected_contract_types.length; i += 1) {
        selected_contract_types.push(this.selectFilters.selected_contract_types[i].value);
      }

      const selected_status = [];
      for (let i = 0; i < this.selectFilters.selected_status.length; i += 1) {
        selected_status.push(this.selectFilters.selected_status[i].value);
      }

      const filters = {
        only_active: this.boolFilters.only_active,
        submission_deadline: this.textFilters.submission_deadline,
        location: {
          country: this.selectFilters.selected_country,
          postal_codes,
          city: this.textFilters.city,
        },
        contracting_party: {
          name: this.textFilters.contracting_party_name,
        },
        amount: {
          from: this.amount.from === '' ? this.amount.from : parseFloat(this.amount.from),
          to: this.amount.to === '' ? this.amount.to : parseFloat(this.amount.to),
        },
        date: {
          ...this.textFilters.date,
          years: this.date_years,
        },
        lot_division: this.selectFilters.selected_division_option,
        cpv_codes: selected_cpv_codes,
        contract_types: selected_contract_types,
        status: selected_status,
        awardee_parties: this.awardee_parties,
      };


      return filters;
    },
    column_size() {
      if (!this.excludedColumns) return '3';
      return Math.floor(12 / this.excludedColumns.length);
    },
  },
  mounted() {
    this.setFiltersFromCurrent();
  },
  methods: {
    addDateYear(year) {
      const index = this.date_years.findIndex(el => el === year);
      if (index > -1) {
        // Already there, remove
        this.date_years.splice(index, 1);
      } else {
        this.date_years.push(year);
      }
    },
    setDateYears(years) {
      Vue.set(this, 'date_years', years);
    },
    clearFilters() {
      this.boolFilters.only_active = false;
      this.textFilters.contracting_party_name = '';
      this.textFilters.submission_deadline = '';
      this.textFilters.city = '';
      this.textFilters.date.from = '';
      this.textFilters.date.to = '';
      this.selectFilters.selected_country = 'ES';
      Vue.set(this.selectFilters, 'selected_regions', []);
      Vue.set(this.selectFilters, 'selected_cpv_codes', []);
      Vue.set(this.selectFilters, 'selected_contract_types', []);
      Vue.set(this.selectFilters, 'selected_status', []);
      Vue.set(this, 'awardee_parties', []);
      Vue.set(this, 'date_years', []);
      this.amount.from = '';
      this.amount.to = '';
      this.saveFilters();
    },
    includeFilter(filter_id) {
      if (!this.excludedFilters) return true;
      return !this.excludedFilters.includes(filter_id);
    },
    includeColumn(column_id) {
      if (!this.excludedColumns) return true;
      return !this.excludedColumns.includes(column_id);
    },
    saveFilters() {
      this.$emit('setFilters', { filters: this.filters });
    },
    cpvCodeLabel({ code, value }) {
      return `${code} – ${value}`;
    },
    removeRegion(value) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i += 1) {
          const region = value[i];
          const index = this.selectFilters.selected_regions.findIndex(elem => elem.code === region.code);
          if (index !== -1) {
            this.selectFilters.selected_regions.splice(index, 1);
          }
        }
      } else {
        const index = this.selectFilters.selected_regions.findIndex(elem => elem.code === value.code);
        if (index !== -1) {
          this.selectFilters.selected_regions.splice(index, 1);
        }
      }
      this.saveFilters();
    },
    selectRegion(value) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i += 1) {
          const region = value[i];
          const index = this.selectFilters.selected_regions.findIndex(elem => elem.code === region.code);
          if (index === -1) {
            this.selectFilters.selected_regions.push(region);
          }
        }
      } else {
        const index = this.selectFilters.selected_regions.findIndex(elem => elem.code === value.code);
        if (index === -1) {
          this.selectFilters.selected_regions.push(value);
        }
      }
      this.saveFilters();
    },
    removeContractType(value) {
      const index = this.selectFilters.selected_contract_types.findIndex(elem => elem.code === value.code);
      if (index !== -1) {
        this.selectFilters.selected_contract_types.splice(index, 1);
      }
      this.saveFilters();
    },
    selectContractType(value) {
      const index = this.selectFilters.selected_contract_types.findIndex(elem => elem.code === value.code);
      if (index === -1) {
        this.selectFilters.selected_contract_types.push(value);
      }
      this.saveFilters();
    },
    removeStatus(value) {
      const index = this.selectFilters.selected_status.findIndex(elem => elem.code === value.code);
      if (index !== -1) {
        this.selectFilters.selected_status.splice(index, 1);
      }
      this.saveFilters();
    },
    selectStatus(value) {
      const index = this.selectFilters.selected_status.findIndex(elem => elem.code === value.code);
      if (index === -1) {
        this.selectFilters.selected_status.push(value);
      }
      this.saveFilters();
    },
    removeCpvCode(value) {
      const index = this.selectFilters.selected_cpv_codes.findIndex(elem => elem.code === value.code);
      if (index !== -1) {
        this.selectFilters.selected_cpv_codes.splice(index, 1);
      }
      this.saveFilters();
    },
    selectCpvCode(value) {
      const index = this.selectFilters.selected_cpv_codes.findIndex(elem => elem.code === value.code);
      if (index === -1) {
        this.selectFilters.selected_cpv_codes.push(value);
      }
      this.saveFilters();
    },
    setFiltersFromCurrent() {
      if (!this.currentFilters) return;
      if (this.currentFilters.contracting_party) {
        if (this.currentFilters.contracting_party.name) {
          this.textFilters.contracting_party_name = this.currentFilters.contracting_party.name;
        }
      }

      if (this.currentFilters.only_active) {
        this.boolFilters.only_active = this.currentFilters.only_active;
      }

      if (this.currentFilters.submission_deadline) {
        this.textFilters.submission_deadline = this.currentFilters.submission_deadline;
      }

      if (this.currentFilters.awardee_parties) {
        Vue.set(this, 'awardee_parties', this.currentFilters.awardee_parties);
      }

      if (this.currentFilters.location) {
        if (this.currentFilters.location.country) {
          this.selectFilters.selected_country = this.currentFilters.location.country;
        }
        if (this.currentFilters.location.postal_codes) {
          const selectedRegions = [];

          for (let i = 0; i < this.currentFilters.location.postal_codes.length; i += 1) {
            const postal_code = this.currentFilters.location.postal_codes[i];
            const region = provinces.find(p => p.code === postal_code);
            if (region) {
              selectedRegions.push(region);
            }
          }
          Vue.set(this.selectFilters, 'selected_regions', selectedRegions);
        }
        if (this.currentFilters.location.city) {
          this.textFilters.city = this.currentFilters.location.city;
        }
      }

      if (this.currentFilters.lot_division) {
        this.selectFilters.selected_division_option = this.currentFilters.lot_division;
      }

      if (this.currentFilters.amount) {
        if (this.currentFilters.amount.from) {
          this.amount.from = this.currentFilters.amount.from;
        }
        if (this.currentFilters.amount.to) {
          this.amount.to = this.currentFilters.amount.to;
        }
      }
      if (this.currentFilters.cpv_codes) {
        const selectedCpvCodes = [];

        for (let i = 0; i < this.currentFilters.cpv_codes.length; i += 1) {
          const code = this.currentFilters.cpv_codes[i];
          const cpv_code = cpv_codes.find(p => p.code === code);
          if (cpv_code) {
            selectedCpvCodes.push(cpv_code);
          }
        }
        Vue.set(this.selectFilters, 'selected_cpv_codes', selectedCpvCodes);
      }

      if (this.currentFilters.contract_types) {
        const selectedContractTypes = [];

        for (let i = 0; i < this.currentFilters.contract_types.length; i += 1) {
          const contract_type_value = this.currentFilters.contract_types[i];
          const contract_type = contract_type_codes.find(c => c.value === contract_type_value);
          if (contract_type) {
            selectedContractTypes.push(contract_type);
          }
        }
        Vue.set(this.selectFilters, 'selected_contract_types', selectedContractTypes);
      }
      if (this.currentFilters.status) {
        const selectedStatus = [];

        for (let i = 0; i < this.currentFilters.status.length; i += 1) {
          const status_value = this.currentFilters.status[i];
          const status = status_codes.find(c => c.value === status_value);
          if (status) {
            selectedStatus.push(status);
          }
        }
        Vue.set(this.selectFilters, 'selected_status', selectedStatus);
      }
    },
  },
  watch: {
    boolFilters: {
      deep: true,
      handler() {
        this.saveFilters();
      },
    },
    // eslint-disable-next-line object-shorthand
    date_years() {
      this.saveFilters();
      this.$emit('yearsChanged', this.date_years);
    },
    updateFiltersUI() {
      this.setFiltersFromCurrent();
    },
    // eslint-disable-next-line object-shorthand
    'selectFilters.selected_division_option'() {
      this.saveFilters();
    },
  },
};

</script>

<style scoped>
input{
  background-color: #FFFFFF;
}
.filter{
  margin-bottom: 15px;
}
.filter h3{
  font-size: 1.2em;
  margin:0px;
  padding: 0px;
}
.filter input, .custom-select{
  border: 1px solid #ccc !important;
  width: 90%;
  height: 35px;
  border-radius:0% !important;
  margin: 1px;
  padding-left: 5px !important;
}
.company input, .company input:active{
  border: 0 !important;
}
.agreement input{
  width: 45%;
}
.filter select{
  margin-bottom: 0 !important;
}
.stripe-light{
  background-color:#F0F0F0;

}
.stripe-dark{
   background-color:#E5E5E5;
}

</style>
