/* eslint-disable import/prefer-default-export */
/* eslint no-shadow: ["error", {"allow": ["state"]}] */
/* eslint no-param-reassign: ["error", { "props": true,
                                        "ignorePropertyModificationsFor": ["state"] }] */
import Vue from 'vue';

import alertService from '../_services/alert.service';

const state = {
  procurement: {
    alerts: [],
    current_page: 1,
    total_results: 0,
    total_pages: 1,
  },
  official_bulletin: {
    alerts: [],
    current_page: 1,
    total_results: 0,
    total_pages: 1,
  },
};

function compareAlerts(alertA, alertB) {
  if (alertA.starred && !alertB.starred) {
    return -1;
  }

  if (!alertA.starred && alertB.starred) {
    return 1;
  }

  return (alertA.name < alertB.name) ? -1 : 1;
}

const actions = {
  indexAlerts({ commit }, { alert_type = '', page = 1 } = {}) {
    // We return the promise from axios so that the view will now when the data has loaded
    return alertService.indexAlerts({ alert_type, page }).then(
      (response) => {
        const { alerts } = response.data;
        const { total_results, total_pages, current_page } = response.data.metadata;
        alerts.sort(compareAlerts);
        commit('storeAlerts', {
          alert_object: {
            alerts, total_results, total_pages, current_page,
          },
          alert_type,
        });
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  createAlert({ dispatch }, alert) {
    // We return the promise from axios so that the view will now when the data has loaded
    return new Promise((resolve, reject) => {
      alertService.createAlert(alert).then(
        () => {
          resolve();
          setTimeout(() => {
            // display success message after route change completes
            dispatch('flashMessages/success', { message: 'alerts.newAlert' }, { root: true });
          });
        },
      ).catch((error) => {
        if (error.response.status === 422) {
          const { errors } = error.response.data;
          let errorString = '';
          Object.keys(errors).forEach((errorKey) => {
            for (let i = 0; i < errors[errorKey].length; i += 1) {
              errorString += `${errorKey}: ${errors[errorKey][i]} <br>`;
            }
          });
          dispatch('flashMessages/error', { message: 'empty', i18nArgs: { content: errorString } }, { root: true });
          console.log(error);
          reject();
        }
      });
    });
  },
  deleteAlert({ dispatch }, id) {
    // We return the promise from axios so that the view will now when the data has loaded
    return alertService.deleteAlert(id).then(
      () => {
        dispatch('indexAlerts');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  updateAlert({ dispatch }, alert) {
    return new Promise((resolve, reject) => {
      alertService.updateAlert(alert).then(
        () => {
          dispatch('indexAlerts', { alert_type: alert.alert_type });
          resolve('');
        },
      ).catch((error) => {
        console.log(error);
        reject(error);
      });
    });
  },
};

const mutations = {
  storeAlerts(state, { alert_object, alert_type }) {
    Vue.set(state, alert_type, alert_object);
  },
};

const alertsModule = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default alertsModule;
