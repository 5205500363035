
<template>
  <div class="col-12 m-0 p-0">
    <iframe :src="sidekiqUrl" frameBorder="0">
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'sidekiqViewerComponent',
  computed: {
    sidekiqUrl() {
      return `${process.env.VUE_APP_API_BASE_URL}/sidekiq`;
    },
  },
};
</script>

<style scoped>
  iframe {
    height: 90vh;
    width: 100%;
  }
</style>
