<template>
<div>
  <div class="row">
    <div class="col-12">
      <apexchart ref="apexchart" width="500" type="bar" :options="chartConfig.options" :series="chartConfig.series"></apexchart>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-3 text-left">
      <button  class="btn btn-filter"
        @click="filtersVisibility = !filtersVisibility"
        aria-controls="procurement-filters-collapse"
        :aria-expanded="filtersVisibility ? 'true' : 'false'">
        <i class="fas fa-filter ml-1" ></i>
        Filtrar Adjudicaciones
      <i class="fas fa-angle-down mr-1" ></i>
      </button>
      <b-collapse v-model="filtersVisibility" class="collapse-tags" id="procurement-filters-collapse">
        <div class="stripe-light  p-3 border">
        <input type="text" class="form-control"
          id="search" placeholder="Filtrar por texto"
          v-model="searchTerms" @keyup.enter="getProcurementPartyTenderResults()" v-debounce:500ms="getTenderResultsDebounceSTerms">
        <procurement-filters
          ref="procurement_filters"
          v-on:setFilters="setFilters($event)"
          v-on:yearsChanged="filterYearsChanged($event)"
          :excludedFilters="['status', 'awardee_party', 'tender_submission_deadline']"/>
          </div>
      </b-collapse>
    </div>
  </div>
  <div class="row">
    <div class="col-6 text-left">
      <paginationComponent
        id="tender-results-pagination"
        :currentPage="current_page"
        :totalPages="total_pages"
        v-on:next-page-tender-results-pagination="changeTenderResultPage($event)"/>
    </div>
    <div class="col-6 text-right">
      <button v-b-tooltip.hover title="Exportar a excel" class="btn btn-excel ml-2 mr-2" @click="openExcelExportModal()">
        <i class="fas fa-file-excel"/> Exportar
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div v-if="tenderResultYears.length > 0">
         Mostrando los años {{ tenderResultYears.join(", ") }}
      </div>
      <table class="table text-left">
        <thead class="corporative">
          <tr>
            <th scope="col"> Fecha Publicación Licitación </th>
            <th scope="col"> Fecha Adjudicación </th>
            <th scope="col"> Resumen Licitación </th>
            <th scope="col"> Título </th>
            <th scope="col"> CPV </th>
            <th scope="col"> Lugar de Ejecución </th>
            <th scope="col"> Importe Total Licitación</th>
            <th scope="col"> Descripción  Lote </th>
            <th scope="col"> Importe Total Lote </th>
            <th scope="col"> Importe Total Recibida </th>
            <th scope="col"> Razón de adjudicación </th>
            <th scope="col"> Órgano o Entidad </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tender_result, index) in tender_results" v-bind:key="`tender_result_ ${index}`">
            <td>{{ tender_result.project.date | moment('DD/MM/YYYY')}} </td>
            <td>{{ tender_result.award_date | moment('DD/MM/YYYY')}} </td>
            <td v-b-tooltip.hover title="Ver Licitación completa">
              <router-link :to="{
                name: 'procurement',
                params: {
                  id: tender_result.project.id
                  }
                }"
                >
                {{ tender_result.project.summary }}
                </router-link>
            </td>
            <td> {{ tender_result.project.title }} </td>
            <td>
              <ul>
                <li v-for="(cpv_code, cpvIndex) in tender_result.project.cpv_codes" v-bind:key="`cpv_code_${index}_${cpvIndex}`">
                  {{cpv_code}}
                </li>
              </ul>
            </td>
            <td>{{ locationStr(tender_result.project.location) }}</td>
            <td> {{ formatAmount(tender_result.project.total_amount) }}</td>
            <td> {{ tender_result.lot_id ? `Lote ${tender_result.lot_id}.` : '' }} {{ tender_result.lot.description }} </td>
            <td> {{ formatAmount(tender_result.lot.total_amount) }}</td>
            <td> {{ formatAmount(tender_result.payable_amount) }}</td>
            <td> {{ tender_result.summary }} </td>
            <td>
              <template v-if="tender_result.contracting_party && tender_result.contracting_party">
                <router-link :to="{
                  name: 'procurement_party',
                  params: {
                  id: tender_result.contracting_party
                      }
                    }">
                  {{tender_result.contracting_party.name}}
                </router-link>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal
      ref="excel-export-modal"
      hide-footer>
       <template #modal-title>
        <h2 class="corporative-principal">Exportar adjudicaciones a Excel </h2>
      </template>
        Adjudicaciones a exportar:
        <b-form-select class="my-2" v-model="exportNumber" :options="exportOptions"></b-form-select>
        <button class="btn btn-secondary mt-2" @click="exportExcel()"> Exportar  <i class="fas fa-file-export"></i></button>
    </b-modal>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import procurementService from '@/_services/procurement.service';
import procurementMixin from '@/_mixins/procurement.mixin';
import excelExporter from '@/_helpers/excel_exporter';
import paginationComponent from '@/components/helpers/pagination.vue';
import procurementFilters from './procurement_filters.vue';

const es = require('apexcharts/dist/locales/es.json');

export default {
  name: 'procurementPartyTenderResultsComponent',
  mixins: [procurementMixin],
  props: ['party'],
  components: {
    paginationComponent,
    procurementFilters,
  },
  data() {
    return {
      filtersVisibility: false,
      tender_results: [],
      current_page: 1,
      total_results: 0,
      total_pages: 0,
      tenderResultYears: [],
      searchTerms: '',
      currentFilters: {},
      exportNumber: 25,
      exportOptions: [
        { value: 25, text: '25 (Página actual)' },
        { value: 50, text: '50' },
        { value: 75, text: '75' },
        { value: 100, text: '100' },
        { value: 1000, text: 'Todas (máximo 1000)' },
      ],
    };
  },
  mounted() {
    this.getProcurementPartyTenderResults();
  },
  computed: {
    chartConfig() {
      const labels = this.party ? Object.keys(this.party.stats.tender_results).sort() : [];
      const self = this;
      const chart = {
        options: {
          colors: ['#4953CF'],
          chart: {
            height: 350,
            type: 'line',
            locales: [es],
            defaultLocale: 'es',
            events: {
              dataPointSelection: (event, chartContext, config) => {
                self.addYear(self.chartConfig.options.labels[config.dataPointIndex], !!event);
              },
            },
          },
          title: {
            text: 'Licitaciones ganadas',
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          labels,
          xaxis: {
            type: 'year',
          },
          states: {
            active: {
              allowMultipleDataPointsSelection: true,
            },
          },
          yaxis: [{
            title: {
              text: 'Miles de euros',
            },
            labels: {
              formatter(val) {
                return val.toFixed(0);
              },
            },
          }],
          tooltip: {
            y: {
              formatter(val) {
                return val.toFixed(2);
              },
            },
            x: {
              formatter: seriesName => `${seriesName} - Haz click para ver o quitar las adjudicaciones del año`,
            },
          },
        },
        series: [
          {
            name: this.party ? this.party.name : '',
            type: 'column',
            data: [],
          },
        ],
      };

      const data = [];
      let inMillions = false;
      for (let i = 0; i < labels.length; i += 1) {
        const value = Math.round(this.party.stats.tender_results[labels[i]]);
        if (value > 1000000) {
          inMillions = true;
        }
        data.push(value);
      }

      if (inMillions) {
        chart.series[0].data = data.map(val => val / 1000000.0);
        chart.options.yaxis[0].title.text = 'Millones de euros';
      } else {
        chart.series[0].data = data;
      }
      return chart;
    },
  },
  methods: {
    filterYearsChanged(years) {
      const yearsToAdd = years.filter(value => !this.tenderResultYears.includes(value));
      const yearsToRemove = this.tenderResultYears.filter(value => !years.includes(value));
      const yearsToToggle = yearsToAdd.concat(yearsToRemove);

      const year_indexes = [];
      yearsToToggle.map((year) => {
        const index = this.chartConfig.options.labels.findIndex(label => label === year);
        if (index >= 0) {
          year_indexes.push(index);
        }
        return index;
      });

      for (let i = 0; i < year_indexes.length; i += 1) {
        this.$refs.apexchart.toggleDataPointSelection(0, year_indexes[i]);
      }
    },
    addYear(year, fromUserClick = true) {
      const index = this.tenderResultYears.findIndex(el => el === year);
      if (index > -1) {
        // Already there, remove
        this.tenderResultYears.splice(index, 1);
      } else {
        this.tenderResultYears.push(year);
      }

      if (fromUserClick) {
        this.$refs.procurement_filters.setDateYears(this.tenderResultYears);
      }
    },
    changeTenderResultPage(page) {
      this.getProcurementPartyTenderResults(page);
    },
    exportExcel() {
      const filename = `${this.$moment().format('YYYY_MM_DD')}_exportacion_adjudicaciones.xlsx`;

      const params = {
        years: this.tenderResultYears,
      };

      if (Object.keys(this.currentFilters).length > 0) {
        params.filters = this.currentFilters;
      }

      if (this.searchTerms !== '') {
        params.searchTerms = this.searchTerms;
      }

      procurementService.getProcurementPartyTenderResults(this.$route.params.id, params).then((res) => {
        const { tender_results } = res.data;
        excelExporter.saveProcurementTenderResults(tender_results, this.party.name, filename);
      });
    },
    getTenderResultsDebounceSTerms() {
      this.getProcurementPartyTenderResults();
    },
    getProcurementPartyTenderResults(page = 1) {
      const params = {
        page,
        years: this.tenderResultYears,
      };

      if (Object.keys(this.currentFilters).length > 0) {
        params.filters = this.currentFilters;
      }

      if (this.searchTerms !== '') {
        params.searchTerms = this.searchTerms;
      }

      const self = this;
      procurementService.getProcurementPartyTenderResults(this.$route.params.id, params).then((res) => {
        const { tender_results } = res.data;
        const { total_results, total_pages, current_page } = res.data.metadata;

        Vue.set(self, 'tender_results', tender_results);

        self.total_results = total_results;
        self.total_pages = total_pages;
        self.current_page = current_page;
      });
    },
    setFilters(event) {
      Vue.set(this, 'currentFilters', event.filters);
      this.getProcurementPartyTenderResults();
    },
    openExcelExportModal() {
      this.$refs['excel-export-modal'].show();
    },
  },
  watch: {
    tenderResultYears() {
      this.getProcurementPartyTenderResults();
    },
  },
};
</script>

<style scoped>

.party{
  padding: 30px;
}
h1{
  display:inline-block;
  margin-left: 5px;
}
.fa-external-link-alt {
  color:#000000;
}
.fa-external-link-alt:hover {
  cursor:pointer;
  color:#4953CF;
}
</style>
