var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row m-0 p-0"},[_c('div',{staticClass:"col-11"},[_vm._v("\n      Fecha notificación: "+_vm._s(_vm.notification.date)+"\n      "),_c('h5',{staticClass:"pt-2"},[_c('router-link',{attrs:{"to":{
        name: 'procurement_party',
        params: {
          id: _vm.notification.data.id
        }
      }}},[_c('span',{staticClass:"circle-tag-entity"},[_vm._v("E")]),_vm._v("  "+_vm._s(_vm.notification.data.name)+"\n      ")]),_vm._v("\n      ha recibido "+_vm._s(_vm.new_tender_results_string)+" ")],1)]),(_vm.showActions)?_c('div',{staticClass:"col-1 text-rigth"},[(_vm.hasSubscription)?[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"far fa-bell-slash fa-2x",attrs:{"title":"Dejar de recibir actualizaciones de esta empresa"},on:{"click":function($event){return _vm.deletePartySubscription(_vm.notification.data.id)}}})]:[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"far fa-bell fa-2x",attrs:{"title":"Recibir actualizaciones de esta empresa"},on:{"click":function($event){return _vm.createPartySubscription({id: _vm.notification.data.id})}}})],_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-trash-alt fa-2x ml-2",attrs:{"title":"Descartar"},on:{"click":function($event){return _vm.updateNotification({processed: true, id: _vm.notification.id})}}})],2):_vm._e()]),_c('div',{staticClass:"row p-0 m-0"},[_c('ul',{staticClass:"m-0"},_vm._l((_vm.tender_results_by_project),function(project){return _c('li',{key:("tender_result_project_" + (project.id))},[_c('router-link',{attrs:{"to":{
        name: 'procurement',
        params: {
          id: project.id
        }
      }}},[_vm._v("\n        "+_vm._s(project.title)+"\n      ")]),_c('ul',{staticClass:"mb-2"},_vm._l((project.tender_results),function(tender_result,index){return _c('li',{key:("tender_result_" + (project.id) + "_" + index)},[(tender_result.lot_id)?[_c('strong',[_vm._v("Lote "+_vm._s(tender_result.lot_id)+" ")])]:_vm._e(),_c('strong',[_vm._v("Descripción:")]),_vm._v(" "+_vm._s(tender_result.description)+"\n          "),_c('br'),_c('strong',[_vm._v("Importe Total Recibido:")]),_vm._v(" "+_vm._s(_vm.formatAmount(tender_result.payable_amount))+"\n        ")],2)}),0)],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }