<template>
  <CorporativeComponent/>
</template>

<script>
import CorporativeComponent from '@/components/CorporativeInfo.vue';

export default {
  name: 'landingcomponent',
  components: {
    CorporativeComponent,
  },
  data() {
    return {
      page: 1,
    };
  },
};
</script>
