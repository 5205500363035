<template>
  <b-modal id="new-alert-modal" hide-footer :title="`Nueva Alerta`" size="xl" >
    <template #modal-title>
      <h1>Nueva Alerta {{ modalTitle }} </h1>
    </template>
    <div class ="new-alert-form">
      <div class="col-12 grid mt-4 mb-0 p-0">
        <div class="col-3 mt-4">
          <h4>Nombre de alerta</h4>
        </div>
        <div class="col-9">
          <div class="input">
            <input type="text" v-model="name" id="name"  v-validate="'required'" name="name" >
            <span v-show="errors.has('name')" class="invalid">{{ errors.first('name') }}</span>
          </div>
        </div>
      </div>
      <div class="col-9">
        Frecuencia emails:
        <b-form-select v-model="mailing_frequency" :options="mailing_frequency_options"></b-form-select>
      </div>
      <div class="col-12 mt-4">
        <h4 class="mb-2"> Resumen alerta: </h4>
        <dl class="row">
          <dt class="col-sm-3"> Términos de búsqueda: </dt>
          <dd class="col-sm-9"> {{searchTerms}} </dd>
        </dl>
        <template v-if="filters && Object.keys(filters).length > 0">
          <dl class="row">
            <template v-if="filters.title && filters.title !== ''">
              <dt class="col-sm-3"> Título: </dt>
              <dd class="col-sm-9"> {{filters.title}} </dd>
            </template>
            <template v-if="alert_type === 'official_bulletin'">
              <template v-if="filters.summary && filters.summary !== ''">
                <dt class="col-sm-3"> Resumen:</dt>
                <dd class="col-sm-9"> {{filters.summary}} </dd>
              </template>
              <template v-if="filters.department && filters.department !== ''">
                <dt class="col-sm-3"> Departamento:</dt>
                <dd class="col-sm-9">{{filters.department}}</dd>
              </template>
              <template v-if="filters.must_not_terms && filters.must_not_terms !== ''">
                <dt class="col-sm-3"> Términos de exclusión:</dt>
                <dd class="col-sm-9">{{filters.must_not_terms}}</dd>
              </template>
              <dt class="col-sm-3"> Fuentes de datos:</dt>
              <dd class="col-sm-9" v-if="filters.sources && filters.sources.length > 0">
                {{ filters.sources.join(', ')}}
              </dd>
              <dd class="col-sm-9" v-else>
                Todas
              </dd>
              <template v-if="filters.highlightTerms && filters.highlightTerms !== ''">
                <dt class="col-sm-3"> Resaltado del documento:</dt>
                <dd class="col-sm-9">{{filters.highlightTerms}}</dd>
              </template>
            </template>
            <template v-if="alert_type === 'procurement'">
              <template v-if="filters.contracting_party && filters.contracting_party.name && filters.contracting_party.name !== ''">
                <dt class="col-sm-3"> Órgano de contratación:</dt>
                <dd class="col-sm-9"> {{filters.contracting_party.name}} </dd>
              </template>
              <template v-if="filters.status && filters.status.length > 0">
                <dt class="col-sm-3"> Estado:</dt>
                <dd class="col-sm-9">{{filters.status.join(', ')}}</dd>
              </template>
              <template v-if="filters.cpv_codes && filters.cpv_codes.length > 0">
                <dt class="col-sm-3"> Códigos CPV:</dt>
                <dd class="col-sm-9">{{cpv_codes(filters.cpv_codes)}}</dd>
              </template>
              <template v-if="filters.contract_types && filters.contract_types.length > 0">
                <dt class="col-sm-3"> Tipo de Contrato:</dt>
                <dd class="col-sm-9">{{filters.contract_types.join(', ')}}</dd>
              </template>
              <template v-if="filters.submission_deadline && filters.submission_deadline !== ''">
                <dt class="col-sm-3"> Fecha límite de presentación:</dt>
                <dd class="col-sm-9">
                  {{date(filters.submission_deadline)}}
                </dd>
              </template>
              <template v-if="filters.date && (filters.date.from || filters.date.to)">
                <dt class="col-sm-3"> Fecha publicación entre:</dt>
                <dd class="col-sm-9">
                    <template v-if="filters.date.from && filters.date.from != ''">
                    {{ date(filters.date.from) }}
                  </template>
                  -
                  <template v-if="filters.date.to && filters.date.to != ''">
                    {{ date(filters.date.to) }}
                  </template>
                </dd>
              </template>
              <template v-if="filters.amount && (filters.amount.from || filters.amount.to)">
                <dt class="col-sm-3"> Valor estimado del contrato:</dt>
                <dd class="col-sm-9">
                    <template v-if="filters.amount.from && filters.amount.from != ''">
                    Desde {{ formatAmount(filters.amount.from) }}
                  </template>
                  <template v-if="filters.amount.to && filters.amount.to != ''">
                    Hasta {{ formatAmount(filters.amount.to) }}
                  </template>
                </dd>
              </template>
              <template v-if="filters.location">
                <template v-if="filters.location.country">
                  <dt class="col-sm-3"> País:</dt>
                  <dd class="col-sm-9">{{country(filters.location.country)}}</dd>
                </template>
                <template v-if="filters.location.city">
                  <dt class="col-sm-3"> Ciudad:</dt>
                  <dd class="col-sm-9">{{filters.location.city}}</dd>
                </template>
                <template v-if="filters.location.postal_codes && filters.location.postal_codes.length > 0">
                  <dt class="col-sm-3"> {{filters.location.postal_codes.length > 1 ? 'Regiones' : 'Región'}} </dt>
                  <dd class="col-sm-9">{{region_names(filters.location.postal_codes)}}</dd>
                </template>
              </template>
            </template>
          </dl>
        </template>
      </div>
      <div class="col-12 submit-block my-2">
          <button class="btn btn-secondary"
          @click="onSubmit()"> <i class="fas fa-save" title="guardar"></i> Guardar Alerta </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import alertFiltersMixin from '@/_mixins/alert_filters.mixin';

export default {
  name: 'newAlertModal',
  props: ['searchTerms', 'filters', 'alert_type'],
  mixins: [alertFiltersMixin],
  data() {
    return {
      name: '',
      mailing_frequency: 'daily',
      mailing_frequency_options: [
        { value: 'daily', text: 'Diario' },
        { value: 'weekly', text: 'Semanal' },
        { value: 'no_email', text: 'Sin email' },
      ],
    };
  },
  computed: {
    modalTitle() {
      if (this.alert_type === 'official_bulletin') {
        return 'Publicaciones Oficiales';
      }
      return 'Licitaciones';
    },
  },
  methods: {
    ...mapActions('alertsModule', {
      createAlert: 'createAlert',
    }),
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const formData = {
            name: this.name,
            mailing_frequency: this.mailing_frequency,
            searchTerms: this.searchTerms,
            filters: this.filters,
            alert_type: this.alert_type,
          };

          this.createAlert(formData).then(() => {
            this.$bvModal.hide('new-alert-modal');
          });
        }
      });
    },
  },
};
</script>

<style scoped>
*{
  text-align: left;
  vertical-align: baseline;
}
h4{
  margin-bottom:0px;
}
ul{
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.new-alert-form {
  min-width: 800px;
  max-width: 1000px;
  margin: 10px auto;
  border: 1px solid #eee;
  padding: 20px;
  box-shadow: 0 2px 3px #ccc;
}
.search-div{
  text-align: right;
}
.multiple {
  display: flex;
  flex-direction: row;
}
.checkboxes {
  text-align: left;
}
.source-name{
  font-size: 0.8em;
}
.paragraph_triggers h5 {
  text-align: left;
}
.submit-block{
  text-align: center;
}
.collapse-tags{
  background-color: #EBEBEB;
}
.fa-save{
  color:white;
}

</style>
