<template>
<div>
  <div class="row m-0 p-0">
    <div class="col-11">
      Fecha notificación: {{notification.date}}
      <h5 class="pt-2">
      <span class ="circle-tag">N</span> {{newProcurementProjectString}}
      <router-link :to="{
          name: 'procurement',
          params: {
            id: notification.data.id
          }
        }">
          {{notification.data.title}}
      </router-link>
    </h5>
    </div>
      <div class="col-1 text-right" v-if="showActions">
        <template v-if="projectHasSubscription(notification.data.id)">
          <i v-b-tooltip.hover title="Dejar de seguir" class="far fa-bell-slash fa-2x" @click="deleteProjectSubscription(notification.data.id)"></i>
        </template>
        <template v-else>
          <template v-if="isNotResolved(notification.data)">
            <i v-b-tooltip.hover title="Seguir" class="far fa-bell fa-2x"  @click="createProjectSubscription(notification.data.id)"></i>
          </template>
          <template v-else-if="isNotOutdated(notification.data)">
              <i v-b-tooltip.hover title="Avisarme cuando queden 6 meses de la finalización del período de ejecución" @click="createProjectSubscription(notification.data.id)" class="far fa-clock fa-2x"></i>
          </template>
        </template>
        <i v-b-tooltip.hover title="Descartar" class ="fas fa-trash-alt fa-2x ml-2"  @click="updateNotification({processed: true, id: notification.id})"></i>
      </div>
  </div>
  <div class="row p-0 m-0">
    <ul class="pt-2">
      <li> <b> Importe Total Estimado: </b> {{ formatAmount(notification.data.estimated_overall_contract_amount)}} </li>
      <li> <b> Lugar Ejecución: </b> {{ locationStr(notification.data.location) }}</li>
      <li>
        <b> Plazo de ejecución: </b>
        {{ plannedPeriod(notification.data.planned_period, notification.data.planned_period_unit) }}
      </li>
      <li> <b> Fecha límite presentación: </b> {{ date(notification.data.submission_deadline) }} </li>
      <li> <b> Órgano o entidad: </b>
        <router-link :to="{
          name: 'procurement_party',
          params: {
            id: notification.data.contracting_party.id
          }
        }">
          {{notification.data.contracting_party.name}}
        </router-link>
      </li>
      <li> <b> Por Lotes: </b> {{ notification.data.has_lots ? 'Sí' : 'No' }} </li>
    </ul>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import procurementMixin from '@/_mixins/procurement.mixin';

export default {
  name: 'projectNotification',
  props: {
    notification: {
      required: true,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [procurementMixin],
  computed: {
    newProcurementProjectString() {
      let str = 'Nueva licitación';
      if (this.notification.notification_types.includes('procurement_party_procurement_project_subscription')) {
        str += ' publicada por un órgano de contratación al que sigues';
      }
      str += ':';
      return str;
    },
  },
  methods: {
    ...mapActions('procurementNotificationsModule', {
      updateNotification: 'updateNotification',
    }),
  },
};
</script>

<style scoped>
ul{
  display:block;
}
</style>
