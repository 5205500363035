<template>
  <div>
    <div class="row px-3 mx-3 my-3" v-if="procurement">
      <div class="col-11 m-0 p-0">
        <h1 class="m-0 p-0"> {{procurement.title}}</h1>
      </div>
      <div class="col-1">
        <template v-if="projectHasSubscription(procurement.id)">
          <i v-b-tooltip.hover title="Dejar de seguir" class="far fa-bell-slash fa-2x" @click="deleteProjectSubscription(procurement.id)"></i>
        </template>
        <template v-else>
          <template v-if="isNotResolved(procurement)">
            <i v-b-tooltip.hover title="Seguir" class="far fa-bell fa-2x"  @click="createProjectSubscription(procurement.id)"></i>
          </template>
          <template v-else-if="isNotOutdated(procurement)">
              <i v-b-tooltip.hover title="Avisarme cuando queden 6 meses de la finalización del período de ejecución" @click="createProjectSubscription(procurement.id)" class="far fa-clock fa-2x"></i>
          </template>
        </template>
      </div>
    </div>
    <div class="px-3 mx-3 my-3" v-if="procurement && procurement.tender_results.length > 0">
      <template v-if="procurement.lots.length <= 0">
      <div class="procurement-result" v-for="(tender_result, tindex) in procurement.tender_results"
        v-bind:key="`tender_result_${tindex}`">
        <h2> Licitación {{ procurement.status }}</h2>
        <p> <strong>Razón: </strong> {{tender_result.summary}} </p>
        <p> <strong>Fecha de adjudicación:</strong> {{date(tender_result.award_date) }}</p>
        <p> <strong>Adjudicado en :</strong> {{ formatAmount(tender_result.payable_amount) }} </p>
        <p> <strong>Diferencia con el importe licitado:</strong> {{formatAmount(procurement.total_amount - tender_result.payable_amount)}}
       <strong> ({{(((procurement.total_amount - tender_result.payable_amount) *100)/ procurement.total_amount).toFixed()}} %)</strong></p>

        <p v-if="tender_result.party.id"> <strong>Empresa: </strong>
          <router-link :to="{
                    name: 'procurement_party',
                    params: {
                      id: tender_result.party.id
                    }
                  }">
                  {{tender_result.party.name}}
          </router-link>
          <template v-if="partyHasSubscription(tender_result.party.id)">
            <i v-b-tooltip.hover title="Dejar de recibir actualizaciones de esta empresa" class="far fa-bell-slash"  @click="deletePartySubscription(tender_result.party.id)"></i>
          </template>
          <template v-else>
            <i v-b-tooltip.hover title="Recibir actualizaciones de esta empresa" class="far fa-bell"  @click="createPartySubscription({id: tender_result.party.id})"></i>
          </template>
        </p>
      </div>
      </template>
      <template v-else>
          <h2> Licitación {{ procurement.status }}</h2>
          <vueper-slides
              fixed-height="420px"
              :visible-slides="3"
              class="no-shadow"
              :slide-ratio="1 / 4"
              :dragging-distance="70"
              :arrows-outsde="false"
          >
              <vueper-slide v-for="(lot, index) in procurement.lots"
                v-bind:key="`lot_${index}`">
                <template v-slot:content>
                  <procurementProjectLot :lot="lot" :tender_result="getLotTenderResult(procurement.tender_results, lot)"/>
                </template>
              </vueper-slide>
          </vueper-slides>
      </template>
    </div>
    <div class="row px-3 mx-3 my-3" v-if="procurement">
      <div class="col-6 pl-0">
        <table>
          <tr>
            <th>Estado</th>
            <td> {{ procurement.status }}</td>
          </tr>
          <tr>
            <th>Resumen </th>
            <td> {{procurement.summary}}</td>
          </tr>
          <tr>
            <th>Importe Total</th>
            <td> {{formatAmount(procurement.total_amount)}}</td>
          </tr>
          <tr>
            <th>Nº expediente</th>
            <td>{{procurement.contract_folder_id}} </td>
          </tr>
          <tr>
            <th>Link al original</th>
            <td><a :href="procurement.link" target="_blank">Abrir link<i class="fas fa-external-link-alt ml-2"></i></a></td>
          </tr>
          <tr>
            <th>Periodo</th>
            <td>{{plannedPeriod(procurement.planned_period, procurement.planned_period_unit)}}</td>
          </tr>
          <tr>
            <th>Tipo de Contrato</th>
            <td>{{procurement.contract_type}} </td>
          </tr>
          <tr>
            <th>Procedimiento</th>
            <td>{{procurement.procedure_code}}</td>
          </tr>
          <tr>
            <th>Entidad</th>
            <td>
              <router-link :to="{
                name: 'procurement_party',
                params: {
                  id: procurement.contracting_party.id
                }
              }">
              {{ procurement.contracting_party.name }}
              </router-link>
              <template v-if="partyHasSubscription(procurement.contracting_party.id)">
                <i v-b-tooltip.hover title="Dejar de recibir actualizaciones de esta empresa" class="far fa-bell-slash"  @click="deletePartySubscription(procurement.contracting_party.id)"></i>
              </template>
              <template v-else>
                <i v-b-tooltip.hover title="Recibir actualizaciones de esta empresa" class="far fa-bell"  @click="createPartySubscription({id: procurement.contracting_party.id})"></i>
              </template>
            </td>
          </tr>
          <tr>
            <th>Lugar</th>
            <td>{{locationStr(procurement.location)}} </td>
          </tr>
          <tr>
            <th>Fecha publicación</th>
            <td> {{procurement.date | moment('DD/MM/YYYY')}} </td>
          </tr>
          <tr>
            <th>Fecha Límite Presentación</th>
            <td>{{procurement.submission_deadline | moment('HH:mm:ss DD/MM/YYYY')}} </td>
          </tr>
          <tr>
            <th>CPV</th>
            <td>
              <ul>
                <li v-for="(cpv_code, cpvIndex) in procurement.cpv_codes" v-bind:key="`cpv_code_${cpvIndex}`">
                  {{cpv_code}}
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
      <div v-if ="Object.keys(procurement.documents).length > 0" class="col-6">
        <b-tabs class="procurement-tabs">
          <b-tab title="Doc. General" v-if="procurement.documents.general_document">
            <documentsList :documents="procurement.documents.general_document"/>
          </b-tab>
          <b-tab title="Doc. Legal" v-if="procurement.documents.legal_document">
            <documentsList :documents="procurement.documents.legal_document"/>
          </b-tab>
          <b-tab title="Doc. Técnica" v-if="procurement.documents.technical_document">
            <documentsList :documents="procurement.documents.technical_document"/>
          </b-tab>
          <b-tab title="Doc. Adicional" v-if="procurement.documents.additional_document">
            <documentsList :documents="procurement.documents.additional_document"/>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div class="row px-3 m-3" v-if="procurement && procurement.tender_results.length <= 0 && procurement.lots.length > 0 ">
      <div class="col-12">
        <h3> Lotes: </h3>
        <div class="col-12">
          <vueper-slides
              fixed-height="420px"
              :visible-slides="3"
              class="no-shadow"
              :slide-ratio="1 / 4"
              :dragging-distance="70"
              :arrows-outsde="false"
          >
              <vueper-slide v-for="(lot, index) in procurement.lots"
                v-bind:key="`lot_${index}`">
                <template v-slot:content>
                  <procurementProjectLot :lot="lot" :tender_result="getLotTenderResult(procurement.tender_results, lot)"/>
                </template>
              </vueper-slide>
          </vueper-slides>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import { VueperSlides, VueperSlide } from 'vueperslides';
import procurementService from '@/_services/procurement.service';
import procurementMixin from '@/_mixins/procurement.mixin';
import 'vueperslides/dist/vueperslides.css';

import documentsList from './documents_list.vue';
import procurementProjectLot from './procurement_project_lot.vue';
// import Tag from '../tags/tag.vue';

export default {
  name: 'procurementComponent',
  mixins: [procurementMixin],
  components: {
    documentsList,
    procurementProjectLot,
    VueperSlides,
    VueperSlide,
    // Tag,
  },
  data() {
    return {
      procurement: null,
    };
  },
  mounted() {
    this.fetchProcurement();
  },
  methods: {
    fetchProcurement() {
      procurementService.getProcurement(this.$route.params.id).then((res) => {
        Vue.set(this, 'procurement', res.data);
        console.log(this.procurement);
      });
    },
  },
  watch: {
    $route() {
      this.fetchProcurement();
    },
  },
};
</script>

<style scoped>

*{
  text-align: left;
}
h1{
  display:inline;
  padding: 10px;
}
.procurement-tabs{
  height: 100%;
  border: none !important;
}
ul, li{
  margin:0;
  padding: 0;
}

table{
  margin-bottom: 15px;
}
table th,td{
  border: 1px solid #dee2e6;
  padding: 10px  20px;
}
.procurement-result{
  border: 2px solid #dee2e6;
  box-shadow: 5px 5px 3px #dee2e6;
  border-radius: 2px;
  padding: 20px 20px 0px 20px;
  margin-bottom: 20px;

}

</style>
