import axios from '../_helpers/axios';

function createTag(tagInfo) {
  // eslint-disable-next-line camelcase
  const { font_color, background_color, name } = tagInfo;

  const postData = {
    tag: {
      font_color,
      background_color,
      name,
    },
  };

  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };

  return axios.post(`/${process.env.VUE_APP_API_VERSION}/tags`, postData, axiosConfig).then(res => res);
}

function deleteTag(id) {
  return axios.delete(`/${process.env.VUE_APP_API_VERSION}/tags/${id}`).then(res => res);
}

function indexTags() {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/tags`).then(res => res);
}

function updateTag(tag) {
  // eslint-disable-next-line camelcase
  const { font_color, background_color, name } = tag;

  const putData = {
    tag: {
      font_color,
      background_color,
      name,
    },
  };

  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };
  return axios.put(`/${process.env.VUE_APP_API_VERSION}/tags/${tag.id}/`, putData, axiosConfig).then(res => res);
}

const tagService = {
  indexTags,
  updateTag,
  createTag,
  deleteTag,
};

export default tagService;
