import Vue from 'vue';
import VeeValidate from 'vee-validate';
import es from 'vee-validate/dist/locale/es';
import en from 'vee-validate/dist/locale/en';
import i18n from './i18n';

const veeValidateConfig = {
  classes: true,
  locale: 'es',
  i18nRootKey: 'validations',
  i18n,
  dictionary: {
    es: {
      messages: es.messages,
      attributes: {
        name: 'nombre',
        password: 'contraseña',
        confirmPassword: 'confirmar contraseña',
        teamName: 'nombre del equipo',
        cardholder: 'titular de la tarjeta',
        subscriptionUsers: 'usuarios',
      },
      custom: {
        'address-line1': {
          required: 'Introduce una calle',
        },
        zip_code: {
          required: 'Introduce un código postal',
        },
        country: {
          required: 'Introduce un país',
        },
        city: {
          required: 'Introduce una ciudad',
        },
        state: {
          required: 'Introduce una provincia',
        },
        subscriptionUsers: {
          required: 'Debe haber al menos 1 usuario en la suscripción',
          min: 'Debe haber al menos 1 usuario en la suscripción',
        },
        confirmText: {
          required: 'Debes introducir la palabra "confirmar"',
        },
      },
    },
  },
  fieldsBagName: 'veeValidateFields',
};

Vue.use(VeeValidate, veeValidateConfig);
