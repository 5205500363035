/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
const state = {
  type: null,
  message: null,
  i18nArgs: null,
};

const actions = {
  success({ commit }, messageInfo) {
    commit('success', messageInfo);
    setTimeout(() => commit('clear'), 3000);
  },
  error({ commit }, messageInfo) {
    commit('error', messageInfo);
    setTimeout(() => commit('clear'), 3000);
  },
  clear({ commit }) {
    commit('clear');
  },
};

const mutations = {
  success(state, messageInfo) {
    state.type = 'alert-success';
    state.message = messageInfo.message;
    state.i18nArgs = messageInfo.i18nArgs;
  },
  error(state, messageInfo) {
    state.type = 'alert-danger';
    state.message = messageInfo.message;
    state.i18nArgs = messageInfo.i18nArgs;
  },
  clear(state) {
    state.type = null;
    state.message = null;
    state.i18nArgs = null;
  },
};

const flashMessages = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default flashMessages;
