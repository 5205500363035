<template>
  <div class="text-left">
    <h1 class="text-center py-3">Mis empresas y entidades</h1>
    <div>
        <label for="email">Buscar Empresa / Entidad </label>
        <input type="company"
          name="company" v-model="searchParty" @keyup.enter="toggleSidebar()"
        class="border mx-3">
        <button class="btn btn-secondary" @click="toggleSidebar()"> Buscar <i class="fas fa-search"></i></button>
    </div>
    <b-sidebar id="companies-sidebar" title="Añadir empresas o entidades" width="50%"  right shadow backdrop>
      <div class="p-3">
        <div class="row">
          <div class="col-12">
            <input type="company"
              name="company" v-model="searchParty" @keyup.enter="searchParties()"
              class="border" v-debounce:500ms="searchParties">
            <button class="btn btn-secondary ml-2" @click="searchParties()"> Buscar <i class="fas fa-search"></i></button>
            <button  class="btn btn-secondary ml-3"
              @click="filtersVisibility = !filtersVisibility"
              aria-controls="procurement-filters-collapse"
              :aria-expanded="filtersVisibility ? 'true' : 'false'">
              <i class="fas fa-filter ml-1" ></i>
                Filtrar
            </button>
          </div>
        </div>
       <div class="row p-0">
          <div class="col-12">
            <b-collapse v-model="filtersVisibility" class="collapse-tags" id="procurement-filters-collapse">
              <p> Filtrar empresa u organismos con
                <b-form-select v-model="filterBy" :options="filterByOptions"/>
                que cumplan
              </p>
              <procurement-filters
                ref="procurement_filters"
                v-on:setFilters="setProcurementFilters($event)"
                v-on:yearsChanged="filterYearsChanged($event)"
                :excludedFilters="['contracting_party', 'status', 'has_lots']"
                :excludedColumns="['amountColumn', 'dateColumn']"/>
            </b-collapse>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <paginationComponent
              id="companies-search-pagination"
              :currentPage="current_results_page"
              :totalPages="total_results_pages"
              v-on:next-page-companies-search-pagination="searchParties($event)"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-3 mb-3 border-bottom" v-for="result in results" v-bind:key="`proc_party_search_${result.id}`">
            <h5>
            <template v-if="partyHasSubscription(result.id)">
              <i v-b-tooltip.hover title="Dejar de recibir actualizaciones de esta empresa" class="far fa-bell-slash"  @click="deletePartySubscription(result.id)"></i>
            </template>
            <template v-else>
              <i v-b-tooltip.hover title="Recibir actualizaciones de esta empresa" class="far fa-bell"  @click="createPartySubscription({id: result.id})"></i>
            </template>
            <router-link :to="{
              name: 'procurement_party',
              params: {
                id: result.id
              }
            }">
              {{result.name}}
            </router-link>
            </h5>
          </div>
        </div>
      </div>
    </b-sidebar>
    <b-overlay :show="fetchingSubscriptionInfo">
      <paginationComponent
        id="procurement-party-subscriptions-pagination"
        :currentPage="current_page"
        :totalPages="total_pages"
        v-on:next-page-procurement-party-subscriptions-pagination="changePage($event)"/>
      <table class="table mt-2">
        <thead class="corporative">
          <tr>
            <th scope="col"> Nombre </th>
            <th scope="col"> CIF </th>
            <th scope="col"> Notificaciones Generadas </th>
            <th scope="col"> Fecha Última Notificación </th>
            <th scope="col"> Frecuencia Emails </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(party_subscription) in paginatedSubscriptionParties" v-bind:key="party_subscription.id">
            <td>
              <router-link :to="{
                name: 'procurement_party',
                params: {
                  id: party_subscription.party.id
                }
              }">
                {{party_subscription.party.name}}
              </router-link>
            </td>
            <td>{{ party_subscription.party.party_id }} </td>
            <td>{{ advancedSubscriptionsInfo[party_subscription.id] ? advancedSubscriptionsInfo[party_subscription.id].total_notifications : '' }} </td>
            <td>{{ advancedSubscriptionsInfo[party_subscription.id] ? advancedSubscriptionsInfo[party_subscription.id].last_notification_date : '' }} </td>
            <td>
              <template v-if="editingSubscriptionId === party_subscription.party.id">
                <b-form-select v-model="edit_subscription_mailing_frequency"
                  :options="mailing_frequency_options"></b-form-select>
              </template>
              <template v-else>
                {{ mailing_frequency(party_subscription.mailing_frequency) }}
              </template>
            </td>
            <td style="min-width:90px">

              <template v-if="editingSubscriptionId === party_subscription.party.id">
                <i v-b-tooltip.hover title="Guardar cambios" class="fas fa-save fa-2x mr-2"
                  @click="updateSubscription(party_subscription)"></i>
                <i v-b-tooltip.hover title="Cerrar sin guardar" class="fas fa-times fa-2x ml-2"
                  @click="editingSubscriptionId=''"></i>
              </template>
              <template v-else>
                <i v-b-tooltip.hover title="Editar suscripción" class="fas fa-edit fa-2x"
                  @click="setEditSubscription(party_subscription)"></i>
                <i v-b-tooltip.hover title="Dejar de seguir" class ="fas fa-trash-alt fa-2x ml-2"
                  @click="deletePartySubscription(party_subscription.party.id)"></i>
              </template>

            </td>
          </tr>
        </tbody>
      </table>
      </b-overlay>
  </div>
</template>
<script>
import Vue from 'vue';
import procurementService from '@/_services/procurement.service';
import paginationComponent from '@/components/helpers/pagination.vue';
import procurementMixin from '@/_mixins/procurement.mixin';
import procurementFilters from './procurement_filters.vue';

export default {
  name: 'procurementPartySubscriptionsTable',
  mixins: [procurementMixin],
  components: {
    paginationComponent,
    procurementFilters,
  },
  data() {
    return {
      filtersVisibility: false,
      searchParty: '',
      searching: false,
      pageSize: 25,
      current_page: 1,
      current_results_page: 1,
      total_results_pages: 0,
      total_results_results: 0,
      results: [],
      editingSubscriptionId: null,
      edit_subscription_mailing_frequency: '',
      mailing_frequency_options: [
        { value: 'daily', text: 'Diario' },
        { value: 'weekly', text: 'Semanal' },
        { value: 'no_email', text: 'Sin email' },
      ],
      fetchingSubscriptionInfo: false,
      advancedSubscriptionsInfo: {},
      currentFilters: {
        contracted_projects: {},
        awarded_projects: {},
      },
      filterBy: 'procurement',
      filterByOptions: [
        { value: 'procurement', text: 'licitaciones' },
        { value: 'tender_results', text: 'adjudicaciones' },
      ],
    };
  },
  computed: {
    paginatedSubscriptionParties() {
      const start = (this.current_page - 1) * this.pageSize;
      return this.party_subscriptions.slice(
        start,
        start + this.pageSize,
      );
    },
    total_pages() {
      return Math.ceil(this.party_subscriptions.length / this.pageSize);
    },
  },
  methods: {
    changePage(page) {
      this.current_page = page;
    },
    searchParties(page = 1) {
      const params = {
        searchTerms: this.searchParty,
        page,
        filters: this.currentFilters,
      };

      const self = this;
      procurementService.searchProcurementParty(params).then(({ data }) => {
        const { total_results, current_page, total_pages } = data.metadata;
        self.total_results_results = total_results;
        self.total_results_pages = total_pages;
        self.current_results_page = current_page;

        const { results } = data;
        Vue.set(self, 'results', results);
      });
    },
    setEditSubscription(subscription) {
      this.editingSubscriptionId = subscription.party.id;
      this.edit_subscription_mailing_frequency = subscription.mailing_frequency;
    },
    updateSubscription(subscription) {
      procurementService.updateProcurementPartySubscription(subscription.party.id, {
        mailing_frequency: this.edit_subscription_mailing_frequency,
      }).then(() => {
        this.getPartySubscriptions();
        this.editingSubscriptionId = '';
      });
    },
    toggleSidebar() {
      this.searchParties();
      this.$root.$emit('bv::toggle::collapse', 'companies-sidebar');
    },
    setProcurementFilters(event) {
      if (this.filterBy === 'procurement') {
        Vue.set(this.currentFilters, 'contracted_projects', event.filters);
        Vue.set(this.currentFilters, 'awarded_projects', {});
      } else if (this.filterBy === 'tender_results') {
        Vue.set(this.currentFilters, 'contracted_projects', {});
        Vue.set(this.currentFilters, 'awarded_projects', event.filters);
      }
      this.searchParties();
    },
    setTenderResultFilters(event) {
      Vue.set(this.currentFilters, 'awarded_projects', event.filters);
      this.searchParties();
    },
  },
  watch: {
    paginatedSubscriptionParties() {
      const ids = this.paginatedSubscriptionParties.map(p => p.id);

      this.fetchingSubscriptionInfo = true;
      procurementService.getProcurementPartySubscriptions({ mode: 'core', ids }).then((res) => {
        const { party_subscriptions } = res.data;
        const advancedSubscriptionsInfo = {};

        for (let i = 0; i < party_subscriptions.length; i += 1) {
          advancedSubscriptionsInfo[party_subscriptions[i].id] = party_subscriptions[i];
          Vue.set(this, 'advancedSubscriptionsInfo', advancedSubscriptionsInfo);
        }
      }).finally(() => {
        this.fetchingSubscriptionInfo = false;
      });
    },
    filterBy(newVal) {
      if (newVal === 'procurement') {
        Vue.set(this.currentFilters, 'contracted_projects', this.currentFilters.awarded_projects);
        Vue.set(this.currentFilters, 'awarded_projects', {});
      } else if (newVal === 'tender_results') {
        Vue.set(this.currentFilters, 'awarded_projects', this.currentFilters.contracted_projects);
        Vue.set(this.currentFilters, 'contracted_projects', {});
      }
      this.searchParties();
    },
  },
};
</script>

<style scoped>
.fa-save{
  color: #4953CF;
  cursor:pointer;
}
.fa-save:hover{
  color:#8F8EF8;
}
</style>
