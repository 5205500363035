<template>
  <div id="accountSummary">
    <div class="row">
      <div class="col-12">
        <table class ="table text-left">
          <tr>
            <th>Estado de la cuenta</th>
            <td>
              <template v-if="user.subscription.status === 'active'">
                <b-alert show variant="success">
                  Cuenta activada
                </b-alert>
              </template>
              <template v-else>
                <b-alert show variant="warning">
                  Te quedan <b> {{user.subscription.trial_days_remaining}} </b>
                  días de la prueba gratuita.
                  <template v-if="user.subscription.trial_days_remaining === 0">
                    ¿Quieres seguir usando legalhound? Escríbenos a <strong> contacto@legalhound.io </strong>
                  </template>
                </b-alert>
              </template>
            </td>
            <td></td>
          </tr>
          <tr>
            <th>Nombre</th>
            <td>{{user.name}}</td>
            <td class="text-right">
              <i v-b-tooltip.hover title="Editar nombre" class="fas fa-edit fa-2x"  @click="updateName.open = !updateName.open"></i>
              <div class="dropdown-menu dropdown-menu-w mg-form" @submit.prevent="" :class="showEdit(updateName.open)">
                <form class="d-block">
                  <div class="form-group">
                    <dl class="form-group">
                      <dt><label>Nombre</label></dt>
                      <dd><input placeholder="Nombre" type="text" class="form-control input-block form-control" v-model="updateName.name"></dd>
                    </dl>
                  </div>
                  <div class="d-flex form-group" >
                    <button class="btn btn-secondary mr-2" @click="updateUser({name: updateName.name})"> Guardar  <i class="fas fa-save"></i></button>
                    <button class="btn btn-secondary " @click="updateName.open = !updateName.open" >Cancelar</button>
                  </div>
                </form>
              </div>
            </td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{user.email}}</td>
            <td></td>
          </tr>
          <tr>
            <th>Contraseña</th>
            <td>**********</td>
            <td class="text-right">
              <i v-b-tooltip.hover title="Editar contraseña" class="fas fa-edit fa-2x"   @click="updatePassword.open = !updatePassword.open"></i>
              <div class="dropdown-menu dropdown-menu-w mg-form" @submit.prevent="" :class="showEdit(updatePassword.open)">
                <form class="d-block">
                  <div class="info-overlay">
                    <strong>INFO:</strong> Actualizar tu contraseña terminará todas las sesiones activas y requerirá que vuelvas a iniciar sesión en tu cuenta.
                  </div>
                  <div class="form-group">
                    <dl class="form-group">
                      <dt><label>Contraseña actual</label></dt>
                      <dd>
                        <input placeholder="contraseña antigua"
                          type="password"
                          class="form-control input-block form-control input-lg"
                          v-model="updatePassword.current_password"
                        >
                      </dd>
                    </dl>
                  </div>
                  <div class="form-group">
                    <dl class="form-group">
                      <dt><label>Nueva contraseña</label></dt>
                      <dd>
                        <input placeholder="nueva contraseña"
                          type="password"
                          class="form-control input-block form-control input-lg"
                          v-model="updatePassword.password"
                          v-validate="'required|min:6|max:35'" name="password" ref="password"
                        >
                        <span v-show="errors.has('password')" class="invalid">{{ errors.first('password') }}</span>
                      </dd>
                    </dl>
                  </div>
                  <div class="form-group">
                    <dl class="form-group">
                      <dt><label>Confirmar nueva contraseña</label></dt>
                      <dd>
                        <input placeholder="confirmación nueva contraseña"
                          type="password"
                          class="form-control input-block form-control input-lg"
                          id="confirmPassword"
                          v-model="updatePassword.password_confirmation" v-validate="'required|confirmed:password'" name="confirmPassword">
                          <span v-show="errors.has('confirmPassword')" class="invalid">{{ errors.first('confirmPassword') }}</span>
                       </dd>
                    </dl>
                  </div>
                  <div class="d-flex form-group">
                    <button class="btn btn-secondary mr-2" @click="updateUser({ password: updatePassword.password, current_password: updatePassword.current_password, password_confirmation: updatePassword.password_confirmation })">
                      Guardar <i class="fas fa-save"></i>
                    </button>
                    <button class="btn btn-secondary" @click="updatePassword.open = !updatePassword.open" >Cancelar</button>
                  </div>
                </form>
              </div>
            </td>
          </tr>
        </table>
<!--     <div class="grid border-bottom" v-if="subscription_owner">
          <div class="col-12 grid bg-white px-2 pt-2">
            <div class="col-12 pt-3">
              <h5 class="account-title">Información de Facturación</h5>
            </div>
            <div class="col-12">
                <div class="Box-row d-flex flex-items-center">
                  <div class="flex-auto"><span class="subsection-title">Nombre cliente</span><span class="subsection">{{customer.name}}</span></div>
                  <div class="d-flex flex-self-start">
                    <div class="details-overlay dropdown d-inline-block" >
                      <summary class="btn btn-secondary mr-2 editButton"  @click="updateBillingName.open = !updateBillingName.open">
                        <span class="d-inline-flex flex-items-center" >Editar</span>
                      </summary>
                      <div class="dropdown-menu dropdown-menu-w mg-form" @submit.prevent="" :class="showEdit(updateBillingName.open)">
                        <form class="d-block">
                          <div class="form-group">
                            <dl class="form-group">
                              <dt><label>Nombre Cliente</label></dt>
                              <dd><input placeholder="Nombre cliente" type="text" class="form-control input-block form-control" v-model="updateBillingName.name"></dd>
                            </dl>
                          </div>
                          <div class="d-flex form-group" >
                            <button class="btn btn-primary mr-2" @click="updateCustomer({name: updateBillingName.name})"> Guardar </button>
                            <button class="btn btn-secondary " @click="updateBillingName.open = !updateBillingName.open" >Cancelar</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Box-row d-flex flex-items-center">
                  <div class="flex-auto"><span class="subsection-title">Teléfono</span><span class="subsection">{{customer.phone}}</span></div>
                  <div class="d-flex flex-self-start">
                    <div class="details-overlay dropdown d-inline-block" >
                      <summary class="btn btn-secondary mr-2 editButton"  @click="updatePhone.open = !updatePhone.open">
                        <span class="d-inline-flex flex-items-center" >Editar</span>
                      </summary>
                      <div class="dropdown-menu dropdown-menu-w mg-form" @submit.prevent="" :class="showEdit(updatePhone.open)">
                        <form class="d-block">
                          <div class="form-group">
                            <dl class="form-group">
                              <dt><label>Teléfono</label></dt>
                              <dd><input placeholder="Teléfono" type="text" class="form-control input-block form-control" v-model="updatePhone.phone"></dd>
                            </dl>
                          </div>
                          <div class="d-flex form-group" >
                            <button class="btn btn-primary mr-2" @click="updateCustomer({phone: updatePhone.phone})"> Guardar </button>
                            <button class="btn btn-secondary " @click="updatePhone.open = !updatePhone.open" >Cancelar</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Box-row d-flex flex-items-center">
                  <div class="flex-auto"><span class="subsection-title">Dirección de Facturación </span> <span class="subsection"> {{address}} </span> </div>
                  <div class="d-flex flex-self-start">
                    <div class="details-overlay dropdown d-inline-block" >
                      <summary class="btn btn-secondary mr-2 editButton"  @click="updateBillinAddress.open = !updateBillinAddress.open"><span class="d-inline-flex flex-items-center" >Editar</span></summary>
                      <div class="dropdown-menu dropdown-menu-w mg-form" @submit.prevent="" :class="showEdit(updateBillinAddress.open)">
                        <form class="d-block">
                          <div class="form-group">
                            <dl class="form-group">
                              <dt><label>{{ $t('userPages.addressLine1PlaceHolder')}}</label></dt>
                              <dd>
                                <input type="text" class="form-control input-block form-control"
                                  v-validate="'required'" name="address-line1" v-model="updateBillinAddress.address.line1"
                                  :placeholder="$t('userPages.addressLine1PlaceHolder')">
                              </dd>
                            </dl>
                          </div>
                          <div class="form-group">
                            <dl class="form-group">
                              <dt><label>{{$t('userPages.addressLine2PlaceHolder')}}</label></dt>
                              <dd>
                                <input type="text" class="form-control input-block form-control"
                                  name="address-line2" v-model="updateBillinAddress.address.line2" :placeholder="$t('userPages.addressLine2PlaceHolder')">
                              </dd>
                            </dl>
                          </div>
                          <div class="form-group">
                            <dl class="form-group">
                              <dt><label>{{ $t('userPages.zipCode')}}</label></dt>
                              <dd>
                                <input type="text" class="form-control input-block form-control"
                                  v-validate="'required'" name="postal_code" v-model="updateBillinAddress.address.postal_code"
                                  :placeholder="$t('userPages.zipCode')">
                              </dd>
                            </dl>
                          </div>
                          <div class="form-group">
                            <dl class="form-group">
                              <dt><label>{{ $t('userPages.city')}}</label></dt>
                              <dd>
                                <input type="text" class="form-control input-block form-control"
                                  v-validate="'required'" name="city" v-model="updateBillinAddress.address.city"
                                  :placeholder="$t('userPages.city')">
                              </dd>
                            </dl>
                          </div>
                          <div class="form-group">
                            <dl class="form-group">
                              <dt><label>{{ $t('userPages.state')}}</label></dt>
                              <dd>
                                <input type="text" class="form-control input-block form-control"
                                  v-validate="'required'" name="state" v-model="updateBillinAddress.address.state"
                                  :placeholder="$t('userPages.state')">
                              </dd>
                            </dl>
                          </div>
                          <div class="form-group">
                            <dl class="form-group">
                              <dt><label>{{$t('userPages.country')}}</label></dt>
                              <dd>
                                <input type="text" class="form-control input-block form-control"
                                  name="country" v-model="updateBillinAddress.address.country" :placeholder="$t('userPages.country')">
                              </dd>
                            </dl>
                          </div>
                          <div class="d-flex form-group" >
                            <button class="btn btn-primary mr-2" @click="updateCustomer({ address: updateBillinAddress.address })"> Guardar </button>
                            <button class="btn btn-secondary " @click="updateBillinAddress.open = !updateBillinAddress.open" >Cancelar</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Box-row d-flex flex-items-center">
                  <div class="flex-auto"><span class="subsection-title">Método de pago</span><span class="subsection">{{card}}</span></div>
                  <div class="d-flex flex-self-start">
                    <div class="details-overlay dropdown d-inline-block" >
                      <summary class="btn btn-secondary mr-2 editButton"  @click="updatePaymentMethod.open = !updatePaymentMethod.open"><span class="d-inline-flex flex-items-center" >Editar</span></summary>
                      <div class="dropdown-menu dropdown-menu-w mg-form" @submit.prevent="" :class="showEdit(updatePaymentMethod.open)" >
                        <form class="d-block">
                          <div class="form-group">
                            <dl class="form-group">
                              <dt><label>{{ $t('userPages.cardHolder') }}</label></dt>
                              <dd><input :placeholder="$t('userPages.cardHolder')" type="text" class="form-control input-block form-control" v-model="updatePaymentMethod.name"></dd>
                            </dl>
                          </div>
                          <div class="form-group">
                            <dl class="form-group">
                              <dd>
                                <div ref="card"></div>
                                <span v-show="errors.has('stripeError')" class="invalid">{{ errors.first('stripeError') }}</span>
                              </dd>
                            </dl>
                          </div>
                          <div class="d-flex form-group" >
                            <button class="btn btn-primary mr-2" @click="updateCustomerPaymentMethod()" > Guardar </button>
                            <button class="btn btn-secondary " @click="updatePaymentMethod.open = !updatePaymentMethod.open" >Cancelar</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
    <div class="row text-left">
      <div class="col-12 pt-3 mt-3">
        <h5>Cancelar Cuenta</h5>
        <div class="Box Box--danger">
          <div class="Box-body">
            <p> Si ya no necesitas utilizar LegalHound, puedes cancelar tu cuenta. Cancelar borrará permanentemente la cuenta y todos los datos asociados a la misma. <br/>Esta acción no se puede revertir.&nbsp;
            </p>
            <button class="btn btn-danger" @click="showConfirmation()">Cancelar Cuenta</button>
          </div>
        </div>
      </div>
    </div>
    <confirm-delete-account/>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import userService from '@/_services/user.service';
import ConfirmDeleteAccountmDialog from '../modals/confirmAccountDelete.vue';

/*
const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, { locale: 'es' });
const elements = stripe.elements();
let card = undefined; */


export default {
  name: 'accountSummary',
  props: [],
  components: {
    'confirm-delete-account': ConfirmDeleteAccountmDialog,
  },
  computed: {
    ...mapGetters('usersModule', ['user']),
    ...mapState({
      customer: state => state.subscriptionsModule.customer,
    }),
    address() {
      let address = `${this.customer.billing_address.line1}`;

      if (this.customer.billing_address.line2) {
        address += this.customer.billing_address.line2;
      }
      address += `, ${this.customer.billing_address.postal_code}, ${this.customer.billing_address.city}, ${this.customer.billing_address.state}`;

      return address;
    },
    card() {
      return `${this.customer.card.brand} ${this.customer.card.funding} \u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 ${this.customer.card.last4}`;
    },
  },
  data() {
    return {
      updateBillinAddress: {
        open: false,
        address: {
          line1: '',
          line2: '',
          postal_code: '',
          city: '',
          state: '',
          country: '',
        },
        phone: '',
      },
      updatePaymentMethod: {
        open: false,
        name: '',
      },
      updateName: {
        open: false,
        name: '',
      },
      updateBillingName: {
        open: false,
        name: '',
      },
      updatePassword: {
        open: false,
        current_password: '',
        password: '',
        password_confirmation: '',
      },
      updatePhone: {
        open: false,
        phone: '',
      },
    };
  },
  created() {
    //this.getCustomer();
  },
  destroyed() {
    if (card) {
      card.destroy();
      card = undefined;
    }
  },
  mounted() {

  },
  methods: {
    ...mapActions('subscriptionsModule', {
      getCustomer: 'getCustomer',
      updateCustomer: 'updateCustomer',
    }),
    showConfirmation() {
      this.$modal.show('confirmAccountDelete');
    },
    showEdit(open) {
      if (open) {
        return 'show active';
      }
      return '';
    },
    updateUser(user) {
      const self = this;
      userService.updateUser(user).then(
        () => {
          self.$auth.fetch();
        },
      ).catch((error) => {
        console.log(error);
      });
    },
    updateCustomerPaymentMethod() {
      this.errors.clear(); // Do this to clean custom errors.
      const self = this;
      const options = {
        name: this.updatePaymentMethod.name,
      };

      stripe.createToken(card, options).then((result) => {
        if (result.error) {
          self.$validator.errors.add({
            field: 'stripeError',
            msg: result.error.message,
          });
          return;
        }
        self.updateCustomer({ payment_method: { token: result.token.id } });
      });
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    'updatePaymentMethod.open': function updatePaymentMethod(newVal, oldVal) {
      if (!card) {
        card = elements.create('card', {
          hidePostalCode: true,
        });

        card.mount(this.$refs.card);
      }
    },
  },
};
</script>

<style scoped>

table, tr, th, td{
  border:0 !important;
}
.editButton {
 float: right;
}

.info-overlay {
  position: relative;
  text-align: left;
  background-color: rgb(238, 247, 255);
  color: rgb(31, 162, 255);
  padding: 16px;
  border-bottom: 1px solid rgba(31, 162, 255, 0.05);
  border-color: rgba(31, 162, 255, 0.05);
}
</style>
