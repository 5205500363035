/* eslint-disable import/prefer-default-export */
/* eslint no-shadow: ["error", {"allow": ["state"]}] */
/* eslint no-param-reassign: ["error", { "props": true,
                                        "ignorePropertyModificationsFor": ["state"] }] */
import Vue from 'vue';
import notificationService from '../_services/notification.service';


const state = {
  notifications: [],
  loading: false,
  total_results: 0,
  current_page: 1,
  total_pages: 0,
};

const actions = {
  changePage({ dispatch }, page) {
    dispatch('indexNotifications', { page });
  },
  indexNotifications({ commit }, { page = 1 } = {}) {
    commit('setLoading', true);

    // We return the promise from axios so that the view will now when the data has loaded
    return notificationService.indexNotifications({ source_type: 'procurement', page }).then(
      (response) => {
        const { notifications } = response.data;
        const { total_results, total_pages, current_page } = response.data.metadata;

        commit('storeNotifications', {
          notifications, total_results, total_pages, current_page,
        });
        commit('setLoading', false);
      },
    ).catch(() => {
      commit('setLoading', false);
    });
  },
  updateNotification({ dispatch }, { id, starred, processed }) {
    return notificationService.updateNotification({ id, starred, processed }).then(
      () => {
        dispatch('indexNotifications');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  updateNotifications({ dispatch }, { ids, processed }) {
    return notificationService.updateNotifications({ ids, processed }).then(
      () => {
        dispatch('indexNotifications');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
};

const mutations = {
  storeNotifications(state, {
    notifications, total_results, total_pages, current_page,
  }) {
    state.total_results = total_results;
    state.total_pages = total_pages;
    state.current_page = current_page;
    Vue.set(state, 'notifications', notifications);
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const procurementNotificationsModule = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default procurementNotificationsModule;
