<template>
  <div class="row">
    <ShareEmailModal :document="shareEmailInfo.document"/>
    <div class="col-4 pl-1">
      <paginationComponent
        id="official-bulletin-notif-pagination"
        :currentPage="current_page"
        :totalPages="total_pages"
        v-on:next-page-official-bulletin-notif-pagination="changePage($event)"/>
    </div>
    <div class="col-8 text-right pr-1">
      <button class="btn btn-secondary" @click="indexNotifications()" >
        Sincronizar <i class="fas fa-sync" :class="{ 'fa-spin': loading}"></i>
      </button>
      <button v-b-tooltip.hover title="Exportar a excel" class="btn btn-excel ml-2 mr-2" @click="openExcelExportModal()">
        <i class="fas fa-file-excel"/> Exportar
      </button>
    </div>
    <div class="col-12 notifications-panel text-left" id="notifications-panel">
      <div v-for="(notification, index) in notifications" v-bind:key="`notification_${index}`" :id="`notification_${notification.id}`">
        <notificationComponent :notification="notification" v-on:share-notification="shareEmail($event)"/>
      </div>
    </div>
    <b-modal
      ref="excel-export-modal"
      hide-footer>
      <template #modal-title>
        <h2 class="corporative-principal">Exportar notificaciones a Excel </h2>
      </template>
      <template v-if="excelExportModalPage === 1">
        Notificaciones a exportar:
        <b-form-select class="my-2" v-model="exportNotificationsNumber" :options="exportNotificationOptions"></b-form-select>
        <button class="btn btn-secondary mt-2" @click="exportModalNextPage()"> Exportar <i class="fas fa-file-export"></i></button>
      </template>
      <template v-else>
        <p><input type="checkbox" v-model="processNotifications"> Eliminar las notificaciones exportadas</p>
        <button class="btn btn-secondary" @click="exportModalNextPage()">
          {{processNotifications ? 'Eliminar y Cerrar' : 'Cerrar'}}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import notificationService from '@/_services/notification.service';
import excelExporter from '@/_helpers/excel_exporter';

import paginationComponent from '@/components/helpers/pagination.vue';
import ShareEmailModal from '@/components/modals/share_email.vue';

import NotificationComponent from './notification.vue';

export default {
  components: {
    NotificationComponent,
    paginationComponent,
    ShareEmailModal,
  },
  mounted() {
    if (this.$route.query.id && this.$route.query.alertId) {
      this.indexNotifications().then(() => {
        notificationService.getNotification(this.$route.query.id).then(({ data }) => {
          // eslint-disable-next-line eqeqeq
          const alert = data.alerts.find(item => item.id == this.$route.query.alertId);
          const scrollElementId = `#notification_${data.id}`; // Always scroll to first line of highlighted paragraph
          const scrollOptions = {
            container: '#notifications-panel',
            offset: -20,
          };

          const self = this;
          // eslint-disable-next-line eqeqeq
          if (data.data.page == this.current_page) {
            console.log('SCROLL!!!');

            self.$nextTick(() => {
              self.$scrollTo(scrollElementId, 500, scrollOptions);
            });
          } else {
            this.changePage(data.data.page).then(() => {
              console.log('now scroll!!');
              self.$nextTick(() => {
                self.$scrollTo(scrollElementId, 500, scrollOptions);
              });
            });
          }
          this.fetchDocument({
            stringId: data.data.string_id,
            source: data.data.source,
            highlightTriggers: alert.filters && alert.filters.highlightTerms ? alert.filters.highlightTerms : '',
            mainTriggers: alert.searchTerms,
            notificationTitle: data.data.title,
          });
        });
      });
    } else if (this.notifications.length > 0) {
      const notification = this.notifications[0];
      const alert = notification.alerts[0];

      this.fetchDocument({
        stringId: notification.data.string_id,
        source: notification.data.source,
        highlightTriggers: alert.filters && alert.filters.highlightTerms ? alert.filters.highlightTerms : '',
        mainTriggers: alert.searchTerms,
        notificationTitle: notification.data.title,
      });
    }
  },
  data() {
    return {
      shareEmailInfo: {
        document: {},
      },
      exportNotificationsNumber: 25,
      exportNotificationOptions: [
        { value: 25, text: '25 (Página actual)' },
        { value: 50, text: '50' },
        { value: 75, text: '75' },
        { value: 100, text: '100' },
        { value: 1000, text: 'Todas (máximo 1000)' },
      ],
      excelExportModalPage: 1,
      processNotifications: false,
      processNotificationsIds: [],
    };
  },
  computed: {
    ...mapState('officialBulletinsNotificationsModule',
      [
        'notifications',
        'loading',
        'current_page',
        'total_pages',
      ]),

  },
  methods: {
    ...mapActions('officialBulletinsNotificationsModule', {
      indexNotifications: 'indexNotifications',
      changePage: 'changePage',
      updateNotifications: 'updateNotifications',
    }),
    ...mapActions('documents', {
      fetchDocument: 'fetchDocument',
    }),
    exportExcel() {
      const filename = `${this.$moment().format('YYYY_MM_DD')}_exportacion_notificaciones_publicaciones.xlsx`;
      this.processNotificationsIds = []; // No need for it to be reactive

      notificationService.indexNotifications(
        { source_type: 'official_bulletin', pageSize: this.exportNotificationsNumber },
      ).then((response) => {
        const { notifications } = response.data;
        const notification_ids = notifications.map(notification => notification.id);
        this.processNotificationsIds = notification_ids;

        excelExporter.saveOfficialBulletinNotifications(notifications, filename).then(() => {
          this.excelExportModalPage += 1;
        }).catch((err) => {
          console.log(err);
        });
      });
    },
    exportModalNextPage() {
      if (this.excelExportModalPage === 1) {
        this.exportExcel();
      } else if (this.excelExportModalPage === 2) {
        if (this.processNotifications) {
          this.updateNotifications({
            ids: this.processNotificationsIds,
            processed: true,
          });
        }
        this.$refs['excel-export-modal'].hide();
        this.excelExportModalPage = 0;
      }
    },
    openExcelExportModal() {
      this.excelExportModalPage = 1;
      this.processNotifications = false;
      this.$refs['excel-export-modal'].show();
    },
    shareEmail(event) {
      Vue.set(this.shareEmailInfo, 'document', event.document);
      this.$bvModal.show('share-email-modal');
    },
  },
};
</script>

<style scoped>

.notifications-panel{
  overflow-y: scroll;
  overflow-x: hidden;
  height:100vh;
  padding: 0;
}


</style>
