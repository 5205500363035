<template>
  <div class="row py-2 px-0 text-left" >
    <div class ="col-4 pt-3 stripe-dark">
      <div class="filter">
        <h3> Fuentes de Datos </h3>
        <multiselect
          :options="sources"
          :multiple="true"
          :preserve-search="true"
          :value="selectFilters.selected_sources"
          placeholder="Selecciona fuente de datos"
          label="name"
          @select="sourceSelected($event)"
          @remove="sourceRemoved($event)"
          track-by="acronym">
          <span slot="noResult">No se han encontrado resultados. Por favor, comprueba lo escrito.</span>
        </multiselect>
      </div>
      <div>
        <div class="filter">
          <h3> Fecha publicación</h3>
        </div>
        <input v-model="boolFilters.only_today" type="checkbox"> Publicados hoy
        <div class="filter">
        <b-input-group class ="mb-2">
          <b-form-input
            name="from"
            v-model="textFilters.publication_dates.from"
            type="text"
            placeholder="Entre el"
            autocomplete="off"
            v-debounce:1000ms="saveFilters"
          >
          </b-form-input>
          <b-input-group-append>
            <b-form-datepicker class="datepicker"
              name="from"
              v-model="textFilters.publication_dates.from"
              button-only
              right
              show-decade-nav
              size="sm"
              locale="es-ES"
              v-on:input="saveFilters"
            >
            </b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
        <b-input-group>
          <b-form-input
            name="from"
            v-model="textFilters.publication_dates.to"
            format="-"
            type="text"
            placeholder="hasta el"
            autocomplete="off"
            v-debounce:1000ms="saveFilters"
          >
          </b-form-input>
          <b-input-group-append>
            <b-form-datepicker class="datepicker"
              name="from"
              v-model="textFilters.publication_dates.to"
              button-only
              right
              show-decade-nav
              size="sm"
              locale="es-ES"
              v-on:input="saveFilters"
            >
            </b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
        </div>
      </div>
    </div>
    <div class="col-4 pt-3 stripe-light">
      <div class="filter">
        <h3> Título </h3>
        <input type="text" v-model="textFilters.title" v-debounce:1000ms="saveFilters">
      </div>
      <div class="filter">
        <h3> Resumen </h3>
        <input type="text" v-model="textFilters.summary" v-debounce:1000ms="saveFilters">
      </div>
      <div class="filter">
        <h3> Departamento / Institución </h3>
        <input type="text" v-model="textFilters.department" v-debounce:1000ms="saveFilters">
      </div>
    </div>
    <div class="col-4 pt-3 stripe-dark">
      <div class="filter">
        <h3> Resaltado del documento</h3>
        <input type="text" v-model="textFilters.highlightTerms" v-debounce:1000ms="saveFilters">
      </div>
      <div class="filter">
        <h3> Términos de exclusión </h3>
        <input type="text" v-model="textFilters.must_not_terms" v-debounce:1000ms="saveFilters">
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import sources from '@/_config/sources.json';

export default {
  name: 'searchFilters',
  props: {
    currentFilters: {
    },
    updateFiltersUI: {
      type: Boolean,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      boolFilters: {
        only_today: false,
      },
      textFilters: {
        title: '',
        summary: '',
        department: '',
        must_not_terms: '',
        publication_dates: {
          from: '',
          to: '',
        },
      },
      selectFilters: {
        selected_sources: [sources[0]],
      },
      sources,
    };
  },
  computed: {
    filters() {
      const selected_sources = [];
      const index = this.selectFilters.selected_sources.findIndex(elem => elem.acronym === 'all');
      if (index === -1) {
        for (let i = 0; i < this.selectFilters.selected_sources.length; i += 1) {
          selected_sources.push(this.selectFilters.selected_sources[i].acronym);
        }
      }

      return {
        sources: selected_sources,
        title: this.textFilters.title,
        summary: this.textFilters.summary,
        department: this.textFilters.department,
        publication_dates: this.textFilters.publication_dates,
        highlightTerms: this.textFilters.highlightTerms,
        must_not_terms: this.textFilters.must_not_terms,
        only_today: this.boolFilters.only_today,
      };
    },
  },
  mounted() {
    this.setFiltersFromCurrent();
  },
  methods: {
    setFiltersFromCurrent() {
      if (!this.currentFilters) return;

      if (this.currentFilters.title) {
        this.textFilters.title = this.currentFilters.title;
      }

      if (this.currentFilters.summary) {
        this.textFilters.summary = this.currentFilters.summary;
      }

      if (this.currentFilters.department) {
        this.textFilters.department = this.currentFilters.department;
      }

      if (this.currentFilters.highlightTerms) {
        this.textFilters.highlightTerms = this.currentFilters.highlightTerms;
      }

      if (this.currentFilters.must_not_terms) {
        this.textFilters.must_not_terms = this.currentFilters.must_not_terms;
      }

      if (this.currentFilters.publication_dates) {
        if (this.currentFilters.publication_dates.from) {
          this.textFilters.publication_dates.from = this.currentFilters.publication_dates.from;
        }
        if (this.currentFilters.publication_dates.to) {
          this.textFilters.publication_dates.to = this.currentFilters.publication_dates.to;
        }
      }


      if (this.currentFilters.sources && this.currentFilters.sources.length > 0) {
        const selectedSources = [];

        for (let i = 0; i < this.currentFilters.sources.length; i += 1) {
          const source = this.sources.find(elem => elem.acronym === this.currentFilters.sources[i]);
          if (source) {
            selectedSources.push(source);
          }
        }
        Vue.set(this.selectFilters, 'selected_sources', selectedSources);
      } else {
        Vue.set(this.selectFilters, 'selected_sources', [this.sources[0]]);
      }
    },
    sourceRemoved(value) {
      if (value.acronym === 'all' && this.selectFilters.selected_sources.length === 1) {
        return;
      }
      if (this.selectFilters.selected_sources.length === 1) {
        Vue.set(this.selectFilters, 'selected_sources', [this.sources[0]]);
        this.saveFilters();
        return;
      }
      const index = this.selectFilters.selected_sources.findIndex(elem => elem.acronym === value.acronym);
      if (index !== -1) {
        this.selectFilters.selected_sources.splice(index, 1);
        this.saveFilters();
      }
    },
    sourceSelected(value) {
      if (value.acronym === 'all') {
        Vue.set(this.selectFilters, 'selected_sources', [value]);
      } else {
        const allIndex = this.selectFilters.selected_sources.findIndex(elem => elem.acronym === 'all');
        if (allIndex !== -1) {
          this.selectFilters.selected_sources.splice(allIndex, 1);
        }

        const index = this.selectFilters.selected_sources.findIndex(elem => elem.acronym === value.acronym);
        if (index === -1) {
          this.selectFilters.selected_sources.push(value);
        }
      }

      this.saveFilters();
    },
    saveFilters() {
      this.$emit('setFilters', { filters: this.filters });
    },
  },
  watch: {
    boolFilters: {
      deep: true,
      handler() {
        this.saveFilters();
      },
    },
    updateFiltersUI() {
      this.setFiltersFromCurrent();
    },
  },
};

</script>

<style scoped>
input{
  background-color: #FFFFFF;
}
.filter{
  margin-bottom: 15px;
}
.filter h3{
  font-size: 1.2em;
  margin:0px;
  padding: 0px;
}
.filter input, .custom-select{
  border: 1px solid #ccc !important;
  width: 90%;
  height: 35px;
  border-radius:0% !important;
  margin: 1px;
  padding-left: 5px !important;
}
.agreement input{
  width: 45%;
}

</style>
