<template>
  <div>
    <div class="row">
      <div class ="col-12 text-center py-3">
        <h1>Cuenta de usuario</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="card text-center px-0 border-0">
          <div class="card-img mx-auto">
            <img :src="user.gravatar_url" alt="user image">
          </div>
          <div class="card-body">
            <h4 class="py-2">{{user.name}}</h4>
            <p>{{user.email}}</p>
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="row">
          <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" @click="currentTab = 0">
                <a class="nav-link" :class="{active: currentTab === 0}"> Información usuario </a>
              </li>
              <li class="nav-item" @click="currentTab = 1">
                <a class="nav-link" :class="{active: currentTab === 1}"> Suscripción </a>
              </li>
              <!--
              <li class="nav-item" @click="currentTab = 2" v-if="subscription_owner">
                <a class="nav-link" :class="{active: currentTab === 2}"> Facturas </a>
              </li>
              -->
            </ul>
          </div>
        </div>
          <div :class="showTab(0)" id="account-tab" class="border p-3 mr-3" v-if="currentTab===0">
             <account-summary/>
          </div>
          <div :class="showTab(1)" id="subscription-tab" class="border p-3 mr-3" v-if="currentTab===1">
            <subscriptions-summary/>
          </div>
            <!--
            <div :class="showTab(2)" id="invoices" v-if="currentTab===2">
              <invoices-table/>
            </div>
            -->

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import InvoicesTable from './invoices.vue';
import SubscriptionSummary from './subscription.vue';
import AccountSummary from './accountSummary.vue';

export default {
  name: 'userPage',
  components: {
    'invoices-table': InvoicesTable,
    'subscriptions-summary': SubscriptionSummary,
    'account-summary': AccountSummary,
  },
  computed: {
    ...mapGetters('usersModule', ['user']),
  },
  data() {
    return {
      currentTab: 0,
    };
  },
  methods: {
    showTab(index) {
      if (index === this.currentTab) {
        return 'show active';
      }
      return '';
    },
  },
};
</script>

<style scoped>

</style>
