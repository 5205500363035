var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-center pt-3 mb-0"},[_vm._v("Licitaciones favoritas")]),_c('p',{staticClass:"mt-0"},[_vm._v("\n    Sigues "+_vm._s(_vm.project_subscriptions.length)+" licitaciones\n  ")]),_c('paginationComponent',{attrs:{"id":"procurement-project-subscriptions-pagination","currentPage":_vm.current_page,"totalPages":_vm.total_pages},on:{"next-page-procurement-project-subscriptions-pagination":function($event){return _vm.changePage($event)}}}),_c('table',{staticClass:"table text-left"},[_vm._m(0),_c('tbody',_vm._l((_vm.advancedSubscriptionsInfo),function(subscription,index){return _c('tr',{key:subscription.project.id},[_c('td',[_vm._v(_vm._s(_vm.date(subscription.project.date))+" ")]),_c('router-link',{attrs:{"to":{
              name: 'procurement',
              params: {
                comingFrom: '/licitaciones',
                id: subscription.project.id
              }
            }}},[_c('td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Abrir licitación"}},[_vm._v(" "+_vm._s(subscription.project.title)+" ")])]),_c('td',[_vm._v(_vm._s(_vm.date(subscription.project.submission_deadline))+" ")]),_c('td',[_vm._v(_vm._s(subscription.project.status))]),_c('td',[_vm._v(_vm._s(_vm.locationStr(subscription.project.location)))]),_c('td',[_vm._v(_vm._s(subscription.project.estimated_overall_amount)+" ")]),_c('td',[_vm._v(_vm._s(subscription.project.contract_type)+" ")]),_c('td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Abrir órgano o entidad"}},[_c('router-link',{attrs:{"to":{
            name: 'procurement_party',
            params: {
              id: subscription.project.contracting_party.id
            }
          }}},[_vm._v("\n          "+_vm._s(subscription.project.contracting_party.name)+"\n          ")])],1),_c('td',[_vm._v(" "+_vm._s(subscription.project.has_lots ? 'Sí' : 'No')+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.date(_vm.projectEndDate(subscription.project)))+" ")]),_c('td',[(_vm.editingSubscriptionId === subscription.id)?[_c('b-form-select',{attrs:{"options":_vm.mailing_frequency_options},model:{value:(_vm.edit_subscription_mailing_frequency),callback:function ($$v) {_vm.edit_subscription_mailing_frequency=$$v},expression:"edit_subscription_mailing_frequency"}})]:[_vm._v("\n            "+_vm._s(_vm.mailing_frequency(subscription.mailing_frequency))+"\n          ")]],2),_c('td',{staticStyle:{"min-width":"90px"}},[(_vm.editingSubscriptionId === subscription.id)?[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-save fa-2x mr-2",attrs:{"title":"Guardar cambios"},on:{"click":function($event){return _vm.updateSubscription(subscription)}}}),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-times fa-2x ml-2",attrs:{"title":"Cerrar sin guardar"},on:{"click":function($event){_vm.editingSubscriptionId=''}}})]:[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-edit fa-2x",attrs:{"title":"Editar suscripción"},on:{"click":function($event){return _vm.setEditSubscription(subscription)}}}),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-trash-alt fa-2x ml-2",attrs:{"title":"Dejar de seguir"},on:{"click":function($event){return _vm.deleteProjectSubscription(subscription.project.id)}}})]],2)],1)}),0)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"corporative"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" Fecha Publicación ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Título ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Fecha límite presentación ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Estado ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Lugar ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Valor estimado")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Tipo de contrato ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Órgano o Entidad ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Por Lotes ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Fecha fin")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Frecuencia Emails ")]),_c('th')])])}]

export { render, staticRenderFns }