<template>
  <div id="signup" class="col-12">
    <div class="signup-form">
      <form @submit.prevent="onSubmit">

         <div class="input">
          <label for="name">{{ $t('userPages.name') }}</label>
          <input type="text" id="name" v-model="form.name" v-validate="'required'" name="name" >
          <span v-show="errors.has('name')" class="invalid">{{ errors.first('name') }}</span>
        </div>

        <div class="input">
          <label for="password">{{ $t('userPages.password') }}</label>
          <input type="password" v-model="form.password" v-validate="'required|min:6|max:35'" name="password" ref="password">
          <span v-show="errors.has('password')" class="invalid">{{ errors.first('password') }}</span>
        </div>
        <div class="input">
          <label for="confirmPassword">{{ $t('userPages.confirmPassword') }}</label>
          <input type="password" id="confirmPassword" v-model="form.confirmPassword" v-validate="'required|confirmed:password'" name="confirmPassword" >
          <span v-show="errors.has('confirmPassword')" class="invalid">{{ errors.first('confirmPassword') }}</span>
        </div>
        <div class="submit">
          <button type="submit"> Aceptar invitación </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import userService from '@/_services/user.service';

export default {
  data() {
    return {
      form: {
        password: '',
        confirmPassword: '',
        name: '',
      },
    };
  },
  methods: {
    ...mapActions('flashMessages', {
      successFlashMessage: 'success',
      errorFlashMessage: 'error',
    }),
    onSubmit() {
      const formData = {
        email: this.form.email,
        password: this.form.password,
        password_confirmation: this.form.confirmPassword,
        name: this.form.name,
      };

      this.$validator.validateAll().then((result) => {
        if (result) {
          console.log('THis is an invite!');
          userService.acceptUserInvitation({
            ...formData,
            invitation_token: this.$route.query.invitation_token,
          }).then(() => {
            this.successFlashMessage({
              message: 'invitationSuccess',
              i18nArgs: { email: formData.email },
            });
            this.$router.push({
              name: 'sign_in',
            });
          }).catch(() => {
            this.errorFlashMessage({
              message: 'No se ha podido procesar tu invitación. Inténtalo más tarde o contacta con nosotros',
            });
          });
        } else {
          // e.preventDefault();
        }
      });
    },
  },
};
</script>

<style scoped>

.signup-form {
  width: 400px;
  margin: 30px auto;
  border: 1px solid #eee;
  padding: 20px;
  box-shadow: 0 2px 3px #ccc;
}


</style>
