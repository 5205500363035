<template>
  <div class="pagination-component">
    <nav v-if="totalPages > 0">
      <ul class="pagination">
        <template v-for="index in 5" >
          <li
            href="#"
            :class="page(index) == currentPage ? 'page-item active' : 'page-item'"
            @click.prevent="changePage(index)"
            v-if="page(index) <= totalPages"
            v-bind:key="`nav_link_${page(index)}`">
              <a class="page-link" href="#">{{page(index)}} </a>
          </li>

        </template>
      </ul>
    </nav>
  </div>
</template>

<script>

export default {
  name: 'paginationComponent',
  props: ['id', 'currentPage', 'totalPages'],
  computed: {
    buttonStartPage() {
      return (this.currentPage < 5) ? 1 : this.currentPage - 2;
    },
  },
  methods: {
    page(index) {
      return this.buttonStartPage + index - 1;
    },
    changePage(index) {
      if (index < 0 || index > this.totalPages) {
        return;
      }

      this.$emit(`next-page-${this.id}`, this.page(index));
    },
  },
};
</script>

<style scoped lang="css">

.pagination-component{
  border:0px;
}

</style>
