<template>
  <table class="table text-left">
    <thead class="corporative">
      <tr>
        <th scope="col"> Fecha Publicación </th>
        <th scope="col"> Título </th>
        <th scope="col"> CPV </th>
        <th scope="col"> Fecha límite presentación </th>
        <th scope="col"> Estado </th>
        <th scope="col"> Lugar </th>
        <th scope="col"> Valor estimado</th>
        <th scope="col"> Tipo de contrato </th>
        <th scope="col"> Órgano o Entidad </th>
        <th scope="col"> Entidad Adjudicataria </th>
        <th scope="col"> Por Lotes </th>
        <th scope="col"> Fecha fin</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(project, index) in projects" v-bind:key="project.id">
        <td>{{ date(project.date)}} </td>
        <router-link :to="{
              name: 'procurement',
              params: {
                comingFrom: '/licitaciones',
                id: project.id
              }
            }"
         >
         <td v-html="projectHighlightedTitle(project)" v-b-tooltip.hover title="Abrir licitación"></td>
        </router-link>
        <td>
          <ul>
            <li v-for="(cpv_code, cpvIndex) in project.cpv_codes" v-bind:key="`cpv_code_${index}_${cpvIndex}`">
              {{cpv_code}}
            </li>
          </ul>
        </td>
        <td>{{ date(project.submission_deadline)}} </td>
        <td>{{ project.status }}</td>
        <td>{{ locationStr(project.location) }}</td>
        <td>{{ formatAmount(project.estimated_overall_amount)}} </td>
        <td>{{ project.contract_type}} </td>
        <td v-b-tooltip.hover title="Abrir órgano o entidad">
          <router-link :to="{
            name: 'procurement_party',
            params: {
              id: project.contracting_party.id
            }
          }">
          {{ project.contracting_party.name }}
          </router-link>
          </td>
        <td>
          <ul>
            <li v-for="(party, partyIndex) in project.awardee_parties" v-bind:key="`awardee_party_${index}_${partyIndex}`">
              <router-link :to="{
                name: 'procurement_party',
                params: {
                  id: party.id
                }
              }">
              {{ party.name }}
              </router-link>

            </li>
          </ul>
        </td>
        <td> {{project.has_lots ? 'Sí' : 'No'}} </td>
        <td> {{ date(projectEndDate(project)) }} </td>
        <td>
          <template v-if="projectHasSubscription(project.id)">
            <i v-b-tooltip.hover title="Dejar de seguir" class="far fa-bell-slash fa-2x" @click="deleteProjectSubscription(project.id)"></i>
          </template>
          <template v-else>
            <template v-if="isNotResolved(project)">
              <i v-b-tooltip.hover title="Seguir" class="far fa-bell fa-2x"  @click="createProjectSubscription(project.id)"></i>
            </template>
            <template v-else-if="isNotOutdated(project)">
               <i v-b-tooltip.hover title="Avisarme cuando queden 6 meses de la finalización del período de ejecución" @click="createProjectSubscription(project.id)" class="far fa-clock fa-2x"></i>
            </template>
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>

import procurementMixin from '@/_mixins/procurement.mixin';

export default {
  name: 'procurementTable',
  props: ['projects'],
  mixins: [procurementMixin],
  methods: {
  },
};
</script>

<style scoped>
th, td{
  width:8.22%;
}

.fa-external-link-alt {
  color:#000000;
}
.fa-external-link-alt:hover {
  cursor:pointer;
  color:#4953CF;
}
</style>
