/* eslint-disable no-loop-func */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
/* eslint no-shadow: ["error", {"allow": ["state"]}] */
/* eslint no-param-reassign: ["error", { "props": true,
                                        "ignorePropertyModificationsFor": ["state"] }] */
import Vue from 'vue';
import documentService from '../_services/document.service';
import stopwords from '../_config/stopwords.json';

const state = {
  currentDocument: '',
  currentDocumentBaseUrl: '',
  currentDocumentSource: '',
  currentDocumentId: '',
  currentHighlightTriggers: '',
  currentMainTriggers: '',
  loading: false,
  notificationTitle: '',
  currentDocumentPages: {},
  currentDocumentParagraphs: {},
  currentMatches: [],
  currentSearchMatches: [],
};

const actions = {
  fetchDocument({ commit }, {
    stringId,
    source,
    highlightTriggers,
    notificationTitle,
    mainTriggers,
  }) {
    commit('setInitialState');
    commit('setLoading', true);

    // Fetch paragraphs
    return documentService.fetchDocument(`${process.env.VUE_APP_S3_BUCKET_URL}/${source}/${stringId}/${stringId}.json`).then(
      (json_response) => {
        const documentParagraphs = json_response.data;
        documentService.fetchDocument(`${process.env.VUE_APP_S3_BUCKET_URL}/${source}/${stringId}/${stringId}.html`).then(
          (response) => {
            const document = {
              document: response.data,
              notificationTitle,
              highlightTriggers: highlightTriggers || '',
              mainTriggers: mainTriggers || '',
              source,
              stringId,
              currentDocumentBaseUrl: `${process.env.VUE_APP_S3_BUCKET_URL}/${source}/${stringId}`,
            };

            const highlight_pages = async function highlight_pages(triggers) {
              const matches = [];
              const regex_quoted_words = /(".*?")/g;
              const regex_unquoted_words = /[a-zA-Z\u00C0-\u00FF]+(?=[^"]*(?:"[^"]*"[^"]*)*$)/g;
              // we cant use \w+ because otherwise we dont capture accents
              const lowercase_triggers = triggers.toLowerCase();
              const quoted_words_matches = lowercase_triggers.match(regex_quoted_words);
              const unquoted_words_matches = lowercase_triggers.match(regex_unquoted_words);

              Object.keys(documentParagraphs).forEach((page_id) => {
                const { paragraphs } = documentParagraphs[page_id];
                for (let i = 0; i < paragraphs.length; i += 1) {
                  let highlight_paragraph = false;
                  const paragraph_text = paragraphs[i].text.toLowerCase();
                  if (unquoted_words_matches) {
                    const unquoted_words = unquoted_words_matches.filter(word => stopwords[word] !== '');
                    for (let j = 0; j < unquoted_words.length && !highlight_paragraph; j += 1) {
                      if (paragraph_text.includes(unquoted_words[j])) {
                        highlight_paragraph = true;
                      }
                    }
                  }

                  if (quoted_words_matches && !highlight_paragraph) {
                    const quoted_words_list = quoted_words_matches.filter(word => word !== ' ' && stopwords[word] !== '');
                    const splitted_quoted_words = quoted_words_list.map(word => word.split(' '));

                    for (let j = 0; j < splitted_quoted_words.length && !highlight_paragraph; j += 1) {
                      let found_words = 0;
                      const quoted_words = splitted_quoted_words[j];

                      for (let k = 0; k < quoted_words.length; k += 1) {
                        if (paragraph_text.includes(quoted_words[k])) {
                          found_words += 1;
                        }
                      }
                      if (found_words === quoted_words.length) {
                        highlight_paragraph = true;
                      }
                    }
                  }

                  if (highlight_paragraph) {
                    matches.push({
                      page_id,
                      match_ids: paragraphs[i].ids,
                    });
                  }
                }
              });

              matches.sort((a, b) => {
                const id_a = parseInt(a.page_id, 16);
                const id_b = parseInt(b.page_id, 16);
                if (id_a > id_b) {
                  return 1;
                }
                if (id_a < id_b) {
                  return -1;
                }
                return 0;
              });

              commit('storeCurrentMatches', matches);
            };

            let triggers = '';

            if (document.highlightTriggers !== '') triggers = document.highlightTriggers;
            else if (document.mainTriggers !== '') triggers = document.mainTriggers;

            if (triggers !== '') {
              highlight_pages(triggers);
            }

            commit('setLoading', false);
            commit('storeDocument', document);
            commit('storeDocumentParagraphs', documentParagraphs);
          },
        );
      },
    );
  },
  searchDocument({ commit }, search_terms) {
    if (Object.keys(state.currentDocumentParagraphs).length === 0) {
      return;
    }

    const lowerCaseTerms = search_terms.toLowerCase().split(' ');

    const matches = [];
    Object.keys(state.currentDocumentParagraphs).forEach((page_id) => {
      const { paragraphs } = state.currentDocumentParagraphs[page_id];
      for (let i = 0; i < paragraphs.length; i += 1) {
        const terms_found = [];
        for (let j = 0; j < lowerCaseTerms.length; j += 1) {
          const term = lowerCaseTerms[j];
          if (paragraphs[i].text.includes(term)) {
            terms_found.push(term);
          }
        }
        if (terms_found.length > 0) {
          matches.push({
            page_id,
            match_ids: paragraphs[i].ids,
            terms_found,
          });
        }
        matches.sort((a, b) => {
          const id_a = parseInt(a.page_id, 16);
          const id_b = parseInt(b.page_id, 16);
          if (id_a > id_b) {
            return 1;
          }
          if (id_a < id_b) {
            return -1;
          }
          return 0;
        });
      }
    });
    commit('storeCurrentSearchMatches', matches);
  },
  setHighlightTriggers({ commit }, highlightTriggers) {
    commit('setHighlightTriggers', highlightTriggers);
  },
};

const mutations = {
  setHighlightTriggers(state, highlightTriggers) {
    state.currentHighlightTriggers = highlightTriggers;
  },
  setInitialState(state) {
    state.currentDocument = '';
    state.currentDocumentBaseUrl = '';
    state.currentDocumentSource = '';
    state.currentDocumentId = '';
    state.currentHighlightTriggers = '';
    state.currentMainTriggers = '';
    state.loading = false;
    state.notificationTitle = '';
    Vue.set(state, 'currentDocumentPages', []);
    Vue.set(state, 'currentDocumentParagraphs', []);
    Vue.set(state, 'currentMatches', []);
    Vue.set(state, 'currentSearchMatches', []);
  },
  storeMatches(state, { total_matches, matchesPageArray }) {
    state.total_matches = total_matches;
    Vue.set(state, 'matchesPageArray', matchesPageArray);
  },
  storeDocument(state, documentInfo) {
    state.currentDocument = documentInfo.document;
    state.notificationTitle = documentInfo.notificationTitle;
    state.currentHighlightTriggers = documentInfo.highlightTriggers;
    state.currentMainTriggers = documentInfo.mainTriggers;
    state.currentDocumentBaseUrl = documentInfo.currentDocumentBaseUrl;
    state.currentDocumentSource = documentInfo.source;
    state.currentDocumentId = documentInfo.stringId;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  storeDocumentParagraphs(state, documentParagraphs) {
    state.currentDocumentParagraphs = documentParagraphs;
  },
  storeCurrentDocumentPages(state, currentDocumentPages) {
    Vue.set(state, 'currentDocumentPages', currentDocumentPages);
  },
  storeCurrentMatches(state, currentMatches) {
    Vue.set(state, 'currentMatches', currentMatches);
  },
  storeCurrentSearchMatches(state, currentSearchMatches) {
    Vue.set(state, 'currentSearchMatches', currentSearchMatches);
  },
};

const getters = {
  totalMatches() {
    return state.currentMatches.length;
  },
  totalSearchMatches() {
    return state.currentSearchMatches.length;
  },
};

const documents = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default documents;
