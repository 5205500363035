// import { authHeader } from '../_helpers/auth-header';
import axios from '../_helpers/axios';


function addPaymentInformation(data) {
  return axios.put('/stripe/customers', data).then(res => res);
}

function addUser(subscription_id, user_email) {
  return axios.post(`/${process.env.VUE_APP_API_VERSION}/subscriptions/${subscription_id}/user`, { user_email }).then(res => res);
}

function createSubscription(params) {
  return axios.post('/stripe/subscriptions', params).then(res => res);
}

function deleteSubscription() {
  return axios.delete('/stripe/subscription').then(res => res);
}

function downloadInvoice(invoiceId) {
  return axios.get(`/stripe/invoices/${invoiceId}`, { responseType: 'blob' }).then(res => res);
}

function getCustomer() {
  return axios.get('/stripe/customer').then(res => res);
}

function getProration(quantity) {
  return axios.get('/stripe/subscription/proration', { params: { quantity } }).then(res => res);
}

function getSubscription(id) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/subscriptions/${id}`).then(res => res);
}

function indexInvoices() {
  return axios.get('/stripe/invoices').then(res => res);
}

function payInvoice(invoiceId) {
  return axios.get(`/stripe/invoices/${invoiceId}/pay`).then(res => res);
}

function removeUser(id, user_id) {
  return axios.delete(`/${process.env.VUE_APP_API_VERSION}/subscriptions/${id}/user/${user_id}`).then(res => res);
}

function updateCustomer(params) {
  return axios.put('/stripe/customer', params).then(res => res);
}

function updateSubscription(params) {
  return axios.put('/stripe/subscription', params).then(res => res);
}

const subscriptionsService = {
  addPaymentInformation,
  addUser,
  createSubscription,
  deleteSubscription,
  downloadInvoice,
  getCustomer,
  getProration,
  getSubscription,
  indexInvoices,
  payInvoice,
  removeUser,
  updateCustomer,
  updateSubscription,
};

export default subscriptionsService;
