<template>
  <div class="advanced-filters">
    <template v-if="filters">
      <h5>Filtros Avanzados:</h5>
      <dl class="row">
        <template v-if="filters.title && filters.title !== ''">
          <dt class="col-sm-3"> Título: </dt>
          <dd class="col-sm-9"> {{filters.title}} </dd>
        </template>
        <template v-if="alert_type === 'official_bulletin'">
          <template v-if="filters.summary && filters.summary !== ''">
            <dt class="col-sm-3"> Resumen:</dt>
            <dd class="col-sm-9"> {{filters.summary}} </dd>
          </template>
          <template v-if="filters.department && filters.department !== ''">
            <dt class="col-sm-3"> Departamento:</dt>
            <dd class="col-sm-9">{{filters.department}}</dd>
          </template>
          <template v-if="filters.must_not_terms && filters.must_not_terms !== ''">
            <dt class="col-sm-3"> Términos de exclusión:</dt>
            <dd class="col-sm-9">{{filters.must_not_terms}}</dd>
          </template>
          <dt class="col-sm-3"> Fuentes de datos:</dt>
          <dd class="col-sm-9" v-if="filters.sources && filters.sources.length > 0">
            {{ filters.sources.join(', ')}}
          </dd>
          <dd class="col-sm-9" v-else>
            Todas
          </dd>
          <template v-if="filters.highlightTerms && filters.highlightTerms !== ''">
            <dt class="col-sm-3"> Resaltado del documento:</dt>
            <dd class="col-sm-9">{{filters.highlightTerms}}</dd>
          </template>
        </template>
        <template v-if="alert_type === 'procurement'">
          <template v-if="filters.contracting_party && filters.contracting_party.name && filters.contracting_party.name !== ''">
            <dt class="col-sm-3"> Órgano de contratación:</dt>
            <dd class="col-sm-9"> {{filters.contracting_party.name}} </dd>
          </template>
          <template v-if="filters.lot_division && filters.lot_division !== 'all'">
            <dt class="col-sm-3"> División:</dt>
            <dd class="col-sm-9">{{ $t(`procurement.filters.${filters.lot_division}`)}}</dd>
          </template>
          <template v-if="filters.status && filters.status.length > 0">
            <dt class="col-sm-3"> Estado:</dt>
            <dd class="col-sm-9">{{filters.status.join(', ')}}</dd>
          </template>
          <template v-if="filters.cpv_codes && filters.cpv_codes.length > 0">
            <dt class="col-sm-3"> Códigos CPV:</dt>
            <dd class="col-sm-9">{{cpv_codes(filters.cpv_codes)}}</dd>
          </template>
          <template v-if="filters.contract_types && filters.contract_types.length > 0">
            <dt class="col-sm-3"> Tipo de Contrato:</dt>
            <dd class="col-sm-9">{{filters.contract_types.join(', ')}}</dd>
          </template>
          <template v-if="filters.submission_deadline && filters.submission_deadline !== ''">
            <dt class="col-sm-3"> Fecha límite de presentación:</dt>
            <dd class="col-sm-9">
              {{date(filters.submission_deadline)}}
            </dd>
          </template>
          <template v-if="filters.date && (filters.date.from || filters.date.to)">
            <dt class="col-sm-3"> Fecha publicación entre:</dt>
            <dd class="col-sm-9">
                <template v-if="filters.date.from && filters.date.from != ''">
                {{ date(filters.date.from) }}
              </template>
              -
              <template v-if="filters.date.to && filters.date.to != ''">
                {{ date(filters.date.to) }}
              </template>
            </dd>
          </template>
          <template v-if="filters.amount && (filters.amount.from || filters.amount.to)">
            <dt class="col-sm-3"> Valor estimado del contrato:</dt>
            <dd class="col-sm-9">
                <template v-if="filters.amount.from && filters.amount.from != ''">
                Desde: {{ formatAmount(filters.amount.from) }}
              </template>
              <template v-if="filters.amount.to && filters.amount.to != ''">
                Hasta: {{ formatAmount(filters.amount.to) }}
              </template>
            </dd>
          </template>
          <template v-if="filters.location">
            <template v-if="filters.location.country">
              <dt class="col-sm-3"> País:</dt>
              <dd class="col-sm-9">{{country(filters.location.country)}}</dd>
            </template>
            <template v-if="filters.location.city">
              <dt class="col-sm-3"> Ciudad:</dt>
              <dd class="col-sm-9">{{filters.location.city}}</dd>
            </template>
            <template v-if="filters.location.postal_codes && filters.location.postal_codes.length > 0">
              <dt class="col-sm-3"> {{filters.location.postal_codes.length > 1 ? 'Regiones' : 'Región'}}</dt>
              <dd class="col-sm-9">{{region_names(filters.location.postal_codes)}}</dd>
            </template>
          </template>
        </template>
      </dl>
    </template>
    <template v-else>
      Esta alerta no tiene filtros avanzados
    </template>
  </div>
</template>

<script>
import alertFiltersMixin from '@/_mixins/alert_filters.mixin';

export default {
  name: 'AdvancedFiltersComponent',
  props: ['filters', 'alert_type'],
  mixins: [alertFiltersMixin],
};
</script>

<style scoped>
  dl {
    text-align: left;
  }
  dd, dt{
    margin: 0px;
    border-bottom: 1px dotted #dddddd;
    padding-top: 10px;
  }
  .advanced-filters{
    background-color: #f5f5f5;
    padding:20px;
    max-width:1200px;
  }
  .advanced-filters h5{
    text-align: left;
    margin-bottom: 10px;
  }
</style>
