var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table text-left"},[_vm._m(0),_c('tbody',_vm._l((_vm.projects),function(project,index){return _c('tr',{key:project.id},[_c('td',[_vm._v(_vm._s(_vm.date(project.date))+" ")]),_c('router-link',{attrs:{"to":{
            name: 'procurement',
            params: {
              comingFrom: '/licitaciones',
              id: project.id
            }
          }}},[_c('td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Abrir licitación"},domProps:{"innerHTML":_vm._s(_vm.projectHighlightedTitle(project))}})]),_c('td',[_c('ul',_vm._l((project.cpv_codes),function(cpv_code,cpvIndex){return _c('li',{key:("cpv_code_" + index + "_" + cpvIndex)},[_vm._v("\n            "+_vm._s(cpv_code)+"\n          ")])}),0)]),_c('td',[_vm._v(_vm._s(_vm.date(project.submission_deadline))+" ")]),_c('td',[_vm._v(_vm._s(project.status))]),_c('td',[_vm._v(_vm._s(_vm.locationStr(project.location)))]),_c('td',[_vm._v(_vm._s(_vm.formatAmount(project.estimated_overall_amount))+" ")]),_c('td',[_vm._v(_vm._s(project.contract_type)+" ")]),_c('td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Abrir órgano o entidad"}},[_c('router-link',{attrs:{"to":{
          name: 'procurement_party',
          params: {
            id: project.contracting_party.id
          }
        }}},[_vm._v("\n        "+_vm._s(project.contracting_party.name)+"\n        ")])],1),_c('td',[_c('ul',_vm._l((project.awardee_parties),function(party,partyIndex){return _c('li',{key:("awardee_party_" + index + "_" + partyIndex)},[_c('router-link',{attrs:{"to":{
              name: 'procurement_party',
              params: {
                id: party.id
              }
            }}},[_vm._v("\n            "+_vm._s(party.name)+"\n            ")])],1)}),0)]),_c('td',[_vm._v(" "+_vm._s(project.has_lots ? 'Sí' : 'No')+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.date(_vm.projectEndDate(project)))+" ")]),_c('td',[(_vm.projectHasSubscription(project.id))?[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"far fa-bell-slash fa-2x",attrs:{"title":"Dejar de seguir"},on:{"click":function($event){return _vm.deleteProjectSubscription(project.id)}}})]:[(_vm.isNotResolved(project))?[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"far fa-bell fa-2x",attrs:{"title":"Seguir"},on:{"click":function($event){return _vm.createProjectSubscription(project.id)}}})]:(_vm.isNotOutdated(project))?[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"far fa-clock fa-2x",attrs:{"title":"Avisarme cuando queden 6 meses de la finalización del período de ejecución"},on:{"click":function($event){return _vm.createProjectSubscription(project.id)}}})]:_vm._e()]],2)],1)}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"corporative"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" Fecha Publicación ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Título ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" CPV ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Fecha límite presentación ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Estado ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Lugar ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Valor estimado")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Tipo de contrato ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Órgano o Entidad ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Entidad Adjudicataria ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Por Lotes ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Fecha fin")]),_c('th')])])}]

export { render, staticRenderFns }