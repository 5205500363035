<template>
  <div class="row">
    <div class="col-12">
      <b-alert
        :show="editAlertCountDown"
        dismissible
        :variant="editAlertAlertVariant"
        @dismissed="editAlertCountDown=0"
        @dismiss-count-down="alertCountDownChanged"
      >
        <p>La alerta {{editAlert.name}} {{editAlertAlertMessage}}</p>
      </b-alert>
    </div>
    <div class="col-12" v-if="no_pagination !== true">
      <paginationComponent
        id="alerts-pagination"
        :currentPage="current_page"
        :totalPages="total_pages"
        v-on:next-page-alerts-pagination="indexAlerts({ alert_type, page: $event})"/>
    </div>

    <div class="col-12 pt-0" v-show="editMode">
      <h4> Editar alerta</h4>
      <div class="row mt-3 pt-3 text-left">
        <div class="col-6">
          <h4>Nombre de alerta</h4>
          <div class="input">
           <input type="text" v-model="editAlert.name" id="name" name="name" >
          </div>
        </div>
        <div class="col-6">
          <h4>Términos de búsqueda</h4>
          <div class="input-group ">
            <i class="fas fa-search fa-2x pt-2 px-2" @click="startSearch()"></i>
            <input type="text" class="form-control"
              id="search" placeholder="Buscar"
              v-model="editAlert.searchTerms"
              @keyup.enter="startEditSearch()"
              v-debounce:500ms="startEditSearch">
          </div>
        </div>
      <div class="col-12">
        Frecuencia emails:
         <b-form-select v-model="editAlert.mailing_frequency" :options="mailing_frequency_options"></b-form-select>
      </div>
      </div>

      <template v-if="editAlert.alert_type === 'procurement'">
        <div class="row text-left">
          <div class="col-12">
            <procurementFilters
              :currentFilters="editAlert.filters"
              :updateFiltersUI="updateEditAlertFiltersUI"
              v-on:setFilters="startEditSearch"/>
          </div>
        </div>
        <div class="row my-3">
          <div class="col-12">
            <button class="btn btn-secondary ml-3" @click="saveAlert(editAlert)"><i class="fas fa-save"></i> Guardar Alerta </button>
            <button class="btn btn-secondary ml-3" @click="editMode=false"> Cerrar sin guardar </button>
          </div>
        </div>
        <div class="row text-left">
          <div class="col-12">
            <procurementResultsTable :projects="editResults.results" v-if="editAlert.alert_type === 'procurement'"/>
          </div>
        </div>
      </template>
      <template v-if="editAlert.alert_type === 'official_bulletin'">
        <div class="row p-0">
          <div class="col-12">
            <officialBulletinFilters
              :currentFilters="editAlert.filters"
              :updateFiltersUI="updateEditAlertFiltersUI"
              v-on:setFilters="startEditSearch"/>
          </div>
        </div>
        <div class="row my-3">
        <div class="col-12">
          <button class="btn btn-secondary ml-3" @click="saveAlert(editAlert)"><i class="fas fa-save"></i> Guardar Alerta </button>
          <button class="btn btn-secondary ml-3" @click="editMode=false"> Cerrar sin guardar </button>
        </div>
      </div>
      <officialBulletinResults :results="editResults.results"
      :currentSearchTerms="editAlert.searchTerms"
      :currentFilters="editAlert.filters"></officialBulletinResults>
      </template>
    </div>
    <div v-show="!editMode" class="col-12">
      <b-table :items="items" :fields="fields" responsive="sm" class="light text-left">
        <template #cell(actions)="row" class="text-right">
            <i v-b-tooltip.hover title="Editar alerta" class="fas fa-edit fa-2x" @click="setEditAlert(row.item)"></i>
            <i v-b-tooltip.hover title="Borrar alerta" class ="fas fa-trash-alt fa-2x ml-2" @click="deleteAlert(row.item.id)"></i>
            <i v-b-tooltip.hover title="Ocultar detalles" v-if="row.detailsShowing" class="fas fa-angle-down fa-2x ml-3" @click="row.toggleDetails"></i>
            <i v-b-tooltip.hover title="Mostrar detalles" v-else class="fas fa-angle-right fa-2x ml-3" @click="row.toggleDetails"></i>
        </template>
        <template #row-details="row">
          <b-card>
            <advancedFilters :filters="row.item.filters" :alert_type="row.item.alert_type"/>
          </b-card>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Vue from 'vue';

import procurementFilters from '@/components/procurement/procurement_filters.vue';
import procurementResultsTable from '@/components/procurement/results_table.vue';
import officialBulletinFilters from '@/components/official_bulletins/search_filters.vue';
import officialBulletinResults from '@/components/official_bulletins/search_results.vue';
import advancedFilters from '@/components/alerts/advanced_filters.vue';
import paginationComponent from '@/components/helpers/pagination.vue';

import procurementService from '@/_services/procurement.service';
import officialBulletinService from '@/_services/official_bulletins.service';

export default {
  name: 'AlertsTable',
  props: ['alerts', 'current_page', 'total_pages', 'alert_type', 'no_pagination'],
  components: {
    procurementFilters,
    procurementResultsTable,
    officialBulletinFilters,
    officialBulletinResults,
    advancedFilters,
    paginationComponent,
  },
  data() {
    return {
      editMode: false,
      editAlert: {},
      mailing_frequency_options: [
        { value: 'daily', text: 'Diario' },
        { value: 'weekly', text: 'Semanal' },
        { value: 'no_email', text: 'Sin email' },
      ],
      editAlertCountDown: 0,
      editAlertAlertVariant: '',
      editAlertAlertMessage: '',
      editResults: {
        current_page: 1,
        total_pages: 1,
        total_results: 0,
        results: [],
      },
      updateEditAlertFiltersUI: false, // Will update with every change, not only in true
      selectedTags: {},
      fields: [
        {
          key: 'name',
          label: 'Nombre',
          sortable: true,
          thClass: 'corporative',
        },
        {
          key: 'searchTerms',
          label: 'Términos de búsqueda',
          thClass: 'corporative',
        },
        {
          key: 'total_notifications',
          label: 'Notificaciones encontradas',
          thClass: 'corporative',
        },
        {
          key: 'last_notification',
          label: 'Última notificación',
          formatter: 'date',
          thClass: 'corporative',
        },
        {
          key: 'send_email',
          label: 'Recibir emails',
          thClass: 'corporative',
        },
        {
          key: 'mailing_frequency',
          label: 'Frecuencia Emails',
          thClass: 'corporative',
          formatter: value => this.mailing_frequency(value),
        },
        {
          key: 'actions',
          label: '',
          thClass: 'corporative',
        },
      ],
      items: [],
    };
  },
  methods: {
    ...mapActions('alertsModule', {
      deleteAlert: 'deleteAlert',
      updateAlert: 'updateAlert',
      indexAlerts: 'indexAlerts',
    }),
    ...mapActions('flashMessages', {
      errorFlashMessage: 'error',
    }),
    alertCountDownChanged(dismissCountDown) {
      this.editAlertCountDown = dismissCountDown;
    },
    alertType(value) {
      if (value === 'official_bulletin') {
        return 'Publicaciones Oficiales';
      }
      return 'Licitaciones';
    },
    mailing_frequency(frequency) {
      if (frequency === 'daily') return 'Diario';
      if (frequency === 'weekly') return 'Semanal';
      if (frequency === 'no_email') return 'Sin email';
      return '';
    },
    saveAlert(alert) {
      this.updateAlert(alert).then(() => {
        this.editAlertCountDown = 5;
        this.editAlertAlertVariant = 'success';
        this.editAlertAlertMessage = ' se ha actualizado correctamente';
        this.editMode = false;
      }).catch(() => {
        this.editAlertCountDown = 5;
        this.editAlertAlertVariant = 'danger';
        this.editAlertAlertMessage = ' no se ha podido actualizar';
      });
    },
    startEditSearch(event) {
      const params = {
        searchTerms: this.editAlert.searchTerms,
        page: 1,
        sortField: 'score',
        sortOrder: 'desc',
      };

      if (event) {
        Vue.set(this.editAlert, 'filters', event.filters);
      }
      params.filters = this.editAlert.filters;

      if (this.editAlert.alert_type === 'procurement') {
        procurementService.search(params).then(
          (response) => {
            const { results } = response.data;
            const { total_results, total_pages, current_page } = response.data.metadata;
            Vue.set(this, 'editResults', {
              results,
              total_results,
              total_pages,
              current_page,
            });
          },
        ).catch((error) => {
          console.log(error);
          this.errorFlashMessage({ message: 'empty', i18nArgs: { content: 'Se ha producido algún error. Por favor, inténtelo de nuevo más tarde' } });
        });
      } else if (this.editAlert.alert_type === 'official_bulletin') {
        officialBulletinService.search(params).then(
          (response) => {
            const { results } = response.data;
            const { total_results, total_pages, current_page } = response.data.metadata;
            Vue.set(this, 'editResults', {
              results,
              total_results,
              total_pages,
              current_page,
            });
          },
        ).catch((error) => {
          console.log(error);
          this.errorFlashMessage({ message: 'empty', i18nArgs: { content: 'Se ha producido algún error. Por favor, inténtelo de nuevo más tarde' } });
        });
      }
    },
    setEditAlert(alert) {
      this.editMode = true;
      Vue.set(this, 'editResults', {
        current_page: 1,
        total_pages: 1,
        total_results: 0,
        results: [],
      });
      Vue.set(this, 'editAlert', alert);
      this.updateEditAlertFiltersUI = !this.updateEditAlertFiltersUI;
      this.startEditSearch();
    },
  },
  watch: {
    alerts() {
      const items = this.alerts.map(alert => ({
        _showDetails: false,
        ...alert,
      }));
      Vue.set(this, 'items', items);
    },
  },
};
</script>

<style scoped>
  dl {
    text-align: left;
  }
  dd, dt{
    margin: 0px;
    border-bottom: 1px dotted #dddddd;
    padding-top: 10px;
  }
  .fa-angle-right, .fa-angle-down{
    color:grey;
    cursor:pointer;
  }
  .advanced-filters{
    background-color: #f5f5f5;
    padding:20px;
    max-width:1200px;
  }
  .advanced-filters h5{
    text-align: left;
    margin-bottom: 10px;
  }
</style>
