<template>
  <div class="row mt-2">
    <div class="col-6">
      <ul>
        <li v-for="(document, index) in documents" v-bind:key="`doc_${index}`">
          <span v-if="document.mime_type === 'application/pdf'"
            @click="loadDocument(document)">{{documentName(document)}}
            <i class="far fa-arrow-alt-circle-down ml-2" ></i>
          </span>
          <span v-else ><a :href="document.uri" target="_blank"> {{documentName(document)}}<i class="far fa-arrow-alt-circle-down ml-2" ></i></a></span>
        </li>
      </ul>
    </div>
    <div class="col-6">
      <iframe :src="currentDocumentUri" v-if="currentDocumentUri">
      </iframe>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf';

const URI = require('urijs');


export default {
  name: 'procurementDocumentsList',
  props: ['documents'],
  components: {
    pdf,
  },
  data() {
    return {
      currentDocumentUri: '',
    };
  },
  mounted() {
  },
  methods: {
    documentName(document) {
      if (document.name) return document.name;
      if (document.string_id) return document.string_id;

      return new URI(document.uri).filename();
    },
    loadDocument(document) {
      this.currentDocumentUri = document.uri;
    },
  },
};
</script>

<style scoped>
 iframe {
   height: 100vh;
   overflow: 'scroll';
   width:90%;position:absolute;top:0px;left:0px;right:0px;bottom:0px;
}


</style>
