import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '../_locales/en.json';
import es from '../_locales/es.json';

const messages = {
  en,
  es,
};

Vue.use(VueI18n);

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD',
    },
  },
  'es-ES': {
    currency: {
      style: 'currency', currency: 'EUR',
    },
  },
};

const i18n = new VueI18n({
  locale: 'es',
  fallbackLocale: 'en', // set fallback locale
  messages,
  numberFormats,
});


export default i18n;
