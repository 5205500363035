<template>

  <div :id="`notification_${notification.id}`"
    class="notification my-2 p-3" >
    <h6> {{notification.data.source}}, {{date(notification.date)}} </h6>
    <h5>
      <a :href="`${notification.data.pdf_url}`" target="_blank">
        {{limitStringLength(notification.data.title)}}
      </a>
    </h5>
    <h6>
      {{limitStringLength(notification.data.department)}}
    </h6>
    <p>
      {{limitStringLength(notification.data.summary)}}
    </p>

    <AlertsTable v-if="showAlerts"
      :alerts="notification.alerts"
      :notification_string_id="notification.data.string_id"
      :notification_source="notification.data.source"
      :notification_title="notification.data.title"
      :no_pagination="true"
      />

    <div class="buttons" v-if="showActions">
      <i v-b-tooltip.hover title="Enivar por email" class="fas fa-share-alt fa-2x mr-3" @click="$emit('share-notification', { document: notification.data } )"></i>
      <i v-b-tooltip.hover title="Descargar" class="far fa-arrow-alt-circle-down fa-2x mr-3" @click="download()" ></i>
      <i v-b-tooltip.hover title="Eliminar" class="fas fa-trash-alt fa-2x mr-3" @click="updateNotification({processed: true, id: notification.id})" ></i>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AlertsTable from './alerts_table.vue';


export default {
  name: 'notification',
  components: {
    AlertsTable,
  },
  props: {
    notification: {
      required: true,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    showAlerts: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      starredHoverState: false,
    };
  },
  computed: {
    tags() {
      const tags = [];
      const { alerts } = this.notification;
      for (let i = 0; i < alerts.length; i += 1) {
        const alertTags = alerts[i].tags;
        for (let j = 0; j < alertTags.length; j += 1) {
          tags.push(alertTags[j]);
        }
      }
      return tags;
    },
    starred() {
      if ((this.notification.starred && !this.starredHoverState)
          || (!this.notification.starred && this.starredHoverState)) return 'fa fa-star star-active fa-2x';
      return 'fa fa-star-o star-inactive fa-2x';
    },
  },
  methods: {
    ...mapActions('officialBulletinsNotificationsModule', {
      updateNotification: 'updateNotification',
    }),
    setStarredHoverState(isHover) {
      this.starredHoverState = isHover;
    },
    download() {
      window.open(this.notification.data.pdf_url, '_blank');
    },
  },
};
</script>

<style scoped>

  .notification {
    border-top: 1px solid #d1d5da;
  }
  #notificationTitle {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }

  .star {
    margin-right: 10px;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  li {
    float: left;
  }
  li:nth-child(n+2) {
    margin-left: 5px;
  }
  .buttons {
    margin-bottom: 5px;
    text-align: right;
  }

</style>
