<template>
  <div id="recover_password" class="col-12">
    <div class="recover-password-form">
      <form @submit.prevent="onSubmit">
        <div class="input">
          <label for="email">Email</label>
          <input type="email"
                 id="email"
                 v-validate="'required|email'"
                 name="email"
                 v-model="email">
          <span v-show="errors.has('email')" class="invalid">{{ errors.first('email') }}</span>
        </div>
        <div>
          <button class ="btn btn-secondary">Recuperar contraseña</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import userService from '../../_services/user.service';

export default {
  data() {
    return {
      email: '',
    };
  },
  methods: {
    ...mapActions('flashMessages', {
      successFlashMessage: 'success',
      errorFlashMessage: 'error',
    }),
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          userService.requestNewPassword(this.email).then(() => {
            this.successFlashMessage({
              message: 'recoverPasswordSuccess',
              i18nArgs: { email: this.email },
            });

            this.$router.push('sign_in');
          });
        } else {
          // e.preventDefault();
        }
      });
    },
  },
};
</script>

<style scoped>
  .recover-password-form {
    width: 400px;
    margin: 30px auto;
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: 0 2px 3px #ccc;
  }

  .submit button {
    border: 1px solid #521751;
    color: #521751;
    padding: 10px 20px;
    font: inherit;
    cursor: pointer;
  }

  .submit button:hover,
  .submit button:active {
    background-color: #521751;
    color: white;
  }

  .submit button[disabled],
  .submit button[disabled]:hover,
  .submit button[disabled]:active {
    border: 1px solid #ccc;
    background-color: transparent;
    color: #ccc;
    cursor: not-allowed;
  }
</style>
