/* eslint-disable import/prefer-default-export */

export function calculatePrice(users, mode) {

  if (mode === 'monthly') {
    if (users === 1) {
      return 45;
    }
    if (users <= 10) {
      return users * 30;
    }
    if (users > 10) {
      return users * 25;
    }
  } else if (mode === 'yearly') {
    if (users === 1) {
      return 450;
    }
    if (users <= 10) {
      return users * 300;
    }
    if (users > 10) {
      return users * 250;
    }
  }

  return 0;
}
