<template>
  <b-modal id="share-email-modal" hide-footer :title="`Compartir ${document.string_id} vía email`" size="xl" >
    <div class="grid">
      <div class="col-12">
        <div class="input">
          <label for="recipients"> Destinatarios </label>
          <p> Separados por espacios, ',' ó ; </p>
          <input type="text" name="recipients" v-model="recipientsString">
        </div>
      </div>
      <div class="col-12 grid">
        <div class="col-4" v-for="(recipient, index) in recipients" v-bind:key="`recipient_${index}`">
          <category-tag
            :tag="{name: recipient, font_color: '#337AB7', background_color: '#F3F7FD'}"
            :editable="true"
            v-on:remove-tag="removeRecipient(recipient)"
          ></category-tag>
        </div>
      </div>
      <div class="col-12">
        <div class="input">
          <label for="message"> Mensaje </label>
          <textarea class="messageArea p-3" type="textarea" name="message" v-model="message"> </textarea>
        </div>
      </div>
      <div class="col-12 grid">
        <div class="col-12" v-show="errorMessage !== ''">
          <span class="invalid">{{ errorMessage }}</span>
        </div>
        <div class="col-12 text-center">
          <button
            class="btn btn-secondary"
            @click="sendMail()"
          >Enviar email</button>
        </div>
       <!-- <div class="col-4"></div>
        <div class="col-4">
          <button class="btn btn-secondary" @click="hide()">Cerrar</button>
        </div>-->
      </div>
    </div>
  </b-modal>
</template>

<script>
import documentsService from '@/_services/document.service';
import Tag from '../tags/tag.vue';

export default {
  name: 'shareEmail',
  props: ['document'],
  components: {
    'category-tag': Tag,
  },
  data() {
    return {
      showModal: false,
      recipientsString: '',
      message: '',
      errorMessage: '',
    };
  },
  computed: {
    recipients() {
      return this.recipientsString.split(/[ ;,]+/).filter(x => x);
    },
  },
  created() {
    this.$validator.attach('email', 'required|email');
  },
  methods: {
    hide() {
      this.$bvModal.hide('share-email-modal');
    },
    removeRecipient(recipient) {
      this.recipientsString = this.recipientsString.replace(recipient, '');
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendMail() {
      if (this.recipients.length === 0) {
        this.errorMessage = 'Debes introducir al menos un email al que compartir el documento.'
      }

      const validated_emails = this.recipients.map((recipient) => {
        return {
          recipient,
          validated: this.validateEmail(recipient),
        };
      });

      const all_emails_valid = validated_emails.reduce((accumulator, item) => accumulator && item.validated, true);

      if (all_emails_valid) {
        documentsService.shareDocument({
          id: this.document.id,
          emails: this.recipients,
          comments: this.message,
          source: this.document.source,
        }).then(() => {
          this.errorMessage = '';
          this.hide();
        }).catch(() => {
          this.errorMessage = 'Error al compartir el documento. No se ha podido enviar el email.';
          console.log('could not send email');
        });
      } else {
        let errorString = 'Los siguientes emails no son válidos: ';

        let erroredEmails = 0;
        for (let i = 0; i < validated_emails.length; i += 1) {
          const { recipient, validated } = validated_emails[i];

          if (!validated) {
            if (erroredEmails > 0) errorString += ', ';
            errorString += recipient;
            erroredEmails += 1;
          }
        }
        this.errorMessage = errorString;
      }
    },
  },
};

</script>

<style scoped>
  .messageArea {
    min-height: 500px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
  }
</style>
