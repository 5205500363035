import axios from '../_helpers/axios';

function getProcurement(id) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/procurement_projects/${id}`).then(res => res);
}

function getProcurementParty(id) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/procurement_party/${id}`).then(res => res);
}

function getProcurementPartyTenderResults(id, params) {
  return axios.post(`/${process.env.VUE_APP_API_VERSION}/procurement_party/${id}/tender_results`, params).then(res => res);
}

function getProcurementPartyContractedProjects(id, params) {
  return axios.post(`/${process.env.VUE_APP_API_VERSION}/procurement_party/${id}/contracted_projects`, params).then(res => res);
}
function createProcurementPartySubscription({ id }) {
  return axios.post(`/${process.env.VUE_APP_API_VERSION}/procurement_party/${id}/subscription`).then(res => res);
}

function getProcurementPartySubscriptions(params = {}) {
  return axios.post(`/${process.env.VUE_APP_API_VERSION}/procurement_party_subscriptions`, params).then(res => res);
}

function deleteProcurementPartySubscription(id) {
  return axios.delete(`/${process.env.VUE_APP_API_VERSION}/procurement_party/${id}/subscription`).then(res => res);
}

function createProcurementProjectSubscription(id) {
  return axios.post(`/${process.env.VUE_APP_API_VERSION}/procurement_projects/${id}/subscription`).then(res => res);
}

function getProcurementProjectSubscriptions(params = {}) {
  return axios.post(`/${process.env.VUE_APP_API_VERSION}/procurement_project_subscriptions`, params).then(res => res);
}

function deleteProcurementProjectSubscription(id) {
  return axios.delete(`/${process.env.VUE_APP_API_VERSION}/procurement_projects/${id}/subscription`).then(res => res);
}

function search(params) {
  return axios.post(`/${process.env.VUE_APP_API_VERSION}/procurement/search`, params).then(res => res);
}

function searchProcurementParty(params) {
  return axios.post(`/${process.env.VUE_APP_API_VERSION}/procurement_party/search`, params).then(res => res);
}

function updateProcurementPartySubscription(id, params) {
  return axios.put(`/${process.env.VUE_APP_API_VERSION}/procurement_party/${id}/subscription`, params).then(res => res);
}

function updateProcurementProjectSubscription(id, params) {
  return axios.put(`/${process.env.VUE_APP_API_VERSION}/procurement_projects/${id}/subscription`, params).then(res => res);
}
const procurementService = {
  createProcurementPartySubscription,
  createProcurementProjectSubscription,
  deleteProcurementPartySubscription,
  deleteProcurementProjectSubscription,
  getProcurement,
  getProcurementParty,
  getProcurementPartyContractedProjects,
  getProcurementPartyTenderResults,
  getProcurementPartySubscriptions,
  getProcurementProjectSubscriptions,
  search,
  searchProcurementParty,
  updateProcurementPartySubscription,
  updateProcurementProjectSubscription,
};

export default procurementService;
