/* eslint-disable import/prefer-default-export */
/* eslint no-shadow: ["error", {"allow": ["state"]}] */
/* eslint no-param-reassign: ["error", { "props": true,
                                        "ignorePropertyModificationsFor": ["state"] }] */
import Vue from 'vue';
import subscriptionService from '../_services/subscriptions.service';

const state = {
  invoices: [],
  fetchingInvoices: false,
  subscription: {},
  fetchingSubscription: false,
  customer: {
    billing_address: {
      line1: '',
      line2: '',
      postal_code: '',
      state: '',
      city: '',
      country: '',
    },
    card: {
      last4: '',
      brand: '',
      funding: '',
    },
  },
};

const actions = {
  addUserToSubscription({ dispatch }, { email }) {
    return subscriptionService.addUser(email).then(
      () => {
        dispatch('getSubscription');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  deleteSubscription({ dispatch }) {
    // We return the promise from axios so that the view will now when the data has loaded
    return subscriptionService.deleteSubscription().then(
      () => {
        dispatch('getSubscription');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  getCustomer({ commit }) {
    subscriptionService.getCustomer().then(
      (response) => {
        const { customer } = response.data;
        commit('storeCustomer', { customer });
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  getSubscription({ commit }) {
    commit('setFetchingSubscription', true);

    // We return the promise from axios so that the view will now when the data has loaded
    return subscriptionService.getSubscription().then(
      (response) => {
        const subscription = response.data;
        commit('setFetchingSubscription', false);
        commit('storeSubscription', { subscription });
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  indexInvoices({ commit }) {
    commit('setFetchingInvoices', true);

    // We return the promise from axios so that the view will now when the data has loaded
    return subscriptionService.indexInvoices().then(
      (response) => {
        const { invoices } = response.data;
        commit('setFetchingInvoices', false);
        commit('storeInvoices', { invoices });
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  payInvoice({ dispatch }, invoiceId) {
    // We return the promise from axios so that the view will now when the data has loaded
    return subscriptionService.payInvoice(invoiceId).then(
      () => {
        dispatch('indexInvoices');
        // dispatch('usersModule/fetchUser');
        // TODO fetch user
        dispatch('getSubscription');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  removeUserFromSubscription({ dispatch }, { email }) {
    return subscriptionService.removeUser(email).then(
      () => {
        dispatch('getSubscription');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  updateCustomer({ dispatch }, params) {
    // We return the promise from axios so that the view will now when the data has loaded
    return subscriptionService.updateCustomer(params).then(
      () => {
        dispatch('getCustomer');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  updateSubscription({ dispatch }, params) {
    // We return the promise from axios so that the view will now when the data has loaded
    return subscriptionService.updateSubscription(params).then(
      () => {
        dispatch('getSubscription');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
};

const mutations = {
  storeCustomer(state, { customer }) {
    Vue.set(state, 'customer', customer);
  },
  setFetchingInvoices(state, fetchingInvoices) {
    state.fetchingInvoices = fetchingInvoices;
  },
  storeInvoices(state, { invoices }) {
    Vue.set(state, 'invoices', invoices);
  },
  setFetchingSubscription(state, fetchingSubscription) {
    state.fetchingSubscription = fetchingSubscription;
  },
  storeSubscription(state, { subscription }) {
    Vue.set(state, 'subscription', subscription);
  },
};

const subscriptionsModule = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default subscriptionsModule;
