<template>
<div>
  <div class="row">
    <div class="col-12">
      <apexchart ref="apexchart" width="500" type="bar" :options="chartConfig.options" :series="chartConfig.series"></apexchart>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-3 text-left">
      <button  class="btn btn-filter"
        @click="filtersVisibility = !filtersVisibility"
        aria-controls="procurement-filters-collapse"
        :aria-expanded="filtersVisibility ? 'true' : 'false'">
        <i class="fas fa-filter ml-1" ></i>
          Filtrar Licitaciones
        <i class="fas fa-angle-down mr-1" ></i>
      </button>
        <b-collapse v-model="filtersVisibility" class="collapse-tags" id="procurement-filters-collapse">
          <div class="stripe-light  p-3 border">
          <input type="text" class="form-control"
            id="search" placeholder="Filtrar por texto"
            v-model="searchTerms" @keyup.enter="getProcurementPartyContractedProjects()" v-debounce:500ms="getContractedProjectsDebounceSTerms">
          <procurement-filters
            ref="procurement_filters"
            v-on:setFilters="setFilters($event)"
            v-on:yearsChanged="filterYearsChanged($event)"
            :excludedFilters="['contracting_party']"/>
          </div>
        </b-collapse>  
      </div>
    </div>
    <div class="row">
      <div class="col-6 text-left">    
        <paginationComponent
        id="procurement-pagination"
        :currentPage="current_page"
        :totalPages="total_pages"
        v-on:next-page-procurement-pagination="changePage($event)"/>
      </div>
      <div class="col-6 text-left">
        <!-- btn excel -->
      </div>
    </div>
    <div class ="row">
      <div class="col-12">
        <div v-if="contractedProjectYears.length > 0">
          Mostrando los años {{ contractedProjectYears.join(", ") }}
        </div>
        <procurementResultsTable :projects="contracted_projects"/>
      </div>
      <div class="col-12 text-left">
        <paginationComponent
          id="procurement-pagination"
          :currentPage="current_page"
          :totalPages="total_pages"
          v-on:next-page-procurement-pagination="changePage($event)"/>
      </div>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import procurementService from '@/_services/procurement.service';
import procurementMixin from '@/_mixins/procurement.mixin';
import paginationComponent from '@/components/helpers/pagination.vue';
import procurementResultsTable from './results_table.vue';
import procurementFilters from './procurement_filters.vue';

const es = require('apexcharts/dist/locales/es.json');

export default {
  name: 'procurementPartyContractedProyectsComponent',
  mixins: [procurementMixin],
  props: ['party'],
  components: {
    paginationComponent,
    procurementFilters,
    procurementResultsTable,
  },
  data() {
    return {
      filtersVisibility: false,
      contracted_projects: [],
      current_page: 1,
      total_results: 0,
      total_pages: 0,
      contractedProjectYears: [],
      searchTerms: '',
      currentFilters: {},
    };
  },
  mounted() {
    this.getProcurementPartyContractedProjects();
  },
  computed: {
    chartConfig() {
      const labels = this.party ? Object.keys(this.party.stats.contracted_projects).sort() : [];
      const self = this;
      const chart = {
        options: {
          colors: ['#4953CF'],
          chart: {
            height: 350,
            type: 'line',
            locales: [es],
            defaultLocale: 'es',
            events: {
              dataPointSelection: (event, chartContext, config) => {
                // Event was toggled by user
                self.addYear(self.chartConfig.options.labels[config.dataPointIndex], !!event);
              },
            },
          },
          title: {
            text: 'Licitaciones ofertadas',
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          labels,
          xaxis: {
            type: 'year',
          },
          states: {
            active: {
              allowMultipleDataPointsSelection: true,
            },
          },
          yaxis: [{
            title: {
              text: 'Miles de euros',
            },
            labels: {
              formatter(val) {
                return val.toFixed(0);
              },
            },
          }],
          tooltip: {
            y: {
              formatter(val) {
                return val.toFixed(2);
              },
            },
            x: {
              formatter: seriesName => `${seriesName} - Haz click para ver o quitar las adjudicaciones del año`,
            },
          },
        },
        series: [
          {
            name: this.party ? this.party.name : '',
            type: 'column',
            data: [],
          },
        ],
      };

      const data = [];
      let inMillions = false;
      for (let i = 0; i < labels.length; i += 1) {
        const value = Math.round(this.party.stats.contracted_projects[labels[i]]);
        if (value > 1000000) {
          inMillions = true;
        }
        data.push(value);
      }

      if (inMillions) {
        chart.series[0].data = data.map(val => val / 1000000.0);
        chart.options.yaxis[0].title.text = 'Millones de euros';
      } else {
        chart.series[0].data = data;
      }

      return chart;
    },
  },
  methods: {
    filterYearsChanged(years) {
      const yearsToAdd = years.filter(value => !this.contractedProjectYears.includes(value));
      const yearsToRemove = this.contractedProjectYears.filter(value => !years.includes(value));
      const yearsToToggle = yearsToAdd.concat(yearsToRemove);

      const year_indexes = [];
      yearsToToggle.map((year) => {
        const index = this.chartConfig.options.labels.findIndex(label => label === year);
        if (index >= 0) {
          year_indexes.push(index);
        }
        return index;
      });

      for (let i = 0; i < year_indexes.length; i += 1) {
        this.$refs.apexchart.toggleDataPointSelection(0, year_indexes[i]);
      }
    },
    addYear(year, fromUserClick = true) {
      const index = this.contractedProjectYears.findIndex(el => el === year);
      if (index > -1) {
        // Already there, remove
        this.contractedProjectYears.splice(index, 1);
      } else {
        this.contractedProjectYears.push(year);
      }

      if (fromUserClick) {
        this.$refs.procurement_filters.setDateYears(this.contractedProjectYears);
      }
    },
    changePage(page) {
      this.getProcurementPartyContractedProjects(page);
    },
    getContractedProjectsDebounceSTerms() {
      this.getProcurementPartyContractedProjects();
    },
    getProcurementPartyContractedProjects(page = 1) {
      const params = {
        page,
        years: this.contractedProjectYears,
      };

      if (Object.keys(this.currentFilters).length > 0) {
        params.filters = this.currentFilters;
      }

      if (this.searchTerms !== '') {
        params.searchTerms = this.searchTerms;
      }


      const self = this;
      procurementService.getProcurementPartyContractedProjects(this.$route.params.id, params).then((res) => {
        const { results } = res.data;
        const { total_results, total_pages, current_page } = res.data.metadata;

        Vue.set(self, 'contracted_projects', results);
        self.total_results = total_results;
        self.total_pages = total_pages;
        self.current_page = current_page;
      });
    },
    setFilters(event) {
      Vue.set(this, 'currentFilters', event.filters);
      this.getProcurementPartyContractedProjects();
    },
  },
};
</script>

<style scoped>
.party{
  padding: 30px;
}
h1{
  display:inline-block;
  margin-left: 5px;
}
.fa-external-link-alt {
  color:#000000;
}
.fa-external-link-alt:hover {
  cursor:pointer;
  color:#4953CF;
}
</style>
