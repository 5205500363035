<template>
<div>
  <div class="container pb-0 text-left">
    <div class="input-group">
        <i class="fas fa-search fa-2x pt-2 px-2 border" @click="startSearch()"></i>
        <input type="text" class="form-control"
                id="search" placeholder="Buscar"
                v-model="searchTerms" @keyup.enter="startSearch()" v-debounce:500ms="startAutomaticSearch">
         <button  class="btn btn-secondary ml-3"
          @click="filtersVisibility = !filtersVisibility"
          aria-controls="search-filters-collapse"
          :aria-expanded="filtersVisibility ? 'true' : 'false'"
          >
            <i class="fas fa-filter ml-1" ></i>
             Añadir filtro
          </button>
        <button  v-if="total_results<=0" class="disabled p-2 ml-3"> Guardar Alerta </button>
        <button v-else class="btn btn-secondary ml-3" @click="$bvModal.show('new-alert-modal')"> Guardar Alerta </button>
      </div>
      <p class ="ml-0 mt-2">Encontrados {{total_results}} boletines</p>
    </div>
    <div class="text-center">
      <i class="fas fa-3x fa-spinner fa-spin" v-if="searching"></i>
    </div>
  <div class="row p-0">
    <div class="col-12">
      <b-collapse
        v-model="filtersVisibility"
        class="collapse-tags"
        id="search-filters-collapse">
        <search-filters ref="search_filters"
          :currentFilters="currentFilters"
          v-on:setFilters="setFilters($event)"/>
      </b-collapse>
    </div>
  </div>
  <template v-if="total_results > 0">
    <div class="row pl-3 py-1">
      <div class="col-6">
      <paginationComponent
        id="official-bulletin-pagination"
        :currentPage="current_page"
        :totalPages="total_pages"
        v-on:next-page-official-bulletin-pagination="changePage($event)"/>
      </div>
      <div class="col-6 text-right">
        <b-form-select v-model="selectedSort" :options="sortOptions" class="search-select"></b-form-select>
        <button v-b-tooltip.hover title="Exportar a excel" class="btn btn-excel ml-2 mr-2" @click="openExcelExportModal()">
          <i class="fas fa-file-excel"/> Exportar
        </button>
      </div>
    </div>
    <searchResults :results="results"
    :currentSearchTerms="currentSearchTerms"
    :currentFilters="currentFilters"></searchResults>
  </template>
  <newAlertModal :searchTerms="currentSearchTerms"
    :filters="currentFilters"
    alert_type="official_bulletin"/>
  <b-modal
    ref="excel-export-modal"
    hide-footer>
    <template #modal-title>
      <h2 class="corporative-principal">Exportar publicaciones a Excel </h2>
    </template>
      Publicaciones a exportar:
      <b-form-select v-model="exportNotificationsNumber" :options="exportNotificationOptions" size="xs" class="my-2"></b-form-select>
      <button class="btn btn-secondary mt-2" @click="exportExcel()">Exportar <i class="fas fa-file-export"></i> </button>
  </b-modal>

</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import excelExporter from '@/_helpers/excel_exporter';
import officialBulletinsService from '@/_services/official_bulletins.service';
import alertService from '@/_services/alert.service';

import newAlertModal from '@/components/alerts/new_alert_modal.vue';
import paginationComponent from '@/components/helpers/pagination.vue';

import searchFilters from './search_filters.vue';
import searchResults from './search_results.vue';

export default {
  name: 'searchSourcesComponent',
  components: {
    searchFilters,
    newAlertModal,
    paginationComponent,
    searchResults,
  },
  data() {
    return {
      selectedSources: [],
      showSourcesDropdown: false,
      searchTerms: '',
      currentDay: '',
      filtersVisibility: true,
      selectedSort: 0,
      sortOptions: [
        { value: 0, text: 'Ordenar por relevancia' },
        { value: 1, text: 'Ordenar por fecha descendente' },
        { value: 2, text: 'Ordenar por fecha ascendente' },
        { value: 3, text: 'Ordenar por nombre del boletín descendente' },
        { value: 4, text: 'Ordenar por nombre del boletín ascendente' },
      ],
      exportNotificationsNumber: 25,
      exportNotificationOptions: [
        { value: 25, text: '25 (Página actual)' },
        { value: 50, text: '50' },
        { value: 75, text: '75' },
        { value: 100, text: '100' },
        { value: 1000, text: 'Todas (máximo 1000)' },
      ],
    };
  },
  mounted() {
    const { alert_id } = this.$route.query;

    if (alert_id) {
      alertService.getAlert(alert_id).then((response) => {
        const { filters, searchTerms } = response.data;

        this.searchTerms = searchTerms;
        this.filtersVisibility = true;
        this.$store.commit('officialBulletinsModule/setSearchTerms', { searchTerms, newSearchFlag: false, root: true });
        this.setFilters({ filters });
        this.$nextTick(() => {
          this.$refs.search_filters.setFiltersFromCurrent();
        });
      });
    } else {
      this.searchTerms = this.currentSearchTerms;
      this.$store.commit('officialBulletinsModule/updateFiltersUI', { root: true });
    }
  },
  computed: {
    ...mapState({
      searching: state => state.officialBulletinsModule.searching,
      results: state => state.officialBulletinsModule.results,
      total_results: state => state.officialBulletinsModule.total_results,
      total_pages: state => state.officialBulletinsModule.total_pages,
      current_page: state => state.officialBulletinsModule.current_page,
      currentSearchTerms: state => state.officialBulletinsModule.searchTerms,
      currentFilters: state => state.officialBulletinsModule.filters,
      newSearchFlag: state => state.officialBulletinsModule.newSearchFlag,
      currentSortField: state => state.officialBulletinsModule.currentSortField,
      currentSortOrder: state => state.officialBulletinsModule.currentSortOrder,
    }),
    currentSort() {
      if (this.selectedSort === 1) {
        return {
          sortField: 'publication_date',
          sortOrder: 'desc',
        };
      } if (this.selectedSort === 2) {
        return {
          sortField: 'publication_date',
          sortOrder: 'asc',
        };
      } if (this.selectedSort === 3) {
        return {
          sortField: 'source',
          sortOrder: 'desc',
        };
      } if (this.selectedSort === 4) {
        return {
          sortField: 'source',
          sortOrder: 'asc',
        };
      }
      return {
        sortField: 'score',
        sortOrder: 'desc',
      };
    },
  },
  methods: {
    ...mapActions('officialBulletinsModule', {
      search: 'search',
      changePage: 'changePage',
      setFilters: 'setFilters',
    }),
    exportExcel() {
      const filename = `${this.$moment().format('YYYY_MM_DD')}_exportacion_publicaciones.xlsx`;

      const searchParams = {
        searchTerms: this.currentSearchTerms,
        sortField: this.currentSortField,
        sortOrder: this.currentSortOrder,
        filters: this.currentFilters,
        pageSize: this.exportNotificationsNumber,
      };

      officialBulletinsService.search(searchParams).then((response) => {
        const { results } = response.data;
        excelExporter.saveOfficialBulletins(results, filename);
      });
    },
    openExcelExportModal() {
      this.$refs['excel-export-modal'].show();
    },
    startSearch({ newSearchFlag = true } = {}) {
      this.search({ searchTerms: this.searchTerms, ...this.currentSort, newSearchFlag });
    },
    startAutomaticSearch() {
      if (this.searchTerms === '') {
        return;
      }
      this.search({ searchTerms: this.searchTerms, ...this.currentSort });
    },
  },
  watch: {
    results() {
      if (this.results.length === 0) return;
      if (this.newSearchFlag) {
        this.filtersVisibility = false;
      }
    },
    selectedSort() {
      this.startSearch({ newSearchFlag: false });
    },
  },
};
</script>

<style scoped>
input{
  max-width: 40%;
  font-size: 1.5em;
}
.disabled{
  cursor: default;
  background-color: #ccc;
  color: white;
}
/* Style to create scroll bar in dropdown */
.scrollable-dropdown {
  height: auto;
  max-height: 320px;
  /* Increase / Decrease value as per your need */
  overflow-x: hidden;
}

.hidden {
  visibility: hidden;
}

.search-results{
    overflow-y: scroll;
    padding: 0;
    height: 100vh;
  }
.not-collapsed  {
 color: #4953CF !important;

}
.fa-filter:focus {
 outline: 0px !important;
}
.fa-search{
  color: #6C757D;
  cursor: pointer;
}

</style>
