<template>
  <div id="subscription" class="container">
    <div v-if="Object.keys(subscription).length >0">
      <h2>{{subscription.owner.email}}</h2>
      <p>Dueño de la suscripción</p>
      <table class="table">
        <tr>
          <th>Licencias Usadas</th>
          <th>Método de pago</th>
          <th>Frecuencia</th>
          <th>Estado</th>
          <th></th>
        </tr>
        <tr>
        <td>{{ subscription.used }}/<input v-if="editMode" class="input-quantity p-1 border" v-model.number="editSubscription.quantity" type="number"><span v-else> {{ subscription.quantity }}</span></td>
          <td>{{ subscription.billing_type }}</td>
          <td>{{ subscription.billing_frequency }}</td>
          <td><b-form-select v-if="editMode" v-model="editSubscription.status" :options="statusOptions" class="select-status"></b-form-select><span v-else>{{ subscription.status }}</span> </td>
          <td>
            <template v-if="editMode">
              <i v-b-tooltip.hover title="Guardar cambios" class="fas fa-save fa-2x mr-2" @click="updateSubscription"></i>
              <i v-b-tooltip.hover title="Cerrar sin guardar" class="fas fa-times fa-2x ml-2" @click="setEditMode"></i>
            </template>
            <template v-else>
              <i v-b-tooltip.hover title="Editar suscripción" class="fas fa-edit fa-2x" @click="setEditMode"></i>
              <i v-b-tooltip.hover title="Borrar suscripción" class ="fas fa-trash-alt fa-2x ml-2"  @click="showConfirmModal()"></i>
            </template>
          </td>
        </tr>
      </table>
    </div>
    <div class="my-3" v-if="subscription.users.length > 0">
     <h6> Usuarios de la suscripción </h6>
      <div v-for="(subscription_user, index) in subscription.users" v-bind:key="`subscription_user_${index}`">
         {{subscription_user.email}}
         <i v-b-tooltip.hover title="Quitar" class ="fas fa-trash-alt  ml-2" @click="removeUserFromSubscription(subscription_user.id)"></i>
      </div>
    </div>
    <div class="py-3 my-3">
      <h6> Añadir usuario </h6>
      <input class="border p-1 mr-2" type="text" placeholder="Introduce el email del usuario" v-model="newSubscriptionUser">
      <span v-b-tooltip.hover title="Añadir email" class ="add-user" @click="addUserToSubscription()"> <i class="fa fa-user-plus fa-2x "></i></span>
    </div>
    <b-modal
      ref="delete-subscription"
      @ok="handleDeleteSubscriptionModalOk"
      title="Borrar suscripción">
      <input type="text" placeholder="Escribe confirmar" v-model="confirmText">
    </b-modal>

  </div>
</template>

<script>
import Vue from 'vue';
import userService from '@/_services/user.service';
import subscriptionsService from '@/_services/subscriptions.service';
import adminService from '@/_services/admin.service';

export default {
  name: 'AdminSubscriptionComponent',
  components: {
  },
  data() {
    return {
      subscription: {},
      editSubscription: {
        quantity: 1,
        status: '',
      },
      newSubscriptionUser: '',
      confirmText: '',
      editMode: false,
      statusOptions: [
        { value: 'active', text: 'Activa' },
        { value: 'canceled', text: 'Cancelada' },
      ],
    };
  },
  computed: {
  },
  mounted() {
    this.fetchSubscription();
  },
  methods: {
    addUserToSubscription() {
      adminService.addUserToSubscription(this.subscription.id, { user_email: this.newSubscriptionUser.trim() }).then(
        () => {
          this.fetchSubscription();
          this.newSubscriptionUser = '';
        },
      );
    },
    fetchUser(user_id) {
      userService.getUser(user_id).then((response) => {
        Vue.set(this, 'user', response.data);
      });
    },
    fetchSubscription() {
      subscriptionsService.getSubscription(this.$route.params.subscription_id).then((response) => {
        Vue.set(this, 'subscription', response.data);
      }).catch(() => {
      });
    },
    handleDeleteSubscriptionModalOk(bvModalEvt) {
      if (this.confirmText === 'confirmar') {
        adminService.deleteSubscription(this.subscription.id).then(() => {
          this.$router.push({
            name: 'admin_users',
          });
        });
      } else {
        bvModalEvt.preventDefault();
      }
    },
    removeUserFromSubscription(user_id) {
      adminService.removeUserFromSubscription(this.subscription.id, user_id).then(
        () => {
          this.fetchSubscription();
        },
      );
    },
    setEditMode() {
      this.editMode = !this.editMode;
      if (this.editMode) {
        this.editSubscription.quantity = this.subscription.quantity;
        this.editSubscription.status = this.subscription.status;
      }
    },
    showConfirmModal() {
      this.confirmText = '';
      this.$refs['delete-subscription'].show();
    },
    updateSubscription() {
      adminService.updateSubscription(this.subscription.id, this.editSubscription).then(
        () => {
          this.editMode = false;
          this.fetchSubscription();
        },
      );
    },
  },
};
</script>

<style scoped>
label, input, button{
  display: inline;
}
.input-quantity{
  width: 50px;
}
.select-status{
  width: 110px;
}
.fa-save{
  color: #4953CF;
  cursor:pointer;
}
.fa-save:hover{
  color:#8F8EF8;
}
</style>
