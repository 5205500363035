<template>
  <div id="app">
    <app-header class="header" />
    <div v-if="flashMessage.message" :class="`alert ${flashMessage.type}`" v-html="$t(flashMessage.message, flashMessage.i18nArgs)"></div>
    <div class="main-container">
      <router-view/>
    </div>
    <footer class="footer">
      <cookie-law theme="cookie-law-theme" v-on:accept="enableGoogleAnalytics()" ref="gdpr-notice">
        <div slot-scope="props" style="display: inline-block; width: 100%">
          <p style="float: left">
            Este sitio web utiliza cookies para poder mejorar la experiencia de usuario.
            <router-link to="legal-notes">Haz click aquí para saber más</router-link>

          </p>
          <div style="float: right">
            <button class="btn btn-success" style="margin-right: 5px;" @click="props.accept"><span>Aceptar</span></button>
            <button class="btn btn-danger" @click="props.close"><span>Cerrar</span></button>
          </div>

        </div>

      </cookie-law>
    </footer>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CookieLaw from 'vue-cookie-law';
import { bootstrap } from 'vue-gtag';
import Header from './components/header/header.vue';

export default {
  name: 'app',
  components: {
    'app-header': Header,
    CookieLaw,
  },
  computed: {
    ...mapState({
      flashMessage: state => state.flashMessages,
    }),
  },
  mounted() {
    const cookies_accepted = localStorage.getItem('cookie:accepted');
    if (!cookies_accepted) {
      this.$refs['gdpr-notice'].open();
    }
  },
  methods: {
    ...mapActions({
      clearFlashMessage: 'flashMessages/clear',
    }),
    enableGoogleAnalytics() {
      bootstrap();
    },
  },
};

</script>


<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  overflow-x: auto;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.Cookie--cookie-law-theme {
      background: #343A40;
      color: white;
      padding: 1.250em;
}

</style>
