<template>
  <div id="subscribe" class="col-12 grid">
    <div class="subscribe-form">
      <form @submit.prevent="onSubmit">

        <div class="input">
          <label>{{ $t('userPages.billing') }}</label>

          <input type="text" v-validate="'required'" name="name" v-model="name" placeholder="Nombre y apellidos">
          <span v-show="errors.has('name')" class="invalid">{{ errors.first('name') }}</span>

          <input type="text" v-validate="'required'" name="address-line1" v-model="billingAddress.address_line_1" :placeholder="$t('userPages.addressLine1PlaceHolder')">
          <span v-show="errors.has('address-line1')" class="invalid">{{ errors.first('address-line1') }}</span>

          <input type="text" name="address-line2" v-model="billingAddress.address_line_2" :placeholder="$t('userPages.addressLine2PlaceHolder')">
          <input type="text" name="zip_code" v-validate="'required'" v-model="billingAddress.zip_code" :placeholder="$t('userPages.zipCode')">
          <span v-show="errors.has('zip_code')" class="invalid">{{ errors.first('zip_code') }}</span>

          <input type="text" name="city" v-validate="'required'" v-model="billingAddress.city" :placeholder="$t('userPages.city')">
          <span v-show="errors.has('city')" class="invalid">{{ errors.first('city') }}</span>

          <input type="text" name="state" v-validate="'required'" v-model="billingAddress.state" :placeholder="$t('userPages.state')">
          <span v-show="errors.has('state')" class="invalid">{{ errors.first('state') }}</span>

        </div>
          <multiselect
                v-model="billingAddress.country"
                :options="countries" 
                :multiple="false" 
                :close-on-select="true" 
                :preserve-search="true" 
                :preselect-first="false"
                @select="billingAddress.country = value">
            </multiselect>
          <span v-show="errors.has('country')" class="invalid">{{ errors.first('state') }}</span>
        <div class="input">

          <input type="text" name="phone" v-model="billingAddress.phone" :placeholder="$t('userPages.phoneNumber')">
        </div>

        <div>

        </div>
        <div class="input">
          <label for="cardholder" >Datos tarjeta</label>
          <input type="text" v-validate="'required'" name="cardholder" placeholder="Titular" v-model="cardholder">
          <span v-show="errors.has('cardholder')" class="invalid">{{ errors.first('cardholder') }}</span>
        </div>
        <div class="input">
          <div ref="card" style="max-width: 350px;"></div>
          <span v-show="errors.has('stripeError')" class="invalid">{{ errors.first('stripeError') }}</span>
        </div>        
        <pricing-table/>
        <div class="input">
          <label for="subscriptionUsers" >Número de usuarios </label>
          <input type="number" v-validate="'min:1'" name="subscriptionUsers" v-model.number="users">
          <span v-show="errors.has('subscriptionUsers')" class="invalid">{{ errors.first('subscriptionUsers') }}</span>
        </div>
        <div class="input">
          <label >Tu precio: {{price}}€ al mes </label>
        </div>
        <div class="input">
          <label for="coupon" >Cupón de descuento</label>
          <input type="text" name="coupon" placeholder="Código" v-model="coupon">
        </div>
        <div class="submit">
          <button type="submit">Suscribirme</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  
  import { calculatePrice } from '../../_helpers/math'
  import countriesJson from '@/_locales/countries_es.json'
  import Multiselect from 'vue-multiselect'

/*
  const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, {locale: 'es'}),
        elements = stripe.elements();
  let card = undefined;*/

  import { mapActions } from 'vuex'
  import { ValidationProvider } from 'vee-validate';
  import subscriptionsService from '../../_services/subscriptions.service';

  import PricingTable from './subscription_pricing'
  export default {
    name: "SubscribeComponent",
    components: {
      ValidationProvider,
      "pricing-table": PricingTable,
      Multiselect
    },
    data () {
      return {
        billingAddress: {
          address_line_1: '',
          address_line_2: '',
          postal_code: '',
          city: '',
          state: '',
          phone: '',
          country: 'España'
        },
        name: '',
        cardholder: '',
        users: 1,
        coupon: ''
      }
    },
    computed: {
      price() {
        return calculatePrice(this.users);
      },     
      countries() {
        return Object.keys(countriesJson.countries).map(function(key, index) {
          return countriesJson.countries[key];
        }).sort();
      }
    },
    mounted: function () {
      card = elements.create('card', {
          hidePostalCode: true,
        }
      );
      card.mount(this.$refs.card);
    },
    destroyed() {
      if (card){
        card.destroy();
        card = undefined;
      }
    },
    methods: {
      ...mapActions('flashMessages', {
        successFlashMessage: "success",
        errorFlashMessage: "error"
      }),
    	activate (index) {
        this.accountType = index;
      },
      addPaymentMethod() {
        this.errors.clear();
      },
      onSubmit () {
        this.errors.clear(); //Do this to clean custom errors.
        console.log(this.users)
        if (this.users <= 0) {
          this.$validator.errors.add({
            field: 'subscriptionUsers',
            msg: 'Debe haber al menos 1 usuario en la suscripción',
          });
          return;
        }
        this.$validator.validateAll().then(result => {
          if (result) {
            const formData = {
              address: {
                line1: this.billingAddress.address_line_1,
                line2: this.billingAddress.address_line_2,
                postal_code: this.billingAddress.postal_code,
                city: this.billingAddress.city,
                state: this.billingAddress.state,
                country: this.billingAddress.country,
              },
              phone: this.billingAddress.phone,
              quantity: this.users,
              name: this.name,
              coupon: this.coupon
            }
            let self = this;
            var options = {
              name: this.cardholder,
            };

            stripe.createToken(card, options).then(function(result) {
              if (result.error) {
                self.$validator.errors.add({
                  field: 'stripeError',
                  msg: result.error.message,
                });
                return;
              }
              
              formData.token = result.token.id;
              subscriptionsService.createSubscription(formData).then(
                () => {
                  self.$router.push({ name:'notifications' });
                },
              );
            });

          } else {
            //e.preventDefault();
            console.log(this.errors)
          }
        });
      }
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

  .subscribe-form {
    margin: 30px auto;
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: 0 2px 3px #ccc;
    max-width: 800px;
  }


  .submit button {
    border: 1px solid #521751;
    color: #521751;
    padding: 10px 20px;
    font: inherit;
    cursor: pointer;
  }

  .submit button:hover,
  .submit button:active {
    background-color: #521751;
    color: white;
  }

  .submit button[disabled],
  .submit button[disabled]:hover,
  .submit button[disabled]:active {
    border: 1px solid #ccc;
    background-color: transparent;
    color: #ccc;
    cursor: not-allowed;
  }
</style>