/* eslint-disable import/prefer-default-export */
/* eslint no-shadow: ["error", {"allow": ["state"]}] */
/* eslint no-param-reassign: ["error", { "props": true,
                                        "ignorePropertyModificationsFor": ["state"] }] */
import Vue from 'vue';

import procurementService from '../_services/procurement.service';

const state = {
  projects: [],
  filters: {},
  party_subscriptions: [],
  project_subscriptions: [],
  searchTerms: '',
  searching: false,
  total_results: 0,
  current_page: 1,
  total_pages: 0,
  currentSortField: 'score',
  currentSortOrder: 'desc',
  newSearchFlag: true,
  updateFiltersUI: false,
  currentTab: 0,
};

const actions = {
  changePage({ dispatch }, page) {
    dispatch('search', {
      searchTerms: state.searchTerms,
      newSearchFlag: false,
      page,
      sortField: state.currentSortField,
      sortOrder: state.currentSortOrder,
    });
  },
  createPartySubscription({ dispatch }, { id }) {
    return procurementService.createProcurementPartySubscription({ id }).then(
      () => {
        dispatch('getPartySubscriptions');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  createProjectSubscription({ dispatch }, id) {
    return procurementService.createProcurementProjectSubscription(id).then(
      () => {
        dispatch('getProjectSubscriptions');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  deletePartySubscription({ dispatch }, id) {
    return procurementService.deleteProcurementPartySubscription(id).then(
      () => {
        dispatch('getPartySubscriptions');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  deleteProjectSubscription({ dispatch }, id) {
    return procurementService.deleteProcurementProjectSubscription(id).then(
      () => {
        dispatch('getProjectSubscriptions');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  getPartySubscriptions({ commit }) {
    return procurementService.getProcurementPartySubscriptions().then(
      (response) => {
        const { party_subscriptions } = response.data;
        commit('storePartySubscriptions', {
          party_subscriptions,
        });
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  getProjectSubscriptions({ commit }) {
    return procurementService.getProcurementProjectSubscriptions().then(
      (response) => {
        const { project_subscriptions } = response.data;
        commit('storeProjectSubscriptions', {
          project_subscriptions,
        });
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  search({ commit, dispatch }, {
    searchTerms, newSearchFlag = true, page = 1, sortField = 'score', sortOrder = 'desc',
  }) {
    commit('setSearchTerms', { searchTerms, newSearchFlag });

    const params = {
      searchTerms,
      page,
      sortField,
      sortOrder,
      filters: {
        ...state.filters,
      },
    };

    // We return the promise from axios so that the view will now when the data has loaded
    // eslint-disable-next-line consistent-return
    return procurementService.search(params).then(
      (response) => {
        const { results } = response.data;
        const { total_results, total_pages, current_page } = response.data.metadata;
        commit('storeProjects', {
          projects: results, total_results, total_pages, current_page, sortField, sortOrder,
        });
      },
    ).catch((error) => {
      console.log(error);
      dispatch('flashMessages/error', { message: 'empty', i18nArgs: { content: 'Se ha producido algún error. Por favor, inténtelo de nuevo más tarde' } }, { root: true });
      commit('storeProjects', { projects: [], total_results: 0 });
    });
  },
  setFilters({ commit, dispatch }, { filters }) {
    commit('storeFilters', { filters });
    dispatch('search', { searchTerms: state.searchTerms, newSearchFlag: false });
  },
};

const mutations = {
  setSearchTerms(state, { searchTerms, newSearchFlag }) {
    state.searchTerms = searchTerms;
    state.newSearchFlag = newSearchFlag;
  },
  setSearching(state, searching) {
    state.searching = searching;
  },
  storeFilters(state, { filters }) {
    Vue.set(state, 'filters', filters);
  },
  storeProjects(state, {
    projects, total_results, total_pages, current_page, sortField, sortOrder,
  }) {
    state.total_results = total_results;
    state.total_pages = total_pages;
    state.current_page = current_page;
    state.currentSortField = sortField;
    state.currentSortOrder = sortOrder;

    Vue.set(state, 'projects', projects);
  },
  storePartySubscriptions(state, { party_subscriptions }) {
    Vue.set(state, 'party_subscriptions', party_subscriptions);
  },
  storeProjectSubscriptions(state, { project_subscriptions }) {
    Vue.set(state, 'project_subscriptions', project_subscriptions);
  },
  updateFiltersUI(state) {
    state.updateFiltersUI = !state.updateFiltersUI;
  },
  setCurrentTab(state, { tabIndex }) {
    state.currentTab = tabIndex;
  },
};

const procurementModule = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default procurementModule;
