// import { authHeader } from '../_helpers/auth-header';
import axios from '../_helpers/axios';

function addUserToSubscription(id, params) {
  return axios.post(`/${process.env.VUE_APP_API_VERSION}/admin/subscriptions/${id}/user`, params).then(res => res);
}

function createSubscription(params) {
  return axios.post(`/${process.env.VUE_APP_API_VERSION}/admin/subscriptions`, params).then(res => res);
}

function deleteSubscription(id) {
  return axios.delete(`/${process.env.VUE_APP_API_VERSION}/admin/subscriptions/${id}`).then(res => res);
}

function removeUserFromSubscription(id, user_id) {
  return axios.delete(`/${process.env.VUE_APP_API_VERSION}/admin/subscriptions/${id}/user/${user_id}`).then(res => res);
}

function updateSubscription(id, params) {
  return axios.put(`/${process.env.VUE_APP_API_VERSION}/admin/subscriptions/${id}`, params).then(res => res);
}

const adminService = {
  addUserToSubscription,
  createSubscription,
  deleteSubscription,
  removeUserFromSubscription,
  updateSubscription,
};

export default adminService;
