import axios from '../_helpers/axios';

function search(params) {
  return axios.post(`/${process.env.VUE_APP_API_VERSION}/search`, params).then(res => res);
}

const officialBulletinsService = {
  search,
};

export default officialBulletinsService;
