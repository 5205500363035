import axios from 'axios';
import apiAxios from '../_helpers/axios';


function fetchDocument(url) {
  return axios.get(url).then(res => res);
}

function shareDocument(params) {
  return apiAxios.post(`/${process.env.VUE_APP_API_VERSION}/sources/share`, params).then(res => res);
}

const documentService = {
  fetchDocument,
  shareDocument,
};

export default documentService;
