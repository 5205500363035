<template>
  <div>
    <div class="row m-0 p-0">
      <div class="col-11">
        Fecha notificación: {{notification.date}}
        <h5 class="pt-2">
        <router-link :to="{
          name: 'procurement_party',
          params: {
            id: notification.data.id
          }
        }">
        <span class ="circle-tag-entity">E</span>  {{notification.data.name}}
        </router-link>
        ha recibido {{ new_tender_results_string }} </h5>
      </div>
      <div class="col-1 text-rigth" v-if="showActions">
        <template v-if="hasSubscription">
          <i v-b-tooltip.hover title="Dejar de recibir actualizaciones de esta empresa" class="far fa-bell-slash fa-2x"  @click="deletePartySubscription(notification.data.id)"></i>
        </template>
        <template v-else>
          <i v-b-tooltip.hover title="Recibir actualizaciones de esta empresa" class="far fa-bell fa-2x"  @click="createPartySubscription({id: notification.data.id})"></i>
        </template>
        <i v-b-tooltip.hover title="Descartar" class ="fas fa-trash-alt fa-2x ml-2"  @click="updateNotification({processed: true, id: notification.id})"></i>
      </div>
    </div>
    <div class="row p-0 m-0">
    <ul class="m-0">
      <li v-for="project in tender_results_by_project" v-bind:key="`tender_result_project_${project.id}`">
        <router-link :to="{
          name: 'procurement',
          params: {
            id: project.id
          }
        }">
          {{project.title}}
        </router-link>
        <ul class="mb-2">
          <li v-for="(tender_result, index) in project.tender_results" v-bind:key="`tender_result_${project.id}_${index}`">
            <template v-if="tender_result.lot_id">
              <strong>Lote {{tender_result.lot_id}} </strong>
            </template>
            <strong>Descripción:</strong> {{tender_result.description}}
            <br/><strong>Importe Total Recibido:</strong> {{formatAmount(tender_result.payable_amount)}}
          </li>
        </ul>
      </li>
    </ul>
  </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'partyNotification',
  props: {
    notification: {
      required: true,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('procurementModule', ['party_subscriptions']),
    tender_results_by_project() {
      const tender_results_by_project = {};
      for (let i = 0; i < this.notification.data.tender_results.length; i += 1) {
        const tender_result = this.notification.data.tender_results[i];
        // eslint-disable-next-line no-prototype-builtins
        if (!(tender_result.procurement_project_id in tender_results_by_project)) {
          tender_results_by_project[tender_result.procurement_project_id] = {
            title: tender_result.project_title,
            id: tender_result.procurement_project_id,
            tender_results: [],
          };
        }
        tender_results_by_project[tender_result.procurement_project_id].tender_results.push(tender_result);
      }
      return tender_results_by_project;
    },
    new_tender_results_string() {
      if (this.notification.data.tender_results.length === 1) return '1 nueva adjudicación';
      return `${this.notification.data.tender_results.length} nuevas adjudicaciones`;
    },
    hasSubscription() {
      return this.party_subscriptions.findIndex(el => el.party.id === this.notification.data.id) !== -1;
    },
  },
  methods: {
    ...mapActions('procurementNotificationsModule', {
      updateNotification: 'updateNotification',
    }),
    ...mapActions('procurementModule', {
      createPartySubscription: 'createPartySubscription',
      deletePartySubscription: 'deletePartySubscription',
    }),
  },
};
</script>

<style scoped>

</style>
