<template>
  <div id="reset-password" class="col-12">
    <div class="reset-password-form">
      <form @submit.prevent="onSubmit">
        <div class="input">
          <p>Por favor, introduce tu nueva contraseña</p>
        </div>

        <div class="input">
          <label for="password">{{ $t('password') }}</label>
          <input type="password" v-model="password" v-validate="'required|min:6|max:35'" name="password" ref="password">
          <span v-show="errors.has('password')" class="invalid">{{ errors.first('password') }}</span>
        </div>
        <div class="input">
          <label for="confirmPassword">{{ $t('confirmPassword') }}</label>
          <input type="password" id="confirmPassword" v-model="confirmPassword" v-validate="'required|confirmed:password'" name="confirmPassword" >
          <span v-show="errors.has('confirmPassword')" class="invalid">{{ errors.first('confirmPassword') }}</span>
        </div>

        <div class="submit">
          <button type="submit">Cambiar Contraseña</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import userService from '../../_services/user.service';

export default {
  data() {
    return {
      password: '',
      confirmPassword: '',
    };
  },
  methods: {
    ...mapActions('flashMessages', {
      successFlashMessage: 'success',
      errorFlashMessage: 'error',
    }),
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const headers = {
            'access-token': this.$route.query['access-token'],
            client: this.$route.query.client,
            uid: this.$route.query.uid,
          };
          userService.resetPassword(
            this.password,
            this.confirmPassword,
            headers,
          ).then(() => {
            this.successFlashMessage({
              message: 'resetPasswordSuccess',
            });
            this.$router.push('sign_in');
          });
        } else {
          // e.preventDefault();
        }
      });
    },
  },
};
</script>

<style scoped>
  .reset-password-form {
    width: 400px;
    margin: 30px auto;
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: 0 2px 3px #ccc;
  }


  .submit button {
    border: 1px solid #521751;
    color: #521751;
    padding: 10px 20px;
    font: inherit;
    cursor: pointer;
  }

  .submit button:hover,
  .submit button:active {
    background-color: #521751;
    color: white;
  }

  .submit button[disabled],
  .submit button[disabled]:hover,
  .submit button[disabled]:active {
    border: 1px solid #ccc;
    background-color: transparent;
    color: #ccc;
    cursor: not-allowed;
  }
</style>
