<template>
  <div id="procurementDashboard" class="row p-0">
    <div class="col-2 side-bar pl-2 pr-0">
      <b-nav vertical tabs class="verticalNavbar">
        <b-nav-item :active="currentTab === 0" :to="{ path: '/licitaciones/buscar' }">
          <i class="fas fa-search mr-2"></i>Buscar
        </b-nav-item>
        <b-nav-item :active="currentTab === 1" :to="{ path: '/licitaciones/notificaciones' }">
           <i class="fas fa-flag-checkered mr-2"></i>Notificaciones ({{total_notifications}})
        </b-nav-item>
        <b-nav-item :active="currentTab === 2" :to="{ path: '/licitaciones/mis_licitaciones' }">
          <i class="far fa-star mr-2"></i>Licitaciones favoritas
        </b-nav-item>
        <b-nav-item :active="currentTab === 3" :to="{ path: '/licitaciones/mis_empresas' }">
          <i class="far fa-building mr-2"></i>Mis Empresas / Entidades
        </b-nav-item>
        <b-nav-item :active="currentTab === 6" :to="{ path: '/licitaciones/alertas' }">
          <i class="fas fa-history mr-2"></i> Mis Alertas
        </b-nav-item>
      </b-nav>
    </div>
    <div class="col-10">
      <template v-if="currentTab === 0">
        <procurementSearch/>
      </template>
      <template v-else-if="currentTab === 1">
        <notificationsDashboard/>
      </template>
      <template v-else-if="currentTab === 2">
        <procurementProjectSubscriptionsTable/>
      </template>
      <template v-else-if="currentTab === 3">
        <procurementPartySubscriptionsTable/>
      </template>
      <template v-else-if="currentTab === 4">
          <procurementComponent/>
      </template>
      <template v-else-if="currentTab === 5">
          <procurementPartyComponent/>
      </template>
      <template v-else-if="currentTab === 6">
        <div>
          <h1 class="text-center pt-3">Mis Alertas</h1>
        </div>
        <alertsTable :alerts="procurementAlerts.alerts"
          :current_page="procurementAlerts.current_page"
          :total_pages="procurementAlerts.total_pages"
          :alert_type="'procurement'"/>
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import alertsTable from '@/components/alerts/alerts_table.vue';
import procurementSearch from '@/components/procurement/procurement_search.vue';
import notificationsDashboard from '@/components/procurement/procurement_notifications_dashboard.vue';
import procurementPartySubscriptionsTable from '@/components/procurement/procurement_party_subscriptions.vue';
import procurementProjectSubscriptionsTable from '@/components/procurement/procurement_project_subscriptions.vue';
import procurementComponent from '@/components/procurement/procurement.vue';
import procurementPartyComponent from '@/components/procurement/procurement_party.vue';

export default {
  name: 'ProcurementDashboard',
  components: {
    alertsTable,
    procurementSearch,
    notificationsDashboard,
    procurementPartySubscriptionsTable,
    procurementProjectSubscriptionsTable,
    procurementPartyComponent,
    procurementComponent,
  },
  data() {
    return {
      alert: null,
    };
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
  mounted() {
    const { tab } = this.$route.params;
    if (tab) {
      this.setCurrentTab(tab);
    }

    this.checkRoute();
    this.indexNotifications();
  },
  methods: {
    ...mapActions('procurementModule', {
      getPartySubscriptions: 'getPartySubscriptions',
      getProjectSubscriptions: 'getProjectSubscriptions',
    }),
    ...mapActions('procurementNotificationsModule', {
      indexNotifications: 'indexNotifications',
    }),
    ...mapActions('alertsModule', {
      indexAlerts: 'indexAlerts',
    }),
    setCurrentTab(tabIndex) {
      if (tabIndex === 1) {
        this.indexNotifications();
      } else if (tabIndex === 2) {
        this.getProjectSubscriptions();
      } else if (tabIndex === 3) {
        this.getPartySubscriptions();
      } else if (tabIndex === 6) {
        this.indexAlerts({ alert_type: 'procurement' });
      }
      this.$store.commit('procurementModule/setCurrentTab', { tabIndex, root: true });
    },
    checkRoute() {
      if ((this.$route.path === '/licitaciones') || this.$route.path.includes('licitaciones/buscar')) {
        this.setCurrentTab(0);
      } else if (this.$route.path.includes('licitaciones/notificaciones')) {
        this.setCurrentTab(1);
      } else if (this.$route.path.includes('licitaciones/mis_licitaciones')) {
        this.setCurrentTab(2);
      } else if (this.$route.path.includes('licitaciones/mis_empresas')) {
        this.setCurrentTab(3);
      } else if (this.$route.path.includes('licitaciones/detalle')) {
        this.setCurrentTab(4);
      } else if (this.$route.path.includes('licitaciones/empresa')) {
        this.setCurrentTab(5);
      } else if (this.$route.path.includes('licitaciones/alertas')) {
        this.setCurrentTab(6);
      }
    },
  },
  computed: {
    ...mapState({
      total_notifications: state => state.procurementNotificationsModule.total_results,
      currentTab: state => state.procurementModule.currentTab,
      procurementAlerts: state => state.alertsModule.procurement,
    }),
  },
};
</script>

<style scoped>
  #procurementDashboard {
    height: 100%
  }
  .nav-link.active{
    background-color: #4953CF !important;
    color:#FFFFFF !important;
    border: none !important;
  }
  .nav-link:hover{
    color: #FFFFFF;
    background-color: #4953CF;
    border: none !important;
  }

  .nav-link{
    color: #4953CF;
    border-radius: 0.25em !important;
    border:none !important;
  }

  .verticalNavbar {
    min-height: 90vh;
    margin: 0;
    text-align: left;

  }
  .side-bar {
    background-color: #F0F0F0 ;
  }

</style>
