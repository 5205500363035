/* eslint-disable camelcase */
import axios from '../_helpers/axios';

function getAlert(id) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/alerts/${id}`).then(res => res);
}

function getAlertNotifications(id, page) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/alerts/${id}/notifications`, { params: { page } }).then(res => res);
}

function indexAlerts(params) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/alerts`, { params }).then(res => res);
}

function updateAlert(alert) {
  return axios.put(`/${process.env.VUE_APP_API_VERSION}/alerts/${alert.id}`, alert).then(res => res);
}

function createAlert(params) {
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };

  return axios.post(`/${process.env.VUE_APP_API_VERSION}/alerts`, params, axiosConfig).then(res => res);
}

function deleteAlert(id) {
  return axios.delete(`/${process.env.VUE_APP_API_VERSION}/alerts/${id}`).then(res => res);
}


const alertService = {
  getAlert,
  getAlertNotifications,
  indexAlerts,
  updateAlert,
  createAlert,
  deleteAlert,
};

export default alertService;
