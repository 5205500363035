import { mapActions, mapState } from 'vuex';

const procurementTableMixin = {
  mounted() {
    if (this.party_subscriptions.length === 0) {
      this.getPartySubscriptions();
    }
    if (this.project_subscriptions.length === 0) {
      this.getProjectSubscriptions();
    }
  },
  computed: {
    ...mapState('procurementModule', ['project_subscriptions', 'party_subscriptions']),
  },
  methods: {
    ...mapActions('procurementModule', {
      createProjectSubscription: 'createProjectSubscription',
      deleteProjectSubscription: 'deleteProjectSubscription',
      deletePartySubscription: 'deletePartySubscription',
      createPartySubscription: 'createPartySubscription',
      getPartySubscriptions: 'getPartySubscriptions',
      getProjectSubscriptions: 'getProjectSubscriptions',
    }),
    projectHasSubscription(id) {
      return this.project_subscriptions.findIndex(el => el.project.id === id) !== -1;
    },
    partyHasSubscription(id) {
      return this.party_subscriptions.findIndex(el => el.party.id === id) !== -1;
    },
    projectEndDate(project) {
      const { planned_period, planned_period_unit } = project;

      const projectEndDate = new Date();
      if (planned_period_unit === 'MON') {
        projectEndDate.setMonth(projectEndDate.getMonth() + Number.parseInt(planned_period, 10));
      } else if (planned_period_unit === 'ANN') {
        projectEndDate.setFullYear(projectEndDate.getFullYear() + Number.parseInt(planned_period, 10));
      } else if (planned_period_unit === 'DAY') {
        projectEndDate.setDate(projectEndDate.getDate() + Number.parseInt(planned_period, 10));
      } else {
        return '';
      }

      return projectEndDate;
    },
    projectHighlightedTitle(project) {
      if (project.highlights && project.highlights.title) {
        return project.highlights.title;
      }
      return project.title;
    },
    isNotResolved(project) {
      return project.status !== 'Resuelta' && project.status !== 'Anulada' && project.status !== 'Adjudicada';
    },
    isNotOutdated(project) {
      const projectEndDate = this.projectEndDate(project);
      if (projectEndDate === '') return true;
      const today = new Date();

      return today.getTime() < projectEndDate.getTime();
    },
    locationStr(location) {
      let locationStr = '';
      if (!location) return locationStr;

      if (location.city && location.city !== '') {
        locationStr += `${location.city}`;
      }

      if (location.subentity_code && location.subentity_code !== '') {
        if (locationStr !== '') locationStr += ', ';
        locationStr += location.subentity_code;
      } else if (location.country_subentity && location.country_subentity !== '') {
        if (locationStr !== '') locationStr += ', ';
        locationStr += location.country_subentity;
      }
      if (location.country && location.country !== '') {
        if (locationStr !== '') locationStr += ', ';
        locationStr += location.country;
      }

      return locationStr;
    },
    plannedPeriod(period, unit) {
      let unitStr = '';
      let periodStr = period;

      if (!period) {
        periodStr = '';
      } else {
        periodStr = period;
      }

      if (unit === 'MON') {
        if (period === '1') unitStr = 'mes';
        else unitStr = 'meses';
      } else if (unit === 'ANN') {
        if (period === '1') unitStr = 'año';
        else unitStr = 'años';
      } else if (unit === 'DAY') {
        if (period === '1') unitStr = 'día';
        else unitStr = 'días';
      }

      return `${periodStr} ${unitStr}`;
    },
    mailing_frequency(frequency) {
      if (frequency === 'daily') return 'Diario';
      if (frequency === 'weekly') return 'Semanal';
      if (frequency === 'no_email') return 'Sin email';
      return '';
    },
    getLotTenderResult(tender_results, lot) {
      return tender_results.find(tender_result => lot.lot_id === tender_result.lot_id);
    },
  },
};

export default procurementTableMixin;
