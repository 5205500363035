<template>
<div>
  <div class="row">
    <div class="col-12">
      <h1 class="text-center p-3">Notificaciones de licitaciones</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
        <paginationComponent
          id="procurement-notif-pagination"
          :currentPage="current_page"
          :totalPages="total_pages"
          v-on:next-page-procurement-notif-pagination="changePage($event)"/>
    </div>
    <div class="col-6 text-right">
        <button v-b-tooltip.hover title="Exportar a excel" class="btn btn-excel ml-2 mr-2" @click="openExcelExportModal()">
          <i class="fas fa-file-excel"/> Exportar
        </button>
    </div>
  </div>
  <div class ="row">
    <div class="col-12">
      <div class="card p-3 mb-1 text-left" v-for="(notification, index) in notifications" v-bind:key="`notification_${index}`">
        <template v-if="notification.noticeable_type === 'Procurement::Project'">
          <template v-if="notification.notification_types.includes('procurement_project_subscription')">
            <procurementProjectChangesNotification :notification="notification"/>
          </template>
          <template v-else>
            <procurementProjectNotification :notification="notification"/>
          </template>
        </template>
        <template v-else-if="notification.noticeable_type === 'Procurement::Party'">
          <procurementPartyNotification :notification="notification"/>
        </template>
      </div>
      <paginationComponent
        id="procurement-notif-pagination-down"
        :currentPage="current_page"
        :totalPages="total_pages"
        v-on:next-page-procurement-notif-pagination-down="changePage($event)"/>
    </div>
    <b-modal
      ref="proc-excel-export-modal"
      hide-footer>      
      <template #modal-title>
        <h2 class="corporative-principal">Exportar notificaciones a Excel </h2>
      </template>      
      <template v-if="excelExportModalPage === 1">
        Notificaciones a exportar:
        <b-form-select class="my-2" v-model="exportNotificationsNumber" :options="exportNotificationOptions"></b-form-select>
        <button class="btn btn-secondary mt-2" @click="exportModalNextPage()"> Exportar <i class="fas fa-file-export"></i> </button>
      </template>
      <template v-else>
        <p><input type="checkbox" v-model="processNotifications">  Eliminar las notificaciones exportadas</p>
        <button class="btn btn-secondary" @click="exportModalNextPage()">
          {{processNotifications ? 'Eliminar y Cerrar' : 'Cerrar'}}
        </button>
      </template>
    </b-modal>
  </div>
</div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import excelExporter from '@/_helpers/excel_exporter';
import notificationService from '@/_services/notification.service';

import paginationComponent from '@/components/helpers/pagination.vue';
import procurementPartyNotification from '@/components/procurement/notifications/procurement_party_notification.vue';
import procurementProjectNotification from '@/components/procurement/notifications/procurement_project_notification.vue';
import procurementProjectChangesNotification from '@/components/procurement/notifications/procurement_project_changes_notification.vue';

import procurementMixin from '@/_mixins/procurement.mixin';

export default {
  name: 'procurmentNotificationsDashboard',
  mixins: [procurementMixin],
  components: {
    paginationComponent,
    procurementPartyNotification,
    procurementProjectNotification,
    procurementProjectChangesNotification,
  },
  mounted() {
    this.indexNotifications();
  },
  data() {
    return {
      exportNotificationsNumber: 25,
      exportNotificationOptions: [
        { value: 25, text: '25 (Página actual)' },
        { value: 50, text: '50' },
        { value: 75, text: '75' },
        { value: 100, text: '100' },
        { value: 1000, text: 'Todas (máximo 1000)' },
      ],
      excelExportModalPage: 1,
      processNotifications: false,
      processNotificationsIds: [],
    };
  },
  computed: {
    ...mapState('procurementNotificationsModule', [
      'notifications',
      'loading',
      'current_page',
      'total_pages',
      'total_results',
    ]),
  },
  methods: {
    ...mapActions('procurementNotificationsModule', {
      indexNotifications: 'indexNotifications',
      changePage: 'changePage',
      updateNotification: 'updateNotification',
      updateNotifications: 'updateNotifications',
    }),
    exportExcel() {
      const filename = `${this.$moment().format('YYYY_MM_DD')}_exportacion_notificaciones_licitaciones.xlsx`;
      this.processNotificationsIds = []; // No need for it to be reactive

      notificationService.indexNotifications(
        { source_type: 'procurement', pageSize: this.exportNotificationsNumber },
      ).then((response) => {
        const { notifications } = response.data;
        const notification_ids = notifications.map(notification => notification.id);
        this.processNotificationsIds = notification_ids;
        excelExporter.saveProcurementProjectNotifications(notifications, filename).then(() => {
          this.excelExportModalPage += 1;
        }).catch((err) => {
          console.log(err);
        });
      });
    },
    exportModalNextPage() {
      if (this.excelExportModalPage === 1) {
        this.exportExcel();
      } else if (this.excelExportModalPage === 2) {
        if (this.processNotifications) {
          this.updateNotifications({
            ids: this.processNotificationsIds,
            processed: true,
          });
        }
        this.$refs['proc-excel-export-modal'].hide();
        this.excelExportModalPage = 0;
      }
    },
    openExcelExportModal() {
      this.excelExportModalPage = 1;
      this.processNotifications = false;
      this.$refs['proc-excel-export-modal'].show();
    },
  },
};
</script>

<style scoped>

</style>
