<template>
  <div id="signin" class="col-12">
    <div class="signin-form">
      <form @submit.prevent="onSubmit">
        <div class="input">
          <label for="email">Email</label>
          <input type="email"
                 id="email"
                 v-validate="'required|email'"
                 name="email"
                 v-model="email">
        </div>
        <div class="input">
          <label for="password" >{{ $t('userPages.password') }}</label>
          <input type="password"
                 id="password"
                 v-model="password"
                 v-validate="'required|min:6|max:35'"
                 name="password"
                 ref="password">
        </div>
        <div class="input">
          <p><router-link to="/recover_password">{{ $t('userPages.forgotPassword') }}</router-link></p>
        </div>
        <button class="btn btn-secondary">Iniciar sesión</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {

  data() {
    return {
      email: '',
      password: '',
    };
  },
  created() {
    if (this.$route.query.account_confirmation_success === 'true') {
      this.successFlashMessage({ message: 'confirmationSuccess' });
    }
  },
  methods: {
    ...mapActions('flashMessages', {
      successFlashMessage: 'success',
      errorFlashMessage: 'error',
    }),
    ...mapActions('usersModule', {
      storeUser: 'storeUser',
    }),
    onSubmit() {
      const formData = {
        email: this.email,
        password: this.password,
      };
      this.$auth.login({
        data: formData,
        fetchUser: true,
      }).then(() => {
        this.successFlashMessage({ message: 'signInSuccess' });
        this.$router.push({
          name: 'procurement_dashboard',
        });
      }).catch(() => {
        this.errorFlashMessage({ message: 'signInError' });
        console.log('error');
      });
    },
  },
};
</script>

<style scoped>
  .signin-form {
    width: 400px;
    margin: 30px auto;
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: 0 2px 3px #ccc;
  }


  .submit button {
    border: 1px solid #521751;
    color: #521751;
    padding: 10px 20px;
    font: inherit;
    cursor: pointer;
  }

  .submit button:hover,
  .submit button:active {
    background-color: #521751;
    color: white;
  }

  .submit button[disabled],
  .submit button[disabled]:hover,
  .submit button[disabled]:active {
    border: 1px solid #ccc;
    background-color: transparent;
    color: #ccc;
    cursor: not-allowed;
  }
</style>
