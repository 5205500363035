<template>
  <div class="row-12">
    <section class="pricing-table">
      <div class="container">
        <div class="block-heading">
          <h2>Nuestros precios</h2>
          <p class="mt-4">
            <b-form-checkbox v-model="monthlyBilling" name="check-button" switch size="lg">
              Facturación {{ monthlyBilling ? 'Mensual' : 'Anual' }}
            </b-form-checkbox>
          </p>
        </div>
        <div class="row justify-content-md-center">
          <div class="col-md-5 col-lg-4">
            <div class="item">
              <div class="heading">
                <h3>INDIVIDUAL</h3>
              </div>
              <template v-if="monthlyBilling">
                <p> Mensual sin permanencia </p> <br/> <br/>
  <!--              <div class="features">
                  <h4><span class="feature">Full Support</span> : <span class="value">No</span></h4>
                  <h4><span class="feature">Duration</span> : <span class="value">30 Days</span></h4>
                  <h4><span class="feature">Storage</span> : <span class="value">10GB</span></h4>
                </div>-->
                <div class="price">
                  <h4>45€ </h4><span>/mes (+IVA)</span>
                </div>
              </template>
              <template v-else>
                <p> Anual, 12 meses de permanencia </p> <br/> <br/>
                <div class="price">
                  <h4>450€ </h4><span>/año (+IVA)</span>
                </div>
                <div class="price mt-2">
                  (<h5>37.5€ </h5><span>/mes +IVA</span>)
                </div>
              </template>
              <b-button to="/sign_up" class="btn btn-block btn-primary mt-4">Empezar prueba gratuita</b-button>
            </div>
          </div>
          <div class="col-md-5 col-lg-4">
            <div class="item">
              <div class="ribbon">Más Popular</div>
              <div class="heading">
                <h3>EQUIPO PEQUEÑO</h3>
              </div>
              <template v-if="monthlyBilling">
                <p>Entre 2 y 10 usuarios. Mensual sin permanencia</p> <br/>
                <!-- <div class="features">
                  <h4><span class="feature">Full Support</span> : <span class="value">Yes</span></h4>
                  <h4><span class="feature">Duration</span> : <span class="value">60 Days</span></h4>
                  <h4><span class="feature">Storage</span> : <span class="value">50GB</span></h4>
                </div> -->
                <div class="price">
                  <h4>30€ </h4><span>/mes por usuario (+IVA)</span>
                </div>
              </template>
              <template v-else>
                <p> Entre 2 y 10 usuarios. Anual, 12 meses de permanencia </p> <br/>
                <div class="price">
                  <h4>300€ </h4><span>/año por usuario (+IVA)</span>
                </div>
                <div class="price mt-2">
                  (<h5>25€ </h5><span>/mes por usuario +IVA</span>)
                </div>
              </template>

              <b-button to="/sign_up" class="btn btn-block btn-primary mt-4">Empezar prueba gratuita</b-button>
            </div>
          </div>
          <div class="col-md-5 col-lg-4">
            <div class="item">
              <div class="heading">
                <h3>ENTERPRISE</h3>
              </div>
              <p>Habla con nosotros para recibir una propuesta personalizada </p>
              <!-- <template v-if="monthlyBilling">
                <p>Más de 10 usuarios. Mensual sin permanencia</p> <br/> <br/>
                <div class="features">
                  <h4><span class="feature">Full Support</span> : <span class="value">Yes</span></h4>
                  <h4><span class="feature">Duration</span> : <span class="value">120 Days</span></h4>
                  <h4><span class="feature">Storage</span> : <span class="value">150GB</span></h4>
                </div> 
                <div class="price">
                  <h4>25€ </h4><span>/mes por usuario (+IVA)</span>
                </div>
              </template>
              <template v-else>
                <p> Más de 10 usuarios. Anual, 12 meses de permanencia </p> <br/>
                <div class="price">
                  <h4>250€ </h4><span>/año (+IVA)</span>
                </div>
                <div class="price mt-2">
                  (<h5>20.8€ </h5><span>/mes por usuario +IVA</span>)
                </div>
              </template>-->

              <b-button to="/sign_up" class="btn btn-block btn-primary mt-4">Empezar prueba gratuita</b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'PricingPage',
  data() {
    return {
      monthlyBilling: true,
    };
  },
};
</script>

<style scoped>
.pricing-table{
  font-family: 'Montserrat', sans-serif;
}

.pricing-table .block-heading {
  padding-top: 50px;
  margin-bottom: 40px;
  text-align: center;
}

.pricing-table .block-heading h2 {
  color: #4953CF;
}

.pricing-table .block-heading p {
  text-align: center;
  max-width: 420px;
  margin: auto;
  opacity: 0.7;
}

.pricing-table .heading {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.pricing-table .item {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  border-top: 2px solid #4953CF;
  padding: 30px;
  overflow: hidden;
  position: relative;
}

.pricing-table .col-md-5:not(:last-child) .item {
  margin-bottom: 30px;
}

.pricing-table .item button {
  font-weight: 600;
}

.pricing-table .ribbon {
  width: 160px;
  height: 32px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
  background: #4dbe3b;
  transform: rotate(45deg);
  position: absolute;
  right: -42px;
  top: 20px;
  padding-top: 7px;
}
.pricing-table .item {
  min-height: 350px;
}

.pricing-table .item p {
  text-align: center;
  margin-top: 20px;
  opacity: 0.7;
}

.pricing-table .features .feature {
  font-weight: 600; }

.pricing-table .features h4 {
  text-align: center;
  font-size: 18px;
  padding: 5px;
}

.pricing-table .price h4 {
  margin: 15px 0;
  font-size: 45px;
  text-align: center;
  color: #4953CF;
  display: inline;
}

.pricing-table .price h5 {
  margin: 15px 0;
  font-size: 20px;
  text-align: center;
  color: #4953CF;
  display: inline;
}

.pricing-table .buy-now button {
  text-align: center;
  margin: auto;
  font-weight: 600;
  padding: 9px 0;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
    background-color: #4953CF !important;
    border-color: #4953CF;
}

</style>
