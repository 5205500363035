/* eslint-disable camelcase */
// import { authHeader } from '../_helpers/auth-header';
import axios from '../_helpers/axios';

function activateUser(user_id) {
  return axios.post(`/${process.env.VUE_APP_API_VERSION}/users/${user_id}/activate`).then(res => res);
}

function acceptUserInvitation(params) {
  return axios.put('/auth/invitation', params).then(res => res);
}

function createUserInvitation(params) {
  return axios.post('/auth/invitation', params).then(res => res);
}

function deleteUser() {
  return axios.delete(`/${process.env.VUE_APP_API_VERSION}/user`).then(res => res);
}

function indexUsers(params) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/users`, { params }).then(res => res);
}

function getUser(id) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/users/${id}`).then(res => res);
}

function userAlerts(user_id) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/users/${user_id}/alerts`).then(res => res);
}

function userProjectSubscriptions(user_id) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/users/${user_id}/project_subscriptions`).then(res => res);
}

function userPartySubscriptions(user_id) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/users/${user_id}/party_subscriptions`).then(res => res);
}

function userProjectSubscriptionNotifications(user_id, subscription_id, page) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/users/${user_id}/project_subscriptions/${subscription_id}/notifications`, { params: { page } }).then(res => res);
}

function userPartySubscriptionNotifications(user_id, subscription_id, page) {
  return axios.get(`/${process.env.VUE_APP_API_VERSION}/users/${user_id}/party_subscriptions/${subscription_id}/notifications`, { params: { page } }).then(res => res);
}

function requestNewPassword(email) {
  return axios.post('/auth/password', { email }).then(res => res);
}

function resetPassword(password, passwordConfirmation, headers) {
  return axios.put('/auth/password',
    {
      password,
      password_confirmation: passwordConfirmation,
    },
    { headers }).then(res => res);
}

function updateUser({
  name,
  current_password,
  password,
  password_confirmation,
}) {
  return axios.put('/auth/',
    { // Payload
      ...(name !== null && { name }),
      ...(current_password !== null && { current_password }),
      ...(password !== null && { password }),
      ...(password_confirmation !== null && { password_confirmation }),
    }).then(res => res);
}

const userService = {
  activateUser,
  acceptUserInvitation,
  createUserInvitation,
  deleteUser,
  getUser,
  indexUsers,
  requestNewPassword,
  resetPassword,
  updateUser,
  userAlerts,
  userProjectSubscriptions,
  userPartySubscriptions,
  userProjectSubscriptionNotifications,
  userPartySubscriptionNotifications,
};


export default userService;
