<template>
  <div>
    <div class="row m-0 py-0">
      <div class="col-11">
        Fecha notificación: {{notification.date}}
        <h5 class ="pt-2">
          <span class ="circle-tag">C</span> Cambios en la licitación:
          <router-link :to="{
              name: 'procurement',
              params: {
                id: notification.data.id
              }
            }">
              {{notification.data.title}}
          </router-link>
        </h5>
      </div>
      <div class="col-1 text-right" v-if="showActions">
        <template v-if="projectHasSubscription(notification.data.id)">
          <i v-b-tooltip.hover title="Dejar de seguir" class="far fa-bell-slash fa-2x" @click="deleteProjectSubscription(notification.data.id)"></i>
        </template>
        <template v-else>
          <template v-if="isNotResolved(notification.data)">
            <i v-b-tooltip.hover title="Seguir" class="far fa-bell fa-2x"  @click="createProjectSubscription(notification.data.id)"></i>
          </template>
          <template v-else-if="isNotOutdated(notification.data)">
              <i v-b-tooltip.hover title="Avisarme cuando queden 6 meses de la finalización del período de ejecución" @click="createProjectSubscription(notification.data.id)" class="far fa-clock fa-2x"></i>
          </template>
        </template>
        <i v-b-tooltip.hover title="Descartar" class ="fas fa-trash-alt fa-2x ml-2"  @click="updateNotification({processed: true, id: notification.id})"></i>
      </div>
    </div>
    <div class="row m-0 p-0" v-if="changes.documents.total_documents > 0">
      <div class="col-12 mt-2">
        <h6> Esta licitación tiene
          <b> {{ changes.documents.total_documents === 1 ? '1 documento nuevo' : `${changes.documents.total_documents} documentos nuevos:`}} </b>
        </h6>
      </div>
    </div>
    <div class="row m-0 p-0">
        <notificationDocumentList title='Documentación Legal'
          :documents="changes.documents.legal_document"
          v-if="'legal_document' in changes.documents"/>
        <notificationDocumentList title='Documentación Técnica'
          :documents="changes.documents.technical_document"
          v-if="'technical_document' in changes.documents"/>
        <notificationDocumentList title='Documentación General'
          :documents="changes.documents.general_document"
          v-if="'general_document' in changes.documents"/>
        <notificationDocumentList title='Documentación Adicional'
          :documents="changes.documents.additional_document"
          v-if="'additional_document' in changes.documents"/>
    </div>
    <template v-if="'status' in changes">
      <div class="row pl-3">
        <div class="col-12">
          <h6> Cambio de Estado: de
          <b> {{changes.status.from}} </b>
           a
          <b> {{changes.status.to}}  </b>
          </h6>
        </div>
      </div>
    </template>
    <template v-if="'planned_period' in changes">
      <div class="row pl-3">
        <div class="col-12">
          <h6> Cambio de período de ejecución: de
          <b> {{changes.planned_period.from}} </b>
           a
          <b> {{changes.planned_period.to}}  </b>
          </h6>
        </div>
      </div>
    </template>
    <template v-if="'tender_submission_deadline' in changes">
      <div class="row pl-3">
        <div class="col-12">
          <h6> Cambio de fecha límite de presentación: de
          <b> {{changes.tender_submission_deadline.from}} </b>
           a
          <b> {{changes.tender_submission_deadline.to}}  </b>
          </h6>
        </div>
      </div>
    </template>
    <template v-if="'tender_submission_description' in changes">
      <div class="row m-0 pl-3">
        <p> <strong>Nueva descripción proceso de presentación</strong>: {{changes.tender_submission_description}} </p>
      </div>
    </template>
    <template v-if="'description' in changes">
      <div class="row m-0 pl-3">
        <p> <strong>Nueva descripción</strong>: {{changes.description}} </p>
      </div>
    </template>
    <template v-if="('contracting_party' in changes)">
      <div class="row m-0 pl-3">
        <div class="col-12">
          <h6> Cambio de órgano de contratación: de
          <b>
            <router-link :to="{
              name: 'procurement_party',
              params: {
                id: changes.contracting_party.from.id
              }
            }">
              {{changes.contracting_party.from.name}}
            </router-link>
          </b>
           a
          <b>
            <router-link :to="{
              name: 'procurement_party',
              params: {
                id: changes.contracting_party.to.id
              }
            }">
              {{changes.contracting_party.to.name}}
            </router-link>
          </b>
          </h6>
        </div>
      </div>
    </template>
    <template v-if="('total_amount' in changes) || ('tax_exclusive_amount' in changes) || ('estimated_overall_contract_amount' in changes)">
      <div class="row mt-0 pt-0 pl-3">
        <div class="col-12">
          <h6> Cambio en los importes de la licitación </h6>
          <ul>
            <li v-if="'total_amount' in changes">
              Importe total: de <b> {{formatAmount(changes.total_amount.from)}} </b> a <b> {{formatAmount(changes.total_amount.to)}}</b>
            </li>
            <li v-if="'tax_exclusive_amount' in changes">
              Importe total sin impuestos: de <b> {{formatAmount(changes.tax_exclusive_amount.from)}} </b> a <b> {{formatAmount(changes.tax_exclusive_amount.to)}}</b>
            </li>
            <li v-if="'estimated_overall_contract_amount' in changes">
              Importe total estimado: de <b> {{formatAmount(changes.estimated_overall_contract_amount.from)}} </b> a <b> {{formatAmount(changes.estimated_overall_contract_amount.to)}}</b>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-if="'location' in changes">
      <div class="row m-0 pl-3">
        <div class="col-12">
          <h6> Cambio de lugar de ejecución: de
          <b> {{changes.status.from}} </b>
           a
          <b> {{changes.status.to}}  </b>
          </h6>
        </div>
      </div>
    </template>
    <template v-if="('contract_type_id' in changes) ||
      ('contract_sub_type_id' in changes) ||
      ('tender_procedure_code' in changes) ||
      ('contract_folder_id' in changes) ||
      ('tender_part_presentation_code' in changes) ||
      ('tender_max_lot_presentation_qty' in changes) ||
      ('tender_max_awarded_lots_qty' in changes) ||
      ('tender_submission_method_code' in changes)">
      <div class="row m-0 py-0 pl-3">
        <h6> Otros cambios: </h6>
        <ul>
          <li v-if="'contract_type_id' in changes">
            Tipo de contrato: de <b> {{changes.contract_type_id.from}} </b> a <b> {{changes.contract_type_id.to}}</b>
          </li>
          <li v-if="'contract_sub_type_id' in changes">
            Subtipo de contrato: de <b> {{changes.contract_sub_type_id.from}} </b> a <b> {{changes.contract_sub_type_id.to}}</b>
          </li>
          <li v-if="'tender_procedure_code' in changes">
            Procedimiento de contratación: de <b> {{changes.tender_procedure_code.from}} </b> a <b> {{changes.tender_procedure_code.to}}</b>
          </li>
          <li v-if="'contract_folder_id' in changes">
            Número de expediente: de <b> {{changes.contract_folder_id.from}} </b> a <b> {{changes.contract_folder_id.to}}</b>
          </li>
          <li v-if="'tender_part_presentation_code' in changes">
            Presentación por lotes: de <b> {{changes.tender_part_presentation_code.from}} </b> a <b> {{changes.tender_part_presentation_code.to}}</b>
          </li>
          <li v-if="'tender_max_lot_presentation_qty' in changes">
            Máximo número de lotes aplicable: de <b> {{changes.tender_max_lot_presentation_qty.from}} </b> a <b> {{changes.tender_max_lot_presentation_qty.to}}</b>
          </li>
          <li v-if="'tender_max_awarded_lots_qty' in changes">
            Máximo número de lotes recibibles: de <b> {{changes.tender_max_awarded_lots_qty.from}} </b> a <b> {{changes.tender_max_awarded_lots_qty.to}}</b>
          </li>
          <li v-if="'tender_submission_method_code' in changes">
            Método de presentación: de <b> {{changes.tender_submission_method_code.from}} </b> a <b> {{changes.tender_submission_method_code.to}}</b>
          </li>
        </ul>
      </div>
    </template>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import procurementMixin from '@/_mixins/procurement.mixin';
import notificationDocumentList from './document_list.vue';

export default {
  name: 'projectChangesNotification',
  props: {
    notification: {
      required: true,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [procurementMixin],
  components: {
    notificationDocumentList,
  },
  computed: {
    changes() {
      return this.notification.data.changes;
    },
  },
  methods: {
    ...mapActions('procurementNotificationsModule', {
      updateNotification: 'updateNotification',
    }),
  },
};
</script>

<style scoped>

</style>
