<template>
<div class="col-3" >
  <h6> {{ title }} </h6>
  <ul class="p-0">
    <li v-for="(document, index) in documents" v-bind:key="`document_${index}`">
      <a :href="document.uri" target="_blank">
        {{ documentName(document) }}
      </a>
    </li>
  </ul>
</div>
</template>

<script>
const URI = require('urijs');

export default {
  name: 'notificationDocumentsList',
  props: ['title', 'documents'],
  methods: {
    documentName(document) {
      if (document.name) return document.name;
      if (document.string_id) return document.string_id;

      return new URI(document.uri).filename();
    },
  },
};
</script>

<style scoped>

</style>
