<template>
  <div id="subscription" class="p-3">
    <div>
      <template v-if="user.subscription.id">
          <p> <strong>Licencias Usadas:</strong> {{ user.subscription.used }}/{{ user.subscription.quantity }} </p>
          <p> <strong>Método de pago:</strong> {{ user.subscription.billing_type }} </p>
          <p> <strong>Dueño de la suscripción: </strong> {{user.subscription.owner_email}} </p>
          <p> <strong>Estado suscripción:</strong> {{ user.subscription.status }} </p>
      </template>
      <template v-else>
        <p> <strong>Estado suscripción:</strong> No tiene </p>
        <p> <strong>Días de prueba restantes:</strong> {{ user.subscription.trial_days_remaining }} </p>
      </template>
    </div>
    <div v-if="!user.subscription.id">
      Crear Subcripción
      Número de licencias: <input v-model.number="editSubscription.quantity" type="number">
      <button class="btn btn-secondary" @click="createSubscription()"> Crear suscripción </button>
    </div>
    <div v-else>
      <template v-if="editMode">
        <b-form-select v-model="editSubscription.status" :options="statusOptions"></b-form-select>
        Cantidad: <input v-model.number="editSubscription.quantity" type="number">
        <button class="btn btn-secondary" @click="updateSubscription"> Guardar </button>
        <button class="btn btn-secondary" @click="setEditMode"> Cerrar </button>
      </template>
      <template v-else>
        <div>
          <button class="btn btn-secondary"> Borrar Suscripción </button>
          <button class="btn btn-secondary" @click="setEditMode"> Editar Suscripción </button>
        </div>
      </template>

      <div>
        <h6> Usuarios de la suscripción </h6>
        <div v-for="(subscription_user, index) in user.subscription.users" v-bind:key="`subscription_user_${index}`">
          {{subscription_user.email}}
          <button class="btn btn-secondary"> Quitar </button>
        </div>

        <input type="text" placeholder="introduce el email del usuario" v-model="newSubscriptionUser">
        <button class="btn btn-secondary" @click="addUserToSubscription()"> Añadir usuario </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import userService from '@/_services/user.service';
import subscriptionsService from '@/_services/subscriptions.service';
import adminService from '@/_services/admin.service';

export default {
  name: 'AdminUserComponent',
  components: {
  },
  data() {
    return {
      user: {
        subscription: {},
      },
      editSubscription: {
        quantity: 1,
        status: '',
      },
      newSubscriptionUser: '',
      editMode: false,
      statusOptions: [
        { value: 'active', text: 'Activa' },
        { value: 'canceled', text: 'Cancelada' },
      ],
    };
  },
  computed: {
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    addUserToSubscription() {
      adminService.addUserToSubscription(this.user.subscription.id, { user_email: this.newSubscriptionUser }).then(
        () => {
          this.fetchUser();
        },
      );
    },
    fetchUser() {
      userService.getUser(this.$route.params.user_id).then((response) => {
        Vue.set(this, 'user', response.data);
      });
    },
    fetchSubscription() {
      subscriptionsService.getSubscription(this.$route.params.subscription_id).then((response) => {
        Vue.set(this, 'user', response.data);
      });
    },
    setEditMode() {
      this.editMode = !this.editMode;
      if (this.editMode) {
        this.editSubscription.quantity = this.user.subscription.quantity;
        this.editSubscription.status = this.user.subscription.status;
      }
    },
    updateSubscription() {
      adminService.updateSubscription(this.user.subscription.id, this.editSubscription).then(
        () => {
          this.editMode = false;
          this.fetchUser();
        },
      );
    },
  },
};
</script>

<style scoped>
label, input, button{
  display: inline;
}
</style>
