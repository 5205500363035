/* eslint-disable import/prefer-default-export */
/* eslint no-shadow: ["error", {"allow": ["state"]}] */
/* eslint no-param-reassign: ["error", { "props": true,
                                        "ignorePropertyModificationsFor": ["state"] }] */
import Vue from 'vue';
import tagService from '../_services/tag.service';

const state = {
  tags: [],
  favoriteTag: {
    name: 'Favoritos',
    background_color: '#EEBD01',
    font_color: '#FFFFFF',
    type: 'system',
    selected: false,
    id: -1,
  },
};

const actions = {

  createTag({ dispatch }, tag) {
    // We return the promise from axios so that the view will now when the data has loaded
    return tagService.createTag(tag).then(
      () => {
        dispatch('indexTags');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  deleteTag({ dispatch }, id) {
    return tagService.deleteTag(id).then(
      () => {
        dispatch('indexTags');
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  indexTags({ commit }) {
    // We return the promise from axios so that the view will now when the data has loaded
    return tagService.indexTags().then(
      (response) => {
        const { tags } = response.data;
        commit('storeTags', tags);
      },
    ).catch((error) => {
      console.log(error);
    });
  },
  updateTag({ commit }, tag) {
    // We return the promise from axios so that the view will now when the data has loaded
    return tagService.updateTag(tag).then(
      () => {
      },
    ).catch((error) => {
      console.log(error);
    });
  },

};

const mutations = {
  storeTags(state, tags) {
    Vue.set(state, 'tags', tags);
  },
};

const tags = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default tags;
