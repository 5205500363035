import Excel from 'exceljs';
import moment from 'moment';
import { saveAs } from 'file-saver';
import axios from '@/_helpers/axios';

import procurementTableMixin from '@/_mixins/procurement.mixin';
import globalMixin from '@/_mixins/global.mixin';

moment.locale('es');

const headerStyle = {
  font: {
    name: 'Candara',
    size: 12,
    bold: true,
    color: { argb: '44546a' },
  },
};

const headerLeftStyle = {
  ...headerStyle,
  alignment: { vertical: 'middle', horizontal: 'left' },
};

const headerTableStyle = {
  ...headerStyle,
  border: {
    bottom: {
      style: 'thick',
      color: { argb: '5b9bd5' },
    },
  },
  alignment: { vertical: 'middle', horizontal: 'center' },
};

const baseStyle = {
  alignment: {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  },
};

const baseStyleHighlighted = {
  ...baseStyle,
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'ffffdb02' },
  },
};

function downloadImage() {
  return axios
    .get('https://legalhound-files.s3.eu-central-1.amazonaws.com/public/logo-nombre-morado.png', {
      responseType: 'arraybuffer',
    })
    .then(response => Buffer.from(response.data, 'base64'));
}

function addNewProcurementSheet(
  workbook,
  notifications,
  logoImageId,
  exportTime,
  mode,
) {
  const sheetTitle = mode === 'alert' ? 'Nuevas Licitaciones Alertas' : 'Nuevas Licitaciones Organismos';
  const worksheet = workbook.addWorksheet(sheetTitle);
  worksheet.properties.defaultRowHeight = 50;
  worksheet.properties.defaultColWidth = 30;

  const columns = [
    { key: 'date' },
    { key: 'title', width: 60 },
    { key: 'contract_folder_id' },
    { key: 'has_lots' },
    { key: 'status' },
    { key: 'contract_type' },
    { key: 'contracting_system_code' },
    { key: 'total_amount' },
    { key: 'tax_exclusive_amount' },
    { key: 'estimated_overall_contract_amount' },
    { key: 'planned_period' },
    { key: 'location' },
    { key: 'contracting_party', width: 60 },
    { key: 'submission_deadline' },
  ];
  if (mode === 'alert') {
    columns.splice(1, 0, { key: 'search_terms' });
  }

  worksheet.columns = columns;

  worksheet.addImage(logoImageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 90, height: 50 },
    hyperlinks: {
      hyperlink: `${process.env.VUE_APP_BASE_URL}`,
    },
  });

  worksheet.getRow.height = 50;
  const titleCell = worksheet.getCell('B1');
  titleCell.value = `${notifications.length} licitaciones exportadas de tus notificaciones el ${exportTime.format('LL')} a las ${exportTime.format('LT')}`;
  titleCell.style = headerLeftStyle;

  // Write header styles
  const headerRow = worksheet.getRow(2);
  const headerRowValues = [
    'Fecha Publicación',
    'Descripción',
    'Expediente',
    'Por Lotes',
    'Estado',
    'Tipo de Contrato',
    'Proc. Adjudicación',
    'Importe Total',
    'Importe Total Sin Impuestos',
    'Importe Total Estimado',
    'Plazo Ejecución',
    'Lugar de Ejecución',
    'Organismo',
    'Fecha límite presentación',
  ];


  if (mode === 'alert') {
    headerRowValues.splice(1, 0, 'Palabras Clave');
  }

  headerRow.values = headerRowValues;

  for (let i = 0; i < worksheet.columns.length; i += 1) {
    headerRow.getCell(i + 1).style = headerTableStyle;
  }

  for (let i = 0; i < notifications.length; i += 1) {
    const notification = notifications[i];
    const procurement_project = notification.data;
    const rowData = {
      date: procurement_project.date,
      title: procurement_project.title,
      contract_folder_id: procurement_project.contract_folder_id,
      has_lots: procurement_project.has_lots ? 'Sí' : 'No',
      status: procurement_project.status,
      contract_type: procurement_project.contract_type,
      contracting_system_code: procurement_project.contracting_system_code,
      total_amount: globalMixin.methods.formatAmount(procurement_project.total_amount),
      tax_exclusive_amount: globalMixin.methods.formatAmount(procurement_project.tax_exclusive_amount),
      estimated_overall_contract_amount: globalMixin.methods.formatAmount(procurement_project.estimated_overall_contract_amount),
      planned_period: procurementTableMixin.methods.plannedPeriod(procurement_project.planned_period, procurement_project.planned_period_unit),
      location: procurementTableMixin.methods.locationStr(procurement_project.location),
      contracting_party: procurement_project.contracting_party.name,
      submission_deadline: procurement_project.submission_deadline ? moment(procurement_project.submission_deadline).format('HH:mm:ss DD/MM/YYYY') : procurement_project.submission_deadline,
    };

    if (mode === 'alert') {
      rowData.search_terms = notification.alerts.map(alert => `- ${alert.searchTerms}`).join('\n');
    }

    const row = worksheet.addRow(rowData);

    row.getCell('contracting_party').value = {
      text: procurement_project.contracting_party.name,
      hyperlink: `${process.env.VUE_APP_BASE_URL}/licitaciones/empresa/${procurement_project.contracting_party.id}`,
    };

    row.getCell('contract_folder_id').value = {
      text: procurement_project.contract_folder_id,
      hyperlink: `${process.env.VUE_APP_BASE_URL}/licitaciones/detalle/${procurement_project.id}`,
    };

    // Set style only for the used cells
    for (let j = 0; j < worksheet.columns.length; j += 1) {
      row.getCell(j + 1).style = baseStyle;
    }
  }

  worksheet.mergeCells('B1:G1');
}

function addNewProcurementChangesSheet(
  workbook,
  notifications,
  logoImageId,
  exportTime,
) {
  const worksheet = workbook.addWorksheet('Licitaciones con cambios');
  worksheet.properties.defaultRowHeight = 50;
  worksheet.properties.defaultColWidth = 30;

  worksheet.columns = [
    { key: 'date' },
    { key: 'description', width: 60 },
    { key: 'status' },
    { key: 'planned_period' },
    { key: 'location' },
    { key: 'lot_changes' },
    { key: 'total_amount' },
    { key: 'tax_exclusive_amount' },
    { key: 'estimated_overall_contract_amount' },
    { key: 'submission_deadline' },
    { key: 'contracting_party' },
    { key: 'legal_documents', width: 60 },
    { key: 'technical_documents', width: 60 },
    { key: 'general_documents', width: 60 },
    { key: 'additional_documents', width: 60 },

  ];
  worksheet.addImage(logoImageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 90, height: 50 },
    hyperlinks: {
      hyperlink: `${process.env.VUE_APP_BASE_URL}`,
    },
  });

  const titleCell = worksheet.getCell('B1');
  titleCell.value = `${notifications.length} licitaciones con cambios. Exportado el ${exportTime.format('LL')} a las ${exportTime.format('LT')}`;
  titleCell.style = headerLeftStyle;

  const headerRow = worksheet.getRow(2);
  headerRow.values = [
    'Fecha Publicación',
    'Descripción',
    'Estado',
    'Plazo Ejecución',
    'Lugar de Ejecución',
    'Cambios en los lotes',
    'Importe Total',
    'Importe Total Sin Impuestos',
    'Importe Total Estimado',
    'Fecha límite presentación',
    'Organismo',
    'Nueva Documentación Legal',
    'Nueva Documentación Técnica',
    'Nueva Documentación General',
    'Nueva Documentación Addicional',
  ];

  for (let i = 0; i < worksheet.columns.length; i += 1) {
    headerRow.getCell(i + 1).style = headerTableStyle;
  }

  for (let i = 0; i < notifications.length; i += 1) {
    const notification = notifications[i];
    const project = notification.data;
    const { changes } = project;

    const highlightCells = [];
    const rowData = {
      date: project.date,
    };

    if ('description' in changes) {
      highlightCells.push('description');
      rowData.description = changes.description;
    } else {
      rowData.description = project.title;
    }

    if ('status' in changes) {
      rowData.status = `${changes.status.from} -> ${changes.status.to}`;
      highlightCells.push('status');
    } else {
      rowData.status = project.status;
    }

    if ('planned_period' in changes) {
      rowData.planned_period = `${changes.planned_period.from} -> ${changes.planned_period.to}`;
      highlightCells.push('planned_period');
    } else {
      rowData.planned_period = procurementTableMixin.methods.plannedPeriod(project.planned_period);
    }

    if ('location' in changes) {
      rowData.location = `${changes.location.from} -> ${changes.location.to}`;
      highlightCells.push('location');
    } else {
      rowData.location = procurementTableMixin.methods.locationStr(project.location);
    }

    if ('total_amount' in changes) {
      rowData.total_amount = `${globalMixin.methods.formatAmount(changes.total_amount.from)} -> ${globalMixin.methods.formatAmount(changes.total_amount.to)}`;
      highlightCells.push('total_amount');
    } else {
      rowData.total_amount = globalMixin.methods.formatAmount(project.total_amount);
    }

    if ('tax_exclusive_amount' in changes) {
      rowData.tax_exclusive_amount = `${globalMixin.methods.formatAmount(changes.tax_exclusive_amount.from)} -> ${globalMixin.methods.formatAmount(changes.tax_exclusive_amount.to)}`;
      highlightCells.push('tax_exclusive_amount');
    } else {
      rowData.tax_exclusive_amount = globalMixin.methods.formatAmount(project.tax_exclusive_amount);
    }

    if ('estimated_overall_contract_amount' in changes) {
      rowData.estimated_overall_contract_amount = `${globalMixin.methods.formatAmount(changes.estimated_overall_contract_amount.from)} -> ${globalMixin.methods.formatAmount(changes.estimated_overall_contract_amount.to)}`;
      highlightCells.push('estimated_overall_contract_amount');
    } else {
      rowData.estimated_overall_contract_amount = globalMixin.methods.formatAmount(project.estimated_overall_contract_amount);
    }

    if (changes.lot_changes.length > 0) {
      rowData.lot_changes = 'Sí';
      highlightCells.push('lot_changes');
    } else {
      rowData.lot_changes = 'No';
    }

    if ('tender_submission_deadline' in changes) {
      rowData.submission_deadline = `${changes.tender_submission_deadline.from} -> ${changes.tender_submission_deadline.to}`;
      highlightCells.push('submission_deadline');
    } else {
      rowData.submission_deadline = project.submission_deadline ? moment(project.submission_deadline).format('HH:mm:ss DD/MM/YYYY') : project.submission_deadline;
    }

    if ('contracting_party' in changes) {
      rowData.contracting_party = `${changes.contracting_party.from.name} -> ${changes.contracting_party.to.name}`;
      highlightCells.push('contracting_party');
    } else {
      rowData.contracting_party = project.contracting_party.name;
    }

    if ('legal_document' in changes.documents) {
      highlightCells.push('legal_documents');
      rowData.legal_documents = changes.documents.legal_document.map(doc => `- ${doc.name ? doc.name : doc.string_id}`).join('\n');
    }

    if ('technical_document' in changes.documents) {
      highlightCells.push('technical_documents');
      rowData.technical_documents = changes.documents.technical_document.map(doc => `- ${doc.name ? doc.name : doc.string_id}`).join('\n');
    }

    if ('general_document' in changes.documents) {
      highlightCells.push('general_documents');
      rowData.general_documents = changes.documents.general_document.map(doc => `- ${doc.name ? doc.name : doc.string_id}`).join('\n');
    }

    if ('additional_document' in changes.documents) {
      highlightCells.push('additional_documents');
      rowData.additional_documents = changes.documents.additional_document.map(doc => `- ${doc.name ? doc.name : doc.string_id}`).join('\n');
    }

    const row = worksheet.addRow(rowData);
    // Set style only for the used cells
    for (let j = 0; j < worksheet.columns.length; j += 1) {
      row.getCell(j + 1).style = baseStyle;

      // eslint-disable-next-line no-underscore-dangle
      if (highlightCells.includes(worksheet.columns[j]._key)) {
        row.getCell(j + 1).style = baseStyleHighlighted;
      }
    }
  }

  worksheet.mergeCells('B1:G1');
}

function addNewProcurementTenderResultsSheet(
  workbook,
  notifications,
  logoImageId,
  exportTime,
) {
  const worksheet = workbook.addWorksheet('Nuevas adjudicaciones');
  worksheet.properties.defaultRowHeight = 50;
  worksheet.properties.defaultColWidth = 30;

  worksheet.columns = [
    { key: 'party' },
    { key: 'description', width: 60 },
    { key: 'contract_folder_id' },
    { key: 'lot_id' },
    { key: 'lot_description', width: 60 },
    { key: 'total_amount' },
    { key: 'payable_amount' },
    { key: 'total_amount' },
    { key: 'tender_result_description', width: 60 },
  ];

  worksheet.addImage(logoImageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 90, height: 50 },
    hyperlinks: {
      hyperlink: `${process.env.VUE_APP_BASE_URL}`,
    },
  });

  const titleCell = worksheet.getCell('B1');
  titleCell.value = `${notifications.length} empresas con nuevas adjudicaciones. Exportado el ${exportTime.format('LL')} a las ${exportTime.format('LT')}`;
  titleCell.style = headerLeftStyle;

  const headerRow = worksheet.getRow(2);
  headerRow.values = [
    'Empresa',
    'Descripción Licitación',
    'Expediente',
    'Lote',
    'Descripción Lote',
    'Cantidad Total',
    'Cantidad Total Recibida',
    'Razón Adjudicación',
  ];
  for (let i = 0; i < worksheet.columns.length; i += 1) {
    headerRow.getCell(i + 1).style = headerTableStyle;
  }

  let cur_row = 3;
  for (let i = 0; i < notifications.length; i += 1) {
    const notification = notifications[i];
    const party = notification.data;

    const start_party_row = cur_row;

    const tender_results_by_project = {};
    for (let j = 0; j < party.tender_results.length; j += 1) {
      const tender_result = party.tender_results[j];
      if (!(tender_result.procurement_project_id in tender_results_by_project)) {
        tender_results_by_project[tender_result.procurement_project_id] = [];
      }
      tender_results_by_project[tender_result.procurement_project_id].push(tender_result);
    }

    // eslint-disable-next-line no-loop-func
    Object.keys(tender_results_by_project).forEach((project_id) => {
      const tender_results = tender_results_by_project[project_id];
      for (let j = 0; j < tender_results.length; j += 1) {
        const tender_result = tender_results[j];
        const rowData = {
          party: party.name,
          description: tender_result.project_title,
          contract_folder_id: tender_result.contract_folder_id,
          lot_id: tender_result.lot_id,
          lot_description: tender_result.lot_description,
          total_amount: globalMixin.methods.formatAmount(tender_result.total_amount),
          payable_amount: globalMixin.methods.formatAmount(tender_result.payable_amount),
          tender_result_description: tender_result.description,
        };

        const row = worksheet.addRow(rowData);

        row.getCell('contract_folder_id').value = {
          text: tender_result.contract_folder_id,
          hyperlink: `${process.env.VUE_APP_BASE_URL}/licitaciones/detalle/${project_id}`,
        };

        row.getCell('party').value = {
          text: party.name,
          hyperlink: `${process.env.VUE_APP_BASE_URL}/licitaciones/empresa/${party.id}`,
        };
        cur_row += 1;
      }
    });
    if (start_party_row + 1 < cur_row) {
      worksheet.mergeCells(`A${start_party_row + 1}:A${cur_row}`);
    }
  }

  worksheet.mergeCells('B1:G1');
}

const saveProcurementProjectNotifications = (notifications,
  filename) => new Promise((resolve, reject) => {
  downloadImage().then((imageBuffer) => {
    const workbook = new Excel.Workbook();
    const logoImageId = workbook.addImage({
      buffer: imageBuffer,
      extension: 'png',
    });

    const exportTime = moment();

    const alert_notifications = notifications.filter(notification => notification.notification_types.includes('alert'));
    const procurement_party_procurement_project_subscription_notifications = notifications.filter(notification => notification.notification_types.includes('procurement_party_procurement_project_subscription'));
    const notifications_changes = notifications.filter(notification => notification.notification_types.includes('procurement_project_subscription'));
    const tender_results_notifications = notifications.filter(notification => notification.notification_types.includes('procurement_party_tender_result_subscription'));


    if (alert_notifications.length > 0) {
      addNewProcurementSheet(workbook, alert_notifications, logoImageId, exportTime, 'alert');
    }

    if (procurement_party_procurement_project_subscription_notifications.length > 0) {
      addNewProcurementSheet(workbook, procurement_party_procurement_project_subscription_notifications, logoImageId, exportTime, 'procurement_party_procurement_project_subscription');
    }

    if (notifications_changes.length > 0) {
      addNewProcurementChangesSheet(workbook,
        notifications_changes, logoImageId, exportTime);
    }

    if (tender_results_notifications.length > 0) {
      addNewProcurementTenderResultsSheet(workbook,
        tender_results_notifications, logoImageId, exportTime);
    }

    workbook.xlsx.writeBuffer()
      .then(
        (excelBuffer) => {
          saveAs(new Blob([excelBuffer]), filename);
          resolve();
        },
      )
      .catch(err => console.log('Error writing excel export', err));
  }).catch((err) => {
    reject(err);
  });
});

const saveProcurementProjects = async (procurement_projects,
  filename) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Licitaciones');
  worksheet.properties.defaultRowHeight = 50;
  worksheet.properties.defaultColWidth = 30;

  worksheet.columns = [
    { key: 'date' },
    { key: 'title', width: 60 },
    { key: 'contract_folder_id' },
    { key: 'has_lots' },
    { key: 'status' },
    { key: 'contract_type' },
    { key: 'contracting_system_code' },
    { key: 'total_amount' },
    { key: 'tax_exclusive_amount' },
    { key: 'estimated_overall_contract_amount' },
    { key: 'total_amount' },
    { key: 'planned_period' },
    { key: 'location' },
    { key: 'contracting_party', width: 60 },
    { key: 'submission_deadline' },
  ];

  const imageBuffer = await downloadImage();

  const logoImageId = workbook.addImage({
    buffer: imageBuffer,
    extension: 'png',
  });

  worksheet.addImage(logoImageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 90, height: 50 },
    hyperlinks: {
      hyperlink: `${process.env.VUE_APP_BASE_URL}`,
    },
  });

  worksheet.getRow.height = 50;
  const titleCell = worksheet.getCell('B1');
  titleCell.value = `${procurement_projects.length} licitaciones exportadas el ${moment().format('LL')} a las ${moment().format('LT')}`;
  titleCell.style = headerLeftStyle;

  // Write header styles
  const headerRow = worksheet.getRow(2);
  headerRow.values = [
    'Fecha Publicación',
    'Descripción',
    'Expediente',
    'Por Lotes',
    'Estado',
    'Tipo de Contrato',
    'Proc. Adjudicación',
    'Importe Total',
    'Importe Total Sin Impuestos',
    'Importe Total Estimado',
    'Plazo Ejecución',
    'Lugar de Ejecución',
    'Organismo',
    'Fecha límite presentación',
  ];

  for (let i = 0; i < worksheet.columns.length; i += 1) {
    headerRow.getCell(i + 1).style = headerTableStyle;
  }

  for (let i = 0; i < procurement_projects.length; i += 1) {
    const row = worksheet.addRow({
      date: procurement_projects[i].date,
      title: procurement_projects[i].title,
      contract_folder_id: procurement_projects[i].contract_folder_id,
      has_lots: procurement_projects[i].has_lots ? 'Sí' : 'No',
      status: procurement_projects[i].status,
      contract_type: procurement_projects[i].contract_type,
      contracting_system_code: procurement_projects[i].contracting_system_code,
      total_amount: globalMixin.methods.formatAmount(procurement_projects[i].total_amount),
      tax_exclusive_amount: globalMixin.methods.formatAmount(procurement_projects[i].tax_exclusive_amount),
      estimated_overall_contract_amount: globalMixin.methods.formatAmount(procurement_projects[i].estimated_overall_contract_amount),
      planned_period: procurementTableMixin.methods.plannedPeriod(procurement_projects[i].planned_period, procurement_projects[i].planned_period_unit),
      location: procurementTableMixin.methods.locationStr(procurement_projects[i].location),
      contracting_party: procurement_projects[i].contracting_party.name,
      submission_deadline: procurement_projects[i].submission_deadline,
    });

    row.getCell('contracting_party').value = {
      text: procurement_projects[i].contracting_party.name,
      hyperlink: `${process.env.VUE_APP_BASE_URL}/licitaciones/empresa/${procurement_projects[i].contracting_party.id}`,
    };

    row.getCell('contract_folder_id').value = {
      text: procurement_projects[i].contract_folder_id,
      hyperlink: `${process.env.VUE_APP_BASE_URL}/licitaciones/detalle/${procurement_projects[i].id}`,
    };

    // Set style only for the used cells
    for (let j = 0; j < worksheet.columns.length; j += 1) {
      row.getCell(j + 1).style = baseStyle;
    }
  }

  worksheet.mergeCells('B1:G1');

  workbook.xlsx.writeBuffer()
    .then(
      excelBuffer => saveAs(new Blob([excelBuffer]), filename),
    )
    .catch(err => console.log('Error writing excel export', err));
};

const saveProcurementTenderResults = async (tender_results,
  party_name,
  filename) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Adjudicaciones');
  worksheet.properties.defaultRowHeight = 50;
  worksheet.properties.defaultColWidth = 30;

  worksheet.columns = [
    { key: 'date' },
    { key: 'award_date' },
    { key: 'summary' },
    { key: 'title', width: 60 },
    { key: 'location' },
    { key: 'total_amount' },
    { key: 'lot_description' },
    { key: 'lot_amount' },
    { key: 'payable_amount' },
    { key: 'tender_result_description' },
    { key: 'contracting_party', width: 60 },
  ];

  const imageBuffer = await downloadImage();

  const logoImageId = workbook.addImage({
    buffer: imageBuffer,
    extension: 'png',
  });

  worksheet.addImage(logoImageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 90, height: 50 },
    hyperlinks: {
      hyperlink: `${process.env.VUE_APP_BASE_URL}`,
    },
  });

  worksheet.getRow.height = 50;
  const titleCell = worksheet.getCell('B1');
  titleCell.value = `${tender_results.length} adjudicaciones a la empresa ${party_name} exportadas el ${moment().format('LL')} a las ${moment().format('LT')}`;
  titleCell.style = headerLeftStyle;

  // Write header styles
  const headerRow = worksheet.getRow(2);
  headerRow.values = [
    'Fecha Publicación',
    'Fecha Adjudicación',
    'Resumen Licitación',
    'Descripción',
    'Lugar de Ejecución',
    'Importe Total Licitación',
    'Descripción Lote',
    'Importe Total Lote',
    'Importe Total Recibido',
    'Razón Adjudicación',
    'Organismo',
  ];

  for (let i = 0; i < worksheet.columns.length; i += 1) {
    headerRow.getCell(i + 1).style = headerTableStyle;
  }

  for (let i = 0; i < tender_results.length; i += 1) {
    const row = worksheet.addRow({
      date: tender_results[i].project.date,
      award_date: tender_results[i].award_date,
      title: tender_results[i].project.title,
      summary: tender_results[i].project.summary,
      location: procurementTableMixin.methods.locationStr(tender_results[i].project.location),
      total_amount: globalMixin.methods.formatAmount(tender_results[i].project.total_amount),
      lot_description: tender_results[i].lot.description,
      lot_amount: globalMixin.methods.formatAmount(tender_results[i].lot.total_amount),
      payable_amount: globalMixin.methods.formatAmount(tender_results[i].payable_amount),
      contracting_party: tender_results[i].contracting_party.name,
      tender_result_description: tender_results[i].summary,
    });

    row.getCell('contracting_party').value = {
      text: tender_results[i].contracting_party.name,
      hyperlink: `${process.env.VUE_APP_BASE_URL}/licitaciones/empresa/${tender_results[i].contracting_party.id}`,
    };

    row.getCell('summary').value = {
      text: tender_results[i].project.summary,
      hyperlink: `${process.env.VUE_APP_BASE_URL}/licitaciones/detalle/${tender_results[i].project.summary.id}`,
    };

    // Set style only for the used cells
    for (let j = 0; j < worksheet.columns.length; j += 1) {
      row.getCell(j + 1).style = baseStyle;
    }
  }

  worksheet.mergeCells('B1:G1');

  workbook.xlsx.writeBuffer()
    .then(
      excelBuffer => saveAs(new Blob([excelBuffer]), filename),
    )
    .catch(err => console.log('Error writing excel export', err));
};

const saveOfficialBulletins = async (results,
  filename) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Publicaciones Oficiales');
  worksheet.properties.defaultRowHeight = 50;
  worksheet.properties.defaultColWidth = 30;

  worksheet.columns = [
    { key: 'date' },
    { key: 'source' },
    { key: 'title', width: 60 },
    { key: 'summary', width: 60 },
    { key: 'department' },
    { key: 'pdf_url' },
  ];

  const imageBuffer = await downloadImage();
  const logoImageId = workbook.addImage({
    buffer: imageBuffer,
    extension: 'png',
  });

  worksheet.addImage(logoImageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 90, height: 50 },
    hyperlinks: {
      hyperlink: `${process.env.VUE_APP_BASE_URL}`,
    },
  });

  worksheet.getRow.height = 50;
  const titleCell = worksheet.getCell('B1');
  titleCell.value = `${results.length} publicaciones exportadas el ${moment().format('LL')} a las ${moment().format('LT')}`;
  titleCell.style = headerLeftStyle;

  // Write header styles
  const headerRow = worksheet.getRow(2);
  headerRow.values = [
    'Fecha Publicación',
    'Fuente (Abrir en LH)',
    'Título',
    'Resumen',
    'Departamento',
    'Link PDF',
  ];

  for (let i = 0; i < worksheet.columns.length; i += 1) {
    headerRow.getCell(i + 1).style = headerTableStyle;
  }

  for (let i = 0; i < results.length; i += 1) {
    const row = worksheet.addRow({
      date: results[i].date,
      source: results[i].source,
      title: results[i].title,
      summary: results[i].summary,
      department: results[i].department,
      pdf_url: results[i].pdf_url,
    });

    row.getCell('pdf_url').value = {
      text: results[i].pdf_url,
      hyperlink: results[i].pdf_url,
    };

    // Set style only for the used cells
    for (let j = 0; j < worksheet.columns.length; j += 1) {
      row.getCell(j + 1).style = baseStyle;
    }
    row.height = 100;
  }

  worksheet.mergeCells('B1:G1');

  workbook.xlsx.writeBuffer()
    .then(
      excelBuffer => saveAs(new Blob([excelBuffer]), filename),
    )
    .catch(err => console.log('Error writing excel export', err));
};

const saveOfficialBulletinNotifications = (notifications,
  filename) => new Promise((resolve, reject) => {
  downloadImage().then((imageBuffer) => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Publicaciones Oficiales');
    worksheet.properties.defaultRowHeight = 50;
    worksheet.properties.defaultColWidth = 30;

    worksheet.columns = [
      { key: 'date' },
      { key: 'source' },
      { key: 'search_terms' },
      { key: 'title', width: 60 },
      { key: 'summary', width: 60 },
      { key: 'department' },
      { key: 'pdf_url' },
    ];

    const logoImageId = workbook.addImage({
      buffer: imageBuffer,
      extension: 'png',
    });

    worksheet.addImage(logoImageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 90, height: 50 },
      hyperlinks: {
        hyperlink: `${process.env.VUE_APP_BASE_URL}`,
      },
    });

    worksheet.getRow.height = 50;
    const titleCell = worksheet.getCell('B1');
    titleCell.value = `${notifications.length} publicaciones exportadas de tus alertas el ${moment().format('LL')} a las ${moment().format('LT')}`;
    titleCell.style = headerLeftStyle;

    // Write header styles
    const headerRow = worksheet.getRow(2);
    headerRow.values = [
      'Fecha Publicación',
      'Fuente (Abrir en LH)',
      'Palabras Clave',
      'Título',
      'Resumen',
      'Departamento',
      'Link PDF',
    ];

    for (let i = 0; i < worksheet.columns.length; i += 1) {
      headerRow.getCell(i + 1).style = headerTableStyle;
    }

    for (let i = 0; i < notifications.length; i += 1) {
      const notification = notifications[i];
      const metadata = notification.data;

      const searchTerms = notification.alerts.map(alert => `- ${alert.searchTerms}`).join('\n');
      const row = worksheet.addRow({
        date: notification.date,
        source: metadata.source,
        search_terms: searchTerms,
        title: metadata.title,
        summary: metadata.summary,
        department: metadata.department,
        pdf_url: metadata.pdf_url,
      });

      row.getCell('pdf_url').value = {
        text: metadata.pdf_url,
        hyperlink: metadata.pdf_url,
      };

      // Set style only for the used cells
      for (let j = 0; j < worksheet.columns.length; j += 1) {
        row.getCell(j + 1).style = baseStyle;
      }
      row.height = 100;
    }

    worksheet.mergeCells('B1:G1');

    workbook.xlsx.writeBuffer()
      .then(
        (excelBuffer) => {
          saveAs(new Blob([excelBuffer]), filename);
          resolve();
        },
      )
      .catch((err) => {
        console.log('Error writing excel export', err);
        reject(err);
      });
  }).catch((err) => {
    reject(err);
  });
});

const excelExporter = {
  saveOfficialBulletins,
  saveOfficialBulletinNotifications,
  saveProcurementProjects,
  saveProcurementProjectNotifications,
  saveProcurementTenderResults,
};

export default excelExporter;
