<template>
  <header id="header">
    <b-navbar toggleable="lg">
      <b-navbar-brand href="/">
        <img class="logo ml-2" alt="logo" src="@/assets/logo-nombre-morado.png" height="50" width="90"/>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
       <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <ul class="navbar-nav ml-auto flex-nowrap">
            <li class="nav-item main-item" v-if="!authenticated">
              <router-link to="/sign_in" class="nav-link m-2 menu-item">{{ $t('signIn') }}</router-link>
            </li>
            <li class="nav-item main-item" v-if="!authenticated">
              <router-link to="/sign_up" class="nav-link m-2 menu-item">{{ $t('signUp') }}</router-link>
            </li>
            <li class="nav-item main-item" v-if="!authenticated">
              <router-link to="/precios" class="nav-link m-2 menu-item">{{ $t('userPages.pricing') }}</router-link>
            </li>
            <li class="nav-item main-item" v-if="authenticated">
              <router-link to="/licitaciones" class="nav-link m-2 menu-item">Licitaciones</router-link>
            </li>
            <li class="nav-item main-item" v-if="authenticated">
              <router-link to="/publicaciones" class="nav-link m-2 menu-item">Publicaciones Oficiales</router-link>
            </li>
            <template v-if="authenticated && administrator">
              <li class="nav-item main-item dropdown" v-on-clickaway="clickAwayAdminDropdown">
                <a class="nav-link dropdown-toggle m-2 " id="adminDropdown" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" @click="toggleAdminDropdown"> Admin
                </a>
                <div class="dropdown-menu dropdown-default" :class="{ show: showAdminDropdown }" aria-labelledby="adminDropdown">
                  <router-link to="/admin/users" class="nav-link m-2 menu-item"> {{ $t('users') }} </router-link>
                  <a class="nav-link m-2 menu-item" :href="sidekiqUrl" target="_blank"> Sidekiq </a>
                </div>
              </li>
            </template>
            <li class="nav-item main-item dropdown" v-on-clickaway="clickAwayHelpDropdown">
              <a class="nav-link dropdown-toggle m-2 " id="userDropdown" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false" @click="showHelpDropdown = !showHelpDropdown"> Ayuda
              </a>
              <div class="dropdown-menu dropdown-default" :class="{ show: showHelpDropdown }" aria-labelledby="userDropdown">
                <a href="https://wiki.legalhound.io" target="_blank"  class="dropdown-item"> Manual de usuario </a>
                 <router-link to="/contacto" class="dropdown-item"> Contacto </router-link>
              </div>
            </li>
            <template v-if="authenticated">
              <li class="nav-item main-item dropdown" v-on-clickaway="clickAwayAccountDropdown">
                <a class="nav-link dropdown-toggle m-2 " id="userDropdown" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" @click="toggleDropdown"> {{user.email}}
                </a>
                <div class="dropdown-menu dropdown-default" :class="{ show: showDropdown }" aria-labelledby="userDropdown">
                  <router-link to="/user" class="dropdown-item" v-on:click.native="toggleDropdown"> Cuenta </router-link>
                  <a href="#" v-on:click="clickSignOut" class="dropdown-item">{{ $t('signOut') }}</a>
                </div>
              </li>
            </template>
          </ul>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],
  data() {
    return {
      showDropdown: false,
      showAdminDropdown: false,
      showHelpDropdown: false,
    };
  },
  computed: {
    ...mapGetters('usersModule', ['user']),
    authenticated() {
      return this.$auth.check();
    },
    administrator() {
      return this.$auth.check(['admin']);
    },
    sidekiqUrl() {
      return `${process.env.VUE_APP_API_BASE_URL}/sidekiq`;
    },
  },
  methods: {
    ...mapActions('usersModule', ['signOut']),

    clickSignOut() {
      this.$auth.logout();
      this.showDropdown = false;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    toggleAdminDropdown() {
      this.showAdminDropdown = !this.showAdminDropdown;
    },
    clickAwayAccountDropdown() {
      this.showDropdown = false;
    },
    clickAwayAdminDropdown() {
      this.showAdminDropdown = false;
    },
    clickAwayHelpDropdown() {
      this.showHelpDropdown = false;
    },
  },
};
</script>

<style scoped>

a {
  cursor: pointer;
  color: #4953CF !important;
}

.main-item > a:hover {
background-color: #E5E5E5 !important;
color: #8F8EF8 !important;
text-decoration: underline;
}

li a.router-link-active{
text-decoration: underline;
}
.navbar{
  background-color: #E5E5E5 !important;
  z-index: 1000;
}
.logo{
  height: 50px;
}
.navbar-toggler{
  color: #4953CF !important;
}
.dropdown-item:focus{
 background-color: #ffffff !important;
}
</style>
