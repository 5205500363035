/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */

import Vue from 'vue';

// Plugin Imports
import Vuetify from 'vuetify';
import BootstrapVue from 'bootstrap-vue';
import VModal from 'vue-js-modal';
import VTooltip from 'v-tooltip';
import VueScrollTo from 'vue-scrollto';
import VueGtag from 'vue-gtag';
import vueDebounce from 'vue-debounce';
import VueCarousel from 'vue-carousel';
import VueApexCharts from 'vue-apexcharts';

import auth from '@websanova/vue-auth/dist/v2/vue-auth.common';
import driverDevise from '@websanova/vue-auth/src/drivers/auth/devise';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x';

import globalMixin from '@/_mixins/global.mixin';

// Helpers

import axios from './_helpers/axios';

import store from './_store/store';
import router from './router';
import i18n from './_helpers/i18n';

import './_helpers/vee_validate';

// Vue Files
import App from './App.vue';

// Css Imports
import 'vuetify/dist/vuetify.min.css';
import 'font-awesome/css/font-awesome.min.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// eslint-disable-next-line import/no-unresolved
import '@/assets/sass/legalhound.scss';


Vue.mixin(globalMixin);

Vue.config.productionTip = false;
Vue.router = router;

Vue.use(Vuetify);
Vue.use(BootstrapVue);

const vueMoment = require('vue-moment');

Vue.use(vueMoment);
Vue.use(VModal);
Vue.use(VTooltip);
Vue.use(VueScrollTo);
Vue.use(vueDebounce);
Vue.use(VueCarousel);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_API_GOOGLE_ANALYTICS_ID },
  params: {
    anonymize_ip: true,
  },
  bootstrap: false,
}, router);


Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.use(auth, {
  plugins: {
    http: axios,
    router,
  },
  drivers: {
    auth: driverDevise,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    authRedirect: { path: '/inicio' },
    staySignedIn: true,
    rememberMe: true,
    stores: ['storage', 'cookie'],
    rolesKey: 'role',
    fetchUser: true,
    loginData: {
      url: `${process.env.VUE_APP_API_BASE_URL}/auth/sign_in`,
      method: 'POST',
    },
    logoutData: {
      url: `${process.env.VUE_APP_API_BASE_URL}/auth/sign_out`,
      method: 'DELETE',
      redirect: '/sign_in',
      makeRequest: true,
    },
    registerData: {
      url: `${process.env.VUE_APP_API_BASE_URL}/auth`,
      method: 'POST',
    },
    fetchData: {
      url: `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_VERSION}/user`,
      method: 'GET',
    },
    refreshData: {
      url: `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_VERSION}/user`,
      method: 'GET',
    },
    parseUserData(data) {
      const user = data;
      return user;
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    axios.interceptors.response.use(response => response, (error) => {
      // Do something with request error
      if (error.response.status === 403) {
        this.$router.push({
          name: 'userPage',
        });
      }
      return Promise.reject(error);
    });
  },
}).$mount('#app');
