<template>
  <div class="party">
    <div class="row" v-if="party">
      <div class="col-11">
        <h1> {{party.name.toUpperCase()}} </h1>
        <p> {{ party.party_id }} </p>
      </div>
      <div class="col-1">
        <template v-if="hasSubscription">
          <i v-b-tooltip.hover title="Dejar de recibir actualizaciones de esta empresa" class="far fa-bell-slash fa-2x"  @click="deletePartySubscription($route.params.id)"></i>
        </template>
        <template v-else>
          <i v-b-tooltip.hover title="Recibir actualizaciones de esta empresa" class="far fa-bell fa-2x"  @click="createPartySubscription({id: $route.params.id})"></i>
        </template>
      </div>
    </div>
    <div class="row" v-if="party">
      <div class="col-12">
        <template v-if="party.procurement_projects_count > 0 && party.tender_results_count > 0">
          <b-tabs class="procurement-tabs">
            <b-tab title="Licitaciones ofertadas">
              <procurementPartyContractedProjectsComponent :party="party"/>
            </b-tab>
            <b-tab title="Licitaciones ganadas">
              <procurementPartyTenderResultsComponent :party="party"/>
            </b-tab>
          </b-tabs>
        </template>
        <template v-else-if="party.procurement_projects_count > 0">
          <procurementPartyContractedProjectsComponent :party="party"/>
        </template>
        <template v-else-if="party.tender_results_count > 0">
          <procurementPartyTenderResultsComponent :party="party"/>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import procurementService from '@/_services/procurement.service';
import procurementMixin from '@/_mixins/procurement.mixin';
import procurementPartyTenderResultsComponent from './procurement_party_tender_results.vue';
import procurementPartyContractedProjectsComponent from './procurement_party_contracted_projects.vue';

export default {
  name: 'procurementPartyComponent',
  mixins: [procurementMixin],
  components: {
    procurementPartyTenderResultsComponent,
    procurementPartyContractedProjectsComponent,
  },
  data() {
    return {
      party: null,
      contractedProjectYears: [],
    };
  },
  computed: {
    ...mapState('procurementModule', ['party_subscriptions']),
    hasSubscription() {
      const id = Number.parseInt(this.$route.params.id, 10);
      return this.party_subscriptions.findIndex(el => el.party.id === id) !== -1;
    },
  },
  mounted() {
    this.getPartySubscriptions();
    this.fetchProcurementParty();
  },
  methods: {
    ...mapActions('procurementModule', {
      getPartySubscriptions: 'getPartySubscriptions',
      createPartySubscription: 'createPartySubscription',
      deletePartySubscription: 'deletePartySubscription',
    }),
    fetchProcurementParty() {
      procurementService.getProcurementParty(this.$route.params.id).then((res) => {
        Vue.set(this, 'party', res.data);
      });
    },
  },
  watch: {
    $route() {
      this.fetchProcurementParty();
    },
  },
};
</script>

<style scoped>
.party{
  padding: 30px;
}
h1{
  display:inline;
  padding-left: 5px;
}
.fa-external-link-alt {
  color:#000000;
}
.fa-external-link-alt:hover {
  cursor:pointer;
  color:#4953CF;
}
</style>
