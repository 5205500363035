<template>
<div>
  <div class="row">
    <!--<div class="col-12">
      <ShareEmailModal :documentId="currentDocumentId" :source="currentDocumentSource" ref="share-email-modal"/>
    </div>-->
      <div class="col-12 grid title">
        <div class="col-12 p-0 mt-2">
          <h4> Resaltado {{currentMatchIndex}}/{{totalMatches}}</h4>
          <a class="btn" href="#" @click="$refs.pdfViewer.scrollToMatch(0)"
            :class="totalMatches <= 1 ? 'disabled' : ''">
            <i class="fas fa-chevron-circle-left fa-2x"></i>
          </a>
           <a class="btn" href="#" @click="$refs.pdfViewer.scrollToMatch(1)"
            :class="totalMatches <= 1 ? 'disabled' : ''">
            <i class="fas fa-chevron-circle-right fa-2x" ></i>
          </a>
        </div>
        <div class="col-9-lg col-12-sm">
          <h2 class=" m-0 p-0">{{notificationTitle}}</h2>
        </div>
     </div>
     <div class="col-12 mt-0 mb-2 search-text">
        <form class="form-inline my-2 my-lg-0 mr-3" @submit.prevent="search">
          <input class="form-control mr-sm-2" type="search" placeholder="Buscar en el texto" :disabled="currentDocument === '' " aria-label="Search" v-model="searchTerms">
          <template v-if="totalSearchMatches > 0">
            {{currentSearchMatchIndex}}/{{totalSearchMatches}}
          </template>
          <a class="btn " href="#"
            :class="totalSearchMatches <= 1 ? 'disabled' : ''" @click="$refs.pdfViewer.scrollToSearchMatch(0)">
            <i class="fas fa-chevron-circle-left" ></i>
          </a>
          <a class="btn" href="#"
            :class="totalSearchMatches <= 1 ? 'disabled' : ''" @click="$refs.pdfViewer.scrollToSearchMatch(1)">
            <i class="fas fa-chevron-circle-right" ></i>
          </a>
         <button class="btn btn-secondary my-2 my-sm-0"><i class="fas fa-search mr-1"></i>Buscar</button>
        </form>
    </div>
    <div class="col-12 docviewer">
      <div class=".wrapper">
        <a class="btn ZoomInPos" href="#" @click="$refs.pdfViewer.zoomIn()">
          <i class="fa fa-search-plus fa-2x"></i>
        </a>
        <a class="btn ZoomOutPos" href="#" @click="$refs.pdfViewer.zoomOut()">
          <i class="fa fa-search-minus fa-2x" ></i >
        </a>
        <template v-if="documentLoading">
          <LoadingSpinner/>
        </template>
        <div>
          <PDFViewer ref="pdfViewer"
            @match-index="updateMatchIndex($event)"
            @search-match-index="updateSearchMatchIndex($event)"/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex';
// import ShareEmailModal from '../modals/share_email.vue';
import PDFViewer from './pdfviewer.vue';
import LoadingSpinner from '../helpers/loadingSpinner.vue';

export default {
  name: 'docviewer',
  components: {
    // ShareEmailModal,
    PDFViewer,
    LoadingSpinner,
  },
  destroyed() {
    this.$store.commit('documents/setInitialState', { root: true });
  },
  data() {
    return {
      searchTerms: '',
      currentMatchIndex: 0,
      currentSearchMatchIndex: 0,
    };
  },
  computed: {
    ...mapState('documents', [
      'currentDocument',
      'notificationTitle',
      'currentDocumentId',
      'currentDocumentSource',
      'currentHighlightTriggers',
      'currentDocumentId',
      'currentMatches']),
    ...mapState({
      documentLoading: state => state.documents.loading,
    }),

    ...mapGetters({
      totalMatches: 'documents/totalMatches',
      totalSearchMatches: 'documents/totalSearchMatches',
    }),
    highlightTriggers() {
      if (this.currentHighlightTriggers === '') return '';

      return `${JSON.stringify(this.currentHighlightTriggers)}`;
    },
  },
  methods: {
    ...mapActions('documents', {
      searchDocument: 'searchDocument',
    }),
    updateMatchIndex($event) {
      this.currentMatchIndex = $event + 1;
    },
    updateSearchMatchIndex($event) {
      this.currentSearchMatchIndex = $event + 1;
    },
    search() {
      if (this.searchTerms === '') {
        return;
      }
      this.searchDocument(this.searchTerms);
    },
  },
};

</script>

<style scoped>
 a {
   color:#6c757d !important;
 }
 a:hover{
  color: #4953CF !important;
 }
 .btn{
   box-shadow: none !important;
 }
  .title{
    text-align: left;
  }
  .title a, h4, h2{
    display:inline-block;
  }

  .search-text form{
    float: right;
  }

  .docviewer {
    width: 100%;
    height: 100vh;
  }

  .wrapper {
    position: relative;
    -webkit-transition: initial !important;
  }

  .ZoomInPos {
    position:absolute; top:10px; right: 40px;
    z-index: 2;
    color:#000000 !important;
  }
  .ZoomOutPos {
    position:absolute; top:60px; right: 40px;
    z-index: 2;
     color:#000000 !important;
  }

  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }

</style>
