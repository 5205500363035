<template>
  <div class="alerts mt-3">
    <table class="table alert-table mb-3">
      <thead class="thead-light">
        <tr>
          <th> Alerta </th>
          <th> Resaltado </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class ="mb-2"
          v-for="(alert, index) in alerts"
          v-bind:key="`alert_${index}`">
          <td>
            {{alert.name}}
          </td>
          <td>
            {{alert.filters.highlightTerms ? alert.filters.highlightTerms : alert.searchTerms}}
          </td>
          <td>
            <span  @click="fetchDocument({
                stringId: notification_string_id,
                source: notification_source,
                highlightTriggers: highlightTriggers(alert),
                mainTriggers: alert.searchTerms,
                notificationTitle: notification_title})"
            class="analyze ml-2">[<i class="fas fa-search mr-1"></i>
            Analizar]
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

import { mapActions } from 'vuex';

export default {
  name: 'alerts-table',
  props: ['alerts', 'notification_string_id', 'notification_source', 'notification_title'],
  methods: {
    ...mapActions('documents', {
      fetchDocument: 'fetchDocument',
    }),
    highlightTriggers(alert) {
      if (alert.filters && alert.filters.highlightTerms) {
        return alert.filters.highlightTerms;
      }
      return '';
    },
  },
};
</script>

<style scoped>
h4{
  display: inline-block;
}

.analyze:hover{
  color: #8F8EF8 !important;
}
.alert-table tr{
  margin:0px;
  padding: 0px;
}
.alert-table td{
  border:none !important;
  margin:0px;
  padding: 0px;
}
.alert-table th{
  border:none !important;
  margin:0px;
  padding: 0px;
  font-weight: 400;
}
.alert-table dl{
  margin:0px;
  padding: 0px;
}
.alert-table dd{
  margin:0px;
  padding: 0px;
  font-weight: 600;
  font-size: 1.2em;
}
.alert-table dt{
  margin:0px;
  padding: 0px;
  font-weight: 400;
}
.alert-table p{
  border:none !important;
  margin:0px;
  padding: 0px;
  font-weight: 600;
  font-size: 1.2em;
}
</style>
