<template>
  <div id="officialBulletinsDashboard" class="row p-0">
    <div class="col-2 side-bar pl-2 pr-0">
      <b-nav vertical tabs class="verticalNavbar">
        <b-nav-item :active="currentTab === 0" :to="{ path: '/publicaciones/buscar' }">
          <i class="fas fa-search mr-2"></i>Buscar
        </b-nav-item>
        <b-nav-item :active="currentTab === 1" :to="{ path: '/publicaciones/notificaciones' }">
           <i class="fas fa-flag-checkered mr-2"></i>Notificaciones ({{total_notifications}})
        </b-nav-item>
        <b-nav-item :active="currentTab === 2" :to="{ path: '/publicaciones/mis_publicaciones' }">
          <i class="far fa-star mr-2"></i>Publicaciones favoritas
        </b-nav-item>
        <b-nav-item :active="currentTab === 3" :to="{ path: '/publicaciones/alertas' }">
          <i class="fas fa-history mr-2"></i> Mis Alertas
        </b-nav-item>
      </b-nav>
    </div>
    <div class="col-10">
      <b-alert show variant="warning" dismissible class="ml-2 mr-2 mb-0">
        Ahora mismo no se busca en todo el histórico, ya que no está todavía disponible. La herramienta de búsqueda sirve para ayudarte a poner los términos de búsqueda
        más útiles en las alertas.
      </b-alert>
      <template v-if="currentTab === 0">
        <searchSources/>
      </template>
      <template v-else-if="currentTab === 1">
        <notificationsDashboard/>
      </template>
      <template v-else-if="currentTab === 2">
        <b-card-text>
          <h1 class="text-center pt-3">Publicaciones Oficiales favoritas</h1>
          Próximamente: Guardar publicaciones de mi interés
        </b-card-text>
      </template>
      <template v-else-if="currentTab === 3">
        <div>
          <h1 class="text-center pt-3">Mis Alertas</h1>
        </div>
        <alertsTable :alerts="officialBulletinsAlerts.alerts"
          :current_page="officialBulletinsAlerts.current_page"
          :total_pages="officialBulletinsAlerts.total_pages"
          :alert_type="'official_bulletin'"/>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import searchSources from '@/components/official_bulletins/search.vue';
import notificationsDashboard from '@/components/official_bulletins/notificationsDashboard.vue';
import alertsTable from '@/components/alerts/alerts_table.vue';

export default {
  name: 'OfficialBulletinsDashboard',
  components: {
    searchSources,
    notificationsDashboard,
    alertsTable,
  },
  data() {
    return {
      currentTab: 0,
      alert: null,
    };
  },
  computed: {
    ...mapState({
      total_notifications: state => state.officialBulletinsNotificationsModule.total_results,
      officialBulletinsAlerts: state => state.alertsModule.official_bulletin,
    }),
  },
  mounted() {
    const { tab } = this.$route.params;
    if (tab) {
      this.setCurrentTab(tab);
      this.currentTab = tab;
    } else if (this.$route.query.id && this.$route.query.alertId) {
      this.setCurrentTab(1);
    } else {
      this.checkRoute();
    }
    this.indexNotifications();
  },
  methods: {
    ...mapActions('officialBulletinsNotificationsModule', {
      indexNotifications: 'indexNotifications',
    }),
    ...mapActions('alertsModule', {
      indexAlerts: 'indexAlerts',
    }),
    setCurrentTab(tabIndex) {
      if (tabIndex === 3) {
        this.indexAlerts({ alert_type: 'official_bulletin' });
      }
      this.currentTab = tabIndex;
    },
    checkRoute() {
      if ((this.$route.path === '/publicaciones') || this.$route.path.includes('publicaciones/buscar')) {
        this.setCurrentTab(0);
      } else if (this.$route.path.includes('publicaciones/notificaciones')) {
        this.setCurrentTab(1);
      } else if (this.$route.path.includes('publicaciones/mis_publicaciones')) {
        this.setCurrentTab(2);
      } else if (this.$route.path.includes('publicaciones/alertas')) {
        this.setCurrentTab(3);
      }
    },
  },
  watch: {
    currentTab() {
      this.$store.commit('documents/setInitialState', { root: true });
    },
    $route() {
      this.checkRoute();
    },
  },
};
</script>

<style scoped>
  #officialBulletinsDashboard {
    height: 100%
  }

  .nav-link.active{
    background-color: #4953CF !important;
    color:#FFFFFF !important;
    border: none !important;
  }
  .nav-link:hover{
    color: #FFFFFF;
    background-color: #4953CF;
    border: none !important;
  }

  .nav-link {
    color: #4953CF;
    border-radius: 0.25em !important;
    border:none !important;
  }

  .verticalNavbar {
    min-height: 90vh;
    margin: 0;
    text-align: left;
    background-color: #F0F0F0 ;
  }
  .side-bar {
    background-color: #F0F0F0 ;
  }
</style>
