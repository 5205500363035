/* eslint-disable import/prefer-default-export */
/* eslint no-shadow: ["error", {"allow": ["state"]}] */
/* eslint no-param-reassign: ["error", { "props": true,
                                        "ignorePropertyModificationsFor": ["state"] }] */
import Vue from 'vue';

import userService from '../_services/user.service';

const state = {
  users: [],
  total_pages: 0,
  current_page: 1,
  total_results: 0,
  sort_by: '',
  sort_order: '',
  filter_by: '',
  filter_value: '',
  last_week_registered_users: 0,
};

const actions = {
  changePage({ dispatch }, page) {
    dispatch('indexUsers', { page });
  },
  deleteAccount() {
    // We return the promise from axios so that the view will now when the data has loaded
    return new Promise((resolve, reject) => {
      userService.deleteUser().then(() => {
        Vue.auth.logout({
          makeRequest: false,
          redirect: { name: 'sign_in' },
        });
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  indexUsers({ commit, dispatch, state }, { page = 1 } = {}) {
    userService.indexUsers({
      page,
      sort_by: state.sort_by,
      sort_order: state.sort_order,
      filter_by: state.filter_by,
      filter_value: state.filter_value,
    }).then(
      (response) => {
        const { users } = response.data;
        const {
          total_pages, current_page, total_results, last_week_registered_users,
        } = response.data.metadata;
        commit('storeUsers', {
          users, total_pages, current_page, total_results, last_week_registered_users,
        });
      },
    ).catch((error) => {
      // eslint-disable-next-line no-underscore-dangle
      state._router.push('/dashboard');
      if (error.response.status === 401) {
        dispatch('flashMessages/error', { message: 'You are not authorized to see this resource. If this is a mistake please contact system administrators.' }, { root: true });
      }
    });
  },
  setUsersSort({ commit, dispatch }, { sort_by, sort_order }) {
    commit('storeUsersSort', { sort_by, sort_order });
    dispatch('indexUsers');
  },
  filterUsers({ commit, dispatch }, { filter_by, filter_value }) {
    commit('storeUsersFilter', { filter_by, filter_value });
    dispatch('indexUsers');
  },
  storeUser({ commit }, user) {
    commit('storeUser', { user });
  },
};

const mutations = {
  storeUsers(state, {
    users, total_pages, current_page, total_results, last_week_registered_users,
  }) {
    Vue.set(state, 'users', users);
    state.total_pages = total_pages;
    state.current_page = current_page;
    state.total_results = total_results;
    state.last_week_registered_users = last_week_registered_users;
  },
  storeUser(state, { user }) {
    state.user = user;
  },
  storeUsersSort(state, { sort_by, sort_order }) {
    state.sort_by = sort_by;
    state.sort_order = sort_order;
  },
  storeUsersFilter(state, { filter_by, filter_value }) {
    state.filter_by = filter_by;
    state.filter_value = filter_value;
  },
};

const getters = {
  user() {
    return Vue.auth.user() || {};
  },
};

const usersModule = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default usersModule;
