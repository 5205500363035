<template>
  <div id="dashboard" class="row">
    <div class= "col-12 text-center pt-3">
      <h1>Notificaciones de publicaciones oficiales</h1>
    </div>
      <template v-if="notifications.length > 0">
        <div class="col-4 pt-3">
            <NotificationsPanel/>
        </div>
        <div class="col-8" >
          <DocViewer/>
        </div>
      </template>
      <div class="col-12 pt-3">
        No tienes todavía ninguna notificación!
        <router-link to="/publicaciones"> prueba a crear alguna alerta</router-link>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import NotificationsPanel from './notifications/notifications_panel.vue';
import DocViewer from '../docviewer/docviewer.vue';

export default {
  name: 'Dashboard',
  components: {
    NotificationsPanel,
    DocViewer,
  },
  computed: {
    ...mapState('officialBulletinsNotificationsModule',
      [
        'notifications',
      ]),
  },
};
</script>
