import { render, staticRenderFns } from "./procurement_project_changes_notification.vue?vue&type=template&id=6d7b2eec&scoped=true&"
import script from "./procurement_project_changes_notification.vue?vue&type=script&lang=js&"
export * from "./procurement_project_changes_notification.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d7b2eec",
  null
  
)

export default component.exports