<template>
  <div id="subscription">
    <b-overlay :show="fetchinngSubscription">
      <div class="row">
        <div class="col-12">
          <table class ="table summary text-left">
            <tr>
              <th>Estado de la cuenta</th>
              <td>
                <template v-if="subscription.status==='active'">
                  <b-alert show variant="success">
                    Suscripción activada
                  </b-alert>
                </template>
                <template v-else>
                  <b-alert show variant="warning">
                    Suscripción cancelada
                  </b-alert>
                </template>
              </td>
              <td></td>
            </tr>
            <tr v-if="!owner">
              <th>Dueño de la suscripción</th>
              <td>{{subscription.owner.email}} </td>
              <td></td>
            </tr>
              <template v-if="owner && subscription.status==='active'">
              <tr>
                <th>Licencias usadas</th>
                <td> {{subscription.used}} / {{subscription.quantity}} </td>
                <td></td>
              </tr>
              <tr>
                <th> Total {{subscription.billing_type === 'manual' ? `(${billingQuencyText})` : ''}}</th>
                <td class="subsection"> {{calculatePrice(subscription.quantity, subscription.billing_frequency)}}€ + IVA</td>
                <td></td>
              </tr>
              
                <template v-if="subscription.billing_type === 'stripe'">
                <tr>
                  <th>Próximo cargo</th>
                  <td v-if="subscription.cancel_at_period_end">Renovación automática desactivada.</td>
                  <td v-else> {{subscription.current_period_end | moment('DD/MM/YYYY')}} </td>
                  <td class="text-right">
                    <button class="btn btn-secondary mr-2" @click="updateSubscription({cancel_at_period_end: false})" v-if="subscription.cancel_at_period_end">
                      Activar renovación automática
                    </button>
                    <button class="btn btn-secondary mr-2" @click="updateSubscription({cancel_at_period_end: true})" v-else>
                      Cancelar renovación automática
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button  class="btn btn-secondary" @click="subscriptionEdit.open = !subscriptionEdit.open"> Modificar suscripción</button>
                    <div class="dropdown-menu dropdown-menu-w mg-form" @submit.prevent="" :class="showEdit(subscriptionEdit.open)">
                      <form class="d-block">
                        <div class="form-group">
                          <dl class="form-group">
                            <dt><label>Número de licencias</label></dt>
                            <dd><input placeholder="nuevo número de licencias"
                                        type="number"
                                        class="form-control input-block form-control"
                                        v-model.number="subscriptionEdit.quantity"
                                        @blur="handleSubscriptionEditBlur"
                                        @focus="handleSubscriptionEditFocus"></dd>
                            </dl>
                        </div>
                        <div class="billingSummary">
                          <div class="flex-auto">
                              <h4  class="account-title">Resumen de facturación</h4>
                            </div>
                            <div class="flex-auto">
                              <span class="subsection-title"> Número total de usuarios</span>
                              <span class="subsection">{{subscriptionEdit.quantity}}</span>
                            </div>
                            <div class="flex-auto">
                              <span class="subsection-title"> Total al mes </span>
                              <span class="subsection">{{calculatePrice(subscriptionEdit.quantity, subscription.billing_frequency)}}€</span>
                            </div>
    <!--
                                  <div class="flex-auto">
                                    <span class="subsection-title"> Próximo cargo </span>
                                    <span class="subsection">{{subscription.current_period_end | moment('DD/MM/YYYY')}}</span>
                                  </div>
                                  <div v-if="subscriptionEdit.fetchingProration || subscriptionEdit.prorrationCalculated">
                                    <div class = "proration">
                                      <div v-if="subscriptionEdit.fetchingProration">
                                        Calculando cargo prorrateado...
                                      </div>
                                      <div v-else-if="subscriptionEdit.prorrationCalculated">
                                        <div class="flex-auto">
                                          <h5  class="account-title" v-if="subscriptionEdit.proration < 0">
                                            Al reducir tu suscripción a {{subscriptionEdit.quantity}} usuarios, se te descontará el importe de los usuarios que has dado prorrateado en el siguiente pago
                                          </h5>
                                          <h5  class="account-title" v-else>Pago hoy para {{subscriptionEdit.quantity}} usuarios, prorrateado hasta el {{subscription.current_period_end | moment('DD/MM/YYYY')}}</h5>
                                        </div>
                                        <div class="flex-auto">
                                          <span class="subsection-title"> Total</span>
                                          <span class="subsection">{{subscriptionEdit.proration}}€</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
    -->
                          </div>
                          <div class="d-flex form-group" >
                            <button class="btn btn-primary mr-2" v-if="subscriptionEdit.prorrationCalculated"
                                      @click="updateSubscription({quantity: subscriptionEdit.quantity})" >
                                      Modificar usuarios
                            </button>
                                    <!-- <button class="btn btn-primary mr-2" v-else
                                      @click="getProration()" >
                                      Calcular cargo prorrateado
                                    </button> -->

                            <button class="btn btn-secondary " @click="subscriptionEdit.open = !subscriptionEdit.open" >Cancelar</button>
                        </div>
                      </form>
                    </div>
                  </td>
                </tr>
                </template>
              </template>
          </table>
        </div>
      </div>
      <div class="row" v-if="owner">
          <div class="col-12 text-left m-3">
            <span class ="add-user" @click="addUserEdit.open = !addUserEdit.open"> <i class="fa fa-user-plus"></i> Añadir usuario a la suscripción</span>
            <div class="dropdown-menu dropdown-menu-w mg-form" @submit.prevent="" :class="showEdit(addUserEdit.open)">
              <form class="d-block">
                <div class="form-group">
                  <dl class="form-group">
                    <dt><label>Email del usuario</label></dt>
                    <dd><input placeholder="email" type="text" class="form-control input-block form-control" v-model="addUserEdit.email"></dd>
                    <span v-show="addUserErrMessage !== ''" class="invalid">{{ addUserErrMessage }}</span>
                  </dl>
                </div>
                <div class="d-flex form-group" >
                  <button class="btn btn-secondary mr-2" @click="addUserToSubscription(addUserEdit.email)" > Añadir </button>
                  <button class="btn btn-secondary " @click="addUserEdit.open = !addUserEdit.open" >Cancelar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row" v-if="owner">
          <div class="col-12">
            <h5 class="account-title">Usuarios</h5>
            <table class="table subscriptionsUserTable corporative mb-3">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Email</th>
                  <th scope="col">N° Alertas</th>
                  <th scope="col">Notificaciones Pendientes</th>
                  <th scope="col">Total Notificaciones</th>
                  <th scope="col">Porcentaje Procesado</th>
                  <th scope="col">Fecha Última Notificación</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in subscription.users" v-bind:key="user.id">
                  <td>{{ user.name }} </td>
                  <td>{{ user.email }} </td>
                  <td> {{ user.total_alerts }} </td>
                  <td> {{ user.unprocessed_notifications }} </td>
                  <td> {{ user.total_notifications }} </td>
                  <td>
                    <template v-if="user.total_notifications > 0">
                    {{ (user.total_notifications - user.unprocessed_notifications) / user.total_notifications * 100 }}%
                    </template>
                    <template v-else> 0 </template>
                  </td>
                  <td>
                    <template v-if="user.last_notification_date"> {{user.last_notification_date | moment('DD/MM/YYYY')}} </template>
                  </td>
                  <td>
                    <a href="#" class="danger" @click="removeUserFromSubscription(user.id)" v-if="user.email !== subscription.owner">
                    <span class="fa-stack">
                      <i class="fa fa-square fa-stack-2x"></i>
                      <i class="fa fa-trash-o fa-stack-1x fa-inverse"></i>
                    </span>
                    </a>
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row text-left mt-3" v-if="subscription.status==='active'">
        <div class="col-12">
        <h5 class="account-title">Cancelar Suscripción</h5>
          <div class="Box Box--danger">
            <div class="Box-body">
              <p> Si ya no necesitas utilizar LegalHound, puedes cancelar la suscripción. Esto mantendrá la cuenta activa, pero dejarás de tener acceso a gran parte de la funcionalidad.&nbsp;
              </p>
              <template v-if="subscription.billing_type === 'manual'">
                <p>
                Por favor, escribe a <strong> administracion@legalhound.io </strong> y te cancelaremos la suscripción lo más pronto posible.
                </p>
              </template>
              <template v-else>
                <a class="btn btn-danger" @click="showConfirmation()" >Cancelar Suscripción</a>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-left" v-else>
        <div class="col-12 p-3 m-3">
          <h5 class="account-title"></h5>
          <b-alert show variant="warning">
            <p v-if="user.subscription.trial_days_remaining > 0">
                Todavía te quedan {{user.subscription.trial_days_remaining}} días de prueba.
                Si quieres, puedes suscribirte ya y acceder a toda la funcionalidad
            </p>
            <p v-else> La suscripción está caducada.
                Si quieres acceder a toda la funcionalidad te puedes suscribir escribiéndonos a
            <strong> contacto@legalhound.io </strong>
          </p>
          </b-alert>
        </div>
      </div>
      <confirm-dialog/>

    </b-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { calculatePrice } from '@/_helpers/math';
import subscriptionsService from '@/_services/subscriptions.service';
import ConfirmDialog from '@/components/modals/confirmSubscriptionDelete.vue';

export default {
  name: 'SubscriptionComponent',
  components: {
    'confirm-dialog': ConfirmDialog,
  },
  data() {
    return {
      fetchinngSubscription: false,
      addUserErrMessage: '',
      subscription: {
        status: '',
        trial_days_remaining: 0,
        owner: {
          email: '',
        },
      },
      addUserEdit: {
        email: '',
        open: false,
      },
      subscriptionEdit: {
        quantity: 0,
      },
    };
  },
  computed: {
    ...mapGetters('usersModule', ['user']),
    owner() {
      return this.user.email === this.subscription.owner.email;
    },
    billingQuencyText() {
      if (this.subscription.billing_frequency === 'monthly') return 'mensual';
      if (this.subscription.billing_frequency === 'yearly') return 'mensual';
      return '';
    },
  },
  mounted() {
    this.subscription.status = this.user.subscription.status;
    this.subscription.trial_days_remaining = this.user.subscription.trial_days_remaining;
    this.fetchSubscription();
  },
  methods: {
    addUserToSubscription(user_email) {
      this.addUserErrMessage = '';
      subscriptionsService.addUser(this.subscription.id, user_email).then(() => {
        this.fetchSubscription();
        this.addUserEdit.email = '';
        this.addUserEdit.open = false;
      }).catch((error) => {
        const { message } = error.response.data;
        if (message.quantity) {
          this.addUserErrMessage = 'No se puede añadir el usuario a la suscripción porque ya se están usando todas las licencias disponibles.';
        } else {
          this.addUserErrMessage = 'Se ha producido un error, si persiste, por favor contacte con contacto@legalhound.io';
        }
        console.log(message);
      });
    },
    calculatePrice,
    fetchSubscription() {
      if (!this.user.subscription.id) return;
      this.fetchinngSubscription = true;
      subscriptionsService.getSubscription(this.user.subscription.id).then((response) => {
        Vue.set(this, 'subscription', response.data);
      }).finally(() => {
        this.fetchinngSubscription = false;
      });
    },
    handleSubscriptionEditBlur() {
      // this.getProration();
    },
    handleSubscriptionEditFocus() {
      // this.subscriptionEdit.prorrationCalculated = false;
    },
    removeUserFromSubscription(user_id) {
      subscriptionsService.removeUser(this.subscription.id, user_id).then(
        () => {
          this.fetchSubscription();
        },
      );
    },
    showEdit(open) {
      if (open) {
        return 'show active';
      }
      return '';
    },
    showConfirmation() {
      this.$modal.show('deleteSubscriptionWarningBox');
    },
  },
};
</script>

<style scoped>
.summary tr, .summary th, .summary td{
  border: 0px !important;
}


</style>
