<template>
  <b-card class="card-class">
    <div class="row">
      <div class="col-12">
        <h3> Lote {{lot.lot_id}} </h3>
        <p> {{lot.summary}} </p>
        <p> {{ locationStr(lot.location) }} </p>
        <p> <strong>Importe total:</strong> {{ formatAmount(lot.total_amount) }}</p>
      </div>
      <div class="col-12" v-if="tender_result">
        <p> <strong>Razón: </strong> {{tender_result.summary}} </p>
        <p> <strong>Fecha de adjudicación:</strong> {{tender_result.award_date | moment('DD/MM/YYYY') }}</p>
        <p> <strong>Adjudicado en:</strong> {{formatAmount(tender_result.payable_amount) }} </p>
        <p> <strong>Diferencia con el importe licitado:</strong> {{ formatAmount(lot.total_amount - tender_result.payable_amount) }}

       <p v-if="tender_result.party.id"> <strong>Empresa: </strong>
          <router-link :to="{
                    name: 'procurement_party',
                    params: {
                      id: tender_result.party.id
                    }
                  }">
                  {{tender_result.party.name}}
          </router-link>
          <template v-if="partyHasSubscription(tender_result.party.id)">
            <i v-b-tooltip.hover title="Dejar de recibir actualizaciones de esta empresa" class="far fa-bell-slash"  @click="deletePartySubscription(tender_result.party.id)"></i>
          </template>
          <template v-else>
            <i v-b-tooltip.hover title="Recibir actualizaciones de esta empresa" class="far fa-bell"  @click="createPartySubscription({id: tender_result.party.id})"></i>
          </template>
        </p>
      </div>
    </div>
  </b-card>
</template>

<script>

import procurementMixin from '@/_mixins/procurement.mixin';

export default {
  name: 'procurementLotComponent',
  mixins: [procurementMixin],
  props: ['lot', 'tender_result'],
  computed: {
    hasSubscription() {
      return this.project_lot_subscriptions.findIndex(el => el.lot.id === this.lot.id) !== -1;
    },
  },
};
</script>

<style scoped>
h3{
  display:inline-block;
  margin-left: 5px;
}
.card-class{
  height: 400px;
  overflow: auto;

}
</style>
