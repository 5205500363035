<template>
  <div>
    <h1 class="text-center pt-3 mb-0">Licitaciones favoritas</h1>
    <p class ="mt-0">
      Sigues {{project_subscriptions.length}} licitaciones
    </p>
    <paginationComponent
      id="procurement-project-subscriptions-pagination"
      :currentPage="current_page"
      :totalPages="total_pages"
      v-on:next-page-procurement-project-subscriptions-pagination="changePage($event)"/>
    <table class="table text-left">
      <thead class="corporative">
        <tr>
          <th scope="col"> Fecha Publicación </th>
          <th scope="col"> Título </th>
          <th scope="col"> Fecha límite presentación </th>
          <th scope="col"> Estado </th>
          <th scope="col"> Lugar </th>
          <th scope="col"> Valor estimado</th>
          <th scope="col"> Tipo de contrato </th>
          <th scope="col"> Órgano o Entidad </th>
          <th scope="col"> Por Lotes </th>
          <th scope="col"> Fecha fin</th>
          <th scope="col"> Frecuencia Emails </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(subscription, index) in advancedSubscriptionsInfo" v-bind:key="subscription.project.id">
          <td>{{ date(subscription.project.date)}} </td>
          <router-link :to="{
                name: 'procurement',
                params: {
                  comingFrom: '/licitaciones',
                  id: subscription.project.id
                }
              }"
          >
          <td v-b-tooltip.hover title="Abrir licitación"> {{ subscription.project.title }} </td>
          </router-link>
          <td>{{ date(subscription.project.submission_deadline)}} </td>
          <td>{{ subscription.project.status }}</td>
          <td>{{ locationStr(subscription.project.location) }}</td>
          <td>{{ subscription.project.estimated_overall_amount}} </td>
          <td>{{ subscription.project.contract_type}} </td>
          <td v-b-tooltip.hover title="Abrir órgano o entidad">
            <router-link :to="{
              name: 'procurement_party',
              params: {
                id: subscription.project.contracting_party.id
              }
            }">
            {{ subscription.project.contracting_party.name }}
            </router-link>
          </td>
          <td> {{subscription.project.has_lots ? 'Sí' : 'No'}} </td>
          <td> {{ date(projectEndDate(subscription.project)) }} </td>
          <td> 
            <template v-if="editingSubscriptionId === subscription.id">
              <b-form-select v-model="edit_subscription_mailing_frequency"
                :options="mailing_frequency_options"></b-form-select>
            </template>
            <template v-else>
              {{ mailing_frequency(subscription.mailing_frequency) }}
            </template>
          </td>
          <td style="min-width:90px">
            <template v-if="editingSubscriptionId === subscription.id">
              <i v-b-tooltip.hover title="Guardar cambios" class="fas fa-save fa-2x mr-2"
                @click="updateSubscription(subscription)"></i>
              <i v-b-tooltip.hover title="Cerrar sin guardar" class="fas fa-times fa-2x ml-2"
                @click="editingSubscriptionId=''"></i>
            </template>
            <template v-else>
              <i v-b-tooltip.hover title="Editar suscripción" class="fas fa-edit fa-2x"
                @click="setEditSubscription(subscription)"></i>
              <i v-b-tooltip.hover title="Dejar de seguir" class ="fas fa-trash-alt fa-2x ml-2"
                @click="deleteProjectSubscription(subscription.project.id)"></i>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

import Vue from 'vue';
import procurementService from '@/_services/procurement.service';
import { mapState, mapActions } from 'vuex';
import procurementMixin from '@/_mixins/procurement.mixin';

import paginationComponent from '@/components/helpers/pagination.vue';
import resultsTable from './results_table.vue';

export default {
  name: 'procurementProjectSubscriptionsTable',
  mixins: [procurementMixin],
  components: {
    resultsTable,
    paginationComponent,
  },
  data() {
    return {
      pageSize: 25,
      current_page: 1,
      advancedSubscriptionsInfo: [],
      editingSubscriptionId: null,
      edit_subscription_mailing_frequency: '',
      mailing_frequency_options: [
        { value: 'daily', text: 'Diario' },
        { value: 'weekly', text: 'Semanal' },
        { value: 'no_email', text: 'Sin email' },
      ],
    };
  },
  computed: {
    ...mapState('procurementModule', ['project_subscriptions']),
    paginatedSubscriptionProjects() {
      const start = (this.current_page - 1) * this.pageSize;
      return this.project_subscriptions.slice(
        start,
        start + this.pageSize,
      );
    },
    total_pages() {
      return Math.ceil(this.project_subscriptions.length / this.pageSize);
    },
  },
  mounted() {
    this.getProjectSubscriptions();
  },
  methods: {
    ...mapActions('procurementModule', {
      getProjectSubscriptions: 'getProjectSubscriptions',
      deleteProjectSubscription: 'deleteProjectSubscription',
    }),
    changePage(page) {
      this.current_page = page;
    },
    setEditSubscription(subscription) {
      this.editingSubscriptionId = subscription.id;
      this.edit_subscription_mailing_frequency = subscription.mailing_frequency;
    },
    updateSubscription(subscription) {
      procurementService.updateProcurementProjectSubscription(subscription.project.id, {
        mailing_frequency: this.edit_subscription_mailing_frequency,
      }).then(() => {
        this.getProjectSubscriptions();
        this.editingSubscriptionId = '';
      });
    },

  },
  watch: {
    paginatedSubscriptionProjects() {

      console.log(this.paginatedSubscriptionProjects)
      const ids = this.paginatedSubscriptionProjects.map((p) => { 
        return p.id
      })

      this.fetchingSubscriptionInfo = true;
      procurementService.getProcurementProjectSubscriptions({ mode: 'core', ids: ids}).then((res) => {
        const { project_subscriptions } = res.data;
        console.log(res.data)
        console.log(project_subscriptions)
        Vue.set(this, 'advancedSubscriptionsInfo', project_subscriptions);
        console.log(this.advancedSubscriptionsInfo)

      }).finally(() => {
        this.fetchingSubscriptionInfo = false;
      })
    }
  }
};
</script>

<style scoped>
.fa-save{
  color: #4953CF;
  cursor:pointer;
}
.fa-save:hover{
  color:#8F8EF8;
}

</style>
