import Vue from 'vue';
import Vuex from 'vuex';

import alertsModule from './alerts.module';
import documents from './documents.module';
import flashMessages from './flashMessages.module';
import officialBulletinsNotificationsModule from './official_bulletins_notifications.module';
import officialBulletinsModule from './official_bulletins.module';
import procurementModule from './procurement.module';
import procurementNotificationsModule from './procurement_notifications.module';
import subscriptionsModule from './subscriptions.module';
import tags from './tags.module';
import usersModule from './users.module';

Vue.use(Vuex);

// eslint-disable-next-line import/prefer-default-export
const store = new Vuex.Store({
  modules: {
    alertsModule,
    documents,
    flashMessages,
    officialBulletinsNotificationsModule,
    officialBulletinsModule,
    procurementModule,
    procurementNotificationsModule,
    subscriptionsModule,
    tags,
    usersModule,
  },
});

export default store;
