<template>
  <div>
    <div class="container text-left">
      <div class="row intro">
        <div class="col-6 pl-3">
          <img src="@/assets/LH_Nombre_Morado.png" width="200px"/>
          <h2>Somos el primer <strong>buscador</strong> simultáneo y monitorizador <strong>inteligente</strong> de información de las administraciones públicas en tiempo real</h2>
        </div>
      <div class="col-6 text-center">
          <img src="@/assets/logo-frontal.png" height="170px"/>
      </div>
    </div>
    <div class="row features">
      <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="p-3 mx-2 my-3 card-corporative">
          <i class="fas fa-search fa-3x"></i>
          <h4>BUSCA</h4>
          <p>Haz una búsqueda simultánea de lo que te interesa en todos los boletines oficiales de las administraciones públicas y encuentra de una vez por todas lo que necesitas: <strong>licitaciones públicas</strong>, <strong>concursos</strong>, textos legales, ordenanzas municipales.</p>
          <p>¡Si está en un boletín , lo encontramos  para ti!</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-xs-12">
          <div class="p-3 my-3 mx-2 card-corporative">
            <i class="far fa-eye fa-3x"></i><h4> MONITORIZA</h4>
            <p>Recibe <strong>alertas</strong> precisas, personalizadas e inmediatas  en tu correo electrónico facilitándote el seguimiento de licitaciones y temas legales de tu interés</p>
             <p>¡Te lo ponemos en bandeja!</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-xs-12">
          <div class="p-3 mx-2 my-3 card-corporative">
            <i class="fas fa-chart-bar fa-3x"></i>
            <h4>ANALIZA</h4>
            <p>Procesamos la información que nos pides para que puedas analizar los resultados fácilmente y, sobre todo, ¡más rápido!</p>
            <p>¡Ahorra tiempo y <strong>adelántate a tus competidores</strong>!</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 entities mb-3 p-3">
        <h4>Ya confían en nosotros:</h4>
        <a href="http://www.sgae.es/es-ES/SitePages/index.aspx" target="_blank"><img class="entity m-3" src="@/assets/images/entities/sgae.png"/></a>
        <a href="http://difemsa.com/" target="_blank"><img class="entity m-3" src="@/assets/images/entities/difemsa.png"/></a>
        <a href="https://lic-sl.com/" target="_blank"><img class="entity m-3" src="@/assets/images/entities/LIC.png"/></a>
        <a href="https://orsal.com/" target="_blank"><img class="entity m-3" src="@/assets/images/entities/orsal.png"/></a>
        <a href="http://rubiodecasas.com/ " target="_blank"><img class="entity m-3" src="@/assets/images/entities/rubio-de-casas.png"/></a>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 my-3">
        <h1 class="pb-3">Equipo de Legal Hound</h1>
        </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 col-xs-12 text-center mb-3">
            <img class="team-img my-2" alt="Maria Gracia" src="@/assets/images/team/maria-rubio.jpg"/>
            <p class="my-0"><strong>María Gracia Rubio de Casas</strong></p>
            <p class="my-0">Cofundadora</p>
            <a href="https://www.linkedin.com/in/mariagraciardc/" target="_blank"><img src="@/assets/images/linkedin.png"/></a>
          </div>
          <div class="col-lg-3 col-md-6 col-xs-12 text-center mb-3">
            <img class="team-img my-2" alt="Jaime Perez" src="@/assets/images/team/jaime-perez.jpg"/>
            <p class="my-0"><strong>Jaime Perez</strong></p>
            <p class="my-0">Cofundador y COO</p>
            <a href="https://www.linkedin.com/in/jaimeperezvilloslada/" target="_blank"><img src="@/assets/images/linkedin.png"/></a>
          </div>
          <div class="col-lg-3 col-md-6 col-xs-12 text-center mb-3">
            <img class="team-img my-2" alt="Vicente Cuellar" src="@/assets/images/team/vicente-cuellar.jpg"/>
            <p class="my-0"><strong>Vicente Cuellar</strong></p>
            <p class="my-0">Cofundador</p>
            <a href="https://www.linkedin.com/in/vicente-cuellar-2293292/" target="_blank"><img src="@/assets/images/linkedin.png"/></a>
          </div>
          <div class="col-lg-3 col-md-6 col-xs-12 text-center mb-3">
            <img class="team-img my-2" alt="Guillermo Molini" src="@/assets/images/team/guillermo-molini.jpg"/>
            <p class="my-0"><strong>Guillermo Moliní</strong></p>
            <p class="my-0">Cofundador y CTO</p>
            <a href="https://www.linkedin.com/in/guillermo-molin%C3%AD-8b8b4941//" target="_blank"><img src="@/assets/images/linkedin.png"/></a>
          </div>
        </div>
        <h1 class="py-3 mt-3">Descubre todo lo que Legal Hound puede hacer por ti</h1>
        <div class="row contact">          
          <div class="col-6">
            <img alt="LegalHound logo" src="@/assets/logo.png" height="100px"/>
          </div>
          <div class="col-6 text-left">
            <p>¿Quieres probar <strong>Legal Hound</strong>?</p>
            <p> <router-link to="/sign_up">{{ $t('signUp') }}</router-link> y prueba la aplicación durante 15 días totalmente gratis.</p>
            <p>Si tienes alguna duda puedes contactar con nosotros en <a href="mailto:contacto@legalhound.io">contacto@legalhound.io</a></p>
            <p>¡Te esperamos!</p>
          </div>
        </div>
      </div>  
    </div>
</template>

<script>

export default {
  name: 'CorporativeComponent',
};

</script>

<style scoped>
.intro{
  min-height: 280px;
  display: flex;
  align-items: center;
}
.features{
  min-height: 350px;
}
.card-corporative{
  background-color:#EDEFFE;
  height: 280px;
}
.entities{
  background-color: #EFEFEF;
  min-height: 120px;
  color: #4d4d4d;
}
.entity{
  display: inline-block;
  height: 35px;
}
table, tr, td{
  border: none !important;
}
.team-img{
  height: 150px;
}
.contact{
  display: flex;
  align-items:center;
}
.linkedin:hover{
  opacity: 0.6;
}
</style>
