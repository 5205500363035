<template>
  <modal name="deleteSubscriptionWarningBox" class="warningBox" :height="'350px'">
    <div slot="top-right">
    </div>
    <div class="grid">
      <div class="col-12">
        <div class="warningIcon"> 
          <i class="fa fa-exclamation-circle fa-5x"></i>
        </div>
      </div>
      <div class="col-12">
        <div class="Box Box--danger">
          <div class="Box-body">
            <div class="input">
              <label for="confirmText">Por favor, escribe "confirmar" para cancelar la suscripción</label>
              <input type="text" 
              id="confirmText" 
              placeholder="confirmar" 
              name="confirmText" v-model="confirmText" 
              v-validate ="'required|confirm2'"  >
              <span v-show="errors.has('confirmText')" class="invalid">{{ errors.first('confirmText') }}</span>
            </div>
              <a class="btn btn-danger" @click="submitDeleteSubscription()" >Cancelar Subcripción</a>
              <a class="btn btn-success" @click="$modal.hide('deleteSubscriptionWarningBox')" >Cerrar</a>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { ValidationProvider, Validator } from 'vee-validate';
  import { mapActions } from 'vuex'

export default {
  name: 'deleteSubscriptionWarningBox',
  components: {
    ValidationProvider
  },
  props: {
    message: String,
    buttonText: String,
  },
  data() {
    return {
      confirmText: ''
    }
  },
  created: function() {
    let self = this

    this.$validator.extend('confirm2', {
      getMessage(field, val) {
        return 'Debes introducir la palabra confirmar para poder cancelar la suscripción'; 
      },
      validate(value, field) {
        return self.confirmText === 'confirmar';                
      }
    });
  },
  methods: {
    ...mapActions("subscriptionsModule", {
      deleteSubscription: "deleteSubscription",
    }),
    submitDeleteSubscription(){
      this.$validator.validateAll().then(result => {
        if (result) {
          this.deleteSubscription();
          this.$modal.hide('deleteSubscriptionWarningBox')
          return;
        }
      });
    },
  }
}
</script>
<style lang="scss">

.warningBox a {
  margin-left: 10px !important;
}

.warningIcon {
  font-size: 24px; 
  text-align: center;
  margin-top: 30px;
  color: #FACEA8;
}

</style> 